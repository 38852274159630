import { Box, FormControlLabel, FormHelperText, RadioGroup, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../common/components/base/button/Button';
import CheckRadio from '../../../common/components/base/checkradio/CheckRadio';
import Text from '../../../common/components/base/text/Text';
import { postUploadLoadFiles } from '../thunk';
import UploadIcon from '@mui/icons-material/Upload';
import InputWrapper from '../../../common/components/base/inputWrapper/inputWrapper';
import Loader from '../../../common/components/base/loader/Loader';
import ReduxStatus from '../../../common/constants/ReduxStatus';

const typeOptions = [
    {
        name: 'Card Number and Load Amount',
        id: '1',
        disabled: false,
    },
    {
        name: 'Sequence Number and Load Amount',
        id: '2',
        disabled: false,
    },
    {
        name: 'Tracking Number and Load Amount',
        id: '3',
        disabled: false,
    },
    // {
    //     name: 'ID Number and Load Amount (*)',
    //     id: '4',
    //     disabled: true,
    // },
];

const InputFile = styled('input')({
    display: 'none',
});

const NewBatchLoadStep1 = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const status = useSelector((state) => state.batchLoad.status);
    const { CLIENTBULKUPLOADUUID, CLIENTTYPEOPTION } = useSelector((state) => {
        return state.batchLoad.data;
    });

    const [stateFormValues, setStateFormValues] = useState({
        file: null,
        typeOption: CLIENTTYPEOPTION,
        clientBulkUploadUUID: CLIENTBULKUPLOADUUID
    });

    const [errors, setErrors] = useState({
        fileError: '',
        radioError: false,
        typeOptionError: '',
    });

    const handleOnChangeInput = (e) => {
        const isMimeTypeValid = () => {
            return [
                'application/vnd.ms-excel',
                'text/csv',
                'application/excel'
            ].indexOf(e.target.files[0].type) > -1;
        };

        if (isMimeTypeValid()) {
            setErrors({ ...errors, fileError: '' });
            setStateFormValues({
                ...stateFormValues,
                file: e.target.files[0],
            });
        } else {
            setErrors({ ...errors, fileError: 'Please use only a file in csv or excel format.' });
        }
    };

    const handleOnChangeRadio = (e) => {
        setErrors({ ...errors, typeOptionError: '' });
        setStateFormValues({
            ...stateFormValues,
            typeOption: e.target.value,
        });
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        let errors = { fileError: '', radioError: false, typeOptionError: '' };
        let errorCount = 0;
        if (!stateFormValues.file) {
            errors = { ...errors, fileError: 'Please upload a file in csv or excel format.' };
            errorCount++;
        }

        if (!stateFormValues.typeOption) {
            errors = { ...errors, typeOptionError: 'Please choose a type.', radioError: true };
            errorCount++;
        }
        setErrors(errors);

        if (errorCount === 0) {
            const payload = {
                UPLOADEDFILE: stateFormValues.file,
                TYPEOPTION: stateFormValues.typeOption,
                CLIENTBULKUPLOADUUID: stateFormValues.clientBulkUploadUUID,
            };
            dispatch(postUploadLoadFiles(payload))
                .unwrap()
                .then(({ CLIENTBULKUPLOADUUID }) => {
                    navigate({
                        pathname: '../step2',
                        search: '?uuid=' + CLIENTBULKUPLOADUUID,
                    });
                }).catch((err) => {
                    setErrors({ ...errors, fileError: err?.response?.data });
                });
        }
    };

    return (
        <>
            {status === ReduxStatus.loading && <Loader open={true} />}
            {status === ReduxStatus.loaded && 
            <form noValidate autoComplete='off' onSubmit={handleOnSubmit}>
                <Typography
                    variant="h6"
                    sx={{ color: "#3c4b6c", fontSize: 20, fontWeight: 400 }}
                    marginBottom={'20px'}
                >
                    Upload the file containing your data.
                </Typography>

                <InputWrapper inputLabel={'ACCEPT .CSV OR .XLS FILES, MAX 200 ROWS'}>
                    <label htmlFor='contained-button-file'>
                        <InputFile
                            accept='text/csv,application/vnd.ms-excel'
                            id='contained-button-file'
                            type='file'
                            onChange={handleOnChangeInput}
                        />
                        <Button variant="contained" component="span" size="small">
                            <UploadIcon /> Choose file
                        </Button>
                        <Box sx={{ display: 'inline-block', marginLeft: '15px' }}>{stateFormValues.file?.name}</Box>
                    </label>
                    <FormHelperText error>{errors.fileError}</FormHelperText>
                </InputWrapper>

                <InputWrapper inputLabel={'FILE TYPE CRITERIA'}>
                    <RadioGroup row onChange={handleOnChangeRadio} name='typeOption'>
                        {typeOptions.map((typeOption) => (
                            <FormControlLabel
                                key={typeOption.id + '-type-option'}
                                value={typeOption.id}
                                control={
                                    <CheckRadio
                                        color='secondary'
                                        checked={stateFormValues?.typeOption.toString() === typeOption.id.toString()}
                                    />
                                }
                                label={<Text>{typeOption.name}</Text>}
                            />
                        ))}
                    </RadioGroup>
                    <FormHelperText error>{errors.typeOptionError}</FormHelperText>
                </InputWrapper>

                <Button type='submit' size='medium' variant='contained' styles={{ margin: '40px 20px 0px 0px' }}>
                    Next
                </Button>
            </form>}
        </>
    );
};

export default NewBatchLoadStep1;
