import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    TablePagination,
    TableFooter,
    TableSortLabel,
    Box
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Checkbox from "../checkbox/checkbox";
import CheckRadio from "../checkradio/CheckRadio";
import Input from "../input/Input";
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRadio, setSelectedCheckbox } from "./slice";
import Button from '../button/Button';
import { visuallyHidden } from '@mui/utils';

const useStyles = makeStyles({
    table: {
        fontSize: '30px !important',
    },
    Header: {
        fontWeight: '400 !important',
        color: '#848D9E !important',
        textTransform: 'uppercase',
        fontSize: '14px !important'
    },
    tBody: {
        fontWeight: '400 !important',
        fontSize: '16px !important'
    },
    rowLine: {
        borderBottom: '2px solid rgba(224, 224, 224, 1) !important'
    },
    rowLineWithError: {
        borderTop: '2px solid rgba(224, 224, 224, 1) !important',
    },
    spacer: {
        flex: 'none !important'
    },
    displayedRows: {
        marginRight: 'auto !important'
    }
})

function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <Button
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                size="small"
                variant="outlined"
                styles={{ marginRight: '10px' }}
            >
                PREVIOUS
            </Button>
            <Button
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                size="small"
                variant="contained"
            >
                NEXT
            </Button>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function DataTable(props) {
    const { columns, rows, extraRows, onChange, noDataFoundMessage, metaData, handlePagination, sortingOrder, sortingOrderBy, onRequestSort } = props;
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [isPageSorted, setIsPageSorted] = useState(false);
    const [inputValues, setInputValues] = useState({
        key: 0,
        value: ''
    });

    const changedValue = useSelector(state => state.dataTable.changedValue);

    const dispatch = useDispatch();

    const handleChange = (event, row, type) => {
        if (type === "checkbox") {
            let [...selectedCheckbox] = changedValue.checkbox;
            if (event.target.checked) {
                let isAlreadyExist = selectedCheckbox.some(item => item.ID === row.ID);
                if (!isAlreadyExist)
                    selectedCheckbox.push(row);
            }
            else {
                let index = selectedCheckbox.findIndex(item => item.ID === row.ID);
                selectedCheckbox.splice(index, 1);
            }
            dispatch(setSelectedCheckbox(selectedCheckbox));
            onChange(event, selectedCheckbox, type);
        }
        if (type === "radio") {
            dispatch(setSelectedRadio(row));
            onChange(event, row, type);
        }
        if (type === "input") {
            setInputValues({ key: row.ID, value: event.target.value });
            onChange(event, row, type);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setIsPageSorted(false);
    };

    useEffect(() => {
        dispatch(setSelectedCheckbox([]));
        dispatch(setSelectedRadio({}));
        setInputValues({ key: 0, value: '' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!(_.isEmpty(metaData)) && !isPageSorted) {
            let offset = (page * 10);
            handlePagination(offset, 10);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, isPageSorted])

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    const renderTableCell = (row, body) => {
        let type = body.type;
        let key = body.key;
        let defaultInputValue = row[key] !== 0 ? row[key] : "";
        switch (type) {
            case "checkbox":
                let checked = changedValue.checkbox.some(item => item.ID === row.ID);
                return <Checkbox color="secondary" checked={checked} onChange={(event) => handleChange(event, row, type)} />;
            case "radio":
                return <CheckRadio color="secondary" checked={row.ID === changedValue.radio.ID} onChange={(event) => handleChange(event, row, type)} />;
            case "input":
                return <Input id={`${row.ID}-data-table-input`} type="number" size="small" sx={{ width: '120px', marginTop: '-20px' }} error={!row.isInputValid} value={inputValues.key === row.ID ? inputValues.value : defaultInputValue} onChange={(event) => handleChange(event, row, type)} InputProps={{ inputProps: { min: 0 } }} onKeyPress={preventMinus} />;
            default:
                return row[key];
        }
    }

    const createSortHandler = (property) => (_event) => {
        setPage(0);
        setIsPageSorted(true);
        onRequestSort(property);
    };

    return (
        <TableContainer>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                className={classes.Header}
                                key={column.name}
                                style={{ minWidth: column.width }}
                            >
                                {column.sortingKey && column.sortingKey !== "select" && sortingOrderBy && <TableSortLabel
                                    active={sortingOrderBy === column.sortingKey}
                                    direction={sortingOrderBy === column.sortingKey ? sortingOrder : 'asc'}
                                    onClick={createSortHandler(column.sortingKey)}
                                >
                                    {column.name}
                                    {sortingOrderBy === column.sortingKey &&
                                        <Box component="span" sx={visuallyHidden}>
                                            {sortingOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>}
                                </TableSortLabel>}
                                {column.sortingKey && column.sortingKey === "select" && column.name}
                                {column.sortingKey === undefined && column.name}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!_.isEmpty(noDataFoundMessage) && <TableRow className={classes.rowLine}>
                        <TableCell key={"table_cell_no_data"} className={classes.tBody}>{noDataFoundMessage}</TableCell>
                    </TableRow>}
                    {rows.map((row) => (
                        <React.Fragment>
                            <TableRow key={"table_row_" + row.ID} sx={{ "& td": { borderBottom: row.error? 0 : undefined }}} className={row.error?classes.rowLineWithError:classes.rowLine}>
                                {columns.map((body) => (
                                    <TableCell className={classes.tBody}>{renderTableCell(row, body)}</TableCell>
                                ))}
                            </TableRow>
                            {
                                row.error &&
                                <TableRow className={classes.rowLine} sx={{ "& td": { borderTop: 0 } }}>
                                    <TableCell colSpan={Object.keys(row).length}>
                                        <small data-testid={`${row.ID}-dataRowError`} style={{color: "red"}}>{row.error}</small>
                                    </TableCell>
                                </TableRow>
                            }
                        </React.Fragment>
                    ))}
                    {extraRows && extraRows.map((row, rowIndex) => (
                        <TableRow key={"table_row_" + rowIndex} className={classes.rowLine}>
                            {columns.map((body, cellIndex) => (
                                <TableCell key={"table_cell_" + cellIndex} className={classes.tBody}>{row[body.key]}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
                {!(_.isEmpty(metaData)) && <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            count={metaData.TOTAL}
                            rowsPerPage={10}
                            page={page}
                            onPageChange={handleChangePage}
                            ActionsComponent={TablePaginationActions}
                            classes={{ spacer: classes.spacer, displayedRows: classes.displayedRows }}
                        />
                    </TableRow>
                </TableFooter>}
            </Table>
        </TableContainer>
    );
}
