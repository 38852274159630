const themeConstants = {
    BRAND_COLOR_PRIMARY: '#0033a1',
    BRAND_COLOR_SECONDARY: '#0089ff',
    BACKGROUND_COLOR: '#f1f1f1',
    PRIMARY_LIGHT: '#3c4b6c',
    SECONDARY_LIGHT: '#FFFFFF',
    SECONDARY_DARK: '#0a2240',
    PRIMARY_DARK: '#0a2240',
    GREY: '#858d9d',
    LIGHTGREY: '#DCDCDC',
    ORANGE_LIGHT: '#FDA33B',
    ORANGE_DARK: '#FB4206',
    TITLE_COLOR: '#858d9d',
    BLACK: '#000000',
    HEADER:'#33e8e0'
};

export default themeConstants;
