import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    changedValue: {
        radio: {},
        checkbox: []
    }
};

export const dataTableSlice = createSlice({
    name: 'dataTable',
    initialState,
    reducers: {
        setSelectedRadio: (state, action) => {
            state.changedValue.radio = action.payload;
        },
        setSelectedCheckbox: (state, action) => {
            state.changedValue.checkbox = action.payload;
        }
    }
});

export const {
    setSelectedRadio,
    setSelectedCheckbox
} = dataTableSlice.actions;

export default dataTableSlice.reducer;
