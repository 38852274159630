import React from "react";
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import Text from "../../../../common/components/base/text";


export default function CardStatementDocument(){
    const {pdf} = useSelector(state => state.cardStatement);
    return (
        <Box sx={{ mt: 8}}>
            {
                pdf.data?
                    <embed
                        src={pdf.data}
                        id='cardStatementDocument'
                        alt='Card Statement'
                        title='Card Statement'
                        width='100%'
                        height='600px'
                        style={{ borderStyle: 'solid', borderWidth: '2px' }}
                        type='application/pdf'
                    />
                    :
                    <Text light={true} sx={{textAlign: "center"}}>
                        No PDF data available
                    </Text>
            }
        </Box>
    )
}
