import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTransactions } from './service';
import {Buffer} from "buffer";
import {downloadData} from "../../profileHistory/common/utils";

export const searchTransactions = createAsyncThunk(
    'donwloadCardTransactions/search_transactions',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getTransactions(params);
            const docInBase64 = new Buffer.from(response.data).toString('base64');
            const data = 'data:text/csv;base64,' + docInBase64;
            downloadData(data, 'CardTransactions.csv');
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
