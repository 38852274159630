import { createAsyncThunk } from '@reduxjs/toolkit';
import { conditionList } from './service';

export const getConditions = createAsyncThunk(
    'conditions',
    async (params, { rejectWithValue }) => {
        try {
            const response = await conditionList();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
