import { createSlice } from "@reduxjs/toolkit";
import ReduxStatus from "../../common/constants/ReduxStatus";
import reducers, { extraReducers } from "./reducers";

export const initialState = {
    cards: {
        data: [],
        status: ReduxStatus.empty,
        metadata: {
            OFFSET: 0,
            LIMIT: 10,
            TOTAL: 0,
        },
        filter: {
            searchField: 'cardNumber',
            searchString: '',
        },
        errorMessages: [],
        errorMessageShown: false
    },
    statement: {
        data: null,
        status: ReduxStatus.empty,
        period: 30,
        errorMessages: []
    },
    view: "list",
    pdf: {
        data: null,
        status: ReduxStatus.empty,
        errorMessage: [],
    },
    docDownload: {
        data: null,
        status: ReduxStatus.empty,
        errorMessages: [],
        errorMessageShown: false
    }
}

export const cardStatementSlice = createSlice({
    name: "cardStatement",
    initialState,
    reducers,
    extraReducers,
});

export const cardStatementActions = cardStatementSlice.actions;

export default cardStatementSlice.reducer;
