import React from 'react';

import { Grid, Box } from '@mui/material';
import Button from '../../../../common/components/base/button/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reset } from '../../redux/slice';

function Invoice({ pdf }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleFinnish = () => {
        dispatch(reset());
        navigate('/dashboard');
    };

    const handleDepositFunds = () => {
        dispatch(reset());
        navigate('/dashboard/deposit-funds');
    };

    const handleViewStatement = () => {
        dispatch(reset());
        navigate('/dashboard/card-statement');
    };

    return (
        <>
            <Grid item xs={12}>
                <Box sx={{mt: 11}}>
                    <embed
                        src={pdf}
                        id='displayFile'
                        alt='your image'
                        width='100%'
                        height='800px'
                        style={{ borderStyle: 'solid', borderWidth: '2px' }}
                        type='application/pdf'
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Button
                    size='medium'
                    variant='contained'
                    styles={{ margin: '40px 20px 0px 0px' }}
                    onClick={handleFinnish}
                >
                    I'm finished
                </Button>
                <Button
                    variant='outlined'
                    size='medium'
                    styles={{ margin: '40px 20px 0px 0px' }}
                    onClick={handleDepositFunds}
                >
                    Deposit funds
                </Button>
                <Button
                    variant='outlined'
                    size='medium'
                    styles={{ margin: '40px 20px 0px 0px' }}
                    onClick={handleViewStatement}
                >
                    View statement
                </Button>
            </Grid>
        </>
    );
}

export default Invoice;
