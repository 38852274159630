import React from 'react';
import {
    RadioGroup,
    FormControlLabel,
    Box,
    FormGroup
} from "@mui/material";
import Text from '../../../common/components/base/text/Text';
import CheckRadio from '../../../common/components/base/checkradio/CheckRadio';
import Checkbox from '../../../common/components/base/checkbox/checkbox';
import SearchBar from '../../../common/components/base/search/Search';
import { useSelector, useDispatch } from 'react-redux';
import { changeCardLoadFromFilter } from '../slice';
import { searchCardLoadFrom } from '../thunk';

const filterTopics = [
    {
        name: "Card Number",
        id: "cardNumber"
    },
    {
        name: "Card Holder",
        id: "cardHolder"
    },
    {
        name: "ID/Passport Number",
        id: "idNumber"
    },
    {
        name: "Card Expiry Date",
        id: "accountCardExpiryDate"
    },
    {
        name: "Reference Number",
        id: "accountCardReference"
    }
]

export default function LoadFundsFromSearchFilter() {
    const filter = useSelector(state => state.loadFunds.cardLoadsFrom.filter);

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "searchField")
            dispatch(changeCardLoadFromFilter({ searchField: value, orderByField: value }));
        if (name === "searchString")
            dispatch(changeCardLoadFromFilter({ searchString: value }));
    }

    const handleExcludeChange = (event) => {
        const { name, checked } = event.target;
        dispatch(changeCardLoadFromFilter({ [name]: checked }))
    }

    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(searchCardLoadFrom({ ...filter }));
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Text light>
                FIND
            </Text>
            <Box sx={{ mt: 1 }}>
                <SearchBar
                    onSubmit={onSubmit}
                    onChange={handleChange}
                    name="searchString"
                    value={filter.query}
                    placeholder="Enter your search here" />
                <Box sx={{ pt: 2 }}>
                    <Text light>(LEAVE BLANK TO SHOW ALL)</Text>
                </Box>
                <hr />
                <Box sx={{ display: "flex", mt: 3 }}>
                    <Box>
                        <Text light>SEARCH BY</Text>
                        <RadioGroup
                            row
                            onChange={handleChange}
                            name="searchField"
                        >
                            {
                                filterTopics.map((topic) => (
                                    <FormControlLabel
                                        key={topic.id + '-filter-topic'}
                                        value={topic.id}
                                        control={<CheckRadio color="secondary"
                                            checked={filter?.searchField === topic.id} />}
                                        label={topic.name}
                                    />
                                ))
                            }

                        </RadioGroup>
                    </Box>
                    <Box ml={12}>
                        <Text light>EXCLUDE</Text>
                        <FormGroup row>
                            <FormControlLabel
                                name="excludeExpired"
                                onChange={handleExcludeChange}
                                control={<Checkbox color="secondary"
                                    checked={Boolean(filter.excludeExpired)} />}
                                label="Expired Cards"
                            />
                            <FormControlLabel
                                name="excludeStopped"
                                onChange={handleExcludeChange}
                                control={<Checkbox color="secondary"
                                    checked={Boolean(filter.excludeStopped)} />}
                                label="Stopped Cards" />
                        </FormGroup>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
