import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getUser,
    getCardOrder,
    postCardOrder,
    postConfirmedCardOrder,
    getCardOrderInvoice,
    getBankBranches,
} from './service';
import { Buffer } from 'buffer';

export const fetchUser = createAsyncThunk(
    'cardOrder/fetch_user',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getUser();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

export const fetchCardOrder = createAsyncThunk(
    'cardOrder/fetch_card_order',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getCardOrder();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

export const sendCardOrder = createAsyncThunk(
    'cardOrder/send_card_order',
    async (order, { rejectWithValue }) => {
        try {
            const response = await postCardOrder(order);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

export const saveConfirmedCardOrder = createAsyncThunk(
    'cardOrder/save_card_order',
    async (order, { rejectWithValue }) => {
        try {
            const response = await postConfirmedCardOrder(order);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchBankBranches = createAsyncThunk(
    'cardOrder/fetch_bank_branches',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getBankBranches();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });

export const fetchInvoice = createAsyncThunk(
    'cardOrder/fetch_invoice',
    async (cardOrderId, { rejectWithValue }) => {
        try {
            const pdfResponse = await getCardOrderInvoice(cardOrderId);
            global.Buffer = Buffer;
            const pdfInBase64 = new Buffer.from(pdfResponse.data).toString('base64');
            return { pdf: 'data:application/pdf;base64,' + pdfInBase64 };
        } catch (error) {
            return rejectWithValue(error);
        }
    });
