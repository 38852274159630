import { createSlice } from '@reduxjs/toolkit';
import {
    verifyLogin,
    passwordReminder,
    resetPassword,
    getAccounts,
    doSelectAccount,
    verifyTotpToken,
    fetchSetupMfaData,
    doSendMeReminder
} from './thunk';
import ReduxStatus, { LOGIN_PAGE_STATE } from '../common/constants/ReduxStatus';

const initialState = {
    open: false,
    loginPageState: LOGIN_PAGE_STATE.login,
    isAuthenticated: false,
    status: ReduxStatus.empty,
    errorMessages: {},
    isLoggedOut: false,
    impersonateData: [],
    headerEnable: true,
    accountSelect:false,
    statusProfile: ReduxStatus.empty,
    username: '',
    secretKey: '',
    mfaData: {},
    userPasswordIsTooOld: !!JSON.parse(localStorage.getItem('user_password_is_too_old')),
    statusRequiredPasswordReminder: ReduxStatus.empty,
    email: ReduxStatus.empty    
};

const setLoginSuccess = (state, payload) => {
    localStorage.setItem('access_token_no_refresh', payload.jwtToken);
    localStorage.setItem('access_token', payload.jwtToken);
    localStorage.setItem('refresh_token', payload.refreshToken);
    localStorage.setItem('multiple_accounts', JSON.stringify(payload.multipleAccounts));
    localStorage.setItem('account_selected', JSON.stringify(state.accountSelect));
    localStorage.setItem('user_password_is_too_old', payload.userPasswordIsTooOld);  
    state.status = ReduxStatus.loaded;
    state.userPasswordIsTooOld = payload.userPasswordIsTooOld; 
    state.isAuthenticated = true;
    state.isLoggedOut = false;
};


export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        openDialog: (state, action) => {
            state.open = true;
            state.loginPageState = LOGIN_PAGE_STATE.login;
        },
        closeDialog: (state, action) => {
            state.open = false;
        },
        renderPasswordReminderDialog: (state, action) => {
            state.loginPageState = LOGIN_PAGE_STATE.passwordReminder;
        },
        getAuthState: (state, action) => {
            let token = localStorage.getItem('access_token');
            state.isAuthenticated = !!token;
        },
        verifyLogout: (state, action) => {
            localStorage.clear();
            state.isAuthenticated = false;
            state.isLoggedOut = true;
        },
        renderMfaSetupDialog: (state, action) => {
            state.loginPageState = LOGIN_PAGE_STATE.mfaSetupRequired;
        },
        renderMfaChallengeDialog: (state, action) => {
            state.loginPageState = LOGIN_PAGE_STATE.mfaChallengeRequired;
        },
    },
    extraReducers: {
        [verifyLogin.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
            state.isAuthenticated = false;
        },
        [verifyLogin.fulfilled]: (state, action) => {
            const { challengeName, ...payload } = action.payload;

            if (challengeName === 'MFA_SETUP') {
                state.status = ReduxStatus.loaded;
                state.isAuthenticated = false;
                state.loginPageState = LOGIN_PAGE_STATE.mfaSetupRequired;
                state.userEmail = payload.userEmail;
            } else if (challengeName === 'MFA_CHALLENGE') {
                state.status = ReduxStatus.loaded;
                state.isAuthenticated = false;
                state.loginPageState = LOGIN_PAGE_STATE.mfaChallengeRequired;
                state.mfaData = {
                    username: payload.username,
                    password: payload.password,
                    challengeName: challengeName,
                    challenge: payload.challenge,
                    message: payload.message,
                }
            } else {
                setLoginSuccess(state, payload);
            }
        },
        [verifyLogin.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
            state.isAuthenticated = false;
            state.mfaData = {
                ...state.mfaData,
                message: state.errorMessages,
            };
        },
        [passwordReminder.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [passwordReminder.fulfilled]: (state, action) => {
            state.status = ReduxStatus.loaded;
        },
        [passwordReminder.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        [resetPassword.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [resetPassword.fulfilled]: (state, action) => {
            state.status = ReduxStatus.loaded;
        },
        [resetPassword.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        [getAccounts.pending]: (state, action) => {
            state.statusProfile = ReduxStatus.loading;
        },
        [getAccounts.fulfilled]: (state, action) => {
            state.statusProfile = ReduxStatus.loaded;
            state.impersonateData = action.payload.APPROVEDACCOUNTS;
            state.headerEnable = false;
        },
        [getAccounts.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.statusProfile = ReduxStatus.loadFailed;
        },
        [doSelectAccount.pending]: (state, action) => {
            state.statusProfile = ReduxStatus.loading;
        },
        [doSelectAccount.fulfilled]: (state, action) => {
            let accountChoose=true;
            localStorage.setItem('access_token_no_refresh', action.payload.jwtToken);
            localStorage.setItem('access_token', action.payload.jwtToken);
            localStorage.setItem('refresh_token', action.payload.refreshToken);
            localStorage.setItem('account_selected',JSON.stringify(accountChoose));
            localStorage.setItem('multiple_accounts',JSON.stringify(action.payload.multipleAccounts));
            state.statusProfile = ReduxStatus.loaded;
            state.headerEnable = true;
        },
        [doSelectAccount.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.statusProfile = ReduxStatus.loadFailed;
        },
        [verifyTotpToken.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [verifyTotpToken.fulfilled]: (state, action) => {
            state.status = ReduxStatus.loaded;
        },
        [verifyTotpToken.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        [fetchSetupMfaData.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [fetchSetupMfaData.fulfilled]: (state, action) => {
            state.status = ReduxStatus.loaded;
        },
        [fetchSetupMfaData.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        [doSendMeReminder.pending]: (state, action) => {
            state.statusRequiredPasswordReminder = ReduxStatus.loading;
            state.email = ReduxStatus.empty;
        },
        [doSendMeReminder.fulfilled]: (state, action) => {
            state.statusRequiredPasswordReminder = ReduxStatus.loaded;
            state.email = action.payload.email;
        },
        [doSendMeReminder.rejected]: (state, action) => {
            state.statusRequiredPasswordReminder = ReduxStatus.loadFailed;
            state.errorMessages = action.payload;
        },
    }
});

export const {
    openDialog,
    closeDialog,
    renderPasswordReminderDialog,
    renderMfaSetupDialog,
    renderMfaChallengeDialog,
    getAuthState,
    verifyLogout
} = loginSlice.actions;
export default loginSlice.reducer;
