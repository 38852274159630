import React from 'react';
import Banner from './components/Banner';
import CardContents from './components/CardContents';
import Steps from './components/Steps';
import { makeStyles } from "@mui/styles";
import CssBaseline from '@mui/material/CssBaseline';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
    },
}))

const Home = () => {
    const classes = useStyles();
    
    return (
        <>
            <div className={classes.mainbg}>
                <CssBaseline />
                <Banner />
                <CardContents />
                <Steps />
            </div>
        </>
    );
};

export default Home;
