import Http from '../../utils/Http';

const userHeader = async (params) => {
    try {
        let response = await Http.get('pr/dashboard/userHeader', params);
        return response;
    } catch (error) {
        throw error;
    }
}

export {
    userHeader
}
