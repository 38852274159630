import React from 'react';
import UpdateEmailRequestForm from './UpdateEmailRequestForm';
import Container from '@mui/material/Container';
import Title from '../../common/components/base/title/index';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
        fontSize: '14px'
    }
}));

const UpdateEmail = () => {
    const classes = useStyles();
    return (
        <div className={classes.mainbg}>
            <Container maxWidth='md'>
                <Title title='Update your email address' subtitle='Enter your current email address to request an email address change.' />
                <UpdateEmailRequestForm />
            </Container>
        </div>
    );
};

export default UpdateEmail;
