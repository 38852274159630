import React from 'react';
import {
    Backdrop,
    CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.modal + 1,
        color: '#fff',
    },
    bottom: {
        color: '#896109',
    },
    top: {
        color: '#704505',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));

export default function Loader(props) {
    const classes = useStyles();
    return (
        <Backdrop
            {...props}
            className={classes.backdrop}
        >
            <div style={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={40}
                    thickness={4}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={40}
                    thickness={4}
                />
            </div>
        </Backdrop>
    )
}
