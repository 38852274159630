import { NotificationsOutlined, PersonOutlined, SettingsOutlined, UpdateOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const NewsIcon = ({ iconType }) => {
    switch (iconType) {
        case 'Personal':
            return <IconButton disableRipple={true} sx={{ cursor: 'auto !important' }}><PersonOutlined fontSize='large' color='primary' /></IconButton>;
        case 'Notice':
            return <IconButton disableRipple={true} sx={{ cursor: 'auto !important' }}><NotificationsOutlined fontSize='large' color='primary' /></IconButton>;
        case 'New feature':
            return <IconButton disableRipple={true} sx={{ cursor: 'auto !important' }}><SettingsOutlined fontSize='large' color='primary' /></IconButton>;
        case 'Update':
            return <IconButton disableRipple={true} sx={{ cursor: 'auto !important' }}><UpdateOutlined fontSize='large' color='primary' /></IconButton>;
        default:
            break;
    }
}

export default NewsIcon;
