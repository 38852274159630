import Http from '../../common/utils/Http';

const fetchBatchLoadUUID = async () =>
    await Http.get('pr/funds/getBatchLoadUUID');

const fetchUploadLoadFiles = async (payload) => {
    if (!payload.CLIENTBULKUPLOADUUID) {
        const {
            data: { CLIENTBULKUPLOADUUID },
        } = await fetchBatchLoadUUID();
        payload.CLIENTBULKUPLOADUUID = CLIENTBULKUPLOADUUID;
    }

    const formData = new FormData();
    formData.append('clientBulkUploadUUID', payload.CLIENTBULKUPLOADUUID);
    formData.append('loadFile', payload.UPLOADEDFILE);
    formData.append('clientTypeOption', payload.TYPEOPTION);

    return await Http.post('pr/funds/uploadLoadFiles', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

const fetchImportLoadFiles = async (payload) => {
    return await Http.post('pr/funds/importLoadFiles', payload);
};

const fetchLoadDetailToggle = async (payload) => {
    await Http.post('pr/funds/toggleLoadDetail', payload);
    return Promise.resolve({
        data: payload
    });
};

const fetchGetLoadList = async () => {
    return await Http.get('pr/funds/getLoadList');
};

const fetchGetLoadDetail = async (clientBulkUploadUUID) => {
    return await Http.get('pr/funds/getLoadDetailsReview', {
        params: { clientBulkUploadUUID }
    });
};

const fetchQueueApproval = async (clientBulkUploadUUID) => {
    return await Http.post('pr/funds/queueApproval', { clientBulkUploadUUID });
};

const fetchQueueApprove = async (clientBulkUploadUUID) => {
    return await Http.post('pr/funds/processBulkLoad', { clientBulkUploadUUID });
};

const fetchQueueEdit = async (clientBulkUploadUUID) => {
    return await Http.post('pr/funds/queueEdit', { clientBulkUploadUUID });
};

const fetchDeleteBulkLoad = async (clientBulkUploadUUID) => {
    return await Http.post('pr/funds/deleteBulkLoad', { clientBulkUploadUUID });
};

const fetchDownloadFinalizedBulkLoad = async (clientBulkUploadUUID) => {
    return await Http.get('pr/funds/downloadFinalizedBulkLoad', {
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
        headers: {
            'Content-Type': 'application/x-msexcel',
            Accept: 'application/x-msexcel',
        },
        params: { clientBulkUploadUUID }
    });
};

const fetchDownloadErrorBulkLoad = async (clientBulkUploadUUID) => {
    return await Http.get('pr/funds/downloadErrorBulkLoad', {
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
        headers: {
            'Content-Type': 'application/x-msexcel',
            Accept: 'application/x-msexcel',
        },
        params: { clientBulkUploadUUID }
    });
};

const getOurFieldsByDataArray = (dataArray) => {
    if (!dataArray.length) return [];
    return dataArray[0].map((data, index) => {
        return 'ourfield' + (index + 1).toString().padStart(3, '0');
    });
};

const service = {
    fetchBatchLoadUUID,
    fetchUploadLoadFiles,
    fetchImportLoadFiles,
    fetchLoadDetailToggle,
    fetchGetLoadList,
    fetchGetLoadDetail,
    fetchQueueApproval,
    fetchQueueApprove,
    fetchQueueEdit,
    fetchDeleteBulkLoad,
    fetchDownloadFinalizedBulkLoad,
    fetchDownloadErrorBulkLoad,
    getOurFieldsByDataArray,
};

export default service;
