import { createSlice } from "@reduxjs/toolkit";
import ReduxStatus from "../../common/constants/ReduxStatus";
import {
    cardAllocationOptionChangedReducer,
    cardFilterChangedReducer,
    cardSelectedReducer, cardsLoaded,
    cardsLoading, cardsLoadingFailed,
    cardUnselectedReducer,
    requirementsAcceptedReducer,
    multipleCardsSelectedReducer,
    allCardsUnSelectedReducer,
    selectedCardsUpdatedReducer,
    allocateCardsFailed,
    allocateCardsInProgress,
    allocateCardsSucceeded,
    cardsLoadingErrorMessageShownReducer,
    linkedCardsLoadingFailed,
    linkedCardsLoading,
    linkedCardsLoaded,
    linkedCardsLoadingErrorMessageShownReducer,
    totalUnallocatedCardsUpdatedReducer,
    cardsAllocationErrorMessageShownReducer,
    bearerValidationInProgress,
    bearerValidationSucceeded,
    bearerValidationFailed
} from "./reducers";

import { fetchCards, allocateCards, fetchLinkedCards, bearerValidationCards  } from "./thunk";

export const initialState = {
    cards: {
        data: [],
        status: ReduxStatus.empty,
        totalRecords: 0,
        metadata: {
            OFFSET: 0,
            LIMIT: 10,
            TOTAL: 0,
        },
        currentPage: 1,
        errorMessages: [],
        errorMessageShown: false,
        filter: {
            searchField: 'cardNumber',
            searchString: '',
            offset: 0,
            orderByField: 'cardNumber',
            orderAsc: true
        },
    },
    allocationOption: 'all',
    selectedCards: [],
    requirementsAccepted: false,
    cardAllocationSubmission: {
        status: ReduxStatus.empty,
        result: {},
        errorMessages: [],
        errorMessageShown: false
    },
    ficaValidation: {
        result:'',
        status: ReduxStatus.empty,
        errorMessages: []
    },
    linkedCards: {
        data: [],
        status: ReduxStatus.empty,
        errorMessages: [],
        errorMessageShown: false
    }
};

export const cardAllocationSlice = createSlice({
    name: "cardAllocation",
    initialState,
    reducers: {
        reset: () => initialState,
        cardSelected: cardSelectedReducer,
        cardUnselected: cardUnselectedReducer,
        multipleCardsSelected: multipleCardsSelectedReducer,
        allCardsUnSelected: allCardsUnSelectedReducer,
        cardAllocationOptionChanged: cardAllocationOptionChangedReducer,
        cardFilterChanged: cardFilterChangedReducer,
        requirementsAccepted: requirementsAcceptedReducer,
        selectedCardsUpdated: selectedCardsUpdatedReducer,
        cardsLoadingErrorMessageShown: cardsLoadingErrorMessageShownReducer,
        linkedCardsLoadingErrorMessageShown: linkedCardsLoadingErrorMessageShownReducer,
        totalUnallocatedCardsUpdated: totalUnallocatedCardsUpdatedReducer,
        cardsAllocationErrorMessageShown: cardsAllocationErrorMessageShownReducer
    },
    extraReducers: {
        [fetchCards.pending]: cardsLoading,
        [fetchCards.fulfilled]: cardsLoaded,
        [fetchCards.rejected]: cardsLoadingFailed,
        [fetchLinkedCards.pending]: linkedCardsLoading,
        [fetchLinkedCards.fulfilled]: linkedCardsLoaded,
        [fetchLinkedCards.rejected]: linkedCardsLoadingFailed,
        [allocateCards.pending]: allocateCardsInProgress,
        [allocateCards.fulfilled]: allocateCardsSucceeded,
        [allocateCards.rejected]: allocateCardsFailed,
        [bearerValidationCards.pending]: bearerValidationInProgress,
        [bearerValidationCards.fulfilled]: bearerValidationSucceeded,
        [bearerValidationCards.rejected]: bearerValidationFailed
    },
});

export const cardAllocationActions = cardAllocationSlice.actions

export default cardAllocationSlice.reducer;
