import { createSlice } from '@reduxjs/toolkit';
import ReduxStatus from "../../../common/constants/ReduxStatus";
import { getAccountDetails, saveRegistration } from "./thunk";

const initialState = {
    accountDetails: {
        data: {},
        errorMessages: [],
        status: ReduxStatus.empty,
    },
    saveDetails: {
        data: {},
        errorMessages: [],
        status: ReduxStatus.empty,
    }
};


export const registrationDetailSlice = createSlice({
    name: 'registrationDetail',
    initialState,
    reducers: {},
    extraReducers: {
        [saveRegistration.pending]: (state, action) => {
            state.saveDetails.status = ReduxStatus.loading;
        },
        [saveRegistration.fulfilled]: (state, action) => {
            state.saveDetails.data = action.payload;
            state.saveDetails.status = ReduxStatus.loaded;
        },
        [saveRegistration.rejected]: (state, action) => {
            state.saveDetails.errorMessages = action.payload;
            state.saveDetails.status = ReduxStatus.loadFailed;
        },
        // 
        [getAccountDetails.pending]: (state, action) => {
            state.accountDetails.status = ReduxStatus.loading;
        },
        [getAccountDetails.fulfilled]: (state, action) => {
            state.accountDetails.data = action.payload;
            state.accountDetails.status = ReduxStatus.loaded;

        },
        [getAccountDetails.rejected]: (state, action) => {
            state.accountDetails.errorMessages = action.payload;
            state.accountDetails.status = ReduxStatus.loadFailed;
        },
    }
});


export default registrationDetailSlice.reducer;
