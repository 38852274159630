import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBar({ placeholder, onSubmit, ...props }) {
  return (
    <Paper 
      onSubmit={onSubmit}
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        background:"none !important",
        boxShadow:"none !important",
        padding: "0 !important"
        
      }}
    >
      <InputBase
        sx={{
            flex: 1,
            background: "#fff",
            padding: "5px 15px",
            fontSize: "15px",
            color: "#858d9d!important",
            borderRadius: "2px !important",
            border: "solid 1px #cdcdcd!important",
            borderRight: "none !important",
            fontWeight: "350 !important"
        }}
        {...props}
        placeholder={placeholder ? placeholder : ""}
        inputProps={{ "aria-label": placeholder ? placeholder : "" }}
      />
      <IconButton
        data-testid="search-btn"
        color="primary"
        variant="contained"
        type="submit"
        aria-label="search"
        sx={{
            background: "#0083f3 !important",
            borderRadius: "0 3px 3px 0 !important",
            padding: "2px !important",
            fontSize: "15px"
            
          }}
      >
        <SearchIcon 
          sx={{
            width: "40px",
            height: "32px",
            padding: "3px",
            color:"#fff !important",
            
          }}
        />
      </IconButton>
    </Paper>
  );
}
