import { useEffect, useState } from "react";
import DataTable from "../../../common/components/base/datatable";
import { useSelector, useDispatch, useStore } from "react-redux";
import { setSelectedLoadFrom, changeCardLoadFromFilter } from "../slice";
import { searchCardLoadFrom } from '../thunk';
import { searchLoadTableHeader } from './SearchLoadTableHeader';

const columns = searchLoadTableHeader("radio");

export default function SearchLoadFrom() {
    const [searchLoadFromSortingOrder, setSearchLoadFromSortingOrder] = useState('asc');
    const [searchLoadFromSortingOrderBy, setSearchLoadFromSortingOrderBy] = useState('cardNumber');

    const cards = useSelector(state => state.loadFunds.cardLoadsFrom.data);
    const noDataFoundMessage = useSelector(state => state.loadFunds.cardLoadsFrom.errorMessages);
    const metaData = useSelector(state => state.loadFunds.cardLoadsFrom.metaData);

    const dispatch = useDispatch();

    const store = useStore();

    useEffect(() => {
        dispatch(setSelectedLoadFrom({}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = (event, element) => {
        dispatch(setSelectedLoadFrom(element));
    }

    const handlePagination = (offset, limit) => {
        dispatch(changeCardLoadFromFilter({ offset, limit }));
        let filter = store.getState().loadFunds.cardLoadsFrom.filter;
        dispatch(searchCardLoadFrom({ ...filter }));
    }

    const handleSearchLoadFromRequestSort = (property) => {
        const isAsc = searchLoadFromSortingOrderBy === property && searchLoadFromSortingOrder === 'asc';
        setSearchLoadFromSortingOrder(isAsc ? 'desc' : 'asc');
        setSearchLoadFromSortingOrderBy(property);
        dispatch(changeCardLoadFromFilter({ orderByField: property, orderAsc: !isAsc, offset: 0 }));
        let filter = store.getState().loadFunds.cardLoadsFrom.filter;
        dispatch(searchCardLoadFrom({ ...filter }));
    };

    const rows = cards.map(card => ({ ...card, ID: card.ACCOUNTCARDID }));

    return (
        <DataTable
            columns={columns}
            rows={rows}
            onChange={onChange}
            noDataFoundMessage={noDataFoundMessage}
            metaData={metaData}
            handlePagination={handlePagination}
            onRequestSort={handleSearchLoadFromRequestSort}
            sortingOrder={searchLoadFromSortingOrder}
            sortingOrderBy={searchLoadFromSortingOrderBy}
        />
    );
}
