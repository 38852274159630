import React from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { cardAllocationActions } from "../../../slice";
import Text from "../../../../../common/components/base/text";
const { DateTime } = require("luxon");

export default function AvailableCardItem({ card, index }) {
    const { selectedCards } = useSelector(state => state.cardAllocation);
    const dispatch = useDispatch();

    const isSelected = () => {
        if (selectedCards.length > 0) {
            let selectedCard = selectedCards.find(({ VOUCHERIDX }) => VOUCHERIDX === card.VOUCHERIDX);
            if (selectedCard && selectedCard.VOUCHERIDX === card.VOUCHERIDX) {
                return true;
            }
        }
        return false;
    };

    const handleClick = (event) => {
        if (event.target.checked) {
            dispatch(cardAllocationActions.cardSelected(card))
        }
        else {
            dispatch(cardAllocationActions.cardUnselected(card.VOUCHERIDX))
        }
    }

    const labelId = `available-cards-table-checkbox-${index}`;
    return (
        <TableRow
            hover
            role="checkbox"
            aria-checked={isSelected()}
            tabIndex={-1}
            key={card.ACCOUNTCARDID}
            selected={isSelected()}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    name="selectSingleCard"
                    onChange={handleClick}
                    color="primary"
                    size="small"
                    checked={isSelected()}
                    inputProps={{
                        "aria-labelledby": labelId,
                    }}
                />
            </TableCell>
            <TableCell component="th" id={labelId} scope="row" padding="none">
                <Text>{card.ACCOUNTCARDNUMBERMASKED}</Text>
            </TableCell>
            <TableCell>
                <Text>{card.VOUCHERCARDDATATRACKINGNUMBER}</Text>
            </TableCell>
            <TableCell>
                <Text>{card.VOUCHERIDX}</Text>
            </TableCell>
            <TableCell>
                <Text>{DateTime.fromFormat(card.ACCOUNTCARDEXPIRYDATE, "LLLL, dd yyyy hh:mm:S").toFormat('yyyy-MM-dd')}</Text>
            </TableCell>
        </TableRow>
    );
}
