import { createSlice } from '@reduxjs/toolkit';
import { getContacts } from './thunk';
import ReduxStatus from '../common/constants/ReduxStatus';

const initialState = {
    status: ReduxStatus.empty,
    data: [],
    errorMessages: []
};

export const contactSlice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {},
    extraReducers: {
        [getContacts.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [getContacts.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [getContacts.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
    }
});

export default contactSlice.reducer;
