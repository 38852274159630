import Http from '../../../common/utils/Http';

export const getInvoices = async (body) => {
    try {
        return await Http.post('/pr/reports/listInvoices', { ...body });
    } catch (error) {
        throw error;
    }
};

export const getCardLoadPdf = async (queryString) => {
    try {
        const response = await Http.get('pr/reports/viewCardLoadInvoice', {
            params: queryString,
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getBulkLoadPdf = async (queryString) => {
    try {
        const response = await Http.get('pr/reports/viewBulkLoadInvoice', {
            params: queryString,
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getCardOrderPdf = async (queryString) => {
    try {
        const response = await Http.get('pr/reports/viewCardOrderInvoice', {
            params: queryString,
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};
