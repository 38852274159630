import React from "react";
import { Container } from "@mui/material";
import Title from "../../../../common/components/base/title";
import CardStatementFilters from "./CardStatementFilters";
import { useDispatch, useSelector } from "react-redux";
import AvailableCardsList from "./AvailableCardsList";
import { fetchAccountCards } from "../../thunk";
import Loader from "../../../../common/components/base/loader/Loader";
import ReduxStatus from "../../../../common/constants/ReduxStatus";

const subtitle = [
    "Search for a card number, card holder name or ID/passport number and",
    "click on the link to view a card statement."
]

export default function CardSelection() {
    const dispatch = useDispatch();

    const { cards } = useSelector(state => state.cardStatement)

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(fetchAccountCards(cards.filter));
    }

    return (
        <Container>
            <Title
                title="View card statements"
                subtitle=""
                subtitleArray={subtitle}
            />
            <CardStatementFilters onSubmit={handleSubmit} />
            {
                cards.data && cards.data.length > 0 &&
                <AvailableCardsList />
            }
            {cards.status === ReduxStatus.loading && <Loader open={true} />}
        </Container>
    )
}
