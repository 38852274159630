import React, { useState } from 'react';
import { makeStyles } from "@mui/styles";
import { Typography, Grid, Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    footer: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        paddingTop: 80,
    },
    dvContact: {
        background: theme.palette.primary.main,
        height: 420,
    },
    dvEnquiry: {
        background: theme.palette.secondary.light,
        height: 420,
    },
    dvDisclaimer: {
        height: 120,
        background: theme.palette.secondary.dark,
    },
    imageMobile: {
        width: 180,
        height: 180,
        marginTop: 127,
        marginLeft: 35,
    },
    texttitle: {
        paddingLeft: 100,
        paddingTop: 45
    },
    enquiry: {
        paddingLeft: 18,
        paddingTop: 45
    },
    icons: {
        marginTop: 24,
        paddingLeft: 68
    },
    socialIcons: {
        color: theme.palette.secondary.light,
        margin: 8,
        padding: 7
    },
    textDisclaimer: {
        textAlign: 'center'
    },
    lastline: {
        opacity: 0.5
    }
}),
)
const Footer = () => {
    const classes = useStyles()
    const [year] = useState(new Date().getFullYear());

    return (
        <Box className={classes.footer}>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classes.dvContact} sm={6} md={6}>
                    <Box className={classes.texttitle}>
                        <Typography variant="h5" color="secondary.light" fontFamily='BentonSansPro_Regular'>
                            PayCard support
                        </Typography>
                        <Typography variant="h5" color="secondary.light" fontFamily='BentonSansPro_Regular'>
                            Support@paycard.co.za
                        </Typography>
                        <Typography variant="h5" color="secondary.light" fontFamily='BentonSansPro_Regular' >
                            <br />PayCard applications
                        </Typography>
                        <Typography variant="h5" color="secondary.light" fontFamily='BentonSansPro_Regular'>
                            PayCardAssist@standardbank.co.za
                        </Typography>
                        <Typography variant="h5" color="secondary.light" fontFamily='BentonSansPro_Regular' >
                            <br /> PayCard disputes
                        </Typography>
                        <Typography variant="h5" color="secondary.light" fontFamily='BentonSansPro_Regular'>
                            disputes@paymentology.com
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} className={classes.dvEnquiry} sm={6} md={6} >
                    <Box className={classes.enquiry}>
                        <Typography variant="h5" color="primary.light" fontFamily='BentonSansPro_Regular'  >
                            General enquiries, fraud, lost/stolen cards:
                        </Typography>
                        <Typography variant="h5" color="primary.light" fontFamily='BentonSansPro_Regular' >
                            0861 115 196
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} mt={5}>
                                <Typography variant="h5" color="primary.light" fontFamily='BentonSansPro_Regular'>
                                    Standard Bank App <br /><br />
                                </Typography>
                                <a href="https://play.google.com/store/apps/details?id=com.engageft.standard.thepc&hl=en_ZA" target="_blank" rel="noreferrer">
                                    <img src={require('../../../assets/images/icon_footer_googlePlay.png')} alt="" />
                                </a><br /><br />
                                <a href="https://apps.apple.com/hn/app/the-paycard/id1356683074?l=en" target="_blank" rel="noreferrer">
                                    <img src={require('../../../assets/images/logo_footer_appStore.png')} alt="" />
                                </a>
                            </Grid>
                            <Grid item xs={6} sm={6} flexDirection='column'>
                                <img src={require('../../../assets/images/image_footer_mobileApp.png')} alt="" className={classes.imageMobile} />

                                <div>
                                    <a href="https://www.facebook.com/standardbankgrp/" target="_blank" rel="noreferrer" className={classes.socialIcons}><FacebookIcon /></a>
                                    <a href="https://twitter.com/SBGroup" target="_blank" rel="noreferrer" className={classes.socialIcons}><TwitterIcon /> </a>
                                    <a href="https://www.linkedin.com/company/standard-bank-group" target="_blank" rel="noreferrer" className={classes.socialIcons}><LinkedInIcon /></a>
                                    <a href="https://www.youtube.com/user/StandardBankGroup" target="_blank" rel="noreferrer" className={classes.socialIcons}><YouTubeIcon /></a>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid container className={classes.dvDisclaimer} justifyContent='center'>
                <Grid item xs={12} className={classes.textDisclaimer} sm={12} mt={5}>
                    <IconButton component={Link} to="/disclaimer">
                        <Typography variant="subtitle2" color="secondary.light" paragraph fontFamily='BentonSansPro_Regular'>
                            Disclaimer
                        </Typography>
                    </IconButton>
                    <IconButton component={Link} to="/conditions" >
                        <Typography variant="subtitle2" color="secondary.light" paragraph fontFamily='BentonSansPro_Regular'>
                            Conditions of access
                        </Typography>
                    </IconButton>
                    <IconButton component={Link} to="/privacy-statement" >
                        <Typography variant="subtitle2" color="secondary.light" paragraph fontFamily='BentonSansPro_Regular' >
                            Privacy and security statement
                        </Typography>
                    </IconButton>

                    <Grid item className={classes.textDisclaimer} xs={12} sm={12} mt={-2} >
                        <Typography variant="subtitle2" color="secondary.light" paragraph fontFamily='BentonSansPro_Regular' className={classes.lastline}>
                            © {year} The Standard Bank of South Africa Limited
                        </Typography>

                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}

export default Footer;
