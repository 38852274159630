import React, { useState } from 'react';
import {
    Container,
    Box,
    Typography,
    Alert,
    Collapse,
    IconButton
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../common/components/base/button/Button';
import LoadFundsSummary from './LoadFundsSummary';
import DataTable from '../../../common/components/base/datatable';
import { useSearchParams } from 'react-router-dom';
import { formatCurrency } from '../../../common/utils/helper';
import { confirmCardLoad } from '../thunk';
import LoadFundsInvoice from './LoadFundsInvoice';
import Loader from '../../../common/components/base/loader/Loader';
import ReduxStatus from '../../../common/constants/ReduxStatus';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';

const columns = [
    {
        type: "string",
        key: "CARDHOLDER",
        name: "NAME",
        width: 300
    },
    {
        type: "number",
        key: "CARDNUMBER",
        name: "CARD NUMBER",
        width: 150
    },
    {
        type: "number",
        key: "AMOUNT",
        name: "AMOUNT",
        width: 150
    },
    {
        type: "number",
        key: "FEE",
        name: "FEE",
        width: 150
    },
    {
        type: "number",
        key: "TRANSFERSMSNOTIFICATIONFEE",
        name: "SMS NOTIFICATION FEE",
        width: 150
    }
];

export default function ConfirmCardLoad() {
    const [confirmCardLoadStatus, setConfirmCardLoadStatus] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    const status = useSelector(state => state.loadFunds.status);
    const loadedDataToConfirm = useSelector(state => state.loadFunds.loadedDataToConfirm);
    const loadClientData = useSelector(state => state.loadFunds.loadClientData);
    const loadedCards = loadedDataToConfirm?.loadedCards ?? [];
    const totalAmount = loadedDataToConfirm.totalAmount;
    const totalFee = loadedDataToConfirm.totalFee;
    const loadEffectiveDateOption = loadedDataToConfirm.loadEffectiveDateOption;
    const totalSMSNotificationFee = loadedDataToConfirm.totalSMSNotificationFee;
    const loadEffectiveDate = loadedDataToConfirm.loadEffectiveDate;
    const confirmCardLoadErrorMessage = useSelector(state => state.loadFunds.confirmCardLoadErrorMessage);

    const userHeader = useSelector(state => state.header.userHeader);

    const [searchParams] = useSearchParams();
    const accountFrom = searchParams.get("accountFrom") === "true";

    const dispatch = useDispatch();

    const handleContinue = () => {
        let cards = [];
        loadedCards.forEach(card => {
            cards.push({
                accountCardId: card.ACCOUNTCARDID,
                transferAmount: parseFloat(card.AMOUNT),
                transferFee: card.FEE,
                transferSMSNotificationFee: card.TRANSFERSMSNOTIFICATIONFEE,
                transferSMSNotification: card.TRANSFERSMSNOTIFICATION
            })
        })
        let params = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            params[param] = value;
        }
		
        params["cardsToLoad"] = loadedCards.map(item => item.ACCOUNTCARDID);
        let selectedSourceAccountID = params["transferFromAccountId"] ? parseInt(params["transferFromAccountId"]) : 0;
        params["transferFromAccountId"] = selectedSourceAccountID;
        let accountFromValue = params["accountFrom"];
        params["accountFrom"] = accountFromValue === "true" ? true : false;
        let processDelay = params["processDelay"] ? parseInt(params["processDelay"]) : 0;
        params["processDelay"] = processDelay;
        params["processType"] = 0;
        params["cards"] = cards;
        dispatch(confirmCardLoad(params))
            .then(response => {
                if (response.type === "loadFunds/confirm_card_load/fulfilled" && response.payload.TRANSFERID) {
                    setConfirmCardLoadStatus(true);
				}
                else if (response.type === "loadFunds/confirm_card_load/rejected") {
                    setOpenAlert(true);
                }
            })
    }

    const getLoadEffectiveDateType = () => {
        let loadEffectiveDateType = "";
        if (loadEffectiveDateOption === "0") {
            loadEffectiveDateType = "Immediately";
        }
        if (loadEffectiveDateOption === "1") {
            loadEffectiveDateType = "Delay until " + loadEffectiveDate;
        }
        return loadEffectiveDateType;
    }

    const extraRows = [
        {
            CARDHOLDER: "Total",
            CARDNUMBER: "",
            AMOUNT: formatCurrency(totalAmount ?? 0),
            FEE: formatCurrency(totalFee ?? 0),
            TRANSFERSMSNOTIFICATIONFEE: formatCurrency(totalSMSNotificationFee ?? 0)
        },
        {
            CARDHOLDER: "Load Effective Date",
            CARDNUMBER: getLoadEffectiveDateType(),
            AMOUNT: "",
            FEE: "",
            TRANSFERSMSNOTIFICATIONFEE: ""
        }
    ];

    const noDataFoundMessage = loadedCards.length === 0 ? "Balance is not loaded to any card." : "";

    const balanceInfoMessage = () => {
        const balance = loadClientData?.PROFILE?.FROMBALANCE;
        const cardNumber = loadClientData?.PROFILE?.FROMACCOUNT;
        if(balance === 0 || balance === undefined) {
            return "The balance available on your profile is " + (userHeader ? formatCurrency(+userHeader.BALANCEACCOUNT) : formatCurrency(0));
        } else {
            return "The balance available on card " + cardNumber + " is " + formatCurrency(balance);
        }
    }

    const rows = loadedCards.map(card => {
        const item = Object.assign({}, card);
        item.AMOUNT = formatCurrency(card.AMOUNT);
        item.FEE = formatCurrency(card.FEE);
        item.TRANSFERSMSNOTIFICATIONFEE = formatCurrency(card.TRANSFERSMSNOTIFICATIONFEE);
        return item;
    });

    return (
        <Container maxWidth="xl" sx={{ width: '1350px !important' }}>
            {!confirmCardLoadStatus && <div>
                <LoadFundsSummary
                    title="Confirm card loads"
                    subtitle={balanceInfoMessage()}
                />
                <Box sx={{ mt: 6 }}>
                    <DataTable
                        columns={columns}
                        rows={rows}
                        extraRows={extraRows}
                        noDataFoundMessage={noDataFoundMessage}
                    />
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Collapse in={openAlert}>
                        <Alert
                            severity="error"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setOpenAlert(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >
                            {confirmCardLoadErrorMessage}
                        </Alert>
                    </Collapse>
                </Box>
                <Box sx={{ mt: 3 }}>
                    <Button
                        onClick={handleContinue}
                        size="medium"
                        variant="contained"
                        disabled={!_.isEmpty(confirmCardLoadErrorMessage) || rows.length === 0}
                    >
                        Confirm
                    </Button>
                </Box>
            </div>}
            {confirmCardLoadStatus && <LoadFundsInvoice accountFrom={accountFrom}/>}
            {status === ReduxStatus.loading && <Loader open={true} />}
            {confirmCardLoadStatus && status === ReduxStatus.loadFailed && <Box>
                <Typography>Could not load the invoice...</Typography>
                <Typography>Please try again another time.</Typography>
            </Box>}
        </Container>
    );
};
