import { createAsyncThunk } from '@reduxjs/toolkit';
import { termsList } from './service';

export const getTerms = createAsyncThunk(
    'conditions',
    async (params, { rejectWithValue }) => {
        try {
            const response = await termsList();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
