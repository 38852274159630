import React, { useState, useEffect } from 'react';
import {
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    tableCellClasses,
    Typography,
    Box,
} from '@mui/material';

import Button from '../../../../common/components/base/button/Button';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../../../common/components/base/dialog/Dialog';
import { formatCurrency } from '../../../../common/utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { saveConfirmedCardOrder } from '../../redux/thunk';
import ReduxStatus from '../../../../common/constants/ReduxStatus';
import Loader from './../../../../common/components/base/loader/Loader';

function OrderCardConfirmation({ order, newBalance }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const savedOrder = useSelector((state) => state.orderCard.cardOrder);
    const [stateValues, setStateValues] = useState({ open: false });

    useEffect(() => {
        if (savedOrder.saveStatus === ReduxStatus.saved) {
            const queryString = `cardOrderId=${savedOrder.data.cardOrderId}&accountBalance=${newBalance}&accountNumber=${savedOrder.data.accountNumber}`;
            navigate('/dashboard/order-card-invoice?' + queryString);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedOrder.saveStatus]);

    const handleOpenDialog = () => {
        setStateValues((prevState) => ({ ...prevState, open: true }));
    };

    const handleClose = () => {
        setStateValues((prevState) => ({ ...prevState, open: false }));
    };

    const handleOk = () => {
        setStateValues((prevState) => ({ ...prevState, open: false }));

        let confirmedOrder = {
            CARDORDERUUID: order.cardOrderUUID,
            CARDORDERQUANTITY: order.cardOrderQuantity,
            CONTACTNUMBER: order.contactNumber,
            ALTCONTACTNUMBER: order.altContactNumber,
            IDNUMBER: order.idNumber,
            NAME: order.name,
            CARDORDERDELIVERYMETHOD: +order.selectedDeliveryMethod,
        };

        if (order.selectedDeliveryMethod === '1') {
            confirmedOrder = {
                ...confirmedOrder,
                BUILDING: order.building,
                STREET: order.street,
                CODE: order.code,
                CITY: order.city,
            };
        }

        if (order.selectedDeliveryMethod === '0') {
            confirmedOrder = {
                ...confirmedOrder,
                BRANCHCODE: order.bankBranchCode,
            };
        }

        dispatch(saveConfirmedCardOrder(confirmedOrder));
    };

    const handleGoBack = () => {
        navigate('/dashboard/order-card');
    };

    return (
        <>
            {savedOrder.saveStatus === ReduxStatus.saving && <Loader open={true} />}
            {savedOrder.saveStatus === ReduxStatus.saveFailed && (
                <Grid item xs={12}>
                    <Typography color='red'>Could not save the order...</Typography>
                    <Typography color='red'>Please try again another time.</Typography>
                </Grid>
            )}
            {savedOrder.sendStatus === ReduxStatus.saveFailed && (
                <Grid item xs={12}>
                    <Typography>Could not send the order to the server...</Typography>
                    <Typography>Please try again another time.</Typography>
                </Grid>
            )}
            {savedOrder.sendStatus === ReduxStatus.saved && (
                <>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table
                                sx={{
                                    mt: 5,
                                    maxWidth: 700,
                                    [`& .${tableCellClasses.root}`]: {
                                        borderBottom: 'none',
                                        padding: 0,
                                        paddingTop: 1,
                                    },
                                }}
                                aria-label='simple table'
                            >
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Number of cards</TableCell>
                                        <TableCell>
                                            {order.cardOrderQuantity} @ {formatCurrency(order.cardUnitPrice)} per card ={' '}
                                            {formatCurrency(order.totalCardCost)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Delivery fee</TableCell>
                                        <TableCell>
                                            {order.cardOrderQuantity} @ {formatCurrency(order.cardUnitPrice)} per card +{' '}
                                            {formatCurrency(order.clientFaceDeliveryCost)} for face-to-face delivery ={' '}
                                            {formatCurrency(order.totalDeliveryCost)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Total cost</TableCell>
                                        <TableCell>{formatCurrency(order.totalCost)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Receiver name</TableCell>
                                        <TableCell>{order.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Receiver ID number</TableCell>
                                        <TableCell>{order.idNumber}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Contact number</TableCell>
                                        <TableCell>{order.contactNumber}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Alternative contact number</TableCell>
                                        <TableCell>{order.altContactNumber}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Delivery method</TableCell>
                                        <TableCell>
                                            {order.selectedDeliveryMethod === '1' ? 'Courier' : 'Bank branche delivery'}
                                        </TableCell>
                                    </TableRow>
                                    {order.selectedDeliveryMethod === '1' && (
                                        <>
                                            <TableRow>
                                                <TableCell>Building</TableCell>
                                                <TableCell>{order.building}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Street</TableCell>
                                                <TableCell>{order.street}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>City</TableCell>
                                                <TableCell>{order.city}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Code</TableCell>
                                                <TableCell>{order.code}</TableCell>
                                            </TableRow>
                                        </>
                                    )}
                                    {order.selectedDeliveryMethod === '0' && (
                                        <>
                                            <TableRow>
                                                <TableCell>Bank name</TableCell>
                                                <TableCell>{order.bankBrancheName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Bank address</TableCell>
                                                <TableCell>{savedOrder.data.bankBrancheAddress}</TableCell>
                                            </TableRow>
                                        </>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            size='medium'
                            variant='contained'
                            styles={{ margin: '40px 20px 0px 0px' }}
                            onClick={newBalance >= 0 ? handleOk : handleOpenDialog}
                        >
                            Confirm
                        </Button>
                        <Button
                            size='medium'
                            variant='outlined'
                            styles={{ margin: '40px 20px 0px 0px' }}
                            onClick={handleGoBack}
                        >
                            Go back
                        </Button>
                        <Dialog
                            open={stateValues.open}
                            onClose={handleClose}
                            showDialogTitle={true}
                            showDialogActions={false}
                            scroll='paper'
                        >
                            <div>
                                <Typography sx={{ mt: 5 }}>
                                    You have insufficient funds to process this order. Your card order will only be
                                    processed once you have used internet banking to deposit sufficient funds into the
                                    "Credit Outstanding: PayCard". Beneficiary with your profile number as the
                                    reference. Instructions are available after clicking confirm.
                                </Typography>
                                <Box textAlign='center'>
                                    <Button
                                        size='medium'
                                        variant='contained'
                                        onClick={handleOk}
                                        styles={{ margin: '40px auto 20px auto' }}
                                    >
                                        Confirm
                                    </Button>
                                </Box>
                            </div>
                        </Dialog>
                    </Grid>
                </>
            )}
        </>
    );
}

export default OrderCardConfirmation;
