import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cardStatementActions } from "../../slice";
import ReduxStatus from "../../../../common/constants/ReduxStatus";

import { Box, Container } from "@mui/material";
import Title from "../../../../common/components/base/title";
import Button from "../../../../common/components/base/button/Button";
import Loader from "../../../../common/components/base/loader/Loader";

import CardStatementList from "./CardStatementList";
import CardStatementDocument from "./CardStatementDocument";
import {
	fetchCardStatement, fetchCardStatementDocumentPDF,
	fetchCardStatementDocumentXLS
} from "../../thunk";
import { CARD_ID_FIELD, CARD_STATEMENT_HOME_URL } from "../../common/utils";
import { downloadData } from "../../../profileHistory/common/utils";

export default function CardStatement() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [params] = useSearchParams();

	const { statement, view, docDownload } = useSelector(state => state.cardStatement);

	const getSubtitle = () => `${new Date().toDateString()}  at
                         ${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}`;

	const handleDownload = () => {
		if (docDownload.data) {
			downloadData(docDownload.data, new Date().toDateString());
		}
	}

	const handleViewChange = (view) => {
		dispatch(cardStatementActions.cardStatementViewChanged(view));
	}

	const sendDataFetch = () => {
		let filters = {
			days: statement.period,
			[CARD_ID_FIELD]: params.get(CARD_ID_FIELD)
		}
		dispatch(fetchCardStatement(filters));
		dispatch(fetchCardStatementDocumentXLS(filters));
		dispatch(fetchCardStatementDocumentPDF(filters));
	}

	useEffect(() => {
		if (statement.status === ReduxStatus.empty) {
			if (params.get(CARD_ID_FIELD)) {
				sendDataFetch();
			} else {
				navigate(CARD_STATEMENT_HOME_URL);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statement])

	return (
		<>
			<Container>
				<Title
					title="Transaction statement"
					subtitle={getSubtitle()}
					subtitleArray={[]}
				/>
				{view === "list" ?
					<CardStatementList statement={statement} />
					:
					<CardStatementDocument statement={statement} />}
				<Box sx={{ mt: 5, display: "flex" }}>
					<Box>
						<Button
							onClick={handleDownload}
							color="secondary"
							variant="contained"
							title={docDownload.data === null ? "Report file is not available" : "Download report file"}
							disabled={statement.errorMessages.length > 0}
						>
							DOWNLOAD
						</Button>
					</Box>
					{view === "list" ?
						<Box sx={{ ml: 4 }}>
							<Button
								onClick={() => handleViewChange("document")}
								color="secondary"
								variant="outlined"
								disabled={statement.errorMessages.length > 0}
							>
								PRINTABLE VERSION
							</Button>
						</Box>
						:
						<Box sx={{ ml: 4 }}>
							<Button
								onClick={() => handleViewChange("list")}
								color="secondary"
								variant="outlined"
							>
								NORMAL VERSION
							</Button>
						</Box>}
				</Box>
			</Container>
			{statement.status === ReduxStatus.loading && <Loader open={true} />}
		</>
	)
}
