import { createAsyncThunk } from '@reduxjs/toolkit';
import { downloadData } from '../../reports/profileHistory/common/utils';

import api from './service';

export const postUploadLoadFiles = createAsyncThunk(
    'funds/post_upload_load_files',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchUploadLoadFiles(payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const postImportLoadFiles = createAsyncThunk(
    'funds/post_import_load_files',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchImportLoadFiles(payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const postLoadDetailToggle = createAsyncThunk(
    'funds/post_load_detail_toggle',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchLoadDetailToggle(payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getGetLoadList = createAsyncThunk(
    'funds/get_get_load_list',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchGetLoadList(payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getGetLoadDetail = createAsyncThunk(
    'funds/get_get_load_detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchGetLoadDetail(payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const postQueueApproval = createAsyncThunk(
    'funds/post_queue_approval',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchQueueApproval(payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const postQueueApprove = createAsyncThunk(
    'funds/post_queue_approve',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchQueueApprove(payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const postQueueEdit = createAsyncThunk(
    'funds/post_queue_edit',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchQueueEdit(payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const postDeleteBulkLoad = createAsyncThunk(
    'funds/post_delete_buld_load',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchDeleteBulkLoad(payload);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getDownloadFinalizedBulkLoad = createAsyncThunk(
    'funds/get_download_finalized_bulk_load',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchDownloadFinalizedBulkLoad(payload.UUID);
            const fileInBase64 = new Buffer.from(response.data).toString('base64');
            const srcBase64 = 'data:application/vnd.ms-excel;base64,' + fileInBase64;
            downloadData(srcBase64, payload.FILENAME.replace(/\.[^.]+$/, '.xls'));
            return true;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

export const getDownloadErrorBulkLoad = createAsyncThunk(
    'funds/get_download_error_bulk_load',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await api.fetchDownloadErrorBulkLoad(payload.UUID);
            const fileInBase64 = new Buffer.from(response.data).toString('base64');
            const srcBase64 = 'data:application/vnd.ms-excel;base64,' + fileInBase64;
            downloadData(srcBase64, payload.FILENAME.replace(/\.[^.]+$/, '.xls'));
            return true;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);
