import { createSlice } from '@reduxjs/toolkit';
import ReduxStatus from '../../common/constants/ReduxStatus';
import { 
    searchCardLoadFrom, 
    searchCardLoadTo, 
    searchLoadAllocatedCards, 
    getLoadClient, 
    confirmCardLoad,
    viewTransferInvoice
} from './thunk';

const initialState = {
    loadFundsFromOption: 'profile',
    status: ReduxStatus.empty,
    cardLoadsFrom: {
        data: [],
        metaData: {},
        filter: {
            searchField: 'cardNumber',
            searchString: '',
            excludeExpired: false,
            excludeStopped: false,
            orderByField: 'cardNumber',
            orderAsc: true,
            offset: 0,
            limit: 10
        },
        errorMessages: ''
    },
    selectedLoadFrom: {},
    cardLoadsTo: {
        data: [],
        metaData: {},
        filter: {
            searchField: 'cardNumber',
            searchString: '',
            orderByField: 'cardNumber',
            orderAsc: true,
            offset: 0,
            limit: 10
        },
        errorMessages: ''
    },
    allocatedCardLoads: {
        data: [],
        metaData: {},
        filter: {},
        errorMessages: ''
    },
    loadFundsOntoOption: 'multiple',
    selectedLoadTo: [],
    loadedDataToConfirm: {},
    loadClientData: {},
    transferedLoadData: {},
    transferInvoiceData: {},
    confirmCardLoadErrorMessage: ''
};

export const loadFundSlice = createSlice({
    name: 'loadFunds',
    initialState,
    reducers: {
        changeLoadFundsFromOption: (state, action) => {
            state.loadFundsFromOption = action.payload;
        },
        changeCardLoadFromFilter: (state, action) => {
            state.cardLoadsFrom.filter = { ...state.cardLoadsFrom.filter, ...action.payload };
        },
        changeCardLoadToFilter: (state, action) => {
            state.cardLoadsTo.filter = { ...state.cardLoadsTo.filter, ...action.payload };
        },
        setSelectedLoadFrom: (state, action) => {
            state.selectedLoadFrom = action.payload;
        },
        changeLoadFundsOntoOption: (state, action) => {
            state.loadFundsOntoOption = action.payload;
        },
        setSelectedLoadTo: (state, action) => {
            state.selectedLoadTo = action.payload;
        },
        setLoadedCards: (state, action) => {
            state.loadedDataToConfirm = action.payload;
            state.allocatedCardLoads.data = [];
            state.confirmCardLoadErrorMessage = "";
        }
    },
    extraReducers: {
        [searchCardLoadFrom.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
            state.cardLoadsFrom.data = [];
            state.cardLoadsFrom.errorMessages = "";
        },
        [searchCardLoadFrom.fulfilled]: (state, action) => {
            state.cardLoadsFrom.data = action.payload.CARDS;
            state.cardLoadsFrom.metaData = action.payload.METADATA;
            state.status = ReduxStatus.loaded;
            if (action.payload.CARDS.length === 0)
                state.cardLoadsFrom.errorMessages = "No results found.";
        },
        [searchCardLoadFrom.rejected]: (state, action) => {
            state.cardLoadsFrom.errorMessages = "No results found.";
            state.status = ReduxStatus.loadFailed;
        },
        [searchCardLoadTo.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
            state.cardLoadsTo.data = [];
            state.cardLoadsTo.errorMessages = "";
        },
        [searchCardLoadTo.fulfilled]: (state, action) => {
            state.cardLoadsTo.data = action.payload.CARDS;
            state.cardLoadsTo.metaData = action.payload.METADATA;
            state.status = ReduxStatus.loaded;
            if (action.payload.CARDS.length === 0)
                state.cardLoadsTo.errorMessages = "No results found.";
        },
        [searchCardLoadTo.rejected]: (state, action) => {
            state.cardLoadsTo.errorMessages = "No results found.";
            state.status = ReduxStatus.loadFailed;
        },
        [searchLoadAllocatedCards.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
            state.allocatedCardLoads.data = [];
            state.allocatedCardLoads.errorMessages = "";
        },
        [searchLoadAllocatedCards.fulfilled]: (state, action) => {
            state.allocatedCardLoads.data = action.payload.CARDS;
            state.allocatedCardLoads.metaData = action.payload.METADATA;
            state.status = ReduxStatus.loaded;
            if (action.payload.CARDS.length === 0)
                state.allocatedCardLoads.errorMessages = "No results found.";
        },
        [searchLoadAllocatedCards.rejected]: (state, action) => {
            state.allocatedCardLoads.errorMessages = "No results found.";
            state.status = ReduxStatus.loadFailed;
        },
        [getLoadClient.pending]: (state, action) => {
            state.loadClientData = {};
            state.status = ReduxStatus.loading;
        },
        [getLoadClient.fulfilled]: (state, action) => {
            state.loadClientData = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [getLoadClient.rejected]: (state, action) => {
            state.status = ReduxStatus.loadFailed;
        },
        [confirmCardLoad.pending]: (state, action) => {
            state.transferedLoadData = {};
            state.status = ReduxStatus.loading;
        },
        [confirmCardLoad.fulfilled]: (state, action) => {
            state.transferedLoadData = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [confirmCardLoad.rejected]: (state, action) => {
            state.status = ReduxStatus.loadFailed;
            state.confirmCardLoadErrorMessage = action.payload;
        },
        [viewTransferInvoice.pending]: (state, action) => {
            state.transferInvoiceData = {};
            state.status = ReduxStatus.loading;
        },
        [viewTransferInvoice.fulfilled]: (state, action) => {
            state.transferInvoiceData = action.payload.pdf;
            state.status = ReduxStatus.loaded;
        },
        [viewTransferInvoice.rejected]: (state, action) => {
            state.status = ReduxStatus.loadFailed;
        }
    }
});

export const {
    changeLoadFundsFromOption,
    changeCardLoadFromFilter,
    changeCardLoadToFilter,
    setSelectedLoadFrom,
    changeLoadFundsOntoOption,
    setSelectedLoadTo,
    setLoadedCards
} = loadFundSlice.actions;

export default loadFundSlice.reducer;
