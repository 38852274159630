import { createAsyncThunk } from "@reduxjs/toolkit";
import api from './service'

export const saveRegistration = createAsyncThunk(
    'setting/saveRegistrationDetail',
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.saveAccountDetails(params);

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAccountDetails = createAsyncThunk(
    "setting/getAccountDetails",
    async (param, { rejectWithValue }) => {

        try {
            let response = await api.getAccountDetails()
            return response.data;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);
