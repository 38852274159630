import React, { useState, useEffect } from 'react';
import Input from '../../common/components/base/input/Input';
import {
    Grid,
    Box,
    FormHelperText
} from '@mui/material';
import Button from '../../common/components/base/button/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import Title from '../../common/components/base/title/Title';
import globalVariables from "../../common/constants/globalVariables";
import { REGEX_EMAIL } from '../../common/utils/regex';
import { verifyConfirmEmailChange } from './thunk';
import { verifyLogout } from '../../login/slice';
import { useDispatch, useStore } from 'react-redux';

const ConfirmEmailChange = () => {
    const [stateValues, setStateValues] = useState({
        newEmail: '',
        confirmEmail: '',
        recaptchaResponse: '',
        changeEmailUUID: '',
        confirmEmailUpdate: false,
        isLoggedOut: false
    })

    const [validationErrors, setValidationErrors] = useState({
        newEmail: '',
        confirmPassword: '',
        recaptchaResponse: ''
    })

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const location = useLocation();
    const { getState } = useStore();

    useEffect(() => {
        let params = new URLSearchParams(location.search);
        let changeEmailUUID = params.get("emailUUID");
        setStateValues(prevStateValues => ({ ...prevStateValues, changeEmailUUID }));
        if (!changeEmailUUID)
            navigate("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getState().login.isLoggedOut) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stateValues.confirmEmailUpdate === true) {
            dispatch(verifyLogout());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateValues]);

    const handleInput = input => event => {
        let value = event.target.value;
        if (input === "newEmail" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, newEmail: "Email is required." }));
        }
        if (input === "newEmail" && value.length !== 0) {
            if (REGEX_EMAIL.test(value))
                setValidationErrors(validationErrors => ({ ...validationErrors, newEmail: '' }));
            else
                setValidationErrors(validationErrors => ({ ...validationErrors, newEmail: 'Invalid email.' }));
        }
        if (input === "confirmEmail" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, confirmEmail: "Confirm email is required." }));
        }
        if (input === "confirmEmail" && value.length !== 0) {
            if (REGEX_EMAIL.test(value))
                setValidationErrors(validationErrors => ({ ...validationErrors, confirmEmail: '' }));
            else
                setValidationErrors(validationErrors => ({ ...validationErrors, confirmEmail: 'Invalid email.' }));
        }
        setStateValues(prevStateValues => ({ ...prevStateValues, [input]: value }));
    };

    const getReCaptcha = (recaptchaResponse) => {
        setStateValues(prevStateValues => ({ ...prevStateValues, recaptchaResponse }));
        setValidationErrors(validationErrors => ({ ...validationErrors, recaptchaResponse: "" }));
    };

    const handleOk = () => {
        let errorCount = 0;
        if (stateValues.newEmail.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, newEmail: "Email is required." }));
        }
        if (stateValues.confirmEmail.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, confirmEmail: "Confirm email is required." }));
        }
        if (stateValues.newEmail.length !== 0 && stateValues.confirmEmail.length !== 0 && stateValues.newEmail !== stateValues.confirmEmail) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, confirmEmail: "Email and confirm email does not match." }));
        }
        if (!stateValues.recaptchaResponse) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, recaptchaResponse: "Please validate recaptcha." }));
        }
        if (errorCount === 0) {
            dispatch(verifyConfirmEmailChange({ "changeEmailUUID": stateValues.changeEmailUUID, "newEmail": stateValues.newEmail, "confirmEmail": stateValues.confirmEmail, "recaptchaResponse": stateValues.recaptchaResponse }))
                .then((response) => {
                    if (response.type === "verify/confirm_email_change/fulfilled") {
                        setStateValues(prevStateValues => ({ ...prevStateValues, confirmEmailUpdate: true }));
                    }
                })
        }
    }

    return (
        <Box
            mx="auto"
            alignItems="center"
            justifyContent="center"
            sx={{
                width: 450
            }}
        >
            <Grid
                container
            >
                <Grid item xs={12}>
                    <Title
                        title="Confirm Email"
                        subtitle="Fill in the form below to change your email."
                        sxSubtitle={{ mb: 0 }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        size="small"
                        type="email"
                        inputLabel="New Email"
                        name="newEmail"
                        fullWidth
                        autoComplete="newEmail"
                        value={stateValues.newEmail}
                        onChange={handleInput("newEmail")}
                        error={validationErrors.newEmail ? true : false}
                        helperText={validationErrors.newEmail}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        size="small"
                        type="email"
                        inputLabel="Confirm email"
                        name="confirmPassword"
                        fullWidth
                        autoComplete="confirmEmail"
                        value={stateValues.confirmEmail}
                        onChange={handleInput("confirmEmail")}
                        error={validationErrors.confirmEmail ? true : false}
                        helperText={validationErrors.confirmEmail}
                    />
                </Grid>
                <Grid item xs={12} my={3}>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <ReCAPTCHA
                            sitekey={globalVariables.RECAPTCHA_KEY}
                            size="normal"
                            onChange={getReCaptcha}
                        />
                    </Grid>
                    <FormHelperText error sx={{ ml: 2 }}>{validationErrors.recaptchaResponse}</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <Button variant="contained" size="medium" onClick={handleOk}>CHANGE EMAIL</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ConfirmEmailChange;
