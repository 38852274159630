import { createSlice } from '@reduxjs/toolkit';

import ReduxStatus from '../common/constants/ReduxStatus';

import {
    getDashboardCardOrders,
    getDashboardCardAllocation,
    getDashboardLastDeposit,
    getDashboardLastLogin
} from './thunk';

const initialState = {
    status: ReduxStatus.empty,
    data: {
        CARDSTOTAL: 0,
        UNALLOCATEDCARDSTOTAL: 0,
        STATEMENTVALUEDATE: '',
        LASTLOGINDATE: '',
        CARDORDERSDASHBOARD: [],
    },
    errorMessages: [],
    expandedAccordion: ''
};

const isRejectedAction = (action) => action.type.endsWith('rejected');
const isPendingAction = (action) => action.type.endsWith('pending');
const isFulfilledAction = (action) => action.type.endsWith('fulfilled');

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setExpandedAccordion: (state, action) => {
            state.expandedAccordion = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardCardAllocation.fulfilled, (state, action) => {
                state.data.CARDSTOTAL = action.payload.CARDSTOTAL;
                state.data.UNALLOCATEDCARDSTOTAL =
                    action.payload.UNALLOCATEDCARDSTOTAL;
            })
            .addCase(getDashboardLastDeposit.fulfilled, (state, action) => {
                state.data.STATEMENTVALUEDATE = action.payload.STATEMENTVALUEDATE;
            })
            .addCase(getDashboardLastLogin.fulfilled, (state, action) => {
                state.data.LASTLOGINDATE = action.payload.LASTLOGINDATE;
            })
            .addCase(getDashboardCardOrders.fulfilled, (state, action) => {
                state.data.CARDORDERSDASHBOARD = action.payload.CARDORDERSDASHBOARD;
            })
            .addMatcher(isPendingAction, (state, action) => {
                state.status = ReduxStatus.loading;
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state.errorMessages = action.payload;
                state.status = ReduxStatus.loadFailed;
            })
            .addMatcher(isFulfilledAction, (state, action) => {
                state.status = ReduxStatus.loaded;
            });

    },
});
export const dashboardSliceActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
