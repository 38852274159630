import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Ptable from "../../../../common/components/base/table/Table";
import { formatCurrency } from "../../../../common/utils/helper";

const profileHistoryHeaders = [
    "PROFILE NUMBER",
    "NAME",
    "BALANCE",
    "STATUS"
]

const profileHistoryFields = [
    "ACCOUNTNUMBER",
    "NAME",
    "BALANCE",
    "STATUS"
]

const transactionStatementHeaders = [
    "DATE",
    "DESCRIPTION",
    "AMOUNT",
    "BALANCE"
]

const transactionStatementFields = [
    "TRANSACTIONDATE",
    "TRANSACTIONDESCRIPTION",
    "TRANSACTIONAMOUNT",
    "TRANSACTIONBALANCE"
]

export default function TransactionStatementList() {
    const { statement } = useSelector(state => state.profileHistory);

    const getProfileData = () => {
        return {
            ...statement.data,
            id: 'profile-history-profile',
            BALANCE: formatCurrency(statement.data.VOUCHER.BALANCEAMOUNT),
            STATUS: statement.data.VOUCHER.STATUS,
            NAME: `${statement.data.ACCOUNTCARDFIRSTNAME} ${statement.data.ACCOUNTCARDSURNAME}`
        }
    }

    const getTransactionData = () => {
        let data = statement.data.LISTSTATEMENT.map((item, index) => {
            return {
                ...item,
                TRANSACTIONAMOUNT: formatCurrency(item.TRANSACTIONAMOUNT),
                TRANSACTIONBALANCE: formatCurrency(item.TRANSACTIONBALANCE),
                id: item.TRANSACTIONID
            }
        })
        return data;
    }

    return (
        <>
            <Box>
                {statement.data &&
                    <Ptable
                        tableHeaders={profileHistoryHeaders}
                        tableBodies={profileHistoryFields}
                        data={[getProfileData()]}
                    />}
            </Box>
            <Box sx={{ mt: 10 }}>
                <Typography variant="h6" sx={{ color: "#3c4b6c", fontSize: 18, fontWeight: 400, mb: 2 }}>
                    TRANSACTIONS
                </Typography>
                {statement.data &&
                    <Ptable
                        tableHeaders={transactionStatementHeaders}
                        tableBodies={transactionStatementFields}
                        data={getTransactionData()}
                    />}
            </Box>
        </>
    )
}
