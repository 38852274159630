import { createSlice } from '@reduxjs/toolkit';
import ReduxStatus from '../../common/constants/ReduxStatus';
import { verifyGetAccountEmail, verifyUpdatePassword } from './thunk';

const initialState = {
    accountDetails: {
        data: {}
    },
    updatePassword: {
        data: {}
    },
    status: ReduxStatus.empty,
    errorMessages: []
};

export const updatePasswordSlice = createSlice({
    name: 'updatePassword',
    initialState,
    reducers: {
    },
    extraReducers: {
        [verifyGetAccountEmail.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [verifyGetAccountEmail.fulfilled]: (state, action) => {
            state.accountDetails.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [verifyGetAccountEmail.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        [verifyUpdatePassword.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [verifyUpdatePassword.fulfilled]: (state, action) => {
            state.updatePassword.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [verifyUpdatePassword.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        }
    }
});

export default updatePasswordSlice.reducer;
