import React from 'react';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/lab';
import Input from '../input/Input';
import { handleChange } from './Utils';

/**
 * @example: pickers.example.md
 */
export default function DatePicker({ onChange, onValueChange, name, value, ...moreProps }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiDatePicker
                onChange={(date) => {
                    handleChange(name, date, onValueChange, onChange);
                }}
                value={value}
                {...moreProps}
                InputAdornmentProps={{ position: 'start' }}
                renderInput={(params) => <Input sx={{ backgroundColor: 'white'}} {...moreProps} {...params} />}
            />
        </LocalizationProvider>
    );
}

DatePicker.propTypes = {
    onChange: PropTypes.func,
    onValueChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.object,
};
