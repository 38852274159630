import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../common/components/base/input/Input';
import {
    Grid,
    Link,
    Typography,
    Box,
    InputAdornment,
    IconButton,
    Alert,
    Collapse,
    FormHelperText
} from '@mui/material';
import ReCAPTCHA from "react-google-recaptcha";
import CloseIcon from '@mui/icons-material/Close';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { makeStyles } from '@mui/styles';

import Button from '../../common/components/base/button/Button';
import {
    closeDialog, 
    renderMfaChallengeDialog, 
    renderMfaSetupDialog, 
    renderPasswordReminderDialog
} from '../slice';
import { verifyLogin } from '../thunk';
import { REGEX_EMAIL } from '../../common/utils/regex';
import globalVariables from '../../common/constants/globalVariables';
import { useNavigate } from 'react-router-dom';
import MfaState from "../../common/constants/MfaState";

const useStyles = makeStyles({
    cursor: {
        cursor: 'pointer'
    }
})

export default function LoginForm(props) {
    const VERIFY_LOGIN_FULFILLED = "login/verify_login/fulfilled";
    const [stateValues, setStateValues] = useState({
        username: '',
        password: '',
        recaptchaResponse: '',
        challenge: '',
    })

    const [validationErrors, setValidationErrors] = useState({
        username: '',
        password: '',
        recaptchaResponse: '',
        challenge: '',
    })

    const [openAlert, setOpenAlert] = useState(false);

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();
    const classes = useStyles();

    const handleInput = input => event => {
        let value = event.target.value;
        if (input === "username" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, username: "Username is required." }));
        }
        if (input === "username" && value.length !== 0) {
            if (REGEX_EMAIL.test(value))
                setValidationErrors(validationErrors => ({ ...validationErrors, username: '' }));
            else
                setValidationErrors(validationErrors => ({ ...validationErrors, username: 'Invalid username.' }));
        }
        if (input === "password" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, password: "Password is required." }));
        }
        if (input === "password" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, password: '' }));
        }
        setStateValues(prevStateValues => ({ ...prevStateValues, [input]: value }));
    };

    const handleOk = () => {
        let errorCount = 0;
        if (stateValues.username.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, username: "Username is required." }));
        }
        if (stateValues.password.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, password: "Password is required." }));
        }
        if (!stateValues.recaptchaResponse) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, recaptchaResponse: "Please validate recaptcha." }));
        }
        if (errorCount === 0) {
            dispatch(verifyLogin(stateValues))
                .then((response) => {
                    handleVerifyLoginResponse(dispatch, response);
                })
        }
    }

    const handlePasswordReminder = () => {
        dispatch(renderPasswordReminderDialog());
    }

    const isAuthenticated = useSelector(state => state.login.isAuthenticated);
    let multipleAccounts = JSON.parse(localStorage.getItem('multiple_accounts'));

    useEffect(() => {
        if (isAuthenticated && multipleAccounts) {
            navigate("/selectProfile")
        } else if (isAuthenticated) {
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    const getCaptcha = (recaptchaResponse) => {
        setStateValues(prevStateValues => ({ ...prevStateValues, recaptchaResponse }));
        setValidationErrors(validationErrors => ({ ...validationErrors, recaptchaResponse: "" }));
    };

    const handleVerifyLoginResponse = (dispatch, response) => {
        const { challengeName } = response.payload;
        const isLoginFulfilled = response.type === VERIFY_LOGIN_FULFILLED;
        const isMfaSetupRequired = challengeName === MfaState.mfaSetupRequired;
        const isMfaChallengeRequired = challengeName === MfaState.mfaChallengeRequired;

        if (isLoginFulfilled) {
            if (isMfaSetupRequired) {
                dispatch(renderMfaSetupDialog())
            } else if (isMfaChallengeRequired) {
                dispatch(renderMfaChallengeDialog())
            } else {
                dispatch(closeDialog())
            }
        } else {
            setOpenAlert(true);
        }
    };

    return (
        <Grid data-testid="login-form-component" container p={1}>
            <Grid item xs={12}>
                <Input
                    size="small"
                    type="email"
                    inputLabel="Email Address"
                    name="username"
                    fullWidth
                    autoComplete="username"
                    autoFocus
                    value={stateValues.username}
                    onChange={handleInput("username")}
                    error={validationErrors.username ? true : false}
                    helperText={validationErrors.username}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    size="small"
                    type={showPassword ? "text" : "password"}
                    inputLabel="Password"
                    name="password"
                    fullWidth
                    autoComplete="password"
                    value={stateValues.password}
                    onChange={handleInput("password")}
                    error={validationErrors.password ? true : false}
                    helperText={validationErrors.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12} mt={3}>
                <Grid item xs={12} display="flex" justifyContent="center">
                    <ReCAPTCHA
                        sitekey={globalVariables.RECAPTCHA_KEY}
                        size="normal"
                        onChange={getCaptcha}
                    />
                </Grid>
                <FormHelperText error sx={{ ml: 2 }}>{validationErrors.recaptchaResponse}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <Collapse in={openAlert}>
                    <Alert
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpenAlert(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mt: 3 }}
                    >
                        The login you used is invalid. Please try again or request a password reminder.
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12} display="flex" my={2}>
                <LockOutlinedIcon />
                <Box display="flex" mt={0.5}>
                    <Typography variant="body2">
                        Forgotten your password? Request a
                        <Link className={classes.cursor} variant="body2" underline="hover" ml={0.5} onClick={handlePasswordReminder}>
                            password reminder.
                        </Link>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box textAlign='center'>
                    <Button data-testid="login-form-login-button" variant="contained" size='medium' onClick={handleOk}>LOG IN</Button>
                </Box>
            </Grid>
        </Grid>
    );
}
