import Http from "../../common/utils/Http";

const FETCH_CARDS_ENDPOINT = "/pr/cards/unallocatedCards";
const LINKED_CARDS_ENDPOINT = "/pr/cards/linkedCards";
const ALLOCATE_CARDS_ENDPOINT = "/pr/cards/allocateCards";
const BEARER_ALLOCATE_CARD = "pr/cards/allocateCardFicaValidate";

const fetchCards = async (filters = null) => {
    let url = `${FETCH_CARDS_ENDPOINT}?`;
    if (filters) {
        Object.keys(filters).forEach(key => {
            url += `${key}=${filters[key]}&`;
        })
    }
    try {
        let response = await Http.get(url);
        return response;
    } catch (error) {
        throw error;
    }
}

const fetchLinkedCards = async () => {
    try {
        let response = await Http.get(LINKED_CARDS_ENDPOINT);
        return response;
    } catch (error) {
        throw error;
    }
}

const allocateCards = async (payload) => {
    try {
        let response = await Http.post(ALLOCATE_CARDS_ENDPOINT, payload);
        return response;
    }
    catch (error) {
        throw error;
    }
}

const bearerValidationCards = async (payload) => {
    try {
        let response = await Http.post(BEARER_ALLOCATE_CARD, payload);
        return response;
    }
    catch (error) {
        throw error;
    }
}

const cardAllocationService = {
    fetchCards,
    fetchLinkedCards,
    allocateCards,
    bearerValidationCards,

}

export default cardAllocationService;
