import React from 'react';
import {
    Container,
    Box
} from "@mui/material";
import { useSelector } from 'react-redux';
import Button from '../../common/components/base/button/Button';
import LoadFundsFromOption from './components/LoadFundsFromOption';
import LoadFundsAnotherCard from './components/LoadFundsAnotherCard';
import LoadFundsSummary from './components/LoadFundsSummary';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Loader from '../../common/components/base/loader/Loader';
import ReduxStatus from '../../common/constants/ReduxStatus';

export default function LoadFundsIntoCard() {
    const loadFundsFromOption = useSelector(state => state.loadFunds.loadFundsFromOption);
    const status = useSelector(state => state.loadFunds.status);

    const navigate = useNavigate();
    
    const handleContinue = () => {
        let params = { "accountFrom": false };
        navigate({
            pathname: "/dashboard/load-funds-from/to",
            search: `?${createSearchParams(params)}`
        });
    }

    return (
        <div>
            <Container maxWidth="xl" sx={{ width: '1350px !important' }}>
                <LoadFundsSummary
                    title="Load funds into card"
                    subtitle="Load funds into cards from your profile or transfer funds from a stopped card."
                />
                <LoadFundsFromOption />
                {loadFundsFromOption === "anotherCard" && <LoadFundsAnotherCard />}
                {loadFundsFromOption === "profile" && <Box sx={{ mt: 3 }}>
                    <Button
                        onClick={handleContinue}
                        size="medium"
                        variant="contained"
                    >
                        Continue
                    </Button>
                </Box>}
            </Container>
            {status === ReduxStatus.loading && <Loader open={true} />}
        </div>
    );
};
