import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../common/components/base/button/Button';
import Dialog from '../../../common/components/base/dialog/Dialog';
import { formatCurrency } from '../../../common/utils/helper';
import { getDownloadErrorBulkLoad, getDownloadFinalizedBulkLoad, getGetLoadList, postDeleteBulkLoad, postQueueApproval, postQueueApprove, postQueueEdit } from '../thunk';
import BatchLoadAccordion from './BatchLoadAccordion';

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            'width': 'auto !important',
        },
        floatRight: {
            'float': 'right',
        },
        cursor: {
            cursor: 'pointer'
        },
    }),
);

const BatchLoadList = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const {
        LOADAPPROVED,
        LOADLOADSFAILED,
        LOADNOTFINAL,
        LOADFINALIZED
    } = useSelector((state) => {
        return state.batchLoad.data;
    });

    const [stateConfirmDialogQueueForApprovalOpen, setStateConfirmDialogQueueForApprovalOpen] = useState({
        open: false,
        item: {},
        message: '',
        action: () => { }
    });

    const [stateReload, setStateReload] = useState(0);

    const reload = () => {
        setStateReload(stateReload + 1);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGetLoadList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateReload]);

    const handleOnSubmit = (e) => {
        e.preventDefault();
    };

    const handleViewEdit = (e, item) => {
        navigate({
            pathname: '../step4',
            search: '?uuid=' + item.UUID,
        });
    };

    const handleCloseConfirmDialog = () => {
        setStateConfirmDialogQueueForApprovalOpen({
            open: false,
            item: {},
            message: '',
            action: () => { }
        });
    };

    const handleQueueForApprovalAction = (item) => {
        dispatch(postQueueApproval(item.UUID)).then(() => {
            handleCloseConfirmDialog();
            reload();
        });
    };

    const handleQueueForApproval = (e, item) => {
        setStateConfirmDialogQueueForApprovalOpen({
            open: true,
            item: item,
            message: 'Once your bulk load is queued for approval, it can no longer be changed. Do you want to continue?',
            action: handleQueueForApprovalAction
        });
    };

    const handleApproveAction = (item) => {
        dispatch(postQueueApprove(item.UUID)).then(() => {
            handleCloseConfirmDialog();
            reload();
        });
    };

    const handleApprove = (e, item) => {
        setStateConfirmDialogQueueForApprovalOpen({
            open: true,
            item: item,
            message: 'You are about to approve the highlighted bulk load. Approved bulk loads will be processed immediately. Do you want to continue?',
            action: handleApproveAction
        });
    };

    const handleDeclineAction = (item) => {
        dispatch(postQueueEdit(item.UUID)).then(() => {
            handleCloseConfirmDialog();
            reload();
        });
    };

    const handleDecline = (e, item) => {
        setStateConfirmDialogQueueForApprovalOpen({
            open: true,
            item: item,
            message: 'You are about to decline the highlighted bulk load. Declined bulk loads are sent back to the create queue, where they can be edited or deleted. Do you want to continue?',
            action: handleDeclineAction
        });
    };

    const handleDownloadExcel = (e, item) => {
        dispatch(getDownloadFinalizedBulkLoad(item));
    };

    const handleDownloadErrorsExcel = (e, item) => {
        dispatch(getDownloadErrorBulkLoad(item));
    };

    const handleDeleteAction = (item) => {
        dispatch(postDeleteBulkLoad(item.UUID)).then(() => {
            handleCloseConfirmDialog();
            reload();
        });
    };

    const handleDelete = (e, item) => {
        setStateConfirmDialogQueueForApprovalOpen({
            open: true,
            item: item,
            message: 'You are about to delete the highlighted bulk load. Do you want to continue?',
            action: handleDeleteAction
        });
    };

    const [expandedAccordion, setExpandedAccordion] = React.useState(false);

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : false);
    };

    const lists = [
        {
            data: LOADAPPROVED,
            type: 'LOADAPPROVED',
            title: 'Processed',
            buttons: [
                {
                    label: 'Download Excel',
                    icon: <DownloadIcon />,
                    action: handleDownloadExcel
                }
            ]
        },
        {
            data: LOADLOADSFAILED,
            type: 'LOADLOADSFAILED',
            title: 'Processed with errors',
            buttons: [
                {
                    label: 'Download Excel',
                    icon: <DownloadIcon />,
                    action: handleDownloadExcel
                },
                {
                    label: 'Download Errors',
                    icon: <DownloadIcon sx={{ color: red[500] }} />,
                    action: handleDownloadErrorsExcel
                },
            ]
        },
        {
            data: LOADNOTFINAL,
            type: 'LOADNOTFINAL',
            title: 'In progress',
            buttons: [
                {
                    label: 'Queue for Approval',
                    icon: <PlaylistAddCheckIcon />,
                    action: handleQueueForApproval
                },
                {
                    label: 'Delete',
                    icon: <DeleteIcon />,
                    action: handleDelete
                },
            ]
        },
        {
            data: LOADFINALIZED,
            type: 'LOADFINALIZED',
            title: 'Waiting for approval',
            buttons: [
                {
                    label: 'Download Excel',
                    icon: <DownloadIcon />,
                    action: handleDownloadExcel
                },
                {
                    label: 'Approve',
                    icon: <CheckIcon sx={{ color: green[500] }} />,
                    action: handleApprove
                },
                {
                    label: 'Decline',
                    icon: <CancelOutlinedIcon sx={{ color: red[500] }} />,
                    action: handleDecline
                },
            ]
        },
    ]
        .filter(list => !!list.data.length);

    const hasNoBatchLoads = lists.every(l => !l.data.length);

    return (
        <>
            <form noValidate autoComplete='off' onSubmit={handleOnSubmit}>
                {hasNoBatchLoads ? <Typography> You have no previous batch loads.</Typography> : <></>}
                {lists.map((list, i) => {
                    return (
                        <BatchLoadAccordion
                            label={list.title}
                            count={list.data.length}
                            key={'list-' + i}
                            expanded={expandedAccordion === list.type}
                            onChange={handleChangeAccordion(list.type)}
                        >
                            <Box>
                                <TableContainer className={classes.table}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{width: '25%'}}>File Name</TableCell>
                                                <TableCell sx={{width: '20%'}}>Created</TableCell>
                                                <TableCell sx={{width: '15%'}}>Criteria</TableCell>
                                                <TableCell sx={{width: '10%'}}>Qty</TableCell>
                                                <TableCell sx={{width: '15%'}}>Total</TableCell>
                                                <TableCell sx={{width: '15%'}}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {list.data.map((item, ii) => {
                                                return (
                                                    <TableRow key={'row-' + i + '-' + ii}>
                                                        <TableCell>
                                                            {list.type === 'LOADNOTFINAL' ?
                                                                <Tooltip title="View Details / Edit" arrow>
                                                                    <Button className={classes.cursor}
                                                                        variant="text"
                                                                        color="secondary"
                                                                        sx={{}}
                                                                        onClick={(e) => {handleViewEdit(e, item)}}>
                                                                        {item.FILENAME}
                                                                    </Button>
                                                                </Tooltip>
                                                                :
                                                                <>
                                                                    {item.FILENAME}
                                                                </>
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.DATECREATED}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.FILEMAPPINGS}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.QTY}
                                                        </TableCell>
                                                        <TableCell>
                                                            {formatCurrency(item.TOTALCOST)}
                                                        </TableCell>
                                                        <TableCell sx={{ textAlign: 'center' }}>
                                                            {list.buttons.map((button, iii) => {
                                                                return (
                                                                    <Tooltip title={button.label} key={'button-' + i + '-' + ii + '-' + iii} arrow>
                                                                        <IconButton variant='contained'
                                                                            size="small"
                                                                            color="primary"
                                                                            onClick={(e) => button.action(e, item)}>
                                                                            {button.icon}
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                );
                                                            })}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </BatchLoadAccordion>
                    );
                })}
                <Dialog
                    open={stateConfirmDialogQueueForApprovalOpen.open}
                    onClose={handleCloseConfirmDialog}
                    showDialogTitle={true}
                    showDialogActions={false}
                    scroll='paper'
                >
                    <div>
                        <Typography sx={{ mt: 5 }}>
                            {stateConfirmDialogQueueForApprovalOpen.message}
                        </Typography>
                        <Box textAlign='center'>
                            <Button
                                size='medium'
                                variant='contained'
                                onClick={() => stateConfirmDialogQueueForApprovalOpen.action(stateConfirmDialogQueueForApprovalOpen.item)}
                                styles={{ margin: '40px auto 20px auto' }}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </div>
                </Dialog>
            </form>
        </>
    );
};

export default BatchLoadList;
