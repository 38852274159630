export const calculateCosts = (
    cardOrderQuantity,
    clientFaceDeliveryCostTier1,
    clientFaceDeliveryCostTier2,
    clientFaceDeliveryCostTier3,
    clientBranchDeliveryCost,
    cardOrderDeliveryMethod,
    cardUnitPrice
) => {
    let clientFaceDeliveryCost = 0;
    if (cardOrderQuantity <= 50) {
        clientFaceDeliveryCost = clientFaceDeliveryCostTier1;
    } else if (cardOrderQuantity <= 600) {
        clientFaceDeliveryCost = clientFaceDeliveryCostTier2;
    } else {
        clientFaceDeliveryCost = clientFaceDeliveryCostTier3;
    }
    const totalDeliveryCost = cardOrderQuantity * clientBranchDeliveryCost + clientFaceDeliveryCost * cardOrderDeliveryMethod;
    const totalCardCost = cardOrderQuantity * cardUnitPrice;
    const totalCost = totalCardCost + totalDeliveryCost;
    return {
        totalDeliveryCost,
        clientFaceDeliveryCost,
        totalCardCost,
        totalCost,
    };
};
