import React from 'react';
import { Button as MuiButton } from '@mui/material';

const buttonStyles = {
    '&.MuiButton-text': {
        fontWeight: '100 !important',
    },
    '&.MuiButton-containedSizeLarge': {
        width: '320px',
        paddingTop: '15px',
        paddingBottom: '15px',
        borderRadius: '2px',
    },
    '&.MuiButton-containedSizeMedium': {
        width: '280px',
        paddingTop: '15px',
        paddingBottom: '15px',
        borderRadius: '2px',
    },
    '&.MuiButton-containedSizeSmall': {
        width: '140px',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderRadius: '2px',
    },
    '&.MuiButton-outlinedSizeLarge': {
        width: '318px',
        paddingTop: '18px',
        paddingBottom: '18px',
        borderRadius: '2px',
        border: '2px solid',
    },
    '&.MuiButton-outlinedSizeMedium': {
        width: '278px',
        paddingTop: '13px',
        paddingBottom: '13px',
        borderRadius: '2px',
        border: '2px solid',
    },
    '&.MuiButton-outlinedSizeSmall': {
        width: '138px',
        paddingTop: '3px',
        paddingBottom: '3px',
        borderRadius: '2px',
        border: '2px solid',
    },
    '&.Mui-disabled': {
        pointerEvents: "unset",
        cursor: "not-allowed",
        opacity: 0.5
    },
    '&.MuiButton-root:hover': {
        backgroundColor: '#0978D7',
        color: '#FFFFFF'
    },
    '&.MuiButton-outlined:disabled':{
        backgroundColor: '#FFFFFF',
        border: '2px solid #0978D7',
        color: '#0978D7'
    }
};

 const Button = React.forwardRef((props, ref) => {
    const { styles, ...restProps } = props;
    return <MuiButton
        disableElevation
        color='secondary'
        sx={{ ...buttonStyles, ...styles }}
        ref={ref}
        {...restProps}
    >
    </MuiButton>;
});

export default Button;
