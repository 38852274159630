import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentBalanceOfCards } from './service';
import { Buffer } from 'buffer';
import { downloadData } from '../profileHistory/common/utils';
import { DateTime } from 'luxon';

global.Buffer = Buffer;

export const loadingExcelSheetData = createAsyncThunk(
    'load/cards_balance_excelsheet',
    async (params, { rejectWithValue }) => {
        try {
            const excelSheetResponse = await getCurrentBalanceOfCards();
            const excelDataInBase64 = new Buffer.from(excelSheetResponse.data).toString('base64');
            const srcBase64 = 'data:application/excel;base64,' + excelDataInBase64;
            downloadData(srcBase64, `${DateTime.now().toISO({includeOffset: false })}.csv`);
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
