import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyFetchNews } from './thunk';
import NewsBaseTable from './components/NewsBaseTable';
import { Container } from '@mui/material';
import Title from '../common/components/base/title/Title';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
        fontSize: '14px'
    }
}));

const News = () => {
    const news = useSelector(state => state.news.newsList.data);
    const dispatch = useDispatch();
    const location = useLocation();
    const messageId = location?.state?.message_id ?? '';

    useEffect(() => {
        dispatch(verifyFetchNews());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rows = news;
    const classes = useStyles();

    return (
        <div className={classes.mainbg}>
            <Container maxWidth='lg'>
                <Title title='News'></Title>
                <NewsBaseTable rows={rows} messageID={messageId} />
            </Container>
        </div >
    );
};

export default News;
