import React from "react";
import PropTypes from "prop-types";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateTimePicker as MuiDateTimePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { handleChange } from "./Utils";


/**
 * @example: pickers.example.md
 */
export default function DateTimePicker({
  onChange,
  onValueChange,
  name,
  value,
  ...moreProps
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDateTimePicker
        name={name}
        onChange={(datetime) => {
          handleChange(name, datetime, onValueChange, onChange);
        }}
        value={value}
        InputAdornmentProps={{ position: 'start' }}
        {...moreProps}
        renderInput={(params) => <TextField {...moreProps} {...params} />}
      />
    </LocalizationProvider>
  );
}

DateTimePicker.propTypes = {
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.object,
};
