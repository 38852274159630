export const searchLoadTableHeader = (selectType) => {
    return [
        {
            type: selectType,
            key: "SELECT",
            name: "SELECT",
            width: 80,
            sortingKey: "select"
        },
        {
            type: "number",
            key: "CARDNUMBER",
            name: "CARD NUMBER",
            width: 120,
            sortingKey: "cardNumber"
        },
        {
            type: "string",
            key: "CARDHOLDER",
            name: "CARD HOLDER",
            width: 200,
            sortingKey: "cardHolder"
        },
        {
            type: "number",
            key: "IDPASSPORTNUMBER",
            name: "ID/PASSPORT NUMBER",
            width: 200,
            sortingKey: "idNumber"
        },
        {
            type: "number",
            key: "CARDEXPIRYDATE",
            name: "EXPIRY DATE",
            width: 150,
            sortingKey: "accountCardExpiryDate"
        },
        {
            type: "number",
            key: "REFERENCENUMBER",
            name: "REFERENCE NUMBER",
            width: 150,
            sortingKey: "accountCardReference"
        }
    ];
}
