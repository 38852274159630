import React from 'react';
import { Box, Table, TableBody, TableHead, TableRow, TableCell, TableContainer } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import Text from './../../../../common/components/base/text/Text';
import Button from '../../../../common/components/base/button/Button';
import themeConstants from '../../../../common/constants/Theme';
import { searchCards } from './../../redux/thunk';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../../../common/utils/helper'
const { DateTime } = require("luxon");

const useStyles = makeStyles({
    table: {
        fontSize: '30px !important',
    },
    Header: {
        fontWeight: '400 !important',
        color: '#848D9E !important',
        textTransform: 'uppercase',
        fontSize: '14px !important',
    },
    tBody: {
        fontWeight: '400 !important',
        fontSize: '16px !important',
    },
    rowLine: {
        borderBottom: '2px solid rgba(224, 224, 224, 1) !important',
    },
});

const prevDisabledStyles = {
    border: `1px solid ${themeConstants.BRAND_COLOR_SECONDARY}`,
    color: themeConstants.BRAND_COLOR_SECONDARY,
    opacity: 0.5,
};

export default function CardLoads() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const cards = useSelector((state) => state.cancelPendingLoad.cards.data);
    const { OFFSET, LIMIT, TOTAL } = useSelector((state) => state.cancelPendingLoad.cards.data.METADATA);
    const noDataFoundMessage = useSelector((state) => state.cancelPendingLoad.cards.errorMessages);

    const handleNext = () => {
        let nextOffset = OFFSET + LIMIT;
        dispatch(searchCards({ ...cards.filter, OFFSET: nextOffset }));
    };

    const handlePrevious = () => {
        let prevOffset = OFFSET - LIMIT;
        dispatch(searchCards({ ...cards.filter, OFFSET: prevOffset }));
    };

    const nextIsDisabled = () => {
        return !(LIMIT + OFFSET < TOTAL);
    };

    const prevIsDisabled = () => {
        return OFFSET === 0;
    };

    const handleNextPage = (id) => {
        navigate(`/dashboard/cancel-pending-load/confirmation?id=${id}`);
    };

    return (
        <>
            {cards.PENDINGTRANSFERFUNDS && (
                <>
                    <Box sx={{ mt: 6 }}>
                        <TableContainer>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.Header}>CARD NUMBER</TableCell>
                                        <TableCell className={classes.Header}>AMOUNT</TableCell>
                                        <TableCell className={classes.Header}>REQUESTED ON</TableCell>
                                        <TableCell className={classes.Header}>FREQUENCY</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!_.isEmpty(noDataFoundMessage) && (
                                        <TableRow className={classes.rowLine}>
                                            <TableCell key={'table_cell_no_data'} className={classes.tBody}>
                                                {noDataFoundMessage}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {cards.PENDINGTRANSFERFUNDS.map((card, rowIndex) => (
                                        <TableRow key={'table_row_' + rowIndex} className={classes.rowLine}>
                                            <TableCell className={classes.tBody}>
                                                <Button
                                                    variant='text'
                                                    color='secondary'
                                                    sx={{}}
                                                    onClick={() => handleNextPage(card.TRANSFERCARDID)}
                                                >
                                                    {card.TRANSFERCARDTOACCOUNT}
                                                </Button>
                                            </TableCell>
                                            <TableCell className={classes.tBody}>{formatCurrency(card.TRANSFERAMOUNT)}</TableCell>
                                            <TableCell className={classes.tBody}>{DateTime.fromFormat(card.TRANSFERREQUESTDATE, "LLLL, dd yyyy hh:mm:S").toFormat('yyyy-MM-dd hh:mm:S')}</TableCell>
                                            <TableCell className={classes.tBody}>{card.TRANSFERTYPE}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ ml: 2 }}>
                            <Text>
                                Records {OFFSET + 1} - {OFFSET + cards.PENDINGTRANSFERFUNDS.length} of {TOTAL}
                            </Text>
                        </Box>
                        <Box sx={{ display: 'flex', mr: 2 }}>
                            <Box>
                                <Button
                                    onClick={handlePrevious}
                                    size='small'
                                    style={prevIsDisabled() ? prevDisabledStyles : {}}
                                    disabled={prevIsDisabled()}
                                    variant='outlined'
                                >
                                    Previous
                                </Button>
                            </Box>
                            <Box sx={{ ml: 3 }}>
                                <Button
                                    onClick={handleNext}
                                    size='small'
                                    disabled={nextIsDisabled()}
                                    style={{ opacity: nextIsDisabled() ? 0.5 : 1 }}
                                    variant='contained'
                                >
                                    Next
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <hr style={{ borderTop: '1px solid rgba(0,0,0,.1)', marginTop: 20 }} />
                </>
            )}
        </>
    );
}
