import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPayCardList } from './service';

export const getPayCard = createAsyncThunk(
    'getPayCard',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getPayCardList();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
