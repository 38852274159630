import Http from '../../../common/utils/Http';

export const getUser = async () => {
    try {
        let response = await Http.get(`/pr/cards/predata`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getCardOrder = async () => {
    try {
        const response = await Http.get(`/pr/cards/predata`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const postCardOrder = async (order) => {
    try {
        const response = await Http.post('pr/cards/order-confirmation', order);
        return response;
    } catch (error) {
        throw error;
    }
};

export const postConfirmedCardOrder = async (order) => {
    try {
        const response = await Http.post('pr/cards/save-order', order);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getCardOrderInvoice = async (id) => {
    try {
        const response = await Http.get('pr/cards/view-invoice?invoiceID=' + id, {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getBankBranches = async () => {
    try {
        let response = await Http.get(`/pr/cards/bank-branches`);
        return response;
    } catch (error) {
        throw error;
    }
};
