import React from "react";
import FormGroup from "@mui/material/FormGroup";
import { FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "../../../../../common/components/base/checkbox";
import CheckRadio from "../../../../../common/components/base/checkradio";
import RadioGroup from "@mui/material/RadioGroup";
import SearchBar from "../../../../../common/components/base/search/Search";

import { cardAllocationActions } from "../../../slice";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../../../common/components/base/text";

const filterTopics = [
    {
        name: "Card Number",
        id: "cardNumber"
    },
    {
        name: "Sequence Number",
        id: "cardSequence"
    },
    {
        name: "Tracking Number",
        id: "cardTracking"
    },
    {
        name: "Expiry Date",
        id: "cardExpiry"
    }
]

export default function CardAllocationFilters({ onSubmit }) {
    const dispatch = useDispatch();
    const filter = useSelector(state => state.cardAllocation.cards.filter)

    const handleTopicChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            dispatch(cardAllocationActions.cardFilterChanged({ searchField: value }));
        }
    }

    const handleExcludeChange = (event) => {
        const { name, checked } = event.target;
        dispatch(cardAllocationActions.cardFilterChanged({ [name]: checked }))
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        dispatch(cardAllocationActions.cardFilterChanged({ [name]: value }))
    }

    return (
        <Box>
            <Text light mt={2}>
                FIND
            </Text>
            <Box sx={{ mt: 1 }}>
                <SearchBar
                    onSubmit={onSubmit}
                    onChange={handleChange}
                    name="searchString" value={filter.query}
                    placeholder="Enter your search here" />
                <Box sx={{ pt: 1 }}>
                    <Text light>(LEAVE BLANK TO SHOW ALL)</Text>
                </Box>
                <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)" }} />
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                    <Box>
                        <Text light>FILTER BY TOPIC</Text>
                        <RadioGroup
                            row
                            onChange={handleTopicChange}
                            name="searchField"
                        >
                            {
                                filterTopics.map((topic) => (
                                    <FormControlLabel
                                        key={topic.id + '-filter-topic'}
                                        value={topic.id}
                                        control={<CheckRadio color="secondary" checked={filter?.searchField === topic.id} />}
                                        label={<Text>{topic.name}</Text>}
                                    />
                                ))
                            }
                        </RadioGroup>
                    </Box>
                    <Box>
                        <Text light>EXCLUDE</Text>
                        <FormGroup row>
                            <FormControlLabel
                                name="excludeExpired"
                                onChange={handleExcludeChange}
                                control={<Checkbox color="secondary"
                                    size="small"
                                    checked={Boolean(filter.excludeExpired)} />}
                                label={<Text>Expired Cards</Text>}
                            />
                            <FormControlLabel
                                name="excludeStopped"
                                onChange={handleExcludeChange}
                                control={<Checkbox color="secondary"
                                    size="small"
                                    checked={Boolean(filter.excludeStopped)} />}
                                label={<Text>Stopped Cards</Text>} />
                        </FormGroup>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
