import { createAsyncThunk } from '@reduxjs/toolkit';
import { contactList } from './service';

export const getContacts = createAsyncThunk(
    'contact',
    async (params, { rejectWithValue }) => {
        try {
            const response = await contactList();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
