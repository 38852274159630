import React, { useEffect, useState } from 'react';
import {
    Container,
    Box,
    RadioGroup,
    FormControlLabel,
    Collapse,
    Alert
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../common/components/base/button/Button';
import CheckRadio from '../../../common/components/base/checkradio/CheckRadio';
import Text from '../../../common/components/base/text/Text';
import LoadFundsSummary from './LoadFundsSummary';
import DataTable from '../../../common/components/base/datatable';
import { getLoadClient, searchLoadAllocatedCards } from '../thunk';
import DateTimePicker from '../../../common/components/base/pickers/DateTimePicker';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { setLoadedCards } from '../slice';
import _ from 'lodash';
import { formatCurrency, dateTimeFormating } from '../../../common/utils/helper';
import Loader from '../../../common/components/base/loader/Loader';
import ReduxStatus from '../../../common/constants/ReduxStatus';
import { handleAmountAndFee, handleNotifyViaSms } from './LoadUtils';

const columns = [
    {
        type: "string",
        key: "CARDHOLDER",
        name: "NAME",
        width: 250
    },
    {
        type: "number",
        key: "CARDNUMBER",
        name: "CARD NUMBER",
        width: 120
    },
    {
        type: "input",
        key: "AMOUNT",
        name: "AMOUNT",
        width: 100
    },
    {
        type: "string",
        key: "CLIENTMAXIMUMMTD",
        name: "",
        width: 400
    },
    {
        type: "string",
        key: "FEE",
        name: "FEE",
        width: 100
    },
    {
        type: "checkbox",
        key: "TRANSFERSMSNOTIFICATION",
        name: "NOTIFY VIA SMS",
        width: 100
    }
];

export default function LoadAllocatedCards() {
    const [stateValues, setStateValues] = useState({
        cards: [],
        loadedCards: [],
        loadEffectiveDateOption: '0',
        loadEffectiveDate: new Date(),
        totalAmount: 0,
        totalFee: 0,
        totalSMSNotificationFee: 0,
        isEffectiveDateValidated: true,
        isEffectiveDateEmpty: false,
        isFundSufficient: true
    })

    const userHeader = useSelector(state => state.header.userHeader);
    const status = useSelector(state => state.loadFunds.status);
    const allocatedCardLoads = useSelector(state => state.loadFunds.allocatedCardLoads.data);
    const noDataFoundMessage = useSelector(state => state.loadFunds.allocatedCardLoads.errorMessages);
    const loadClientData = useSelector(state => state.loadFunds.loadClientData);
    const metaData = useSelector(state => state.loadFunds.allocatedCardLoads.metaData);
    const loadFundsFromOption = useSelector(state => state.loadFunds.loadFundsFromOption);
    const loadFundsOntoOption = useSelector(state => state.loadFunds.loadFundsOntoOption);
    const selectedLoadFrom = useSelector(state => state.loadFunds.selectedLoadFrom);
    const selectedLoadTo = useSelector(state => state.loadFunds.selectedLoadTo);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        dispatch(setLoadedCards({}));
        let params = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            params[param] = value;
        }

        if (_.isEmpty(params)) {
            let accountFrom = loadFundsFromOption === "profile" ? "false" : "true";
            let transferFromAccountId = loadFundsFromOption === "profile" ? "" : selectedLoadFrom.ACCOUNTCARDID;
            let cardsToLoad = loadFundsOntoOption === "search" ? selectedLoadTo.map(item => item.ACCOUNTCARDID) : "";
            params["accountFrom"] = accountFrom;
            if (transferFromAccountId) {
                params["transferFromAccountId"] = transferFromAccountId;
            }
            if (cardsToLoad.length > 0) {
                params["cardsToLoad"] = cardsToLoad.join();
            }
            setSearchParams(params);
        }

        let selectedCardsToLoad = params["cardsToLoad"];
        if (selectedCardsToLoad) {
            params["cardsToLoad"] = selectedCardsToLoad.split(",").map(item => parseInt(item));
        }
        let selectedSourceAccountID = params["transferFromAccountId"] ? parseInt(params["transferFromAccountId"]) : 0;
        params["transferFromAccountId"] = selectedSourceAccountID;
        let accountFromValue = params["accountFrom"];
        params["accountFrom"] = accountFromValue === "true" ? true : false;
        dispatch(getLoadClient(params));
        let filter = { ...params, offset: 0, limit: 10 };
        dispatch(searchLoadAllocatedCards(filter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (allocatedCardLoads.length > 0 && !(_.isEmpty(loadClientData))) {
            let cards = allocatedCardLoads.map(obj => ({
                ...obj,
                CLIENTMAXIMUMMTD: (obj.CARDNUMBER.length <= 10 ? "The maximum load amount is unlimited" : "The load amount must be " + formatCurrency(loadClientData.DETAILS.CLIENTMINIMUMCARDLOAD) + " - " + formatCurrency(loadClientData.DETAILS.CLIENTMAXIMUMBALANCE - obj.BALANCE)),
                AMOUNT: 0,
                FEE: 0,
                TRANSFERSMSNOTIFICATION: 0,
                TRANSFERSMSNOTIFICATIONFEE: 0,
                CLIENTMINIMUMCARDLOAD: loadClientData.DETAILS.CLIENTMINIMUMCARDLOAD,
                CLIENTMAXIMUMBALANCE: loadClientData.DETAILS.CLIENTMAXIMUMBALANCE,
                isInputValid: true,
                isAmountValid: true,
                error: obj.FICAVALIDATION,
            }))

            setStateValues(prevStateValues => ({ ...prevStateValues, cards }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allocatedCardLoads, loadClientData])

    const onChange = (event, element, type) => {
        if (type === "input") {
            handleLoadFundsAmountAndFee(event, element);
        }
        if (type === "checkbox") {
            handleLoadFundsNotifyViaSms(element);
        }
    }

    const handleLoadFundsAmountAndFee = (event, element) => {
        const { cards, loadedCards, totalAmount, totalFee } = handleAmountAndFee(event, element, loadClientData, stateValues, "isAmountValid");
        const isFundSufficient = searchParams.get("accountFrom") === "true" ? (totalAmount <= loadClientData?.PROFILE?.FROMBALANCE) : true;
        cards.forEach((card) => {
            if(card.AMOUNT) {
                card.isInputValid = card.isAmountValid && isFundSufficient;
            }
            else {
                card.isInputValid = card.isAmountValid;
            }
        })
        setStateValues(prevStateValues => ({ ...prevStateValues, cards, loadedCards, totalAmount, totalFee, isFundSufficient }));
    }

    const handleLoadFundsNotifyViaSms = (element) => {
        const { cards, loadedCards, totalSMSNotificationFee } = handleNotifyViaSms(element, loadClientData, stateValues);
        setStateValues(prevStateValues => ({ ...prevStateValues, cards, loadedCards, totalSMSNotificationFee }));
    }

    const handleContinue = () => {
        let data = {
            loadedCards: stateValues.loadedCards.filter(card => (card.AMOUNT >= card.CLIENTMINIMUMCARDLOAD && card.AMOUNT <= (card.CLIENTMAXIMUMBALANCE - card.BALANCE)) || card.CARDNUMBER.length <= 10),
            loadEffectiveDateOption: stateValues.loadEffectiveDateOption,
            totalAmount: stateValues.totalAmount,
            totalFee: stateValues.totalFee,
            totalSMSNotificationFee: stateValues.totalSMSNotificationFee,
            loadEffectiveDate: dateTimeFormating(stateValues.loadEffectiveDate)
        }
        dispatch(setLoadedCards(data));
        let params = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            params[param] = value;
        }
        params["transferUuid"] = loadClientData.TRANSFERUUID;
        params["processDelay"] = stateValues.loadEffectiveDateOption;
        params["startDate"] = dateTimeFormating(stateValues.loadEffectiveDate);
        params["endDate"] = dateTimeFormating(stateValues.loadEffectiveDate);
        navigate({
            pathname: "/dashboard/load-funds-from/card-loads/confirm-load",
            search: `?${createSearchParams(params)}`
        });
    }

    const handleLoadEffectiveDate = (event) => {
        const { name, value } = event.target;
        if(name === 'loadEffectiveDate') {
            setStateValues(prevStateValues => ({ ...prevStateValues, isEffectiveDateEmpty: !value}))
        }
        setStateValues(prevStateValues => ({ ...prevStateValues, [name]: value }));
    }

    const handleDateTimePickerError = (reason) => {
        setStateValues(prevStateValues => ({ ...prevStateValues, isEffectiveDateValidated: !reason}))
    }

    const extraRows = [
        {
            CARDHOLDER: "Total",
            CARDNUMBER: "",
            AMOUNT: formatCurrency(stateValues.totalAmount),
            CLIENTMAXIMUMMTD: "",
            FEE: formatCurrency(stateValues.totalFee)
        }];

    const handlePagination = (offset, limit) => {
        let params = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            params[param] = value;
        }
        if (!_.isEmpty(params)) {
            let filter = { ...params, offset, limit };
            dispatch(searchLoadAllocatedCards(filter));
        }
    }

    const getAllocatedCards = () => {
        const { loadedCards } = stateValues;
        const cards = stateValues.cards.map(card => {
            const loadedCardIndex = loadedCards.findIndex(loadedCard => loadedCard.ACCOUNTCARDID === card.ACCOUNTCARDID);
            const loadedCard = loadedCardIndex !== -1 ? { ...loadedCards[loadedCardIndex] } : {};
            return { 
                ...card,
                ...loadedCard,
                ID: card.ACCOUNTCARDID,
                FEE: formatCurrency(card.FEE)
            }
        });
        return cards;
    }

    const balanceInfoMessage = () => {
        const balance = loadClientData?.PROFILE?.FROMBALANCE;
        const cardNumber = loadClientData?.PROFILE?.FROMACCOUNT;
        if(balance === 0) {
            return "The balance available on your profile is " + (userHeader ? formatCurrency(+userHeader.BALANCEACCOUNT) : formatCurrency(0));
        } else {
            return "The balance available on card " + cardNumber + " is " + formatCurrency(balance);
        }
    }

    const rows = getAllocatedCards();

    const enableContinueButton = () => {
        let enabled = false;
        let isAmountInputInAnyRow = rows.some(row => row.AMOUNT > 0);
        let isAmountNotValidated = rows.some(row => !row.isInputValid);
        let isFicaInvalid = rows.some(row => row.error);
        if(stateValues.loadEffectiveDateOption === "0") {
            enabled = !isAmountNotValidated && isAmountInputInAnyRow && !isFicaInvalid;
        }
        if(stateValues.loadEffectiveDateOption === "1") {
            enabled = stateValues.isEffectiveDateValidated && !isAmountNotValidated && isAmountInputInAnyRow && !stateValues.isEffectiveDateEmpty && !isFicaInvalid;
        }
        return enabled;
    }

    return (
        <Container maxWidth="xl" sx={{ width: '1350px !important' }}>
            <LoadFundsSummary
                title="Load funds into card"
                subtitleArray={[
                    "Load funds into cards from your profile or transfer funds from a stopped card.",
                    balanceInfoMessage()
                ]}
            />
            <Box sx={{ mt: 6 }}>
                <DataTable
                    columns={columns}
                    rows={rows}
                    extraRows={extraRows}
                    onChange={onChange}
                    noDataFoundMessage={noDataFoundMessage}
                    metaData={metaData}
                    handlePagination={handlePagination}
                />
            </Box>
            {!stateValues.isFundSufficient && <Box sx={{ mt: 3 }}>
                <Collapse in={!stateValues.isFundSufficient}>
                    <Alert
                        severity="error"
                    >
                        The card has insufficient funds to cover the load.
                    </Alert>
                </Collapse>
            </Box>}
            <Box sx={{ mt: 3 }}>
                <Text color='primary.light'>LOAD EFFECTIVE DATE</Text>
                <RadioGroup
                    row
                    onChange={handleLoadEffectiveDate}
                    name="loadEffectiveDateOption">
                    <FormControlLabel
                        value="0"
                        control={<CheckRadio color="secondary" checked={stateValues.loadEffectiveDateOption === "0"} />}
                        label="Immediately"
                    />
                    <FormControlLabel
                        value="1"
                        control={<CheckRadio color="secondary" checked={stateValues.loadEffectiveDateOption === "1"} />}
                        label="Delay until"
                    />
                </RadioGroup>
            </Box>
            {stateValues.loadEffectiveDateOption === "1" && <Box sx={{ mt: 2 }}>
                <Text color='primary.light' size={11} mb={1}>SELECT DATE</Text>
                <DateTimePicker
                    name="loadEffectiveDate"
                    value={stateValues.loadEffectiveDate}
                    onChange={handleLoadEffectiveDate}
                    onError={handleDateTimePickerError}
                />
            </Box>}
            <Box sx={{ mt: 3 }}>
                <Button
                    onClick={handleContinue}
                    size="medium"
                    variant="contained"
                    data-testid="loadFundsContinue"
                    disabled={!enableContinueButton()}
                >
                    Continue
                </Button>
            </Box>
            {status === ReduxStatus.loading && <Loader open={true} />}
        </Container>
    );
};
