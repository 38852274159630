import ReduxStatus from "../../common/constants/ReduxStatus";

export const cardSelectedReducer = (state, action) => {
    state.selectedCards = [...state.selectedCards, {
        ...action.payload
    }]
}

export const multipleCardsSelectedReducer = (state, action) => {
    state.selectedCards = action.payload;
}

export const cardUnselectedReducer = (state, action) => {
    let selectedCards = state.selectedCards;
    let newSelectedCards = selectedCards.filter((card) => card.VOUCHERIDX !== action.payload);
    state.selectedCards = newSelectedCards;
}

export const allCardsUnSelectedReducer = (state, action) => {
    state.selectedCards = [];
}

export const cardAllocationOptionChangedReducer = (state, action) => {
    state.allocationOption = action.payload;
}

export const cardFilterChangedReducer = (state, action) => {
    state.cards.filter = {...state.cards.filter, ...action.payload}
}

export const requirementsAcceptedReducer = (state, action) => {
    state.requirementsAccepted = action.payload;
}

export const cardsLoading = (state, action) => {
    if(action.meta.arg && !action.meta.arg.initialLoad){
        state.cards.status = ReduxStatus.loading;
    }
    state.cards.errorMessageShown = false;

}

export const cardsLoaded = (state, action) => {
    state.cards.data = action.payload["UNALLOCATEDCARDS"];
    state.cards.metadata = action.payload["METADATA"];

    if(action.payload["METADATA"]){
        state.cards.totalRecords =
            action.meta.arg && action.meta.arg.initialLoad
                ? action.payload["METADATA"]["TOTAL"]
                : state.cards.totalRecords
    }
    state.cards.status = ReduxStatus.loaded;
}

export const cardsLoadingFailed = (state, action) => {
    state.cards.errorMessages = ["Error Loading unallocated cards"];
    state.cards.status = ReduxStatus.loadFailed;
}

export const linkedCardsLoading = (state, action) => {
    state.linkedCards.status = ReduxStatus.loading;
    state.linkedCards.errorMessageShown = false;
}

export const linkedCardsLoaded = (state, action) => {
    state.linkedCards.status = ReduxStatus.loaded;
    state.linkedCards.data = action.payload;
}

export const linkedCardsLoadingFailed = (state, action) => {
    state.linkedCards.errorMessages = ["Error Loading Linked cards"];
    state.linkedCards.status = ReduxStatus.loadFailed;
}

export const selectedCardsUpdatedReducer = (state, action) => {
    let newSelectedCards = state.selectedCards.filter((card) => card.VOUCHERIDX !== action.payload.VOUCHERIDX);
    state.selectedCards = [...newSelectedCards, action.payload]
}

export const allocateCardsInProgress = (state, action) => {
    state.cardAllocationSubmission.status = ReduxStatus.saving;
}

export const allocateCardsSucceeded = (state, action) => {
    state.cardAllocationSubmission.status = ReduxStatus.saved;
    state.cardAllocationSubmission.result = action.payload;
}

export const allocateCardsFailed = (state, action) => {
    state.cardAllocationSubmission.status = ReduxStatus.saveFailed;
    state.cardAllocationSubmission.errorMessages = action.payload;
}

export const cardsLoadingErrorMessageShownReducer = (state, action) => {
    state.cards.errorMessageShown = action.payload;
}

export const linkedCardsLoadingErrorMessageShownReducer = (state, action) => {
    state.linkedCards.errorMessageShown = action.payload;
}

export const totalUnallocatedCardsUpdatedReducer = (state, action) => {
    state.cards.totalRecords = action.payload;
}

export const cardsAllocationErrorMessageShownReducer = (state, action) => {
    state.cardAllocationSubmission.errorMessageShown = action.payload;
}

export const bearerValidationInProgress = (state, action) => {
    state.ficaValidation.status = ReduxStatus.saving;
}

export const bearerValidationSucceeded = (state, action) => {
    state.ficaValidation.status = ReduxStatus.saved;
    state.ficaValidation.result = action.payload;
}

export const bearerValidationFailed = (state, action) => {
    state.ficaValidation.status = ReduxStatus.saveFailed;
    state.ficaValidation.errorMessages =  action.payload;

}
