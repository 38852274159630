import React from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import InputWrapper from '../inputWrapper/inputWrapper';

const useStyles = makeStyles({
    input: {
        backgroundColor: 'white',
        [`& fieldset`]: {
            borderRadius: 2,
        },
    },
});

// use "inputLabel" as a property for creating a label on top of the input field
const Input = ({ inputLabel, ...restProps }) => {
    const classes = useStyles();
    return (
        <InputWrapper inputLabel={inputLabel}>
            <TextField
                variant='outlined'
                size='small'
                color='secondary'
                InputProps={{ className: classes.input }}
                {...restProps}
            />
        </InputWrapper>
    );
};
export default Input;
