import { React, useState } from 'react';
import { Box, Table, TableBody, TableHead, TableRow, TableCell, TableContainer, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import Text from '../../../common/components/base/text/Text';
import Button from '../../../common/components/base/button/Button';
import themeConstants from '../../../common/constants/Theme';
import { searchInvoices, fetchCardLoadPdf, fetchBulkLoadPdf, fetchCardOrderPdf } from '../redux/thunk';
import ReduxStatus from '../../../common/constants/ReduxStatus';

const useStyles = makeStyles({
    table: {
        fontSize: '30px !important',
    },
    Header: {
        fontWeight: '400 !important',
        color: '#848D9E !important',
        textTransform: 'uppercase',
        fontSize: '14px !important',
    },
    tBody: {
        fontWeight: '400 !important',
        fontSize: '16px !important',
    },
    rowLine: {
        borderBottom: '2px solid rgba(224, 224, 224, 1) !important',
    },
});

const prevDisabledStyles = {
    border: `1px solid ${themeConstants.BRAND_COLOR_SECONDARY}`,
    color: themeConstants.BRAND_COLOR_SECONDARY,
    opacity: 0.5,
};

export default function Invoices() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { filter, data } = useSelector((state) => state.downloadPreviousInvoice.invoices);
    const { OFFSET, LIMIT, TOTAL } = data.METADATA;
    const pdf = useSelector((state) => state.downloadPreviousInvoice.pdf);
    const noDataFoundMessage = useSelector((state) => state.downloadPreviousInvoice.invoices.errorMessages);

    const [stateValues, setStateValues] = useState({
        errorMsg: ''
    });


    const handleNext = () => {
        let nextOffset = OFFSET + LIMIT;
        dispatch(searchInvoices({ ...filter, offset: nextOffset }));
    };

    const handlePrevious = () => {
        let prevOffset = OFFSET - LIMIT;
        dispatch(searchInvoices({ ...filter, offset: prevOffset }));
    };

    const nextIsDisabled = () => {
        return !(LIMIT + OFFSET < TOTAL);
    };

    const prevIsDisabled = () => {
        return OFFSET === 0;
    };

    const handleDownloadPdf = (id, type) => {
        if (type === 'Card Order') {
            dispatch(fetchCardOrderPdf({ invoiceID: id }));
        } else if (type === 'Card Load') {
            dispatch(fetchCardLoadPdf({ invoiceID: id }));
        } else if (type === 'Bulk Load') {
            dispatch(fetchBulkLoadPdf({ bulkLoadID: id }));
        }
        else {
            setStateValues({ ...stateValues, errorMsg: `Invalid invoice type: ${type}` });
        }
    };

    return (
        <>
            {pdf.status === ReduxStatus.loadFailed && (
                <Alert severity='error' sx={{ mt: 5 }}>
                    {pdf.errorMessages}
                </Alert>
            )}
            {stateValues.errorMsg && (
                    <Alert severity='error' sx={{ mt: 5 }}>
                        {stateValues.errorMsg}
                    </Alert>
            )}
            {data.INVOICES && (
                <>
                    <Box sx={{ mt: 6 }}>
                        <TableContainer>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.Header}>INVOICE NUMBER</TableCell>
                                        <TableCell className={classes.Header}>TYPE</TableCell>
                                        <TableCell className={classes.Header}>INVOICE DATE</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!_.isEmpty(noDataFoundMessage) && (
                                        <TableRow className={classes.rowLine}>
                                            <TableCell key={'table_cell_no_data'} className={classes.tBody}>
                                                {noDataFoundMessage}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {data.INVOICES.map((invoice, rowIndex) => (
                                        <TableRow key={'table_row_' + rowIndex} className={classes.rowLine}>
                                            <TableCell className={classes.tBody}>
                                                <Button
                                                    variant='text'
                                                    color='secondary'
                                                    sx={{}}
                                                    onClick={() =>
                                                        handleDownloadPdf(invoice.INVOICEID, invoice.INVOICETYPE)
                                                    }
                                                >
                                                    {`${invoice.INVOICETYPE === 'Card Order' ? '1' : '2'}${invoice.INVOICEIDFORMATTED
                                                        }`}
                                                </Button>
                                            </TableCell>
                                            <TableCell className={classes.tBody}>{invoice.INVOICETYPE}</TableCell>
                                            <TableCell className={classes.tBody}>{invoice.INVOICEDATE}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ ml: 2 }}>
                            <Text>
                                Records {OFFSET + 1} - {OFFSET + data.INVOICES.length} of {TOTAL}
                            </Text>
                        </Box>
                        <Box sx={{ display: 'flex', mr: 2 }}>
                            <Box>
                                <Button
                                    onClick={handlePrevious}
                                    size='small'
                                    style={prevIsDisabled() ? prevDisabledStyles : {}}
                                    disabled={prevIsDisabled()}
                                    variant='outlined'
                                >
                                    Previous
                                </Button>
                            </Box>
                            <Box sx={{ ml: 3 }}>
                                <Button
                                    onClick={handleNext}
                                    size='small'
                                    disabled={nextIsDisabled()}
                                    style={{ opacity: nextIsDisabled() ? 0.5 : 1 }}
                                    variant='contained'
                                >
                                    Next
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <hr style={{ borderTop: '1px solid rgba(0,0,0,.1)', marginTop: 20 }} />
                </>
            )}
        </>
    );
}
