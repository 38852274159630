import React from 'react';
import Title from '../../common/components/base/title/Title';
import { Container, Alert } from '@mui/material';
import SearchPreviousInvoices from './components/SearchPreviousInvoices';
import { useSelector } from 'react-redux';
import ReduxStatus from '../../common/constants/ReduxStatus';
import Invoices from './components/Invoices';
import Loader from './../../common/components/base/loader/Loader';

const DownloadPreviousInvoice = () => {
    const invoices = useSelector((state) => state.downloadPreviousInvoice.invoices);

    return (
        <Container size='sm'>
            <Title title='Download a previous invoice' subtitle={['Find and download an invoice.']} />
            <SearchPreviousInvoices />
            {invoices.status === ReduxStatus.loading && <Loader open={true} />}
            {invoices.status === ReduxStatus.loadFailed && (
                <Alert severity='error' sx={{ mt: 5 }}>
                    {invoices.errorMessages}
                </Alert>
            )}
            {invoices.data && invoices.status === ReduxStatus.loaded && <Invoices />}
        </Container>
    );
};

export default DownloadPreviousInvoice;
