import React, { useEffect } from 'react';
import Title from '../../common/components/base/title/Title';
import { Typography, Container } from '@mui/material';

const Disclaimer = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Title title='Disclaimer' subtitle=''></Title>
            <Container maxWidth='lg'>
                <br /><Typography color='primary.dark' variant='title' fontFamily='BentonSansPro_Regular' fontWeight={800} >
                    IMPORTANT NOTICE:
                </Typography>
                <Typography color='secondary.dark' variant='subtitle2' fontFamily='BentonSansPro_Regular'>
                    <br />The services and information (the "services") available on this website are not targeted at residents outside of the Republic of South Africa. In particular, nothing on this website should be interpreted as targeting persons or entities in the United States of America, Canada, the United Kingdom, Australia and Hong Kong.<br /><br />
                    Applicants may be granted access to the services once we are satisfied that such access would not be in breach of the relevant laws of the jurisdiction in which the applicant is resident, registered or domiciled.
                </Typography>
            </Container >
        </div >
    );
};

export default Disclaimer;
