import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Ptable from "../../../../common/components/base/table/Table";
import { Box } from "@mui/material";
import Button from "../../../../common/components/base/button/Button";
import { useNavigate } from "react-router-dom";
import { allocateCards } from "../../thunk";
import ReduxStatus from "../../../../common/constants/ReduxStatus";
import { ALLOCATE_CARD_HOLDER_DETAILS_URL } from "../../common/utils";

export default function CardConfirmationList() {
    const { selectedCards, cardAllocationSubmission } = useSelector(state => state.cardAllocation);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getCardConfirmationData = () => {
        let data = [];
        selectedCards.forEach(item => {
            data.push({
                accountCardNumber: item.ACCOUNTCARDNUMBERMASKED,
                id: item.VOUCHERIDX,
                ...item.cardHolderDetails
            });
        })

        return data;
    }

    const handleBack = () => {
        navigate(ALLOCATE_CARD_HOLDER_DETAILS_URL);
    }

    const submitCardAllocation = () => {
        let cards = [];
        selectedCards.forEach(item => {
            cards.push(
                {
                    accountCardID: item.ACCOUNTCARDID,
                    accountCardFirstName: item.cardHolderDetails.firstName,
                    accountCardLastName: item.cardHolderDetails.surname,
                    accountCardIdOrPassportNumber: item.cardHolderDetails.idNumber,
                    accountCardCellPhoneNumber: item.cardHolderDetails.cellPhoneNumber,
                    accountCardReference: item.cardHolderDetails.reference
                }
            )
        });

        dispatch(allocateCards({ "accountCards": cards }));
    }

    const getCardConfirmationHeaders = () => {
        return [
            "CARD NUMBER",
            "FIRST NAME",
            "SURNAME",
            "ID/PASSPORT NUMBER",
            "CELLPHONE NUMBER",
            "REFERENCE"
        ]
    }

    const getCardConfirmationFields = () => {
        return [
            "accountCardNumber",
            "firstName",
            "surname",
            "idNumber",
            "cellPhoneNumber",
            "reference"
        ]
    }

    return (
        <>
            {
                selectedCards &&
                selectedCards.length > 0 &&
                <Box sx={{ mt: 8 }}>
                    <Ptable data={getCardConfirmationData()}
                        tableHeaders={getCardConfirmationHeaders()}
                        tableBodies={getCardConfirmationFields()}
                    />

                    <Box sx={{ mt: 4, display: "flex", justifyContent: "right" }}>
                        <Button
                            onClick={handleBack}
                            disabled={cardAllocationSubmission.status === ReduxStatus.saving}
                            variant="outlined"
                            size="medium"
                        >
                            BACK
                        </Button>
                        <Button
                            disabled={cardAllocationSubmission.status === ReduxStatus.saving}
                            onClick={submitCardAllocation}
                            variant="contained"
                            size="medium"
                            styles={{ ml: 4 }}>
                            CONFIRM
                        </Button>
                    </Box>
                </Box>
            }
        </>
    )
}
