import React from 'react';

import { Container } from '@mui/material/';
import Title from '../../common/components/base/title';
import SearchCardTransactions from './components/SearchCardTransactions';

const DownloadCardTransactions = () => {
    return (
        <Container size='sm'>
            <Title title='Download all card transactions' subtitle={['Download a report of card transactions',]} />
            <SearchCardTransactions />
        </Container>
    );
};

export default DownloadCardTransactions;
