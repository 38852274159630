import React from "react";
import { Box, Container } from "@mui/material";
import Title from "../../../../common/components/base/title";
import CardConfirmationList from "./CardConfirmationList";
import { useDispatch, useSelector } from "react-redux";
import ReduxStatus from "../../../../common/constants/ReduxStatus";
import CardAllocationComplete from "./CardAllocationComplete";
import { cardAllocationActions } from "../../slice";
import Loader from "../../../../common/components/base/loader/Loader";
import useCustom from "../../../../common/components/hooks";

export default function CardConfirmation() {
    const dispatch = useDispatch();
    const { showAlert } = useCustom();

    const { cardAllocationSubmission, cards } = useSelector(state => state.cardAllocation);

    if (cardAllocationSubmission.status === ReduxStatus.saveFailed
        && !cardAllocationSubmission.errorMessageShown) {
        dispatch(cardAllocationActions.cardsAllocationErrorMessageShown(true));
        cardAllocationSubmission.errorMessages.forEach(message => {
            showAlert(message, "error");
        })
    }

    return (
        <Container>
            {cardAllocationSubmission.status === ReduxStatus.saved ?
                <CardAllocationComplete />
                :
                <Box>
                    <Title title="Confirm allocation" />
                    <CardConfirmationList />
                </Box>
            }
            {
                (cards.status === ReduxStatus.loading ||
                    cardAllocationSubmission.status === ReduxStatus.saving) &&
                <Loader open={true} />
            }
        </Container>
    )
}
