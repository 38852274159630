import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    FormControlLabel,
    FormLabel,
    Box
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Input from '../../common/components/base/input/Input';
import Button from '../../common/components/base/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import * as validation from './validation/RegistrationDetailsValidation';
import useCustom from '../../common/components/hooks/index';
import { getAccountDetails, saveRegistration } from './redux/thunk';
import { useNavigate } from 'react-router-dom';
import Loader from '../../common/components/base/loader/Loader';

const RegistrationDetailForm = () => {
    const dispatch = useDispatch();
    const { showAlert } = useCustom();
    const navigate = useNavigate();
   //const haveBusiness = useSelector(state => state.settingAccount.haveBusiness);
    const status = useSelector(state => state.settingAccount.saveDetails.status);
    const open = status === "loading" ? true : false;

    const details = useSelector((state) => state.settingAccount.accountDetails.data);

    const [stateValues, setStateValues] = useState({
        accountIsCompany: false,
        accountCompany: '',
        accountCompanyNumber: '',
        accountFirstName: '',
        accountLastName: '',
        accountIDNumber: '',
        accountTelephone: '',
        accountCellphone: '',
        accountPhysicalCity: '',
        accountPostalCity: '',
        accountPostalCode: '',
        accountVATNumber: '',
        accountPhysicalStreet: '',
        accountPhysicalCode: '',
        accountPhysicalBuilding: '',
        accountPostalBox: '',
        accountPhysicalIsPostal: ''

    });

    const [validationErrors, setValidationErrors] = useState({});

    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        dispatch(getAccountDetails());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setStateValues({
            ...stateValues,
            accountIsCompany: !!details.ACCOUNTISCOMPANY, // DB is 0/1, so !! to cast to boolean
            accountCompany: details.ACCOUNTCOMPANY,
            accountCompanyNumber: details.ACCOUNTCOMPANYNUMBER,
            accountFirstName: details.ACCOUNTFIRSTNAME,
            accountLastName: details.ACCOUNTLASTNAME,
            accountIDNumber: details.ACCOUNTIDNUMBER,
            accountTelephone: details.ACCOUNTTELEPHONE,
            accountCellphone: details.ACCOUNTCELLPHONE,
            accountPhysicalCity: details.ACCOUNTPHYSICALCITY,
            accountPostalCity: details.ACCOUNTPOSTALCITY,
            accountPostalCode: details.ACCOUNTPOSTALCODE,
            accountVATNumber: details.ACCOUNTVATNUMBER,
            accountPhysicalStreet: details.ACCOUNTPHYSICALSTREET,
            accountPhysicalCode: details.ACCOUNTPHYSICALCODE,
            accountPhysicalBuilding: details.ACCOUNTPHYSICALBUILDING,
            accountPostalBox: details.ACCOUNTPOSTALBOX,
            accountPhysicalIsPostal: details.ACCOUNTPHYSICALISPOSTAL === 1 ? false : true
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details]);

    useEffect(() => {
        if (isSubmit && isValidated()) {
            dispatch(saveRegistration(stateValues))
                .then((response) => {
                    if (response.type === "setting/saveRegistrationDetail/fulfilled") {
                        navigate('/dashboard')
                    } else {
                        showAlert("Something went wrong", 'error');
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmit]);

    const mystyles={
        margin: "0 auto",
        width: "80%"
    }
    const  mystyle= {
        paddingTop: "0px"
        
    };

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === "accountCompany" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountCompanyError: 'Company name is required' }));
        }
        if (name === "accountCompany" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountCompanyError: '' }));
        }

        if (name === "accountCompanyNumber" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountCompanyNumberError: 'Company number is required' }));
        }
        if (name === "accountCompanyNumber" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountCompanyNumberError: '' }));
        }

        if (name === "accountFirstName" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, isfirstNameError: 'First name is required' }));
        }
        if (name === "accountFirstName" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, isfirstNameError: '' }));
        }

        if (name === "accountLastName" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, islastNameError: 'Last name is required' }));
        }
        if (name === "accountLastName" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, islastNameError: '' }));
        }

        if (name === "accountIDNumber" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, isIdError: 'ID number is required' }));
        }
        if (name === "accountIDNumber" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, isIdError: '' }));
        }

        if (name === "accountTelephone" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscontactNumberError: 'Telephone number is required' }));
        }
        if (name === "accountTelephone" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscontactNumberError: '' }));
        }

        if (name === "accountCellphone" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscellphoneNumberError: 'Cell number is required' }));
        }
        if (name === "accountCellphone" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscellphoneNumberError: '' }));
        }

        if (name === "accountPhysicalCity" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, isaddressError: 'Physical address is required' }));
        }
        if (name === "accountPhysicalCity" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, isaddressError: '' }));
        }

        if (name === "accountPostalCity" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscityError: 'City is required' }));
        }
        if (name === "accountPostalCity" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscityError: '' }));
        }

        if (name === "accountPostalCode" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscodeError: 'Code is required' }));
        }
        if (name === "accountPostalCode" && value.length !== 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscodeError: '' }));
        }

        setStateValues({ ...stateValues, [name]: value });
        setIsSubmit(false);
    };

    const handleInputCompany = (e) => {
        const checked = e.target.checked;
        setStateValues({...stateValues, accountIsCompany: checked });
    };

    // const handleInputCompany = (event) => {
    //     dispatch(registrationDetailSlice.requirementsAccepted(event.target.checked));
    //     if (accountIsCompany === true) {
    //         dispatch(accountIsCompany.multipleCardsSelected(cards.data));
    //     }
    // }


    const handleSave = (e) => {
        e.preventDefault();

        if (validation.isNameError(stateValues.accountCompany)) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountCompanyError: 'Please fill in a valid company name.' }));
        }

        if (validation.iscnumError(stateValues.accountCompanyNumber)) {
            setValidationErrors(validationErrors => ({ ...validationErrors, accountCompanyNumberError: 'Please fill in a valid company number.' }));
        }

        if (validation.isfirstNameError(stateValues.accountFirstName)) {
            setValidationErrors(validationErrors => ({ ...validationErrors, isfirstNameError: 'Please fill in a valid first Name.' }));
        }

        if (validation.islastNameError(stateValues.accountLastName)) {
            setValidationErrors(validationErrors => ({ ...validationErrors, islastNameError: 'Please fill in a valid last Name.' }));
        }

        if (validation.isIdError(stateValues.accountIDNumber)) {
            setValidationErrors(validationErrors => ({ ...validationErrors, isIdError: 'Please fill in a valid ID.' }));
        }

        if (validation.iscontactNumberError(stateValues.accountTelephone)) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscontactNumberError: 'Please fill in a valid Contact Number.' }));
        }

        if (validation.iscellphoneNumberError(stateValues.accountCellphone)) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscellphoneNumberError: 'Please fill in a valid Cellphone Number.' }));
        }

        if (validation.isaddressError(stateValues.accountPhysicalCity)) {
            setValidationErrors(validationErrors => ({ ...validationErrors, isaddressError: 'Please fill in a valid Address.' }));
        }

        if (validation.iscityError(stateValues.accountPostalCity)) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscityError: 'Please fill in a valid City.' }));
        }

        if (validation.iscodeError(stateValues.accountPostalCode)) {
            setValidationErrors(validationErrors => ({ ...validationErrors, iscodeError: 'Please fill in a valid code.' }));
        }

        setIsSubmit(true);
    }

    const isValidated = () => {
        for(const key in validationErrors) {
            if(validationErrors[key] !== '') {
                return false;
            }
        }
        return true;
    }
    return (
        <form noValidate autoComplete='off'>
            <Loader open={open} />
            <Grid container spacing={3} style={mystyles}>
                <Grid item xs={12} >
                    <Box>
                        <Typography variant='h4' component='h3' color='primary.light'>
                            1. BUSINESS DETAILS
                        </Typography>
                    </Box>
                    <FormControlLabel
                        label='Select if you have a business'
                        aria-label='controlled'
                        control={
                            <Checkbox color="secondary"
                                checked={stateValues.accountIsCompany}
                                onChange={handleInputCompany}
                            />
                        }
                />

                    <Input
                        inputLabel='COMPANY NAME'
                        name='accountCompany'
                        data-testid='accountCompanyInput'
                        value={stateValues.accountIsCompany === true ? stateValues.accountCompany : ''}
                        type='text'
                        fullWidth
                        required
                        onChange={(e) => handleInput(e)}
                        error={validationErrors.accountCompanyError ? true : false}
                        helperText={validationErrors.accountCompanyError}
                        disabled={stateValues.accountIsCompany === true ? false : true}
                    />
                    <Input
                        inputLabel='COMPANY / CC NUMBER'
                        name='accountCompanyNumber'
                        data-testid='accountCompanyNumberInput'
                        value={stateValues.accountIsCompany === true ? stateValues.accountCompanyNumber : ''}
                        type='text'
                        fullWidth
                        required
                        onChange={(e) => handleInput(e)}
                        error={validationErrors.accountCompanyNumberError ? true : false}
                        helperText={validationErrors.accountCompanyNumberError}
                        disabled={stateValues.accountIsCompany === true ? false : true}
                    />

                    <FormLabel
                        color='secondary'
                        id='delivery-method-group-label'
                        sx={{
                            color: 'primary.subtitle',
                        }}

                    >
                        <Typography variant='h4' component='h4' sx={{
                            mt: 3, mb: 3
                        }}>*Required fields</Typography>
                    </FormLabel>
                </Grid>
                <Grid item xs={12} m={0} p={0}>
                    <Box>
                        <Typography variant='h4' component='h3' color='primary.light'>
                            2. PERSONAL INFORMATION
                        </Typography>
                    </Box>
                    <Input
                        inputLabel='FIRST NAME*'
                        name='accountFirstName'
                        value={stateValues.accountFirstName || ''}
                        type='text'
                        fullWidth
                        required
                        onChange={(e) => handleInput(e)}
                        error={validationErrors.isfirstNameError ? true : false}
                        helperText={validationErrors.isfirstNameError}

                    />
                    <Input
                        inputLabel='LAST NAME*'
                        name='accountLastName'
                        value={stateValues.accountLastName || ''}
                        type='text'
                        fullWidth
                        required
                        onChange={(e) => handleInput(e)}
                        error={validationErrors.islastNameError ? true : false}
                        helperText={validationErrors.islastNameError}

                    />
                    <Input
                        inputLabel='ID NUMBER*'
                        name='accountIDNumber'
                        value={stateValues.accountIDNumber || ''}
                        type='text'
                        fullWidth
                        required
                        onChange={(e) => handleInput(e)}
                        error={validationErrors.isIdError ? true : false}
                        helperText={validationErrors.isIdError}


                    />
                    <Input
                        inputLabel='CONTACT NUMBER*'
                        name='accountTelephone'
                        value={stateValues.accountTelephone || ''}
                        type='text'
                        fullWidth
                        required
                        onChange={(e) => handleInput(e)}
                        error={validationErrors.iscontactNumberError ? true : false}
                        helperText={validationErrors.iscontactNumberError}

                    />
                    <Input
                        inputLabel='CELLPHONE NUMBER*'
                        name='accountCellphone'
                        value={stateValues.accountCellphone || ''}
                        type='text'
                        fullWidth
                        required
                        onChange={(e) => handleInput(e)}
                        error={validationErrors.iscellphoneNumberError ? true : false}
                        helperText={validationErrors.iscellphoneNumberError}

                    />
                    <Input
                        inputLabel='ADDRESS*'
                        name='accountPhysicalCity'
                        value={stateValues.accountPhysicalCity || ''}
                        type='text'
                        fullWidth
                        required
                        onChange={(e) => handleInput(e)}
                        error={validationErrors.isaddressError ? true : false}
                        helperText={validationErrors.isaddressError}

                    />

                </Grid>
                <Grid item xs={8} sx={{ pt: 0 }} style={mystyle}>
                    <Input
                        inputLabel='CITY*'
                        name='accountPostalCity'
                        value={stateValues.accountPostalCity || ''}
                        type='text'
                        fullWidth
                        required
                        onChange={(e) => handleInput(e)}
                        error={validationErrors.iscityError ? true : false}
                        helperText={validationErrors.iscityError}

                    />

                </Grid>
                <Grid item xs={4} sx={{ pt: 0 }} style={mystyle}>
                    <Input
                        inputLabel='CODE*'
                        name='accountPostalCode'
                        value={stateValues.accountPostalCode || ''}
                        type='text'
                        fullWidth
                        required
                        onChange={(e) => handleInput(e)}
                        error={validationErrors.iscodeError ? true : false}
                        helperText={validationErrors.iscodeError}

                    />

                </Grid>
                <Grid item xs={12} style={mystyle}>
                    <Input
                        inputLabel='VAT NUMBER'
                        name='accountVATNumber'
                        value={stateValues.accountVATNumber || ''}
                        type='text'
                        fullWidth
                        onChange={(e) => handleInput(e)}
                    />
                    <Button
                        onClick={handleSave}
                        size='medium'
                        variant='contained'
                        styles={{ margin: '40px 20px 0px 0px' }}
                        disabled={!isValidated()}
                    >
                        SAVE
                    </Button>
                </Grid>
            </Grid>
        </form>

    );
}

export default RegistrationDetailForm;
