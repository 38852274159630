import React from 'react';
import Box from "@mui/material/Box";
import Text from "../../../common/components/base/text"
import SearchBar from "../../../common/components/base/search/Search";
import { useDispatch, useSelector } from "react-redux";
import { RadioGroup, Grid, FormControlLabel, FormGroup } from '@mui/material';
import CheckRadio from "../../../common/components/base/checkradio";
import Checkbox from "../../../common/components/base/checkbox";
import { manageCardActions } from '../slice';
import { fetchCards } from '../thunk';
import AvailableCardsList from './AvailableCardsList';
import Loader from '../../../common/components/base/loader/Loader';

const filterTopics = [
    {
        name: "Card Number",
        id: "cardNumber"
    },
    {
        name: "Card Holder",
        id: "cardHolder"
    },
    {
        name: "Card Holder Requiring FICA",
        id: "cardHolderFica"
    },
    {
        name: "ID/Passport Number",
        id: "idNumber"

    },
    {
        name: "Card Expiry Date",
        id: "cardExpiry"
    },
    {
        name: "Reference Number",
        id: "cardReference"
    }
]

export default function ManageSearch() {
    const dispatch = useDispatch();
    const filter = useSelector(state => state.manageCard.cards.filter)
    const cards = useSelector(state => state.manageCard)
    const status = useSelector(state => state.manageCard.cards.status);
    const open = status === "loading" ? true : false;

    const handleChange = (event) => {
        const { name, value } = event.target;
        dispatch(manageCardActions.cardFilterChanged({ [name]: value }))
    }

    const handleTopicChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            dispatch(manageCardActions.cardFilterChanged({ searchField: value }));
        }
    }

    const handleExcludeChange = (event) => {
        const { name, checked } = event.target;
        dispatch(manageCardActions.cardFilterChanged({ [name]: checked }))
    }

    const handleCardSearchSubmit = (event) => {
        event.preventDefault();
        dispatch(fetchCards(filter));
    }

    return (
        <Box sx={{ mt: 8 }}>
            <Text light>
                FIND
            </Text>
            <Loader open={open} />
            <Box sx={{ mt: 1 }} >
                <SearchBar
                    onSubmit={handleCardSearchSubmit}
                    onChange={handleChange}
                    name="searchString" value={filter.query}
                    placeholder="Enter your search here" />
                <Box sx={{ pt: 1 }}>
                    <Text light>(LEAVE BLANK TO SHOW ALL)</Text>
                </Box>
                <hr />

                <Grid container sx={{ display: "flex", mt: 3 }}>
                    <Grid item xs={8}>
                        <Text light>FILTER BY TOPIC</Text>
                        <RadioGroup
                            row
                            onChange={handleTopicChange}
                            name="searchField"
                        >
                            {
                                filterTopics.map((topic) => (
                                    <FormControlLabel
                                        key={topic.id + '-filter-topic'}
                                        value={topic.id}
                                        control={<CheckRadio color="secondary"
                                            checked={filter?.searchField === topic.id} />}
                                        label={<Text>{topic.name}</Text>} />
                                ))
                            }

                        </RadioGroup>
                    </Grid>
                    <Grid item xs={4} >
                        <Text light>EXCLUDE</Text>
                        <FormGroup row>
                            <FormControlLabel
                                name="excludeExpired"
                                onChange={handleExcludeChange}
                                control={<Checkbox color="secondary" size="small"
                                    checked={Boolean(filter.excludeExpired)} />}
                                label={<Text>Expired Cards</Text>}
                            />
                            <FormControlLabel
                                name="excludeStopped"
                                onChange={handleExcludeChange}
                                control={<Checkbox color="secondary" size="small"
                                    checked={Boolean(filter.excludeStopped)} />}
                                label={<Text>Stopped Cards</Text>} />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Box>
            {
                cards && cards.cards.data &&
                cards.cards.data.length > 0 &&
                <div>
                    <AvailableCardsList />
                </div>
            }
        </Box>
    );
}
