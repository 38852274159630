import { createSlice } from '@reduxjs/toolkit';
import { fetchCards, getCardInfo, getStopReason, verifyBearerInfo, verifyCardStop, verifyCardEnable, verifyCardRetire } from './thunk';
import ReduxStatus from '../../common/constants/ReduxStatus';

const initialState = {
    cards: {
        data: [],
        totalRecords: 0,
        metadata: {
            OFFSET: 0,
            LIMIT: 10,
            TOTAL: 0,
        },
        currentPage: 1,
        status: ReduxStatus.empty,
        errorMessages: [],
        errorMessageShown: false,
        filter: {
            searchField: 'cardNumber',
            searchString: '',
            offset: 0,
            orderByField: 'cardNumber',
            orderAsc: true
        },
    },
    cardInfo: {
        data: {},
        errorMessages: [],
        status: ReduxStatus.empty,

    },
    stopReasons: {
        data: [],
        errorMessages: [],
        status: ReduxStatus.empty,
    }
};

export const manageCardSlice = createSlice({
    name: 'manageCard',
    initialState,
    reducers: {
        reset: () => initialState,
        cardFilterChanged: (state, action) => {
            state.cards.filter = { ...state.cards.filter, ...action.payload }
        },
        errorMessageChanged: (state, action) => {
            state.errorMessages = action.payload;
        }
    },
    extraReducers: {
        [fetchCards.pending]: (state, action) => {
            if (action.meta.arg && !action.meta.arg.initialLoad) {
                state.cards.status = ReduxStatus.loading;
            }
          
        },
        [fetchCards.fulfilled]: (state, action) => {
            state.cards.data = action.payload["ALLOCATEDCARDS"];
            state.cards.metadata = action.payload["METADATA"];

            if (action.payload["METADATA"]) {
                state.cards.totalRecords =
                    action.meta.arg && action.meta.arg.initialLoad
                        ? action.payload["METADATA"]["TOTAL"]
                        : state.cards.totalRecords
            }

            state.cards.status = ReduxStatus.loaded;
        },
        [fetchCards.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        //
        [getCardInfo.pending]: (state, action) => {
            state.cardInfo.status = ReduxStatus.loading;
        },
        [getCardInfo.fulfilled]: (state, action) => {
            state.cardInfo.data = action.payload;
            state.cardInfo.status = ReduxStatus.loaded;

        },
        [getCardInfo.rejected]: (state, action) => {
            state.cardInfo.errorMessages = action.payload;
            state.cardInfo.status = ReduxStatus.loadFailed;
        },
        //
        [verifyBearerInfo.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [verifyBearerInfo.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [verifyBearerInfo.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        //
        [verifyCardStop.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [verifyCardStop.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [verifyCardStop.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        //
        [verifyCardEnable.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [verifyCardEnable.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [verifyCardEnable.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        //
        [verifyCardRetire.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [verifyCardRetire.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;

        },
        [verifyCardRetire.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
        //
        [getStopReason.pending]: (state, action) => {
            state.stopReasons.status = ReduxStatus.loading;
        },
        [getStopReason.fulfilled]: (state, action) => {
            state.stopReasons.data = action.payload;
            state.stopReasons.status = ReduxStatus.loaded;
        },
        [getStopReason.rejected]: (state, action) => {
            state.stopReasons.errorMessages = action.payload;
            state.stopReasons.status = ReduxStatus.loadFailed;
        }
    }
});

export const manageCardActions = manageCardSlice.actions
export default manageCardSlice.reducer;
