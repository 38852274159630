
export const PayCardIconContentClose = () => {
  return (
    <img src={require(`../../../assets/images/icons/icon_content_close.png`)} alt="" />
  );
};

export const PayCardIconContentCopy = () => {
  return (
    <img src={require(`../../../assets/images/icons/icon_content_copy.png`)} alt="" />
  );
};

export const PayCardIconContentGoing = () => {
  return (
    <img src={require(`../../../assets/images/icons/icon_content_going.png`)} alt="" />
  );
};

export const PayCardIconUser = () => {
  return (
    <img src={require(`../../../assets/images/icons/icon_user.png`)} alt="" />
  );
};

