import React from "react";
import Typography from "@mui/material/Typography";

const lightStyle = {
    color: "#858d9d",
    fontSize: 11,
    letterSpacing: 1,
    fontWeight: 400,
    lineSpacing: 1
}

export default function Text({light, sx, ...props}){
    const _lightStyle = light?lightStyle:{};
    return (
        <Typography
            sx={{fontSize: props.size?props.size:15,  ..._lightStyle, ...sx}}
            {...props}
        >
            {props.children}
        </Typography>
    )
}
