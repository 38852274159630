import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';

import Button from '../../common/components/base/button/Button';
import { closeDialog } from '../slice';

const SendEmailMfaForm = () => {
    const userEmail = useSelector(state => state.login.userEmail);

    const dispatch = useDispatch();
    const handleOk = () => {
        dispatch(closeDialog());
    }

    return (
        <Grid container spacing={1} p={1}>
            <Grid item xs={12} display="flex">
                <Typography variant="body2" mt={0.5} ml={0.5}>Instructions to setup your MFA have been sent to your email address at {userEmail}</Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
                <Box textAlign='center'>
                    <Button variant="contained" size="medium" onClick={handleOk}
                            data-testid="btn-ok">OK</Button>
                </Box>
            </Grid>
        </Grid>
    );
}

export default SendEmailMfaForm;
