import { createSlice } from '@reduxjs/toolkit';
import { givePayCard } from './thunk';
import ReduxStatus from '../../common/constants/ReduxStatus';

const initialState = {
    status: ReduxStatus.empty,
    data: [],
    errorMessages: [],
};

export const givePayCardSlice = createSlice({
    name: 'givePayCard',
    initialState,
    reducers: {},
    extraReducers: {
        [givePayCard.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [givePayCard.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [givePayCard.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
    }
});

export default givePayCardSlice.reducer;
