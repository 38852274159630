import Http from "../../common/utils/Http";
import { addUrlParams } from "../../common/utils/helper";

const FETCH_ALLOCATED_ENDPOINT = "/pr/reports/allocatedCards";
const FETCH_UNALLOCATED_ENDPOINT = "/pr/reports/unallocatedCards";
const FETCH_CARDS_XLS_ENDPOINT = "/pr/reports/downloadAllocatedAndUnallocatedCardsInXLS?";
const FETCH_CARDS_PDF_ENDPOINT = "/pr/reports/viewAllocatedAndUnallocatedCardsInPDF?";

const fetchAllocatedCards = async (filters = null) => {
    let url = `${FETCH_ALLOCATED_ENDPOINT}?`;
    if (filters) {
        Object.keys(filters).forEach(key => {
            url += `${key}=${filters[key]}&`;
        })
        url = url.slice(0, -1);
    }
    try {
        let response = await Http.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const fetchUnAllocatedCards = async (filters = null) => {
    let url = `${FETCH_UNALLOCATED_ENDPOINT}?`;
    if (filters) {
        Object.keys(filters).forEach(key => {
            url += `${key}=${filters[key]}&`;
        })
        url = url.slice(0, -1);
    }
    try {
        let response = await Http.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const fetchCardsDocumentXLS = async (filters) => {
    let url = addUrlParams(filters, FETCH_CARDS_XLS_ENDPOINT);
    try {
        const response = await Http.get(url, {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/vnd.ms-excel',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const fetchCardsDocumentPDF = async (filters) => {
    let url = addUrlParams(filters, FETCH_CARDS_PDF_ENDPOINT);
    try {
        const response = await Http.get(url, {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const api = {
    fetchAllocatedCards,
    fetchUnAllocatedCards,
    fetchCardsDocumentXLS,
    fetchCardsDocumentPDF
};

export default api;
