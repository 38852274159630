import React, { useEffect } from "react";
import { DateTime } from "luxon";
import Title from "../../../../common/components/base/title";
import { Box, Container, Grid, Typography } from "@mui/material";
import Text from "../../../../common/components/base/text";
import DatePicker from "../../../../common/components/base/pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { profileHistoryActions } from "../../slice";
import Button from "../../../../common/components/base/button/Button";
import { fetchStatements, fetchStatementsDocumentPDF, fetchStatementsDocumentXLS } from "../../thunk";
import ReduxStatus from "../../../../common/constants/ReduxStatus";
import useCustom from "../../../../common/components/hooks";
import { downloadData, PROFILE_HISTORY_STATEMENTS_URL } from "../../common/utils";

export default function DateRangeSelectionForm() {
    const dispatch = useDispatch();
    const { showAlert } = useCustom();
    const navigate = useNavigate();

    const { statement, docDownload } = useSelector(state => state.profileHistory);

    const handleChange = (name, value) => {
        let payload = {
            ...statement.filter,
            [name]: DateTime.fromJSDate(value).toFormat('yyyy-MM-dd')
        }
        dispatch(profileHistoryActions.statementFilterUpdated(payload));
    }

    const handleView = () => {
        dispatch(fetchStatements(statement.filter));
        dispatch(fetchStatementsDocumentPDF(statement.filter));
        dispatch(fetchStatementsDocumentXLS(statement.filter));
    }

    const handleDownload = () => {
        dispatch(fetchStatementsDocumentXLS(statement.filter));
        dispatch(profileHistoryActions.statementDownloadRequested(true));
    }

    useEffect(() => {
        if (docDownload.data && docDownload.shouldDownload && !docDownload.downloaded) {
            dispatch(profileHistoryActions.statementDownloadStatusUpdated(true));
            dispatch(profileHistoryActions.statementDownloadRequested(false));
            downloadData(docDownload.data, `${statement.filter.startDate} - ${statement.filter.endDate}.xls`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docDownload])

    const isValidDateRange = () => {
        let valid = false;
        let filter = statement.filter;
        if (filter.startDate && filter.endDate) {
            let startDate = DateTime.fromISO(filter.startDate);
            let endDate = DateTime.fromISO(filter.endDate);
            let diffObj = endDate.diff(startDate, "days").toObject()
            valid = (diffObj.days > 0 && diffObj.days < 8);
        }
        return valid;
    }

    useEffect(() => {
        if (statement.status === ReduxStatus.loadFailed && !statement.errorMessageShown) {
            dispatch(profileHistoryActions.loadingStatementsErrorMessageShown(true));
            statement.errorMessages.forEach(message => {
                showAlert(message, 'info');
            })
        }

        if (docDownload.status === ReduxStatus.loadFailed && !docDownload.errorMessageShown) {
            dispatch(profileHistoryActions.docDownloadErrorMessageShown(true));
            docDownload.errorMessages.forEach(message => {
                showAlert(message, 'info');
            })
        }

        if (statement.status === ReduxStatus.loading) {
            navigate(PROFILE_HISTORY_STATEMENTS_URL);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statement, docDownload])

    return (
        <Container>
            <Title
                title="View your profile history"
                subtitle="Select a date range for your statements."
                subtitleArray={[]}
            />
            <Box sx={{ mt: 8}}>
                <Typography variant="h6" sx={{ color: "#3c4b6c", fontSize: 18, fontWeight: 400, mb: 2 }}>
                    SELECT A DATE
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ pr: 1, mr: 1 }}>
                            <Text light sx={{ mb: -2 }}>
                                START DATE
                            </Text>
                            <DatePicker
                                fullWidth
                                value={statement.filter.startDate}
                                name='startDate'
                                onValueChange={(date) => {
                                    handleChange("startDate", date);
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Text light sx={{ mb: -2 }}>
                                END DATE
                            </Text>
                            <DatePicker
                                fullWidth
                                value={statement.filter.endDate}
                                name='endDate'
                                onValueChange={(date) => {
                                    handleChange("endDate", date);
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 4, display: "flex" }}>
                    <Box>
                        <Button
                            onClick={handleView}
                            color="secondary"
                            variant="contained"
                            disabled={(statement.status === ReduxStatus.loading) || !isValidDateRange()}
                        >
                            VIEW
                        </Button>
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Button
                            onClick={handleDownload}
                            color="secondary"
                            variant="outlined"
                            disabled={(statement.status === ReduxStatus.loading ||
                                docDownload.status === ReduxStatus.loading)}
                        >
                            DOWNLOAD
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}
