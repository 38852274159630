import React, { useState } from 'react';
import { Grid, Box, Typography, Alert } from '@mui/material/';
import Text from '../../../common/components/base/text/Text';
import DatePicker from '../../../common/components/base/pickers/DatePicker';
import Button from '../../../common/components/base/button/Button';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { searchTransactions } from '../redux/thunk';
import ReduxStatus from '../../../common/constants/ReduxStatus';

const startDate = new Date();
const endDate = new Date();

const SearchCardTransactions = () => {
    const dispatch = useDispatch();
    const cardTransactions = useSelector((state) => state.downloadCardTransactions.transactions);

    const [stateValues, setStateValues] = useState({
        startDate,
        endDate,
        errorMsg: '',
    });

    const validateDate = (startDate, endDate) => {
        if (startDate - endDate > 0) {
            setStateValues({ ...stateValues, errorMsg: 'The start date cannot be greater than the end date.' });
            return false;
        } else {
            return true;
        }
    };

    const validateDateRange = (startDate, endDate) => {
        const startDateYear = startDate.getFullYear();
        const startDateMonth = startDate.getMonth();
        const endDateYear = endDate.getFullYear();
        const endDateMonth = endDate.getMonth();
        if (
            endDateMonth - startDateMonth > 2 ||
            (endDateMonth - startDateMonth > -10 && endDateYear - startDateYear > 0) ||
            (endDateMonth - startDateMonth === 0 && endDateYear - startDateYear !== 0) ||
            endDateYear - startDateYear > 1
        ) {
            setStateValues({ ...stateValues, errorMsg: 'The date range cannot exceed 2 months.' });
            return false;
        } else {
            return true;
        }
    };

    const handleDownload = () => {
        setStateValues({ ...stateValues, errorMsg: '' });
        if (
            validateDate(stateValues.startDate, stateValues.endDate) &&
            validateDateRange(stateValues.startDate, stateValues.endDate)
        ) {
            setStateValues({ ...stateValues, errorMsg: '' });
            const queryString = {
                startDate: DateTime.fromJSDate(stateValues.startDate).toISODate(),
                endDate: DateTime.fromJSDate(stateValues.endDate).toISODate(),
            };
            dispatch(searchTransactions(queryString));
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ mt: 3 }}>
                    <Typography variant='h6' color='primary.light'>
                        SELECT THE DATE RANGE FOR A REPORT OF CARD TRANSACTIONS
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{ pr: 1, mr: 1, mt: 2 }}>
                    <Text light sx={{ mb: -2 }}>
                        START DATE
                    </Text>
                    <DatePicker
                        fullWidth
                        value={stateValues.startDate}
                        name='startDate'
                        onValueChange={(date) => setStateValues({ ...stateValues, startDate: date })}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{ mt: 2 }}>
                    <Text light sx={{ mb: -2 }}>
                        END DATE
                    </Text>
                    <DatePicker
                        fullWidth
                        value={stateValues.endDate}
                        name='endDate'
                        onValueChange={(date) => setStateValues({ ...stateValues, endDate: date })}
                    />
                </Box>
            </Grid>
            {stateValues.errorMsg && (
                <Grid item xs={12}>
                    <Box sx={{ mt: 1 }}>
                        <Alert severity='error' sx={{ mt: 3 }}>
                            {stateValues.errorMsg}
                        </Alert>
                    </Box>
                </Grid>
            )}
            <Grid item xs={12} md={6}>
                <Button
                    onClick={handleDownload}
                    disabled={cardTransactions.status === ReduxStatus.loading}
                    size='medium'
                    variant='contained'
                    styles={{ margin: '40px 20px 0px 0px' }}
                >
                    Download
                </Button>
            </Grid>
            {cardTransactions.status === ReduxStatus.loadFailed && (
                <Grid item xs={12}>
                    <Alert severity='error' sx={{ mt: 5 }}>
                        {cardTransactions.errorMessages}
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

export default SearchCardTransactions;
