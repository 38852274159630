const { DateTime } = require("luxon");

export const dateFormating = (dt) => {
    var parsedDate = DateTime.fromISO(dt).toFormat('LLLL, dd yyyy');
    return parsedDate;
}

export const dateTimeFormating = (dt) => {
    var parsedDate = DateTime.fromJSDate(dt).toFormat('yyyy-MM-dd HH:mm:ss');
    return parsedDate;
}

export const formatCurrency = (value) => {
    return 'R\xa0' + new Intl.NumberFormat('en-ZA', {
        minimumFractionDigits: 2,
    }).format(value);
}

export const addUrlParams = (params, url) => {
    Object.keys(params).forEach(key => {
        url += `${key}=${params[key]}&`;
    });
    return url.slice(0, -1);
}
