import { createSlice } from '@reduxjs/toolkit';
import { getConditions } from './thunk';
import ReduxStatus from '../../common/constants/ReduxStatus';

const initialState = {
    status: ReduxStatus.empty,
    data: [],
    errorMessages: [],
};

export const conditionSlice = createSlice({
    name: 'conditions',
    initialState,
    reducers: {},
    extraReducers: {
        [getConditions.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [getConditions.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [getConditions.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        }
    }
});

export default conditionSlice.reducer;
