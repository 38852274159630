// Checks if the id is a string of numbers, with not more or less than 13 character.
export const REGEX_USER_ID = /^\d{13}$/;

// Checks if the number is a valid South African phone number.
export const REGEX_SA_PHONE_NUMBER = /[0](\d{9})|([0](\d{2})( |-)((\d{3}))( |-)(\d{4}))|[0](\d{2})( |-)(\d{7}|)|(\d{11})/; // source: https://regexlib.com/REDetails.aspx?regexp_id=1572

// Checks if the code is a string of numbers, with not more or less than 4 character.
export const REGEX_POSTAL_CODE = /^\d{4}$/;

export const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,20}$/;

export const REGEX_LETTER = /^[a-zA-Z]+$/;
