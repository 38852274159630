import Http from "../../common/utils/Http";
import { addUrlParams } from "../../common/utils/helper";

const FETCH_STATEMENTS_ENDPOINT = "/pr/reports/account-statement?";
const FETCH_STATEMENTS_XLS_ENDPOINT = "/pr/reports/downloadCardStatement?"
const FETCH_STATEMENTS_PDF_ENDPOINT = "/pr/reports/viewCardStatementInPDF?";

const fetchStatements = async (filters = null) => {
    let url = `${FETCH_STATEMENTS_ENDPOINT}`;
    if (filters) {
        Object.keys(filters).forEach(key => {
            url += `${key}=${filters[key]}&`;
        })
        url = url.slice(0, -1);
    }
    try {
        let response = await Http.get(url);
        return response;
    } catch (error) {
        throw error;
    }
};

const fetchStatementDocumentXLS = async (filters) => {
    let url = addUrlParams(filters, FETCH_STATEMENTS_XLS_ENDPOINT);
    try {
        const response = await Http.get(url, {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.ms-excel, application/excel, application/x-msexcel',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const fetchStatementDocumentPDF = async (filters) => {
    let url = addUrlParams(filters, FETCH_STATEMENTS_PDF_ENDPOINT);
    try {
        const response = await Http.get(url, {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const profileHistoryService = {
    fetchStatements,
    fetchStatementDocumentXLS,
    fetchStatementDocumentPDF
};

export default profileHistoryService;
