import { createSlice } from '@reduxjs/toolkit';
import ReduxStatus from '../../../common/constants/ReduxStatus';
import { searchTransactions } from './thunk';

const initialState = {
    transactions: {
        data: {},
        errorMessages: '',
        status: ReduxStatus.empty,
    },
};

export const downloadCardTransactionsSlice = createSlice({
    name: 'downloadCardTransactions',
    initialState,
    reducers: {},
    extraReducers: {
        [searchTransactions.pending]: (state, action) => {
            state.transactions.status = ReduxStatus.loading;
        },
        [searchTransactions.fulfilled]: (state, action) => {
            state.transactions.data = action.payload;
            state.transactions.status = ReduxStatus.loaded;
        },
        [searchTransactions.rejected]: (state, action) => {
            state.transactions.errorMessages = 'Could not download the card transactions. Please try again another time...';
            state.transactions.status = ReduxStatus.loadFailed;
        },
    },
});

export default downloadCardTransactionsSlice.reducer;
