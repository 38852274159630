import { createAsyncThunk } from '@reduxjs/toolkit';
import { userHeader } from './service';

export const dashboardUserHeader = createAsyncThunk(
    'header/user_header',
    async (params, { rejectWithValue }) => {
        try {
            const response = await userHeader(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
