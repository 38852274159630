const globalVariables = {
    RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
    API_URL: process.env.REACT_APP_API_URL,
    RAYGUN_API_KEY: process.env.REACT_APP_RAYGUN_API_KEY,
    RANDOM_NONCE: process.env.REACT_APP_RANDOM_NONCE,
    CHATBOT_ID: process.env.REACT_APP_PAYCARD_CHATBOT_ID,
    CHATBOT_CODE: process.env.REACT_APP_PAYCARD_CHATBOT_CODE
}

export default globalVariables;

if (!process.env.REACT_APP_RECAPTCHA_KEY) { throw new Error('process.env.REACT_APP_RECAPTCHA_KEY is missing!') }
if (!process.env.REACT_APP_API_URL) { throw new Error('process.env.REACT_APP_API_URL is missing!') }
if (!process.env.REACT_APP_RAYGUN_API_KEY) { throw new Error('process.env.REACT_APP_RAYGUN_API_KEY is missing!') }
if (!process.env.REACT_APP_RANDOM_NONCE) { throw new Error('process.env.REACT_APP_RANDOM_NONCE is missing!') }
if (!process.env.REACT_APP_RANDOM_NONCE) { throw new Error('process.env.REACT_APP_PAYCARD_CHATBOT_ID is missing!') }
if (!process.env.REACT_APP_RANDOM_NONCE) { throw new Error('process.env.REACT_APP_PAYCARD_CHATBOT_CODE is missing!') }
