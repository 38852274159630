import { useState } from "react";
import { makeStyles } from "@mui/styles";
import themeConstants from "../../../../common/constants/Theme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    TableSortLabel
} from "@mui/material";
import Text from "../../../../common/components/base/text";
import Button from "../../../../common/components/base/button/Button";
import {
    fetchAccountCards,
    fetchCardStatement,
    fetchCardStatementDocumentXLS,
    fetchCardStatementDocumentPDF,
} from "../../thunk";
import { CARD_ID_FIELD, CARD_STATEMENT_URL } from "../../common/utils";
import { visuallyHidden } from '@mui/utils';

const headCells = [
    {
        id: "ACCOUNTCARDNUMBER",
        label: "CARD NUMBER",
        width: 120,
        sortingKey: "cardNumber"
    },
    {
        id: "ACCOUNTCARDHOLDER",
        label: "CARD HOLDER",
        width: 200,
        sortingKey: "cardHolder"
    },
    {
        id: "ACCOUNTCARDIDNUMBER",
        label: "ID/PASSPORT NUMBER",
        width: 200,
        sortingKey: "idNumber"
    },
    {
        id: "ACCOUNTCARDEXPIRYDATE",
        label: "EXPIRY DATE",
        width: 100,
        sortingKey: "cardExpiry"
    },
    {
        id: "REFERENCENUMBER",
        label: "REFERENCE NUMBER",
        width: 170,
        sortingKey: "cardReference"
    },
    {
        id: "VOUCHERIDX",
        label: "SEQUENCE NUMBER",
        width: 150,
        sortingKey: "cardSequence"
    }
];

const useStyles = makeStyles({
    tableRow: {
        height: "80%",
    },
});

const prevDisabledStyles = {
    border: `1px solid ${themeConstants.BRAND_COLOR_SECONDARY}`,
    color: themeConstants.BRAND_COLOR_SECONDARY,
    opacity: 0.5,
};

export default function AvailableCardsList() {
    const [sortingOrder, setSortingOrder] = useState('asc');
    const [sortingOrderBy, setSortingOrderBy] = useState('cardNumber');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const { cards, statement } = useSelector((state) => state.cardStatement);

    const { OFFSET, LIMIT, TOTAL } = cards.metadata || {};

    const getCardStatement = (event, card) => {
        event.preventDefault();
        let filters = {
            days: statement.period,
            [CARD_ID_FIELD]: card?.ACCOUNTCARDID,
        };
        if (card?.ACCOUNTCARDID) {
            dispatch(fetchCardStatement(filters));
            dispatch(fetchCardStatementDocumentXLS(filters));
            dispatch(fetchCardStatementDocumentPDF(filters));
        }

        let cardStatementUrl = `${CARD_STATEMENT_URL}?${CARD_ID_FIELD}=${card.ACCOUNTCARDID}`;
        navigate(cardStatementUrl);
    };

    const handlePrevious = () => {
        let prevOffset = OFFSET - LIMIT;
        dispatch(
            fetchAccountCards({ ...cards.filter, limit: LIMIT, offset: prevOffset, orderByField: sortingOrderBy, orderAsc: sortingOrder === 'asc' })
        );
    };

    const handleNext = () => {
        let nextOffset = OFFSET + LIMIT;
        dispatch(
            fetchAccountCards({ ...cards.filter, limit: LIMIT, offset: nextOffset, orderByField: sortingOrderBy, orderAsc: sortingOrder === 'asc' })
        );
    };

    const prevIsDisabled = () => {
        return OFFSET === 0;
    };

    const nextIsDisabled = () => {
        return !(LIMIT + OFFSET < TOTAL);
    };

    const createSortHandler = (property) => (_event) => {
        const isAsc = sortingOrderBy === property && sortingOrder === 'asc';
        setSortingOrder(isAsc ? 'desc' : 'asc');
        setSortingOrderBy(property);
        dispatch(fetchAccountCards({ ...cards.filter, limit: LIMIT, offset: 0, orderByField: property, orderAsc: !isAsc }))
    };

    return (
        <Box sx={{ width: "100%", mt: 3 }}>
            <div sx={{ width: "100%", mb: 2 }}>
                <TableContainer className={classes.tableRow}>
                    <Table aria-labelledby="AvailableCards">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        style={{ minWidth: headCell.width }}
                                    >
                                        <TableSortLabel
                                            active={sortingOrderBy === headCell.sortingKey}
                                            direction={sortingOrderBy === headCell.sortingKey ? sortingOrder : 'asc'}
                                            onClick={createSortHandler(headCell.sortingKey)}
                                        >
                                            <Text light>
                                                {headCell.label}
                                            </Text>
                                            {sortingOrderBy === headCell.sortingKey &&
                                                <Box component="span" sx={visuallyHidden}>
                                                    {sortingOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cards.data.map((card, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Button
                                                data-testid = "StatementGet"
                                                className ="StatementGets"
                                                variant="text"
                                                color="secondary"
                                                sx={{}}
                                                onClick={(event) => getCardStatement(event, card)}
                                            >
                                                {card.ACCOUNTCARDNUMBERMASKED}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{card.ACCOUNTCARDFIRSTNAME + " " + card.ACCOUNTCARDSURNAME}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{card.ACCOUNTCARDIDNUMBER}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{card.ACCOUNTCARDEXPIRYDATE}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{card.ACCOUNTCARDREFERENCE}</Text>
                                        </TableCell>
                                        <TableCell>
                                            <Text>{card.VOUCHERIDX}</Text>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <Text>
                        Records {OFFSET + 1} - {OFFSET + cards.data.length} of {TOTAL}
                    </Text>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Box>
                        <Button
                            onClick={handlePrevious}
                            size="small"
                            style={prevIsDisabled() ? prevDisabledStyles : {}}
                            disabled={prevIsDisabled()}
                            variant="outlined"
                        >
                            Previous
                        </Button>
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Button
                            onClick={handleNext}
                            size="small"
                            disabled={nextIsDisabled()}
                            style={{ opacity: nextIsDisabled() ? 0.5 : 1 }}
                            variant="contained"
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
            <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)", marginTop: 20 }} />
        </Box>
    );
}
