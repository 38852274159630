import {
    FormControlLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Alert
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../common/components/base/button/Button';
import Checkbox from '../../../common/components/base/checkbox/checkbox';
import Text from '../../../common/components/base/text/Text';
import service from '../service';
import { postImportLoadFiles } from '../thunk';
import Loader from '../../../common/components/base/loader/Loader';
import ReduxStatus from '../../../common/constants/ReduxStatus';

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            width: 'auto !important',
        },
        buttons: {
            float: 'right',
            '& button': {
                width: '150px !important',
                paddingTop: '5px !important',
                paddingBottom: '2px !important',
                marginLeft: 15,
            },
            '& button:first-child': {
                marginRight: 15,
            },
            '& button:last-child': {
                marginLeft: 15,
            },
            '& button:disabled': {
                opacity: 0.5,
            },
            '& div': {
                width: 100,
                display: 'inline-block',
                textAlign: 'center',
            },
        },
        select: {
            width: '100%',
        },
    })
);

const NewBatchLoadStep2 = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const status = useSelector((state) => state.batchLoad.status);
    const { CLIENTBULKUPLOADUUID, UPLOADEDFILE, CLIENTTYPEOPTION, DATAARRAY, OPTGROUP, HASHEADER, ...STATE } =
        useSelector((state) => {
            return state.batchLoad.data;
        });

    const [errors, setErrors] = useState('');
    const [stateCurrentRow, setStateCurrentRow] = useState(0);
    const ourFields = service.getOurFieldsByDataArray(DATAARRAY);

    const initStateForm = {
        hasHeader: HASHEADER || false,
        ...ourFields.reduce((fields, ourField) => {
            fields[ourField] = STATE[ourField.toUpperCase()] || 'Not Mapped';
            return fields;
        }, {}),
    };

    const [stateFormValues, setStateFormValues] = useState(initStateForm);

    const handleOnClickPrev = () => {
        navigate({
            pathname: '../step1',
            search: '?uuid=' + CLIENTBULKUPLOADUUID,
        });
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        let optionHasBeenSelected = 0;

        OPTGROUP.forEach((o) => {
            optionHasBeenSelected += Object.values(stateFormValues).filter((value) => value === o.LABEL).length;
        });

        if (optionHasBeenSelected === 2) {
            const payload = {
                clientBulkUploadUUID: CLIENTBULKUPLOADUUID,
                clientTypeOption: CLIENTTYPEOPTION,
                uploadedFile: UPLOADEDFILE,
                ...stateFormValues,
            };
            dispatch(postImportLoadFiles(payload)).then((response) => {
                let pathname = '../step3';

                if (
                    !response.payload?.DOUBLEVOUCHERS?.DOUBLEVOUCHERS?.length &&
                    !response.payload?.FILEERRORS?.length
                ) {
                    pathname = '../step4';
                }

                navigate({
                    pathname,
                    search: '?uuid=' + CLIENTBULKUPLOADUUID,
                });
            });
        } else {
            setErrors('Please choose the correct options.');
        }
    };

    const handlePrevRow = () => {
        if (stateCurrentRow) {
            setStateCurrentRow(stateCurrentRow - 1);
        }
    };

    const handleNextRow = () => {
        if (stateCurrentRow < dataArray.length - 1) {
            setStateCurrentRow(stateCurrentRow + 1);
        }
    };

    const handleOnChangeOurField = (e, ourFieldName) => {
        const newState = {};
        newState[ourFieldName] = e.target.value;
        setStateFormValues({
            ...stateFormValues,
            ...newState,
        });
    };

    const handleOnChangeCheckbox = (e) => {
        setStateFormValues({
            ...stateFormValues,
            hasHeader: e.target.checked,
        });
    };

    const dataArray = [...DATAARRAY];

    if (stateFormValues.hasHeader) {
        dataArray.shift();
    }

    const yourData = dataArray[stateCurrentRow < dataArray.length ? stateCurrentRow : stateCurrentRow - 1];
    const prevDisabled = stateCurrentRow === 0;
    const nextDisabled = stateCurrentRow >= dataArray.length - 1;

    return (
        <>
            {status === ReduxStatus.loading && <Loader open={true} />}
            {status === ReduxStatus.loaded && 
            <form noValidate autoComplete='off' onSubmit={handleOnSubmit}>
                <Typography
                    variant="h6"
                    sx={{color: "#3c4b6c", fontSize: 20, fontWeight:400}}
                    marginBottom={'20px'}
                >
                    Take a moment to match up your data to our fields.
                </Typography>

                {errors && <Alert severity='error' sx={{ mt: 5, mb: 2 }}>{errors}</Alert>}
                {(!ourFields || !OPTGROUP || !yourData) && (
                    <Alert severity='error' sx={{ mt: 5 }}>
                        Could not load the data. Please try uploading your file again...
                    </Alert>         
                )}
                {ourFields && OPTGROUP && yourData && 
                <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>OUR FIELDS</TableCell>
                                <TableCell>
                                    <div className={classes.buttons}>
                                        <Button
                                            variant='outlined'
                                            size='small'
                                            onClick={handlePrevRow}
                                            disabled={prevDisabled}
                                        >
                                            Previous
                                        </Button>
                                        <Box>ROW {stateCurrentRow + 1}</Box>
                                        <Button
                                            variant='contained'
                                            size='small'
                                            onClick={handleNextRow}
                                            disabled={nextDisabled}
                                        >
                                            Next
                                        </Button>
                                    </div>
                                    YOUR DATA
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ourFields.map((ourField, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Select
                                                size='small'
                                                color='secondary'
                                                className={classes.select}
                                                key={index}
                                                value={stateFormValues[ourField]}
                                                onChange={(e) => handleOnChangeOurField(e, ourField)}
                                            >
                                                <MenuItem value={'Not Mapped'}>Not Mapped</MenuItem>
                                                {OPTGROUP.map((o) => {
                                                    return (
                                                        <MenuItem key={o.VALUE} value={o.LABEL}>
                                                            {o.LABEL}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </TableCell>
                                        <TableCell>{yourData[index]}</TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color='secondary'
                                                checked={stateFormValues?.hasHeader}
                                                onChange={handleOnChangeCheckbox}
                                            />
                                        }
                                        label={<Text>Ignore the first row when importing</Text>}
                                    />
                                    <p>
                                        Check this box if the first row are column headers ("first name" "last name"
                                        "email" etc.) instead of actual data.
                                    </p>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>}
                <Button
                    type='button'
                    size='medium'
                    variant='outlined'
                    styles={{ margin: '40px 20px 0px 0px' }}
                    onClick={handleOnClickPrev}
                >
                    Previous
                </Button>
                <Button type='submit' size='medium' variant='contained' styles={{ margin: '40px 20px 0px 0px' }}>
                    Next
                </Button>
            </form>}
        </>
    );
};

export default NewBatchLoadStep2;
