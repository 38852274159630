import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import Text from "../../../../common/components/base/text";

export default function TransactionStatementDocument() {
    const { pdf } = useSelector(state => state.profileHistory);
    return (
        <Box sx={{ mt: 8 }}>
            {pdf.data ?
                <embed
                    src={pdf.data}
                    id='profileStatementDocument'
                    alt='Profile Statement'
                    title='Profile Statement'
                    width='100%'
                    height='600px'
                    style={{ borderStyle: 'solid', borderWidth: '2px' }}
                    type='application/pdf'
                />
                :
                <Text light={true} sx={{ textAlign: "center" }}>
                    No Document data available
                </Text>
            }
        </Box>
    )
}
