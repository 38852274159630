import Http from "../../common/utils/Http";
import { addUrlParams } from "../../common/utils/helper";

const FETCH_ACCOUNT_CARDS_ENDPOINT = "/pr/cards/allocatedCards?";
const FETCH_CARD_STATEMENT_ENDPOINT = "/pr/reports/account-statement?";
const CARD_STATEMENT_DOCUMENT_PDF_ENDPOINT = "/pr/reports/viewCardStatementInPDF?";
const CARD_STATEMENT_DOCUMENT_XLS_ENDPOINT = "/pr/reports/downloadCardStatement?";

const fetchAccountCards = async (filters = null) => {
    let url = FETCH_ACCOUNT_CARDS_ENDPOINT;
    if (filters) {
        url = addUrlParams(filters, url);
    }
    try {
        let response = await Http.get(url);
        return response;
    } catch (error) {
        throw error;
    }
}

const fetchCardStatement = async (filters = null) => {
    let url = FETCH_CARD_STATEMENT_ENDPOINT;
    if (filters) {
        url = addUrlParams(filters, url);
    }
    try {
        let response = await Http.get(url);
        return response;
    } catch (error) {
        throw error;
    }
};

const fetchCardStatementDocumentXLS = async (filters) => {
    let url = addUrlParams(filters, CARD_STATEMENT_DOCUMENT_XLS_ENDPOINT);
    try {
        const response = await Http.get(url, {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.ms-excel, application/excel, application/x-msexcel',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const fetchCardStatementDocumentPDF = async (filters) => {
    let url = addUrlParams(filters, CARD_STATEMENT_DOCUMENT_PDF_ENDPOINT);
    try {
        const response = await Http.get(url, {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const cardStatementService = {
    fetchCardStatement,
    fetchCardStatementDocumentPDF,
    fetchCardStatementDocumentXLS,
    fetchAccountCards
};

export default cardStatementService;
