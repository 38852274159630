import React from 'react';
import FindPendingLoad from './findPendingLoad';

const CancelPendingLoad = () => {
    return (
        <FindPendingLoad />
    );
}

export default CancelPendingLoad;
