import React, { useEffect, useState } from 'react';

import Container from '@mui/material/Container';
import OrderCardConfirmation from './components/OrderCardConfirmation';
import Title from '../../../common/components/base/title/index';
import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency } from '../../../common/utils/helper';
import { useLocation } from 'react-router-dom';
import { sendCardOrder } from '../redux/thunk';

const OrderCardConfirm = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const balance = useSelector((state) => state.header.userHeader.BALANCEACCOUNT);
    const [order, setOrder] = useState({});

    const createSubtitleArray = () => {
        return [
            `Your balance is currently ${formatCurrency(balance)}. You have ${balance - order.totalCost >= 0 ? 'sufficient funds.' : 'insufficient funds.'
            }`,
            balance >= 0
                ? 'Your card order will be processed immediately.'
                : 'Your card order will be processed once sufficient funds have been added.',
        ];
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setOrder({
            cardOrderUUID: params.get('cardOrderUUID'),
            cardOrderQuantity: params.get('cardOrderQuantity'),
            cardUnitPrice: params.get('cardUnitPrice'),
            totalCardCost: params.get('totalCardCost'),
            clientFaceDeliveryCost: params.get('clientFaceDeliveryCost'),
            totalDeliveryCost: params.get('totalDeliveryCost'),
            totalCost: params.get('totalCost'),
            name: params.get('name'),
            idNumber: params.get('idNumber'),
            contactNumber: params.get('contactNumber'),
            altContactNumber: params.get('altContactNumber'),
            selectedDeliveryMethod: params.get('selectedDeliveryMethod'),
            building: params.get('building'),
            street: params.get('street'),
            city: params.get('city'),
            code: params.get('code'),
            bankBrancheName: params.get('bankBrancheName'),
            branchCode: params.get('branchCode'),
        });

        let cardOrderApiData = {
            cardOrderQuantity: params.get('cardOrderQuantity'),
            cardOrderDeliveryMethod: params.get('selectedDeliveryMethod'),
        };
        if (params.get('selectedDeliveryMethod') === '0') {
            cardOrderApiData = {
                ...cardOrderApiData,
                branchCode: params.get('branchCode'),
            };
        }
        dispatch(sendCardOrder(cardOrderApiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container size='sm'>
            {order && balance && (
                <>
                    <Title title='Confirm card order' subtitleArray={createSubtitleArray()} />
                    <OrderCardConfirmation order={order} newBalance={balance - order.totalCost} />
                </>
            )}
        </Container>
    );
};

export default OrderCardConfirm;
