import React, { useEffect } from 'react';
import { getFaqs } from './thunk';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../common/components/base/title/Title';
import { Typography, Container } from '@mui/material';
import ReactHtmlParser from 'html-react-parser';

const Faqs = () => {
    const faqs = useSelector(state => state.faqs.data.content);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getFaqs());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Title title='FAQ' subtitle='Frequently Asked Questions'></Title>
            <Container maxWidth='lg'>
                {
                    faqs && faqs.map((faqs, idx) => (
                        <div key={faqs.CONTENTID}>
                            <Typography color='primary.light' variant='h6' fontFamily='BentonSansPro_Regular' >{faqs.CONTENTHEADER}</Typography>
                            <Typography color='secondary.dark' variant='subtitle2' fontFamily='BentonSansPro_Regular'>{ReactHtmlParser(faqs.CONTENTDETAIL)}</Typography><br /><br />
                        </div>
                    ))
                }
            </Container>
        </div>
    );
};

export default Faqs;
