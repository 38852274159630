import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardHolderDetailsForm from "./CardHolderDetailsForm";
import { Box, Container } from "@mui/material";
import { cardAllocationActions } from "../../slice";
import { bearerValidationCards } from "../../thunk";
import {
    allItemsHaveField,
    ALLOCATE_CARD_CONFIRM_URL,
    cardHolderFieldNames,
    defaultCardHolderDetails,
    getItemFromList, 
    cardHolderRequiredFields,
    maxFieldSizes,
    minFieldSizes
} from "../../common/utils";
import Button from "../../../../common/components/base/button/Button";
import { useNavigate } from "react-router-dom";

export default function CardHolderDetailsList() {
    const { selectedCards } = useSelector(state => state.cardAllocation);
    const [initiallySelected, setInitiallySelected] = useState([]);
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = selectedCards.length;

    const activeCard = selectedCards?.length > 0 ? getItemFromList(selectedCards, "index", activeStep) : null;

    const dispatch = useDispatch();

    const setupSelectedCardsToAllocate = () => {
        let index = 0;
        let cards = []
        selectedCards.forEach(card => {
            cards.push({
                ...card,
                index,
            });
            index += 1;
        })
        dispatch(cardAllocationActions.multipleCardsSelected(cards));
    }

    useEffect(() => {
        if (selectedCards.length > 0
            &&
            (!allItemsHaveField(selectedCards, 'index')
                || selectedCards.length !== initiallySelected.length)) {
            setupSelectedCardsToAllocate();
            setInitiallySelected(selectedCards);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCards])

    const isFicaValid= async (card) => {
        let isValid = false;
        let payload = {
            "accountCardFirstName": card.cardHolderDetails.firstName,
            "accountCardLastName": card.cardHolderDetails.surname,
            "accountCardIdOrPassportNumber": card.cardHolderDetails.idNumber,
            "accountCardCellPhoneNumber": card.cardHolderDetails.cellPhoneNumber
        }

        let ficResponse = await dispatch(bearerValidationCards(payload));
        if (ficResponse.type === "cardAllocation/bearerValidationCards/fulfilled") {
            if(ficResponse.payload.trim() === ""){
                isValid = true
            }
        }
        return isValid;
    }

    const validateUserDetails = (card) => {
        let validDetails = true;
        let validationErrors = {};

        let _card = { ...card };
        if (!_card.cardHolderDetails) {
            _card['cardHolderDetails'] = defaultCardHolderDetails;
        }

        Object.keys(_card.cardHolderDetails).forEach(key => {
            if (Object.keys(cardHolderRequiredFields).includes(key) &&  _card.cardHolderDetails[key].trim() === "") {
                validationErrors[key] = `${cardHolderFieldNames[key]} is required`;
                validDetails = false;
            } else if (Object.keys(maxFieldSizes).includes(key) && _card.cardHolderDetails[key].length > maxFieldSizes[key]) {
                validationErrors[key] = `${cardHolderFieldNames[key]} is too long`;
                validDetails = false;
            } else if (Object.keys(minFieldSizes).includes(key) && _card.cardHolderDetails[key].length < minFieldSizes[key]) {
                validationErrors[key] = `${cardHolderFieldNames[key]} is too short`;
                validDetails = false;
            }
        });

        _card['validationErrors'] = validationErrors;

        dispatch(cardAllocationActions.selectedCardsUpdated(_card));
        return validDetails;
    }

    const validateAllUserDetails = async() => {
        let allValid = true;
        let i = 0;
        for(i = 0; i < selectedCards.length; i++ ){
            const card = getItemFromList(selectedCards, 'index', i);
            if (!validateUserDetails(card)) {
                setActiveStep(card.index);
                allValid = false;
                break
            }
            let ficaValid = await isFicaValid(card);
            if(!ficaValid){
                setActiveStep(card.index);
                allValid = false;
                break
            }
        }
          

        return allValid;
    }

    const handleCardDetailsSubmit = async () => {
        let allDataIsValid = await validateAllUserDetails()
        if(allDataIsValid){
            navigate(ALLOCATE_CARD_CONFIRM_URL);
        }
    }

    const handleNext = async() => {
        if (validateUserDetails(activeCard)) {
            let ficaValid = await isFicaValid(activeCard);
            if(ficaValid){
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const selectedCardsValid = () => {
        const isCardHolderDetailsFilled = selectedCards.every(card => {
            return !!card.cardHolderDetails
        })
        const cardHolderDetailsValid = selectedCards.every(card => {
            return !card.validationErrors || 
                (   
                    card.validationErrors && 
                    Object.keys(card.validationErrors).length === 0 && 
                    Object.keys(cardHolderRequiredFields).every(key => card.cardHolderDetails[key])
                )
        })
        return isCardHolderDetailsFilled && cardHolderDetailsValid;
    }

    return (
        <Container sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ width: "65%" }}>
                {
                    activeCard &&
                    <CardHolderDetailsForm card={activeCard}
                        activeStep={activeStep}
                        maxSteps={maxSteps}
                        handleBack={handleBack}
                        handleNext={handleNext}
                    />
                }
                <Box sx={{ mt: 4 }}>
                    <Button
                        data-testid="allocateCardSavesBtn"
                        onClick={handleCardDetailsSubmit}
                        size="medium"
                        variant="contained"
                        disabled={!selectedCardsValid()}
                    >
                        Continue
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}
