import { useEffect, useState } from 'react';
import {
    Container,
    Box
} from "@mui/material";
import { useSelector, useDispatch, useStore } from 'react-redux';
import Button from '../../../common/components/base/button/Button';
import LoadFundsSummary from './LoadFundsSummary';
import DataTable from '../../../common/components/base/datatable';
import LoadFundsToSearchFilter from './LoadFundsToSearchFilter';
import { setSelectedLoadTo, changeCardLoadToFilter } from "../slice";
import { searchCardLoadTo } from '../thunk';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import Loader from '../../../common/components/base/loader/Loader';
import ReduxStatus from '../../../common/constants/ReduxStatus';
import { searchLoadTableHeader } from './SearchLoadTableHeader';

const columns = searchLoadTableHeader("checkbox");

export default function SearchLoadTo() {
    const [searchLoadToSortingOrder, setSearchLoadToSortingOrder] = useState('asc');
    const [searchLoadToSortingOrderBy, setSearchLoadToSortingOrderBy] = useState('cardNumber');

    const cards = useSelector(state => state.loadFunds.cardLoadsTo.data);
    const status = useSelector(state => state.loadFunds.status);
    const noDataFoundMessage = useSelector(state => state.loadFunds.cardLoadsTo.errorMessages);
    const selectedLoadTo = useSelector(state => state.loadFunds.selectedLoadTo);
    const metaData = useSelector(state => state.loadFunds.cardLoadsTo.metaData);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const store = useStore();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        dispatch(setSelectedLoadTo([]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChange = (event, element) => {
        dispatch(setSelectedLoadTo(element));
    }

    const cardsToLoad = selectedLoadTo.map(item => item.ACCOUNTCARDID);

    const handleContinue = () => {
        let params = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            params[param] = value;
        }
        params["cardsToLoad"] = cardsToLoad.join();
        navigate({
            pathname: "/dashboard/load-funds-from/card-loads",
            search: `?${createSearchParams(params)}`
        });
    }

    const handlePagination = (offset, limit) => {
        dispatch(changeCardLoadToFilter({ offset, limit }));
        let filter = store.getState().loadFunds.cardLoadsTo.filter;
        dispatch(searchCardLoadTo({ ...filter }));
    }

    const handleSearchLoadToRequestSort = (property) => {
        const isAsc = searchLoadToSortingOrderBy === property && searchLoadToSortingOrder === 'asc';
        setSearchLoadToSortingOrder(isAsc ? 'desc' : 'asc');
        setSearchLoadToSortingOrderBy(property);
        dispatch(changeCardLoadToFilter({ orderByField: property, orderAsc: !isAsc, offset: 0 }));
        let filter = store.getState().loadFunds.cardLoadsTo.filter;
        dispatch(searchCardLoadTo({ ...filter }));
    };

    const rows = cards.map(card => ({ ...card, ID: card.ACCOUNTCARDID }));

    return (
        <div>
            <Container maxWidth="xl" sx={{ width: '1350px !important' }}>
                <LoadFundsSummary
                    title="Load funds into card"
                    subtitle="Load funds into cards from your profile or transfer funds from a stopped card."
                />
                <LoadFundsToSearchFilter />
                <DataTable
                    columns={columns}
                    rows={rows}
                    onChange={onChange}
                    noDataFoundMessage={noDataFoundMessage}
                    metaData={metaData}
                    handlePagination={handlePagination}
                    onRequestSort={handleSearchLoadToRequestSort}
                    sortingOrder={searchLoadToSortingOrder}
                    sortingOrderBy={searchLoadToSortingOrderBy}
                />
                <Box sx={{ mt: 3 }}>
                    <Button
                        onClick={handleContinue}
                        size="medium"
                        variant="contained"
                        disabled={rows.length === 0 || cardsToLoad.length === 0}
                    >
                        Continue
                    </Button>
                </Box>
            </Container>
            {status === ReduxStatus.loading && <Loader open={true} />}
        </div>
    );
};
