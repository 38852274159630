import { createSlice } from '@reduxjs/toolkit';
import { getPayCard } from './thunk';
import ReduxStatus from '../../common/constants/ReduxStatus';

const initialState = {
    status: ReduxStatus.empty,
    data: [],
    errorMessages: [],
};

export const getPayCardSlice = createSlice({
    name: 'getPayCard',
    initialState,
    reducers: {},
    extraReducers: {
        [getPayCard.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [getPayCard.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [getPayCard.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
    }
});

export default getPayCardSlice.reducer;
