import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./service";

export const fetchCards = createAsyncThunk(
    "manageCard/fetchCards",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await api.fetchCards(filters)
            return response.data;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const getCardInfo = createAsyncThunk(
    "manageCard/getCardInfo",
    async (accountId, { rejectWithValue }) => {

        try {
            let response = await api.getCardInfo(accountId)
            return response.data;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const verifyBearerInfo = createAsyncThunk(
    'manageCard/verifyBearerInfo',
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.verifyBearerInfo(params);
            return response.data;
        } catch (error) {
            let errorMessage = error?.response?.headers?.explanation ?? error?.response?.data;
            return rejectWithValue(errorMessage);
        }
    }
)

export const verifyCardStop = createAsyncThunk(
    'manageCard/verifyCardStop',
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.verifyCardStop(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const verifyCardEnable = createAsyncThunk(
    'manageCard/verifyCardEnable',
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.verifyCardEnable(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const verifyCardRetire = createAsyncThunk(
    'manageCard/verifyCardRetire',
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.verifyCardRetire(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const getStopReason = createAsyncThunk(
    'manageCard/getStopReason',
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.getStopReason();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
