import React, { useEffect } from "react";
import CardAllocationOptions from "./filters/CardAllocationOptions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "../../../../common/components/base/checkbox";
import Button from "../../../../common/components/base/button/Button";
import Box from "@mui/material/Box"

import CardAllocationFilters from "./filters/CardAllocationFilters";
import AvailableCardsList from "./availableCards/AvailableCardsList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { cardAllocationActions } from "../../slice";
import ReduxStatus from "../../../../common/constants/ReduxStatus";
import { fetchCards, fetchLinkedCards } from "../../thunk";
import SelectedCardsList from "./selectedCards/SelectedCardsList";
import CardAllocationSummary from "../../common/components/CardAllocationSummary";
import { Container } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Loader from "../../../../common/components/base/loader/Loader";
import FICApaycard from "../../../../assets/fica/FICApaycard.pdf"
import useCustom from "../../../../common/components/hooks";
import { ALLOCATE_CARD_HOLDER_DETAILS_URL } from "../../common/utils";
import Text from "../../../../common/components/base/text";

const handleFica = () => {
    window.open(FICApaycard, 'fica', 'width=850,height=600');
}

const RequirementsLabel = () => (
    <span>
        <Text>
            I have read <span><a onClick={handleFica} href="#!">the Standard Bank FICA requirements</a></span>
        </Text>
    </span>
)

export default function SelectCards() {
    const navigate = useNavigate();
    const { showAlert } = useCustom();

    const dispatch = useDispatch();
    const {
        allocationOption,
        requirementsAccepted,
        linkedCards,
        cards,
        selectedCards
    } = useSelector(state => state.cardAllocation);

    const handleFetchCardsErrorEntered = () => {
        dispatch(cardAllocationActions.cardsLoadingErrorMessageShown(true));
    }

    const handleFetchLinkedCardsErrorEntered = () => {
        dispatch(cardAllocationActions.linkedCardsLoadingErrorMessageShown(true));
    }

    useEffect(() => {
        if (linkedCards.status === ReduxStatus.empty) {
            dispatch(fetchLinkedCards());
        }

        if (cards.status === ReduxStatus.empty) {
            dispatch(fetchCards({ limit: cards.metadata.LIMIT, initialLoad: true }));
        }

        if (cards.status === ReduxStatus.loadFailed && !cards.errorMessageShown) {
            handleFetchCardsErrorEntered();
            cards.errorMessages.forEach(message => {
                showAlert(message, "info");
            })
        }

        if (linkedCards.status === ReduxStatus.loadFailed && !linkedCards.errorMessageShown) {
            handleFetchLinkedCardsErrorEntered();
            linkedCards.errorMessages.forEach(message => {
                showAlert(message, "info");
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkedCards, cards])

    const handleRequirementsAcceptedChanged = (event) => {
        dispatch(cardAllocationActions.requirementsAccepted(event.target.checked));
        if (allocationOption === "all") {
            dispatch(cardAllocationActions.multipleCardsSelected(cards.data));
        }
    }

    const handleCardSearchSubmit = (event) => {
        event.preventDefault();
        dispatch(fetchCards(cards.filter));
    }

    const handleSelectCardSubmit = () => {
        if (!submitIsDisabled()) {
            navigate(ALLOCATE_CARD_HOLDER_DETAILS_URL)
        }
    }

    const submitIsDisabled = () => {
        return (!requirementsAccepted || selectedCards.length === 0);
    }

    return (
        <Container>
            <CardAllocationSummary title="Allocate Card" />
            <CardAllocationOptions />
            {
                allocationOption === "search" &&
                <div>
                    <div>
                        <CardAllocationFilters onSubmit={handleCardSearchSubmit} />
                    </div>

                    {
                        cards && cards.data &&
                        cards.data.length > 0 &&
                        <div>
                            <AvailableCardsList />
                        </div>
                    }

                    <br />
                </div>
            }
            {
                allocationOption === "search" &&
                <Box>
                    <SelectedCardsList />
                </Box>
            }
            <Box sx={{ mt: 4 }}>
                <FormControlLabel
                    value="requirementsAccepted"
                    name="requirementsAccepted"
                    onChange={handleRequirementsAcceptedChanged}
                    control={<Checkbox color="secondary" checked={requirementsAccepted} />}
                    label={<RequirementsLabel />}
                />
            </Box>
            {
                cards.status === ReduxStatus.loaded
                && cards.data.length === 0 &&
                requirementsAccepted &&
                <Box mt={2}>
                    <Text size={18} style={{ display: "flex", alignItems: "center" }} light={true}>
                        <span>
                            <InfoIcon />
                        </span>
                        <span style={{ marginLeft: 10 }}>
                            You have no cards to allocate.
                        </span>
                    </Text>
                </Box>
            }
            <Box sx={{ mt: 2 }}>
                <Button
                    data-testid="allocateCardContinueeBtn"
                    onClick={handleSelectCardSubmit}
                    size="medium"
                    disabled={submitIsDisabled()}
                    styles={{ opacity: submitIsDisabled() ? 0.5 : 1 }}
                    variant="contained"
                >
                    Continue
                </Button>
            </Box>
            {
                (cards.status === ReduxStatus.loading) &&
                <Loader open={true} />
            }
        </Container>
    );
}
