import React, { useEffect } from 'react';
import { getConditions } from './thunk';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../common/components/base/title/Title';
import { Typography, Container, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactHtmlParser from 'html-react-parser';
import Theme from '../../common/constants/Theme';

const Conditions = () => {
    const conditions = useSelector(state => state.conditions.data.content);
    const dispatch = useDispatch();
    const theme = Theme.SECONDARY_LIGHT;

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getConditions());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Title title='Conditions' subtitle='The conditions for use of the site'></Title>
            <Container maxWidth='lg'>
                {
                    conditions && conditions.map((conditions, idx) => (
                        <div key={idx}>
                            <Accordion style={{ backgroundColor: theme, marginBottom: 20, padding: 3, marginTop: 20, boxShadow: "none" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#0E70FF' }} />}>
                                    <Typography color='primary.light' variant='title' fontFamily='BentonSansPro_Regular' >
                                        {conditions.CONTENTHEADER}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography color='secondary.dark' variant='subtitle2' fontFamily='BentonSansPro_Regular'>
                                        {ReactHtmlParser(conditions.CONTENTDETAIL)}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))
                }
            </Container>
        </div>
    );
};

export default Conditions;
