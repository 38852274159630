import { useState } from "react";
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../common/components/base/text";
import { useNavigate } from 'react-router-dom';
import { fetchCards } from "../thunk";
import Button from "../../../common/components/base/button/Button";
import themeConstants from "../../../common/constants/Theme";
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, TableSortLabel } from "@mui/material";
import { visuallyHidden } from '@mui/utils';

const headCells = [
    {
        id: "ACCOUNTCARDNUMBER",
        label: "CARD NUMBER",
        width: 120,
        sortingKey: "cardNumber"
    },
    {
        id: "ACCOUNTCARDHOLDER",
        label: "CARD HOLDER",
        width: 200,
        sortingKey: "cardHolder"
    },
    {
        id: "VOUCHERIDX",
        label: "ID/PASSPORT NUMBER",
        width: 200,
        sortingKey: "idNumber"
    },
    {
        id: "ACCOUNTCARDEXPIRYDATE",
        label: "EXPIRY DATE",
        width: 150,
        sortingKey: "cardExpiry"
    },
    {
        id: "REFERENCENUMBER",
        label: "REFERENCE NUMBER",
        width: 150,
        sortingKey: "cardReference"
    }

];

const useStyles = makeStyles({
    cursor: {
        cursor: 'pointer'
    },
    tableRow: {
        height: '80%'
    },
    Header: {
        fontWeight: '400 !important',
        color: '#848D9E !important',
        textTransform: 'uppercase',
        fontSize: '14px !important'
    },
})

const prevDisabledStyles = {
    border: `1px solid ${themeConstants.BRAND_COLOR_SECONDARY}`,
    color: themeConstants.BRAND_COLOR_SECONDARY,
    opacity: 0.5
}

export default function AvailableCardsList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();

    const [sortingOrder, setSortingOrder] = useState('asc');
    const [sortingOrderBy, setSortingOrderBy] = useState('cardNumber');

    const cards = useSelector((state) => state.manageCard.cards);

    const { OFFSET, LIMIT, TOTAL } = cards.metadata || {};

    const getCardDetails = (event, id, maskedcard) => {
        event.preventDefault();
        localStorage.setItem("accountId", id)
        localStorage.setItem("maskedcard", maskedcard)
        navigate('/dashboard/manage-card/card-holder-details');
    }

    const handlePrevious = () => {
        let prevOffset = OFFSET - LIMIT;
        dispatch(fetchCards({ ...cards.filter, limit: LIMIT, offset: prevOffset, orderByField: sortingOrderBy, orderAsc: sortingOrder === 'asc' }))
    }

    const handleNext = () => {
        let nextOffset = OFFSET + LIMIT;
        dispatch(fetchCards({ ...cards.filter, limit: LIMIT, offset: nextOffset, orderByField: sortingOrderBy, orderAsc: sortingOrder === 'asc' }))
    }

    const prevIsDisabled = () => {
        return (OFFSET === 0);
    }

    const nextIsDisabled = () => {
        return !(
            (LIMIT + OFFSET) < TOTAL
        );
    }

    const createSortHandler = (property) => (_event) => {
        const isAsc = sortingOrderBy === property && sortingOrder === 'asc';
        setSortingOrder(isAsc ? 'desc' : 'asc');
        setSortingOrderBy(property);
        dispatch(fetchCards({ ...cards.filter, limit: LIMIT, offset: 0, orderByField: property, orderAsc: !isAsc }))
    };

    return (
        <Box sx={{ width: "100%", mt: 3 }}>
            <div sx={{ width: "100%", mb: 2 }}>
                <TableContainer className={classes.tableRow}>
                    <Table aria-labelledby="AvailableCards">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        style={{ minWidth: headCell.width }}
                                    >
                                        <TableSortLabel
                                            active={sortingOrderBy === headCell.sortingKey}
                                            direction={sortingOrderBy === headCell.sortingKey ? sortingOrder : 'asc'}
                                            onClick={createSortHandler(headCell.sortingKey)}
                                        >
                                            <Text className={classes.Header}>
                                                {headCell.label}
                                            </Text>
                                            {sortingOrderBy === headCell.sortingKey &&
                                                <Box component="span" sx={visuallyHidden}>
                                                    {sortingOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </Box>}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {cards.data.map((card, index) => {
                                return (
                                    <TableRow key={index} >
                                        <TableCell>
                                            <Button
                                                variant='text'
                                                color='secondary'
                                                sx={{}}
                                                onClick={(event) => getCardDetails(event, card.ACCOUNTCARDID, card.ACCOUNTCARDNUMBERMASKED)}
                                            >
                                                {card.ACCOUNTCARDNUMBERMASKED}
                                            </Button>
                                        </TableCell>
                                        <TableCell><Text>{card.ACCOUNTCARDFIRSTNAME + " " + card.ACCOUNTCARDSURNAME}</Text></TableCell>
                                        <TableCell><Text>{card.ACCOUNTCARDIDNUMBER}</Text></TableCell>
                                        <TableCell><Text>{card.ACCOUNTCARDEXPIRYDATE}</Text></TableCell>
                                        <TableCell><Text>{card.ACCOUNTCARDREFERENCE}</Text></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
                <Box >
                    <Text>
                        Records {OFFSET + 1} - {OFFSET + cards.data.length} of {TOTAL}
                    </Text>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Box>
                        <Button
                            onClick={handlePrevious}
                            size="small"
                            style={prevIsDisabled() ? prevDisabledStyles : {}}
                            disabled={prevIsDisabled()}
                            variant="outlined"
                        >
                            Previous
                        </Button>
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Button
                            onClick={handleNext}
                            size="small"
                            disabled={nextIsDisabled()}
                            style={{ opacity: nextIsDisabled() ? 0.5 : 1 }}
                            variant="contained"
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
            <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)", marginTop: 20 }} />
        </Box>
    );
}
