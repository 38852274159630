export const handleChange = (name, value, onValueChange, onChange) => {
  if (onChange) {
    onChange({
      target: {
        name: name,
        value: value,
      },
    });
  }

  if (onValueChange) {
    onValueChange(value);
  }
};
