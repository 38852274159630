import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import Button from "../../../../common/components/base/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cardAllocationActions } from "../../slice";
import CardAllocationSummary from "../../common/components/CardAllocationSummary";
import { fetchCards } from "../../thunk";
import ReduxStatus from "../../../../common/constants/ReduxStatus";
import Text from "../../../../common/components/base/text";
import {
    ALLOCATE_CARD_HOLDER_DETAILS_URL,
    ALLOCATE_CARD_SELECT_CARDS_URL,
    DASHBOARD_URL, DEPOSIT_FUNDS_URL,
    LOAD_FUNDS_URL
} from "../../common/utils";
import InfoIcon from "@mui/icons-material/InfoOutlined";

export default function CardAllocationComplete() {
    const [ready, setReady] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { cards, cardAllocationSubmission, allocationOption } = useSelector(state => state.cardAllocation);
    const { LIMIT } = cards.metadata || {};

    const getCardAllocationSummary = () => {
        return `${cardAllocationSubmission.result['ALLOCATECARDSRESULTS'].length}  
                    cards where successfully allocated. 
                    You have ${cards.totalRecords} cards that can still be allocated.`;
    }

    const resetCardAllocation = () => {
        dispatch(cardAllocationActions.reset(null));
    }

    const handleFinished = () => {
        resetCardAllocation();
        navigate(DASHBOARD_URL);
    }

    const handleLoadFunds = () => {
        navigate(LOAD_FUNDS_URL);
    }

    const handleDepositFunds = () => {
        navigate(DEPOSIT_FUNDS_URL);
    }

    const handleAllocateMore = () => {
        resetCardAllocation();
        navigate(ALLOCATE_CARD_SELECT_CARDS_URL);
    }


    const handleContinue = () => {
        dispatch(cardAllocationActions.multipleCardsSelected(cards.data));
        navigate(ALLOCATE_CARD_HOLDER_DETAILS_URL);
    }


    useEffect(() => {
        if (!ready) {
            dispatch(fetchCards({ limit: LIMIT, initialLoad: true }));
            dispatch(cardAllocationActions.allCardsUnSelected(null));
            setReady(true);
        }
    }, [ready, LIMIT, dispatch])

    return (
        <Container>
            {
                ready &&
                <Box>
                    {
                        cards.status === ReduxStatus.loaded &&
                        <CardAllocationSummary
                            title="Complete"
                            subtitleArray={[]}
                            subtitle={getCardAllocationSummary()}
                        />
                    }
                    {
                        allocationOption === "all" &&
                        cards.totalRecords > 0 &&
                        <Box sx={{ mb: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Box>
                                <Text size={16} style={{ display: "flex", alignItems: "center", color: "#878787" }}>
                                    <span>
                                        <InfoIcon />
                                    </span>
                                    <span style={{ marginLeft: 10 }}>
                                        You have {cards.totalRecords} more cards to allocate
                                    </span>
                                </Text>
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <Button
                                    onClick={handleContinue}
                                    styles={{ ml: 4 }}
                                    size="medium"
                                    variant="outlined"
                                >
                                    Continue
                                </Button>
                            </Box>
                        </Box>
                    }
                    {
                        (allocationOption !== "all" ||
                            (allocationOption === "all" && cards.totalRecords === 0)) &&
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Button
                                onClick={handleFinished}
                                variant="contained"
                                size="medium">
                                I'M FINISHED
                            </Button>
                            <Button
                                onClick={handleAllocateMore}
                                variant="outlined"
                                size="medium"
                                styles={{ ml: 4 }}>
                                ALLOCATE MORE CARDS
                            </Button>
                            <Button
                                onClick={handleDepositFunds}
                                variant="outlined"
                                size="medium"
                                styles={{ ml: 4 }}>
                                DEPOSIT FUNDS
                            </Button>
                            <Button
                                onClick={handleLoadFunds}
                                variant="outlined"
                                size="medium"
                                styles={{ ml: 4 }}>
                                LOAD FUNDS
                            </Button>
                        </Box>
                    }
                </Box>
            }
        </Container>
    )
}
