import Http from "../common/utils/Http";

export const fetchNotices = async () => {
    try {
        let response = await Http.get('pr/dashboard/noticeList');
        return response;
    } catch ({ errorMessages }) {
        throw errorMessages;
    }
}

export const hideNotice = async (messageID) => {
    try {
        let response = await Http.post('pr/news/hide/', { messageID });
        return response;
    } catch ({ errorMessages }) {
        throw errorMessages;
    }
}
