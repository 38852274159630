import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestEmailChange, changeEmail } from './service';

export const verifyRequestEmailChange = createAsyncThunk(
    'verify/request_email_change',
    async (params, { rejectWithValue }) => {
        try {
            const response = await requestEmailChange(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const verifyConfirmEmailChange = createAsyncThunk(
    'verify/confirm_email_change',
    async (params, { rejectWithValue }) => {
        try {
            const response = await changeEmail(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
