import React from 'react';
import UpdatePasswordForm from './UpdatePasswordForm';
import Container from '@mui/material/Container';
import Title from '../../common/components/base/title/index';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
        fontSize: '14px'
    }
}));

const UpdatePassword = () => {
    const classes = useStyles();
    return (
        <div className={classes.mainbg}>
            <Container maxWidth='md'>
                <Title title='Update your password' subtitle='Change the password you use to log in to your profile' />
                <UpdatePasswordForm />
            </Container>
        </div>
    );
};

export default UpdatePassword;
