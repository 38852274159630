import React from 'react';
import RegistrationDetailForm from './RegistrationDetailForm'
import Container from '@mui/material/Container';
import Title from '../../common/components/base/title/index'

const RegistrationDetails = () => {
    return (
        <Container size='sm'>
            <Title title='Update your registration details' subtitle='Complete required fields to order new cards.' />
            <RegistrationDetailForm />
        </Container>
    );
};

export default RegistrationDetails;
