import Http from '../../../common/utils/Http';

const CARD_TRANSACTIONS_DOWNLOAD_ENDPOINT = '/pr/reports/downloadAllCardsTransactions';

export const getTransactions = async (queryString) => {
    try {
        let response = await Http.get(CARD_TRANSACTIONS_DOWNLOAD_ENDPOINT,
            {
                params: queryString,
                headers: {
                    'Accept': '*/*'
                },
            });
        return response;
    } catch (error) {
        throw error;
    }
};
