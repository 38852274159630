import React, { useState } from 'react';
import { closeDialog } from '../slice';
import { useDispatch } from 'react-redux';
import Input from '../../common/components/base/input/Input';
import {
    Grid,
    Typography,
    Alert,
    Collapse,
    IconButton,
    Box
} from '@mui/material';
import KeyOffOutlinedIcon from '@mui/icons-material/KeyOffOutlined';
import Button from '../../common/components/base/button/Button';
import { REGEX_EMAIL } from '../../common/utils/regex';
import { passwordReminder } from '../thunk';
import CloseIcon from '@mui/icons-material/Close';

export default function PasswordReminderForm(props) {
    const [stateValues, setStateValues] = useState({
        username: ''
    })

    const [validationErrors, setValidationErrors] = useState({
        username: ''
    })

    const [openAlert, setOpenAlert] = useState(false);

    const dispatch = useDispatch();

    const handleInput = input => event => {
        let value = event.target.value;
        if (input === "username" && value.length === 0) {
            setValidationErrors(validationErrors => ({ ...validationErrors, username: "username is required." }));
        }
        if (input === "username" && value.length !== 0) {
            if (REGEX_EMAIL.test(value))
                setValidationErrors(validationErrors => ({ ...validationErrors, username: '' }));
            else
                setValidationErrors(validationErrors => ({ ...validationErrors, username: 'Invalid username.' }));
        }
        setStateValues(prevStateValues => ({ ...prevStateValues, [input]: value }));
    };

    const handleOk = () => {
        let errorCount = 0;
        if (stateValues.username.length === 0) {
            errorCount++;
            setValidationErrors(validationErrors => ({ ...validationErrors, username: "username is required." }));
        }
        if (errorCount === 0) {
            dispatch(passwordReminder(stateValues))
                .then(response => {
                    if (response.type === "login/password_reminder/fulfilled") {
                        setOpenAlert(true);
                    }
                })
        }
    }

    const closeAlert = () => {
        setOpenAlert(false);
        dispatch(closeDialog());
    }

    return (
        <Grid container spacing={2.5} p={2}>
            <Grid item xs={12} display="flex">
                <KeyOffOutlinedIcon />
                <Typography variant="body2" mt={0.5} ml={0.5}>Forgotten your password? Enter your email address to be sent a new password.</Typography>
            </Grid>
            <Grid item xs={12}>
                <Input
                    size="small"
                    type="email"
                    inputLabel="Email Address"
                    name="username"
                    fullWidth
                    autoComplete="username"
                    autoFocus
                    value={stateValues.username}
                    onChange={handleInput("username")}
                    error={validationErrors.username ? true : false}
                    helperText={validationErrors.username}
                />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={openAlert}>
                    <Alert
                        severity="success"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={closeAlert}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mt: 3 }}
                    >
                        Instructions to reset your password have been sent to your email address.
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Box textAlign='center'>
                    <Button variant="contained" size="medium" onClick={handleOk}>Send Reminder</Button>
                </Box>
            </Grid>
        </Grid>
    );
}
