import {
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../common/components/base/button/Button';
import Checkbox from '../../../common/components/base/checkbox/checkbox';
import { postLoadDetailToggle } from '../thunk';

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            'width': 'auto !important',
        },
    }),
);

const NewBatchLoadStep3 = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        FILEERRORS,
        DOUBLEVOUCHERS,
        CLIENTBULKUPLOADUUID
    } = useSelector((state) => {
        return state.batchLoad.data;
    });

    const handleOnClickPrev = () => {
        navigate({
            pathname: '../step2',
            search: '?uuid=' + CLIENTBULKUPLOADUUID,
        });
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        navigate({
            pathname: '../step4',
            search: '?uuid=' + CLIENTBULKUPLOADUUID,
        });
    };

    const handleGoToStep1 = (e) => {
        navigate({
            pathname: '../step1',
            search: '?uuid=' + CLIENTBULKUPLOADUUID,
        });
    };

    const handleOnChangeCheckbox = (e, BULKLOADDETAILID) => {
        const payload = {
            value: e.target.checked ? 1 : 0,
            bulkLoadDetailID: BULKLOADDETAILID
        };
        dispatch(postLoadDetailToggle(payload));
    };

    return (
        <>
            <form noValidate autoComplete='off' onSubmit={handleOnSubmit}>
                {FILEERRORS.length ? (
                    <>
                        <Typography
                            variant="h6"
                            sx={{color: "#3c4b6c", fontSize: 20, fontWeight:400}}
                            marginBottom={'20px'}
                        >
                            Some concerns were raised while checking your file
                        </Typography>
                        <TableContainer className={classes.table}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Line</TableCell>
                                        <TableCell>Column</TableCell>
                                        <TableCell>Field</TableCell>
                                        <TableCell>Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {FILEERRORS.map((fileError, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {fileError.line}
                                                </TableCell>
                                                <TableCell>
                                                    {fileError.column}
                                                </TableCell>
                                                <TableCell>
                                                    {fileError.field}
                                                </TableCell>
                                                <TableCell>
                                                    {fileError.reason}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <p>
                                                These errors cannot be handled,
                                                you may choose to:
                                            </p>
                                            <ul>
                                                <li>Ignore them</li>
                                                <li>
                                                    Or edit and upload a{' '}
                                                    <Button variant="text"
                                                        color="secondary"
                                                        sx={{}}
                                                        onClick={handleGoToStep1}>
                                                        New File
                                                    </Button>
                                                </li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <></>
                )}

                {DOUBLEVOUCHERS.length ? (
                    <>
                        <Typography
                            variant="h6"
                            sx={{color: "#3c4b6c", fontSize: 20, fontWeight:400}}
                            marginTop={'20px'}
                            marginBottom={'20px'}
                        >
                            Possible duplicate loads detected
                        </Typography>

                        <TableContainer className={classes.table}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Card</TableCell>
                                        <TableCell>Cardholder Name</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {DOUBLEVOUCHERS.map((doubleVoucher, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {doubleVoucher.ACCOUNTCARDNUMBER}
                                                </TableCell>
                                                <TableCell>
                                                    {doubleVoucher.ACCOUNTCARDFIRSTNAME} {doubleVoucher.ACCOUNTCARDSURNAME}
                                                </TableCell>
                                                <TableCell>
                                                    {doubleVoucher.BULKLOADDETAILAMOUNT}
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel
                                                        control={<Tooltip title="Uncheck for remove" arrow><Checkbox
                                                            color='secondary'
                                                            checked={!!doubleVoucher.BULKLOADDETAILENABLED}
                                                            onChange={(e) => handleOnChangeCheckbox(e, doubleVoucher.BULKLOADDETAILID)}
                                                        /></Tooltip>}
                                                        label={''}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    <TableRow>
                                        <TableCell colSpan={4}>
                                            <p>
                                                These duplicates might be intentional so we give you the chance to enable or disable the load by ticking the checkbox next to each transaction.
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : <></>}

                <Button
                    type='button'
                    size='medium'
                    variant='outlined'
                    styles={{ margin: '40px 20px 0px 0px' }}
                    onClick={handleOnClickPrev}
                >
                    Previous
                </Button>
                <Button
                    type='submit'
                    size='medium'
                    variant='contained'
                    styles={{ margin: '40px 20px 0px 0px' }}
                >
                    Next
                </Button>
            </form>
        </>
    );
};

export default NewBatchLoadStep3;
