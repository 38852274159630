import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchNotices, hideNotice } from './service';

export const verifyFetchNotice = createAsyncThunk(
    'verify/notice_fetch',
    async (params, { rejectWithValue }) => {
        try {
            const response = await fetchNotices();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const verifyHideNotice = createAsyncThunk(
    'verify/notice_hide',
    async (messageID, { rejectWithValue }) => {
        try {
            const response = await hideNotice(messageID);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
