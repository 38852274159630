import axios from 'axios';
import store from '../../app/store';
import { verifyLogout } from '../../login/slice';
import globalVariables from '../../common/constants/globalVariables';
import _ from 'lodash';

const baseURL = globalVariables.API_URL;
const noRefreshUrls = [
    'pr/dashboard/userHeader'
];

const Http = axios.create({
    baseURL,
    headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
});

Http.interceptors.request.use(
    config => {
        var isNoRefreshUrl = noRefreshUrls.some((noRefreshUrl) => {
            return config.url.includes(noRefreshUrl);
        });
        var token = isNoRefreshUrl ? localStorage.getItem('access_token_no_refresh') : localStorage.getItem('access_token');
        config.params = config.params || {};
        if (token)
            config.headers['Authorization'] = 'Bearer ' + token;
        return config;
    },
    error => {
        Promise.reject(error)
    });

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (!prom.resolve || !prom.reject) {
            return;
        }
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const willRefreshToken = () => {
    return !failedQueue.length || failedQueue.some((req) => {
        return !noRefreshUrls.some((noRefreshUrl) => {
            return req.url.includes(noRefreshUrl);
        });
    });
}

Http.interceptors.response.use(
    response => response,
    (error) => {
        var token = localStorage.getItem('refresh_token');
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !_.isEmpty(token) && originalRequest.url.includes('jwt/refreshToken')) {
            // Only handle logout if user actions cause the 401
            // Otherwise, interval URLs failed due to expired refresh token, just silently reject the queue.
            // User actions afterwards will cause another 401 and log user out normally.
            if (willRefreshToken()) {
                store.dispatch(verifyLogout());
            } else {
                processQueue(error);
            }
        }
        else if (error?.response?.status === 401 && !_.isEmpty(token) && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject, url: originalRequest.url });
                })
                    .then(accessToken => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
                        return Http(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }
            originalRequest._retry = true;
            isRefreshing = true;
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve: null, reject: null, url: originalRequest.url });
                return Http.post(`${baseURL}jwt/refreshToken`, {
                    refreshToken: token
                })
                    .then(res => {
                        const data = res.data
                        if (res.status === 200) {
                            // To prevent interval URLs from refreshing token indefinitely, only refresh
                            // if one of the requests is a normal request (user-triggered)
                            // Otherwise, only refresh login token (no refresh token) for interval URLs
                            // Next normal requests will use the access_token and get 401, refreshing token
                            // again normally.
                            localStorage.setItem('access_token_no_refresh', data.jwtToken);
                            if (willRefreshToken()) {
                                localStorage.setItem('access_token', data.jwtToken);
                                localStorage.setItem('refresh_token', data.refreshToken);
                                Http.defaults.headers.common['Authorization'] = `Bearer ${data.jwtToken}`;
                            }
                            originalRequest.headers['Authorization'] = 'Bearer ' + data.jwtToken;
                            processQueue(null, data.jwtToken);
                            resolve(Http(originalRequest));
                        }
                    })
                    .catch(err => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }
        return Promise.reject(error);
    });

const get = (url, config = {}) => {
    return Http.get(url, config);
}

const post = (url, data, config = {}) => {
    // Ensure the JSON values are string to avoid blocking from WAF rules on AWS
    _convertToStrictJson(data);
    return Http.post(url, data, config);
}

const _convertToStrictJson = (data) => {
    const sensitiveKeywords = ['limit', 'offset'];
    for (const key in data) {
        if(sensitiveKeywords.includes(key)) {
            data[key] = String(data[key]);
        }
    }
}

const exportedHttp = {
    get,
    post
};

export default exportedHttp;
