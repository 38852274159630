import { createSlice } from '@reduxjs/toolkit';
import ReduxStatus from '../../common/constants/ReduxStatus';
import {
    getGetLoadDetail,
    getGetLoadList,
    postImportLoadFiles,
    postLoadDetailToggle,
    postUploadLoadFiles,
} from './thunk';

const initialState = {
    status: ReduxStatus.empty,
    currentStep: 1,
    currentTab: 'TABNEW',
    data: {
        fetchUuid: true,
        CLIENTBULKUPLOADUUID: '',
        CLIENTTYPEOPTION: '',
        UPLOADEDFILE: '',
        DATAARRAY: [],
        OPTGROUP: [],
        FILEERRORS: [],
        DOUBLEVOUCHERS: [],
        LOADAPPROVED: [],
        LOADLOADSFAILED: [],
        LOADNOTFINAL: [],
        LOADFINALIZED: [],
        TOTALCOST: 0,
        LOADDETAIL: [],
        TOTALFEE: 0,
        TOTALLOAD: 0,
        HASHEADER: false,
        OURFIELD001: "Not Mapped",
        OURFIELD002: "Not Mapped",
    },
    errorMessages: [],
};

const isRejectedAction = (action) => action.type.endsWith('rejected');
const isPendingAction = (action) => action.type.endsWith('pending');
const isFulfilledAction = (action) => action.type.endsWith('fulfilled');

export const batchLoadSlice = createSlice({
    name: 'batchLoad',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(postUploadLoadFiles.fulfilled, (state, action) => {
                state.data.CLIENTBULKUPLOADUUID =
                    action.payload.CLIENTBULKUPLOADUUID;
                state.data.UPLOADEDFILE = action.payload.UPLOADEDFILE;
                state.data.DATAARRAY = action.payload.DATAARRAY;
                state.data.OPTGROUP = action.payload.OPTGROUP;
                state.data.CLIENTTYPEOPTION = action.payload.CLIENTTYPEOPTION;
                state.data.fetchUuid = false;
            })
            .addCase(postImportLoadFiles.fulfilled, (state, action) => {
                state.data.FILEERRORS = action.payload.FILEERRORS;
                state.data.DOUBLEVOUCHERS = action.payload.DOUBLEVOUCHERS;
                state.data.HASHEADER = action.meta.arg.hasHeader;
                Object.keys(action.meta.arg).forEach(field => {
                    if (field.includes('ourfield')) {
                        state.data[field.toUpperCase()] = action.meta.arg[field];
                    }
                });
                state.data.fetchUuid = true;
            })
            .addCase(getGetLoadList.fulfilled, (state, action) => {
                const fixMappings = (data) => {
                    return data.map((d) => {
                        const mappings = d.FILEMAPPINGS ? JSON.parse(d.FILEMAPPINGS) : {};
                        d.FILEMAPPINGS = Object.keys(mappings)
                            .map((key) => key)
                            .filter((x) => x !== 'Load Amount');
                        return d;
                    });
                };

                state.data = {
                    ...initialState.data
                };
                state.data.LOADAPPROVED = fixMappings(
                    action.payload.LOADAPPROVED,
                );
                state.data.LOADLOADSFAILED = fixMappings(
                    action.payload.LOADLOADSFAILED,
                );
                state.data.LOADNOTFINAL = fixMappings(
                    action.payload.LOADNOTFINAL,
                );
                state.data.LOADFINALIZED = fixMappings(
                    action.payload.LOADFINALIZED,
                );
            })
            .addCase(postLoadDetailToggle.fulfilled, (state, action) => {
                state.data.DOUBLEVOUCHERS = state.data.DOUBLEVOUCHERS.map(
                    (doubleVoucher) => {
                        if (
                            doubleVoucher.BULKLOADDETAILID ===
                            action.payload.bulkLoadDetailID
                        ) {
                            doubleVoucher.BULKLOADDETAILENABLED =
                                action.payload.value;
                        }
                        return doubleVoucher;
                    },
                );
                state.data.fetchUuid = true;
            })
            .addCase(getGetLoadDetail.fulfilled, (state, action) => {
                state.data = {
                    ...state.data,
                    ...action.payload
                };
            })
            .addMatcher(isPendingAction, (state, action) => {
                state.status = ReduxStatus.loading;
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state.errorMessages = action.payload;
                state.status = ReduxStatus.loadFailed;
            })
            .addMatcher(isFulfilledAction, (state, action) => {
                state.status = ReduxStatus.loaded;
            });
    },
});

export const batchLoadSliceActions = batchLoadSlice.actions;

export default batchLoadSlice.reducer;
