import React, { useEffect, useState } from 'react';
import { Container, Grid, Alert, Typography, Box } from '@mui/material/';
import Title from '../../../common/components/base/title/Title';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPendingLoad } from '../redux/thunk';
import PendingLoadTable from './components/PendingLoadTable';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
import ReduxStatus from '../../../common/constants/ReduxStatus';
import Button from '../../../common/components/base/button/Button';
import { useNavigate } from 'react-router-dom';
import { confirmCancelation } from './../redux/thunk';
import Loader from './../../../common/components/base/loader/Loader';
import Dialog from './../../../common/components/base/dialog/Dialog';

const timeStamp = new Date();

const PendingLoadConfirm = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [loadId, setloadId] = useState();
    const [open, setOpen] = useState(false);
    const pendingLoad = useSelector((state) => state.cancelPendingLoad.pendingLoad);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get('id');
        setloadId(id);
        dispatch(fetchPendingLoad(`transferCardID=${id}`));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (pendingLoad.status === ReduxStatus.saved) {
            handleOpenDialog();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingLoad.status]);

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        navigate('/dashboard/');
    };

    const handleOk = () => {
        setOpen(false);
        navigate('/dashboard/');
    };

    const handleConfirm = () => {
        dispatch(confirmCancelation({ transferCardID: loadId }));
    };

    return (
        <Container size='sm'>
            {(pendingLoad.status === ReduxStatus.saving || pendingLoad.status === ReduxStatus.loading) && (
                <Loader open={true} />
            )}
            <Title
                title='Pending load'
                subtitle={`${DateTime.fromJSDate(timeStamp).toLocaleString(
                    DateTime.DATE_SHORT
                )} at ${DateTime.fromJSDate(timeStamp).toLocaleString(DateTime.TIME_SIMPLE)}`}
            />
            <Grid container>
                {(pendingLoad.status === ReduxStatus.loadFailed || pendingLoad.status === ReduxStatus.saveFailed) && (
                    <Grid item xs={12}>
                        <Alert severity='error' sx={{ mt: 5 }}>
                            {pendingLoad.errorMessages}
                        </Alert>
                    </Grid>
                )}
                {loadId && pendingLoad.status === ReduxStatus.loaded && (
                    <>
                        <PendingLoadTable loadId={loadId} pendingLoad={pendingLoad.data} />
                        <Grid item xs={12}>
                            <Button onClick={handleConfirm} variant='contained' size='medium' styles={{ marginTop: 5 }}>
                                Confirm
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
            <Dialog open={open} onClose={handleClose} showDialogTitle={false} showDialogActions={false} scroll='paper'>
                <div>
                    <Box textAlign='center'>
                        <Typography sx={{ mt: 5 }}>The pending load was successfully cancelled.</Typography>
                        <Button
                            size='medium'
                            variant='contained'
                            onClick={handleOk}
                            styles={{ margin: '40px auto 20px auto' }}
                        >
                            Go to dashboard
                        </Button>
                    </Box>
                </div>
            </Dialog>
        </Container>
    );
};

export default PendingLoadConfirm;
