import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { cardAllocationActions } from "../../../slice";
import Text from "../../../../../common/components/base/text";

export default function SelectedCardItem({ card }) {
    const dispatch = useDispatch();

    const handleRemoveCard = () => {
        dispatch(cardAllocationActions.cardUnselected(card.VOUCHERIDX));
    }

    return (
        <TableRow
            hover
            tabIndex={-1}
            role="checkbox"
            key={card.VOUCHERIDX}
        >
            <TableCell component="th" id={card.ACCOUNTCARDID} scope="row" padding="none">
                <Text>{card.ACCOUNTCARDNUMBERMASKED}</Text>
            </TableCell>
            <TableCell>
                <Text>{card.VOUCHERCARDDATATRACKINGNUMBER}</Text>
            </TableCell>
            <TableCell>
                <Text>{card.VOUCHERIDX}</Text>
            </TableCell>
            <TableCell>
                <Text>{card.ACCOUNTCARDEXPIRYDATE}</Text>
            </TableCell>
            <TableCell>
                <IconButton onClick={handleRemoveCard}
                    size="small" sx={{ fontSize: 10, height: 30, width: 30 }}>
                    <Close />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}
