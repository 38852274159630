import { createSlice } from '@reduxjs/toolkit';
import { dashboardUserHeader } from './thunk';
import ReduxStatus from '../../constants/ReduxStatus';

const initialState = {
    status: ReduxStatus.empty,
    userHeader: {
        BALANCEACCOUNT: 0,
        FULLNAME: '',
    },
    errorMessages: [],
    needUpdateBalance: false
};

export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        updateBalanceAfterCardOrder: (state, action) => {
            state.userHeader = {
                ...state.userHeader,
                BALANCEACCOUNT: action.payload
            }
        },
        requestUpdateBalance: (state, action) => {
            state.needUpdateBalance = action.payload;
        }
    },
    extraReducers: {
        [dashboardUserHeader.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [dashboardUserHeader.fulfilled]: (state, action) => {
            state.userHeader = action.payload;
            state.status = ReduxStatus.loaded;
            state.needUpdateBalance = false;
        },
        [dashboardUserHeader.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
            state.needUpdateBalance = false;
        }
    }
});

export const { updateBalanceAfterCardOrder, requestUpdateBalance } = headerSlice.actions;
export default headerSlice.reducer;
