import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    cardLoadSearchFrom,
    cardLoadSearchTo,
    allocatedCardLoads,
    loadClientData,
    saveTransfer,
    viewInvoice
} from './service';
import { Buffer } from 'buffer';

export const searchCardLoadFrom = createAsyncThunk(
    'loadFunds/search_card_load_from',
    async (params, { rejectWithValue }) => {
        try {
            const response = await cardLoadSearchFrom(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const searchCardLoadTo = createAsyncThunk(
    'loadFunds/search_card_load_to',
    async (params, { rejectWithValue }) => {
        try {
            const response = await cardLoadSearchTo(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const searchLoadAllocatedCards = createAsyncThunk(
    'loadFunds/search_load_allocated_cards',
    async (params, { rejectWithValue }) => {
        try {
            const response = await allocatedCardLoads(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const getLoadClient = createAsyncThunk(
    'loadFunds/get_load_client',
    async (params, { rejectWithValue }) => {
        try {
            const response = await loadClientData(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const confirmCardLoad = createAsyncThunk(
    'loadFunds/confirm_card_load',
    async (params, { rejectWithValue }) => {
        try {
            const response = await saveTransfer(params);
            return response.data;
        } catch (error) {
            let errorMessage = error?.response?.headers?.explanation ?? error?.response?.data;
            return rejectWithValue(errorMessage);
        }
    }
)

export const viewTransferInvoice = createAsyncThunk(
    'loadFunds/view_transfer_invoice',
    async (params, { rejectWithValue }) => {
        try {
            const pdfResponse = await viewInvoice(params);
            global.Buffer = Buffer;
            const pdfInBase64 = new Buffer.from(pdfResponse.data).toString('base64');
            return { pdf: 'data:application/pdf;base64,' + pdfInBase64 };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
