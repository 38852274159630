import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { useSelector, useDispatch } from "react-redux";

import CheckRadio from "../../../../../common/components/base/checkradio";

import { cardAllocationActions } from "../../../slice";
import { Typography } from "@mui/material";
import Text from "../../../../../common/components/base/text";

export default function CardAllocationOptions() {
    const { allocationOption, cards } = useSelector(state => state.cardAllocation);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            dispatch(cardAllocationActions.cardAllocationOptionChanged(value));
            if (value === "all") {
                dispatch(cardAllocationActions.multipleCardsSelected(cards.data));
            } else {
                dispatch(cardAllocationActions.allCardsUnSelected(null));
            }
        }
    }
    
    return (
        <div>
            <br />
            <Typography variant="h6" sx={{ mt: 3, color: "#3c4b6c", fontSize: 20, fontWeight: 400 }}>ALLOCATE CARD BY:</Typography>
            <div>
                <RadioGroup
                    row
                    onChange={handleChange}
                    name="allocationOption"
                >
                    <FormControlLabel
                        value="all"
                        control={<CheckRadio size="small" color="secondary" checked={allocationOption === "all"} />}
                        label={<Text>Allocate all cards</Text>}
                    />
                    <FormControlLabel
                        value="search"
                        control={<CheckRadio size="small" color="secondary" name="searchForCards" checked={allocationOption === "search"} />}
                        label={<Text>Search for cards</Text>}
                    />
                </RadioGroup>
            </div>
        </div>
    );
}
