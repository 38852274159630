import React, { useEffect } from 'react';
import {
    Container,
    Box,
    RadioGroup,
    FormControlLabel
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import Text from '../../../common/components/base/text/Text';
import CheckRadio from '../../../common/components/base/checkradio/CheckRadio';
import Button from '../../../common/components/base/button/Button';
import LoadFundsSummary from './LoadFundsSummary';
import { changeLoadFundsOntoOption } from '../slice';
import { useNavigate, createSearchParams, useSearchParams, useLocation } from 'react-router-dom';
import Loader from '../../../common/components/base/loader/Loader';
import _ from 'lodash';
import ReduxStatus from '../../../common/constants/ReduxStatus';

export default function LoadFundsOntoOption() {
    const loadFundsFromOption = useSelector(state => state.loadFunds.loadFundsFromOption);
    const loadFundsOntoOption = useSelector(state => state.loadFunds.loadFundsOntoOption);
    const status = useSelector(state => state.loadFunds.status);
    const selectedLoadFrom = useSelector(state => state.loadFunds.selectedLoadFrom);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const location = useLocation();

    useEffect(() => {
        if (_.isEmpty(location.search)) {
            let accountFrom = loadFundsFromOption === "profile" ? false : true;
            let transferFromAccountId = loadFundsFromOption === "profile" ? "" : selectedLoadFrom.ACCOUNTCARDID;
            let params = {};
            params["accountFrom"] = accountFrom;
            if (transferFromAccountId)
                params["transferFromAccountId"] = transferFromAccountId;
            setSearchParams(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search])

    const handleChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            dispatch(changeLoadFundsOntoOption(value));
        }
    }

    const handleContinue = () => {
        let params = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            params[param] = value;
        }
        if (loadFundsOntoOption === "multiple") {
            navigate({
                pathname: "/dashboard/load-funds-from/card-loads",
                search: `?${createSearchParams(params)}`
            });
        }
        else if (loadFundsOntoOption === "search") {
            navigate({
                pathname: "/dashboard/load-funds-from/to/search-card",
                search: `?${createSearchParams(params)}`
            });
        }
    }

    return (
        <div>
            <Container maxWidth="xl" sx={{ width: '1350px !important' }}>
                <LoadFundsSummary
                    title="Load funds into card"
                    subtitle="Load funds into cards from your profile or transfer funds from a stopped card."
                />
                <Text sx={{ mt: 7 }} color='primary.light'>LOAD FUNDS ONTO</Text>
                <RadioGroup
                    row
                    onChange={handleChange}
                    name="loadFundsFromOption">
                    <FormControlLabel
                        value="multiple"
                        control={<CheckRadio color="secondary" checked={loadFundsOntoOption === "multiple"} />}
                        label="Multiple cards"
                    />
                    <FormControlLabel
                        value="search"
                        control={<CheckRadio color="secondary" checked={loadFundsOntoOption === "search"} />}
                        label="Search for a card"
                    />
                </RadioGroup>
                <Box sx={{ mt: 3 }}>
                    <Button
                        onClick={handleContinue}
                        size="medium"
                        variant="contained"
                    >
                        Continue
                    </Button>
                </Box>
            </Container>
            {status === ReduxStatus.loading && <Loader open={true} />}
        </div>
    );
};
