import * as regex from '../../../../common/utils/regex';

const MAX_NUMBER_OF_CARDS = 10000;
const MIN_NUMBER_OF_CARDS = 1;
const MAX_ID_LENGTH = 20;
const MIN_ID_LENGTH = 6;
const MAX_NAME_LENGTH = 30;
const MAX_ADDRESS_LENGTH = 30;
const MAX_CITY_LENGTH = 30;

export const isNumberOfCardsError = (numberOfCards) => {
    if (!numberOfCards || numberOfCards < MIN_NUMBER_OF_CARDS || numberOfCards > MAX_NUMBER_OF_CARDS) {
        return true;
    }
    return false;
};

export const isNameError = (name) => {
    if (!name || name.length > MAX_NAME_LENGTH) {
        return true;
    }
    return false;
};

export const isIdError = (idNumber) => {
    if (!idNumber || idNumber.length < MIN_ID_LENGTH || idNumber.length > MAX_ID_LENGTH) {
        return true;
    }
    return false;
};

export const isContactNumberError = (contactNumber) => {
    if (!contactNumber || !regex.REGEX_SA_PHONE_NUMBER.test(contactNumber)) {
        return true;
    }
    return false;
};

export const isAltContactNumberError = (contactNumber) => {
    if (contactNumber && !regex.REGEX_SA_PHONE_NUMBER.test(contactNumber)) {
        return true;
    }
    return false;
};

export const isBuildingError = (building) => {
    if (building && building.length > MAX_ADDRESS_LENGTH) {
        return true;
    }
    return false;
};

export const isStreetError = (street) => {
    if (!street || street.length > MAX_ADDRESS_LENGTH) {
        return true;
    }
    return false;
};

export const isCityError = (city) => {
    if (!city || city.length > MAX_CITY_LENGTH) {
        return true;
    }
    return false;
};

export const isCodeError = (code) => {
    if (!code || !regex.REGEX_POSTAL_CODE.test(code)) {
        return true;
    }
    return false;
};

export const isDeliveryMethodsError = (deliveryMethods) => {
    if (!deliveryMethods) {
        return true;
    }
    return false;
};

export const isBankBrancheError = (bankBranche) => {
    if (!bankBranche) {
        return true;
    }
    return false;
}
