import { createAsyncThunk } from '@reduxjs/toolkit';

import api from './service';

export const getDashboardCardAllocation = createAsyncThunk(
    'dashboard/get_card_allocation',
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.fetchDashboardCardAllocation();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const getDashboardLastDeposit = createAsyncThunk(
    'dashboard/get_last_deposit',
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.fetchDashboardLastDeposit();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const getDashboardLastLogin = createAsyncThunk(
    'dashboard/get_last_login',
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.fetchDashboardLastLogin();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const getDashboardCardOrders = createAsyncThunk(
    'dashboard/get_card_orders',
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.fetchDashboardCardOrders();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)

export const cancelCardOrder = createAsyncThunk(
    'dashboard/cancelCardOrder',
    async (cardOrderId, { rejectWithValue }) => {
        try {
            const response = await api.cancelCardOrder(cardOrderId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
