import { Alert, Box, Grid } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../common/components/base/button/Button';
import Loader from '../../common/components/base/loader/Loader';
import Title from '../../common/components/base/title/Title';
import ReduxStatus from '../../common/constants/ReduxStatus';
import { doSendMeReminder } from '../thunk';
import { verifyLogout } from '../slice';

export default function RequiredPasswordReminder() {
  const dispatch = useDispatch();

  const { email, statusRequiredPasswordReminder } = useSelector(
    (state) => state.login,
  );

  const handleClick = () => {
    dispatch(doSendMeReminder())
      .then(response => {
        if (response.type === "login/send-me-reminder/fulfilled") {
          setTimeout(()=>{
            dispatch(verifyLogout());
          }, 10 * 1000);
        }
    });
  };

  return (
    <Box
      mx='auto'
      alignItems='center'
      justifyContent='center'
      sx={{
        width: 650,
      }}
    >
      <Grid container p={1} data-testid='required-password-reminder-component'>
        <Title
          title='Please Reset Your Password'
          subtitle='Click the button below to reset your password.'
          sxSubtitle={{ mb: 0 }}
        />
        {email ? (
          <Grid item xs={12}>
            <Alert
              severity='success'
              data-testid='alert-success-message'
              sx={{ mt: 3 }}
            >
              Instructions to reset your password have been sent to your email
              address: {email}.
            </Alert>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Box textAlign='center'>
              {statusRequiredPasswordReminder === ReduxStatus.loading ? (
                <Loader open={true} />
              ) : null}
              <Button
                data-testid='password-reminder-button'
                variant='contained'
                size='medium'
                onClick={handleClick}
              >
                PASSWORD REMINDER
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
