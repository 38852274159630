import { createSlice } from '@reduxjs/toolkit';

import ReduxStatus from '../../common/constants/ReduxStatus';

import { getDashboardBankAccounts } from './thunk';

const initialState = {
    status: ReduxStatus.empty,
    data: {
        ACCOUNTNUMBER: 0,
        BALANCEACCOUNT: 0,
        CONTENTDETAIL: '',
    },
    errorMessages: [],
};

const isRejectedAction = (action) => action.type.endsWith('rejected');
const isPendingAction = (action) => action.type.endsWith('pending');
const isFulfilledAction = (action) => action.type.endsWith('fulfilled');

export const depositFundsSlice = createSlice({
    name: 'depositFunds',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardBankAccounts.fulfilled, (state, action) => {
                state.data = {
                    ...action.payload,
                };
            })
            .addMatcher(isPendingAction, (state, action) => {
                state.status = ReduxStatus.loading;
            })
            .addMatcher(isRejectedAction, (state, action) => {
                state.errorMessages = action.payload;
                state.status = ReduxStatus.loadFailed;
            })
            .addMatcher(isFulfilledAction, (state, action) => {
                state.status = ReduxStatus.loaded;
            });
    },
});

export default depositFundsSlice.reducer;
