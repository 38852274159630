import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function useCustom() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // show alert notification
    const action = key => (
        <IconButton onClick={() => { closeSnackbar(key); }}>
            <CloseIcon />
        </IconButton>
    );

    const showAlert = (message, variant) => {
        enqueueSnackbar(message, {
            variant,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            action
        })
    }

    return { showAlert }
}
