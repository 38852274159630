import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from "react-redux";
import Text from "../../common/components/base/text";
import { fetchUnAllocatedCards } from "./thunk";
import Button from "../../common/components/base/button/Button";
import themeConstants from "../../common/constants/Theme";
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography, TableHead } from "@mui/material";

const headCells = [
    {
        id: "ACCOUNTCARDNUMBER",
        label: "CARD NUMBER",
    },

    {
        id: "ACCOUNTCARDEXPIRYDATE",
        label: "EXPIRY",
    }
];

const useStyles = makeStyles({
    cursor: {
        cursor: 'pointer'
    },
    tableRow: {
        height: '80%'
    },
    Header: {
        fontWeight: '400 !important',
        color: '#848D9E !important',
        textTransform: 'uppercase',
        fontSize: '14px !important'
    },
})

const prevDisabledStyles = {
    border: `1px solid ${themeConstants.BRAND_COLOR_SECONDARY}`,
    color: themeConstants.BRAND_COLOR_SECONDARY,
    opacity: 0.5
}


export default function UnAllocatedCards() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const cards = useSelector((state) => state.reportCardList.unAllocatedCards);

    const { OFFSET, LIMIT, TOTAL } = cards.metadata || {};

    const handlePrevious = () => {
        let prevOffset = OFFSET - LIMIT;
        dispatch(fetchUnAllocatedCards({ ...cards.filter, limit: LIMIT, offset: prevOffset }))
    }

    const handleNext = () => {
        let nextOffset = OFFSET + LIMIT;
        dispatch(fetchUnAllocatedCards({ ...cards.filter, limit: LIMIT, offset: nextOffset }))
    }

    const prevIsDisabled = () => {
        return (OFFSET === 0);
    }

    const nextIsDisabled = () => {
        return !(
            (LIMIT + OFFSET) < TOTAL
        );
    }

    useEffect(() => {
        dispatch(fetchUnAllocatedCards(cards.filter))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cards.filter]);

    return (
        <>
            <Typography variant="h6" sx={{ color: "primary.light", fontSize: 20, fontWeight: 400 }} mt={10} mb={2}>
                UNALLOCATED CARDS
            </Typography>
            <Box sx={{ width: "100%" }}>
                <div sx={{ width: "100%", mb: 2 }}>
                    <TableContainer className={classes.tableRow}>
                        <Table aria-labelledby="AvailableCards">
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell key={headCell.id}>
                                            <Text className={classes.Header}>
                                                {headCell.label}
                                            </Text>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {cards.data.length > 0 ? cards.data.map((card, index) => {
                                    return (
                                        <TableRow key={index} >
                                            <TableCell><Text>{card.ACCOUNTCARDNUMBERMASKED}</Text></TableCell>
                                            <TableCell><Text>{card.ACCOUNTCARDEXPIRYDATE}</Text></TableCell>
                                        </TableRow>
                                    );
                                }) :
                                    <TableRow>
                                        <TableCell><Text>You have no unallocated cards linked to your profile.</Text></TableCell>

                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
                    <Box >
                        <Text>
                            Records {OFFSET + (cards.data.length > 0 ? 1 : 0)} - {OFFSET + cards.data.length} of {TOTAL}
                        </Text>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Box>
                            <Button
                                onClick={handlePrevious}
                                size="small"
                                style={prevIsDisabled() ? prevDisabledStyles : {}}
                                disabled={prevIsDisabled()}
                                variant="outlined"
                            >
                                Previous
                            </Button>
                        </Box>
                        <Box sx={{ ml: 3 }}>
                            <Button
                                onClick={handleNext}
                                size="small"
                                disabled={nextIsDisabled()}
                                style={{ opacity: nextIsDisabled() ? 0.5 : 1 }}
                                variant="contained"
                            >
                                Next
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <hr style={{ borderTop: "1px solid rgba(0,0,0,.1)", marginTop: 20 }} />
            </Box>
        </>
    );
}
