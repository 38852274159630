import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchDashboardBankAccounts } from './service';

export const getDashboardBankAccounts = createAsyncThunk(
    'dashboard/get_bank_accounts',
    async (params, { rejectWithValue }) => {
        try {
            const response = await fetchDashboardBankAccounts();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);
