export const PROFILE_HISTORY_STATEMENTS_URL = '/dashboard/profile-history/statement';
export const PROFILE_HISTORY_HOME_URL = '/dashboard/profile-history';

// revokeObjectURL needs to be called to avoid memory leaks
// However, each browser has its own implementation and will have different behaviors
// when download actually starts and URL can be revoked
// Ref: https://stackoverflow.com/questions/29200256/wait-for-user-to-finish-downloading-a-blob-in-javascript/66905746
// So revoke after 5 minutes, after which browsers would already timed out if they cannot load the URL
const URL_EXPIRE = 5 * 60 * 1000;

export const downloadData = (data, title) => {
    let downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(convertBase64ToBlob(data));
    downloadLink.download = title;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setTimeout(() => {
        URL.revokeObjectURL(downloadLink.href);
    }, URL_EXPIRE);
}

function convertBase64ToBlob(base64String) {
    // Split data and type
    const parts = base64String.split(';base64,');
    const mimeType = parts[0].split(':')[1];

    // Create decoded data for blob creation
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: mimeType });
}
