import { createSlice } from '@reduxjs/toolkit';
import { verifyFetchAllocatedCards, verifyGetScheduledClient, verifySaveTransfer, verifyTransferInvoice } from './thunk';
import ReduxStatus from '../../common/constants/ReduxStatus';

const initialState = {
    allocatedCards: {
        status: ReduxStatus.empty,
        activePage: 1,
        totalPage: 1,
        totalRecord: 0,
        data: [],
        metaData: {},
        loadEffectiveStartDate: '',
        loadEffectiveEndDate: '',
        loadEffectiveDateOption: '3',
        errorMessages: [],
        filter: {},
    },
    loadClientData: {},
    getClients: {
        status: ReduxStatus.empty,
        profile: {},
        details: {},
        EFFECTIVEDATE: '',
        EFFECTIVESTOPDATE: ''
    },
    loadedCards: [],
    loadedDataToConfirm: {},
    saveTransfer: {
        status: ReduxStatus.empty
    },
    transferedLoadData: {},
    transferInvoiceData: {},
    confirmCardLoadErrorMessage: ''
};

export const scheduledLoadSlice = createSlice({
    name: 'scheduledLoads',
    initialState,
    reducers: {
        setLoadedCards: (state, action) => {
            state.loadedDataToConfirm = action.payload;
            state.allocatedCards.data = [];
            state.confirmCardLoadErrorMessage = "";
        }
    },
    extraReducers: {
        [verifyFetchAllocatedCards.pending]: (state, action) => {
            state.allocatedCards.status = ReduxStatus.loading;
            state.allocatedCards.data = [];
        },
        [verifyFetchAllocatedCards.fulfilled]: (state, action) => {
            state.allocatedCards.data = action.payload.CARDS;
            state.allocatedCards.metaData = action.payload.METADATA;
            state.allocatedCards.status = ReduxStatus.loaded;
            if (action.payload.CARDS.length === 0)
                state.allocatedCards.errorMessages = "No results found.";
        },
        [verifyFetchAllocatedCards.rejected]: (state, action) => {
            state.allocatedCards.errorMessages = action.payload;
            state.allocatedCards.status = ReduxStatus.loadFailed;
        },
        [verifyGetScheduledClient.pending]: (state, action) => {
            state.getClients.status = ReduxStatus.loading;
        },
        [verifyGetScheduledClient.fulfilled]: (state, action) => {
            state.loadClientData = action.payload;
            state.getClients.status = ReduxStatus.loaded;
        },
        [verifyGetScheduledClient.rejected]: (state, action) => {
            state.getClients.errorMessages = action.payload;
            state.getClients.status = ReduxStatus.loadFailed;
        },
        [verifySaveTransfer.pending]: (state, action) => {
            state.transferedLoadData = {};
            state.saveTransfer.status = ReduxStatus.loading;
        },
        [verifySaveTransfer.fulfilled]: (state, action) => {
            state.transferedLoadData = action.payload;
            state.saveTransfer.status = ReduxStatus.loaded;
        },
        [verifySaveTransfer.rejected]: (state, action) => {
            state.saveTransfer.status = ReduxStatus.loadFailed;
            state.confirmCardLoadErrorMessage = action.payload;
        },
        [verifyTransferInvoice.pending]: (state, action) => {
            state.transferInvoiceData = {};
            state.status = ReduxStatus.loading;
        },
        [verifyTransferInvoice.fulfilled]: (state, action) => {
            state.transferInvoiceData = action.payload.pdf;
            state.status = ReduxStatus.loaded;
        },
        [verifyTransferInvoice.rejected]: (state, action) => {
            state.status = ReduxStatus.loadFailed;
        }
    }
});

export const {
    setLoadedCards,
    changeAllocatedCardsFilter
} = scheduledLoadSlice.actions;

export default scheduledLoadSlice.reducer;
