import Http from '../../../common/utils/Http';

export const getCards = async (queryString) => {
    try {
        return await Http.get('/pr/funds/pendingTransferFunds', { params: queryString });
    } catch (error) {
        throw error;
    }
};

export const getLoad = async (queryString) => {
    try {
        return await Http.get('/pr/funds/pendingTransferFundDetail?' + queryString);
    } catch (error) {
        throw error;
    }
};

export const postCancelation = async (body) => {
    try {
        return await Http.post('/pr/funds/cancelPendingTransferFund', body);
    } catch (error) {
        throw error;
    }
};
