import { createAsyncThunk } from '@reduxjs/toolkit';
import { jwtLogin, jwtReminder, jwtResetPw, getMultipleAccounts, doSelectAccounts, sendMeReminder, jwtVerifyTotpToken, jwtSetupMfaData } from './service';
import { LOGIN_PAGE_STATE } from '../common/constants/ReduxStatus';

const INVALID_MFA_CODE = 'Invalid MFA code.';

export const verifyLogin = createAsyncThunk(
    'login/verify_login',
    async (params, { rejectWithValue, getState }) => {
        try {
            const response = await jwtLogin(params);
            const { username, password, recaptchaResponse } = params;
            return {
                username,
                password,
                recaptchaResponse,
                ...response.data
            }
        } catch (error) {
            const loginPageState = getState().login.loginPageState;
            const errorMessage = loginPageState === LOGIN_PAGE_STATE.mfaChallengeRequired ? INVALID_MFA_CODE : error.message;
            return rejectWithValue(errorMessage);
        }
    }
)

export const passwordReminder = createAsyncThunk(
    'login/password_reminder',
    async (params, { rejectWithValue }) => {
        try {
            const response = await jwtReminder(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const resetPassword = createAsyncThunk(
    'login/reset_password',
    async (params, { rejectWithValue }) => {
        try {
            const response = await jwtResetPw(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const getAccounts = createAsyncThunk(
    'login/get_multiple_accounts',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getMultipleAccounts();
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const doSelectAccount = createAsyncThunk(
    'login/do_select_profile',
    async (params, { rejectWithValue }) => {
        try {
            const response = await doSelectAccounts(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
)

export const verifyTotpToken = createAsyncThunk(
    'login/verify_totp_token',
    async (params, { rejectWithValue }) => {
        try {
            const response = await jwtVerifyTotpToken(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(INVALID_MFA_CODE);
        }
    }
)

export const fetchSetupMfaData = createAsyncThunk(
    'login/setup_mfa_data',
    async (params, { rejectWithValue }) => {
        try {
            const response = await jwtSetupMfaData(params);
            return response.data;
        } catch (error) {
            return rejectWithValue('Cannot find MFA setup request or link has expired');
        }
    }
)

export const doSendMeReminder = createAsyncThunk(
    'login/send-me-reminder',
    async (params, { rejectWithValue }) => {
        try {
            const response = await sendMeReminder();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
)
