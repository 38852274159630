import React, { useEffect } from 'react';
import {
    Box
} from "@mui/material";
import Button from '../../../common/components/base/button/Button';
import LoadFundsSummary from './LoadFundsSummary';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { viewTransferInvoice } from '../thunk';
import { requestUpdateBalance } from '../../../common/components/Header/slice'
import _ from 'lodash';

export default function LoadFundsInvoice(props) {
    const pdf = useSelector(state => state.loadFunds.transferInvoiceData);
    const transferedLoadData = useSelector(state => state.loadFunds.transferedLoadData);
    const userHeader = useSelector(state => state.header.userHeader);
    const accountFrom = props.accountFrom;

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(viewTransferInvoice(transferedLoadData.TRANSFERID));
        dispatch(requestUpdateBalance(true));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFinished = () => {
        navigate("/dashboard");
    }

    const handleDepositFunds = () => {
        navigate("/dashboard/deposit-funds");
    }

    const handleViewStatement = () => {
        navigate("/dashboard/card-statement");
    }

    return (
        <div>
            <LoadFundsSummary
                title="Invoice"
                subtitle={(userHeader?.BALANCEACCOUNT >= 0 || accountFrom) 
                    ? "Your card load request has been submitted and will be processed immediately."
                    : "Your card load request will not be processed until you have deposited funds into your profile"}
            />
            <Box sx={{ mb: 3 }}>
                {!_.isEmpty(pdf) && !accountFrom && <embed
                    src={pdf}
                    id="displayFile"
                    alt="your image"
                    width="100%"
                    height="800px"
                    style={{ borderStyle: 'solid', borderWidth: '2px' }}
                    type="application/pdf"
                />}
            </Box>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                    onClick={handleFinished}
                    variant="contained"
                    size="medium">
                    I'M FINISHED
                </Button>
                <Button
                    onClick={handleDepositFunds}
                    variant="outlined"
                    size="medium"
                    styles={{ ml: '20px' }}>
                    DEPOSIT FUNDS
                </Button>
                <Button
                    onClick={handleViewStatement}
                    variant="outlined"
                    size="medium"
                    styles={{ ml: '20px' }}>
                    VIEW STATEMENT
                </Button>

            </Box>
        </div>
    );
};
