import { createSlice } from '@reduxjs/toolkit';
import { getTerms } from './thunk';
import ReduxStatus from '../../common/constants/ReduxStatus';

const initialState = {
    status: ReduxStatus.empty,
    data: [],
    errorMessages: [],
};

export const termsSlice = createSlice({
    name: 'termsAndConditions',
    initialState,
    reducers: {},
    extraReducers: {
        [getTerms.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [getTerms.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [getTerms.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        }
    }
});

export default termsSlice.reducer;
