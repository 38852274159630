import CardHolderDetailsList from "./CardHolderDetailsList";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardAllocationSummary from "../../common/components/CardAllocationSummary";
import { useEffect, useState } from "react";
import { ALLOCATE_CARD_SELECT_CARDS_URL } from "../../common/utils";

export default function CardHolderDetails() {
    const navigate = useNavigate();
    const [ready, setReady] = useState(false);

    const selectedCards = useSelector(state => state.cardAllocation.selectedCards);

    useEffect(() => {
        if (!ready) {
            if (selectedCards.length < 1) {
                navigate(ALLOCATE_CARD_SELECT_CARDS_URL)
            }
            else {
                setReady(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready])

    return (
        ready &&
        <>
            <CardAllocationSummary title="Card holder details" />
            <CardHolderDetailsList />
        </>
    )
}
