import { createSlice } from '@reduxjs/toolkit';
import { getPrivacyStatement } from './thunk';
import ReduxStatus from '../../common/constants/ReduxStatus';

const initialState = {
    status: ReduxStatus.empty,
    activePage: 1,
    totalPage: 1,
    totalRecord: 0,
    data: [],
    errorMessages: [],
    filter: {},
};

export const privacyStatementSlice = createSlice({
    name: 'privacyStatement',
    initialState,
    reducers: {},
    extraReducers: {
        [getPrivacyStatement.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [getPrivacyStatement.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [getPrivacyStatement.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
    }
});

export default privacyStatementSlice.reducer;
