import { createSlice } from '@reduxjs/toolkit';
import { verifyFetchNews } from './thunk';
import ReduxStatus from '../common/constants/ReduxStatus';

const initialState = {
    newsList: {
        status: ReduxStatus.empty,
        activePage: 1,
        totalPage: 1,
        totalRecord: 0,
        data: [],
        errorMessages: [],
        filter: {},
    }
};

export const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {},
    extraReducers: {
        [verifyFetchNews.pending]: (state, action) => {
            state.newsList.status = ReduxStatus.loading;
        },
        [verifyFetchNews.fulfilled]: (state, action) => {
            state.newsList.data = action.payload.HISTORYNEWS;
            state.newsList.status = ReduxStatus.loaded;
        },
        [verifyFetchNews.rejected]: (state, action) => {
            state.newsList.errorMessages = action.payload;
            state.newsList.status = ReduxStatus.loadFailed;
        }
    }
});

export default newsSlice.reducer;
