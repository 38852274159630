import Http from "../common/utils/Http";

const fetchDashboardCardAllocation = async () =>
    await Http.get('pr/dashboard/cardAllocation');

const fetchDashboardLastDeposit = async () =>
    await Http.get('pr/dashboard/lastDeposit');

const fetchDashboardLastLogin = async () =>
    await Http.get('pr/dashboard/lastLogin');

const fetchDashboardCardOrders = async () =>
    await Http.get('pr/dashboard/cardOrders');

const cancelCardOrder = async (cardOrderId) => {
    try {
        let payload = {
            cardOrderId: cardOrderId
        };
        let response = await Http.post('pr/dashboard/cancelCardOrder', payload);
        return response;
    }
    catch (error) {
        throw error;
    }
}

const api = {
    fetchDashboardCardAllocation,
    fetchDashboardLastDeposit,
    fetchDashboardLastLogin,
    fetchDashboardCardOrders,
    cancelCardOrder,
};

export default api;
