import React, { useEffect } from "react";
import Input from "../../../../common/components/base/input/Input";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../../../common/components/base/text";
import { Box, Tooltip } from "@mui/material";
import Button from "../../../../common/components/base/button/Button";
import { cardAllocationActions } from "../../slice";
import ReduxStatus from "../../../../common/constants/ReduxStatus";
import { cardHolderFieldNames, defaultCardHolderDetails, cardHolderRequiredFields, maxFieldSizes, minFieldSizes, prevDisabledStyles } from "../../common/utils";

export default function CardHolderDetailsForm({ card, activeStep, handleBack, handleNext, maxSteps }) {
    const dispatch = useDispatch();
    let details = card.cardHolderDetails;
    const prevDisabledStyle = (activeStep === 0) ? prevDisabledStyles : {}
    const result = useSelector(state => state.cardAllocation.ficaValidation.result);
    const status = useSelector((state) => state.cardAllocation.ficaValidation.status);
    const phoneTipItems = [
        "Only digits allowed",
        "Must be 10 or 11 characters long",
        "10 character phone must start with 0",
        "11 character phone must start with 27",
        "First 3 digits must not be all zeros"
    ]
    const handleCardHolderDetailChange = (event) => {
        const { name, value } = event.target;
        let validationErrors = { ...card.validationErrors }

        if (Object.keys(cardHolderRequiredFields).includes(name) && value.trim() === "") {
            validationErrors[name] = `${cardHolderFieldNames[name]} is required`;
        } else if ( Object.keys(maxFieldSizes).includes(name) && value.length > maxFieldSizes[name]) {
            validationErrors[name] = `${cardHolderFieldNames[name]} is too long`;
        } else if ( Object.keys(minFieldSizes).includes(name) && value.length < minFieldSizes[name]) {
            validationErrors[name] = `${cardHolderFieldNames[name]} is too short`;
        } else {
            delete validationErrors[name];
        }

        let data = {
            ...card,
            cardHolderDetails: {
                ...card.cardHolderDetails,
                [name]: value
            },
            validationErrors,
        }

        dispatch(cardAllocationActions.selectedCardsUpdated(data));
    }

    const setDefaultCardHolderDetails = () => {
        let data = {
            ...card,
            cardHolderDetails: defaultCardHolderDetails,
            validationErrors: {}
        }
        dispatch(cardAllocationActions.selectedCardsUpdated(data));
    }

    const phoneNumberTip = (
        <ul style={{paddingLeft: "5px"}}>
        {
            phoneTipItems.map((item, i) => (
                <li style={{margin: "5px"}} key={`${i}-phone-tip`}>{item}</li>
            ))
        }
        </ul>
            
    )

    useEffect(() => {
        if (!details) {
            setDefaultCardHolderDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details])

    const handleSubmit = (event) => {
        event.preventDefault();
        handleNext();
    }

    return (
        <Box sx={{ mt: 5 }}>
            {
                details &&
                <form action="#" onSubmit={handleSubmit} >
                    <Input
                        name="firstName"
                        fullWidth
                        value={details.firstName}
                        inputLabel={(<small style={{ fontSize: 11 }}>FIRST NAME *</small>)}
                        size="small"
                        error={card.validationErrors.firstName ? true : false}
                        helperText={card.validationErrors.firstName}
                        onChange={handleCardHolderDetailChange}

                    />

                    <Input
                        name="surname"
                        fullWidth
                        value={details.surname}
                        inputLabel={(<small style={{ fontSize: 11 }}>SURNAME *</small>)}
                        size="small"
                        error={card.validationErrors.surname ? true : false}
                        helperText={card.validationErrors.surname}
                        onChange={handleCardHolderDetailChange}

                    />

                    <Input
                        name="idNumber"
                        fullWidth
                        value={details.idNumber}
                        inputLabel={(<small style={{ fontSize: 11 }}>ID/PASSPORT NUMBER *</small>)}
                        size="small"
                        error={card.validationErrors.idNumber ? true : false}
                        helperText={card.validationErrors.idNumber}
                        onChange={handleCardHolderDetailChange}

                    />
                    <Tooltip title={phoneNumberTip} arrow >
                        <Input
                            name="cellPhoneNumber"
                            fullWidth
                            value={details.cellPhoneNumber}
                            inputLabel={(<small style={{ fontSize: 11 }}>CELLPHONE NUMBER *</small>)}
                            size="small"
                            error={card.validationErrors.cellPhoneNumber ? true : false}
                            helperText={card.validationErrors.cellPhoneNumber}
                            onChange={handleCardHolderDetailChange}

                        />
                    </Tooltip>
                    
                    <Input
                        name="reference"
                        fullWidth
                        value={details.reference}
                        inputLabel={(<small style={{ fontSize: 11 }}>REFERENCE</small>)}
                        size="small"
                        onChange={handleCardHolderDetailChange}

                    />

                    <Box mt={4}>
                        <Text>
                            Card Number: {card.ACCOUNTCARDNUMBERMASKED}
                        </Text>
                        <Text>
                            Sequence Number: {card.VOUCHERIDX}
                        </Text>
                        <Text>
                            Tracking Number: {card.VOUCHERCARDDATATRACKINGNUMBER}
                        </Text>
                    </Box>

                    {
                        status === ReduxStatus.saved &&
                        result &&
                        <p style={{ color: "red" }} data-testid="cardUpdateFicaErrorMessage"> {result}</p>
                    }
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
                        <Box>
                            <Text light>
                                {activeStep + 1} of {maxSteps}
                            </Text>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Box>
                                <Button
                                    type="button"
                                    size="small"
                                    variant="outlined"
                                    onClick={handleBack}
                                    style={prevDisabledStyle}
                                    disabled={activeStep === 0}>
                                    PREVIOUS
                                </Button>
                            </Box>

                            <Box sx={{ ml: 3 }}>
                                <Button
                                    data-testid="allocateCardSaveBtn"
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    disabled={activeStep === maxSteps - 1}
                                    styles={{ opacity: (activeStep === maxSteps - 1) ? 0.5 : 1 }}
                                >
                                    NEXT
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </form>
            }
        </Box>
    )
}
