import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./service";
import { Buffer } from 'buffer';

global.Buffer = Buffer;

export const fetchAllocatedCards = createAsyncThunk(
    "reportCardList/fetchAllocatedCards",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await api.fetchAllocatedCards(filters);
            return response;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchUnAllocatedCards = createAsyncThunk(
    "reportCardList/fetchUnAllocatedCards",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await api.fetchUnAllocatedCards(filters);
            return response;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchCardsDocumentXLS = createAsyncThunk(
    "reportCardList/fetchCardsDocumentXLS",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await api.fetchCardsDocumentXLS(filters);
            const docInBase64 = new Buffer.from(response.data).toString('base64');
            return 'data:application/vnd.ms-excel;base64,' + docInBase64;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchCardsDocumentPDF = createAsyncThunk(
    "reportCardList/fetchCardsDocumentPDF",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await api.fetchCardsDocumentPDF(filters);
            const pdfInBase64 = new Buffer.from(response.data).toString('base64');
            return 'data:application/pdf;base64,' + pdfInBase64;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);


