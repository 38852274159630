import React, { useEffect } from 'react';
import { getPayCard } from './thunk';
import { useDispatch, useSelector } from 'react-redux';
import Title from '../../common/components/base/title/Title';
import { Typography, Container, } from '@mui/material';
import ReactHtmlParser from 'html-react-parser';
import ReduxStatus from '../../common/constants/ReduxStatus';
import Loader from '../../common/components/base/loader/Loader';

const GetPayCard = () => {
    const getdata = useSelector(state => state.getPayCard.data.content);
    const status = useSelector(state => state.getPayCard.status);
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getPayCard())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Title title='Read More' subtitle='Read more about the PayCard product and how it works'></Title>
            <Container maxWidth='lg'>
                {
                    getdata && getdata.map((getdata, idx) => (
                        <div key={getdata.CONTENTID}>
                            <Typography color='primary.light' variant='h6' fontFamily='BentonSansPro_Regular' fontSize={20} fontWeight={400}>{getdata.CONTENTHEADER}</Typography>
                            <Typography color='secondary.dark' variant='subtitle2' fontFamily='BentonSansPro_Regular'>{ReactHtmlParser(getdata.CONTENTDETAIL)}</Typography><br /><br />
                        </div>
                    ))
                }
                {status === ReduxStatus.loading && <Loader open={true} />}
            </Container>
        </div>
    );
};

export default GetPayCard;
