import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
    box: {
        marginTop: '22px',
    },
    textBox: {
        marginBottom: '8px',
    }
});

const InputWrapper = ({ inputLabel, children }) => {
    const classes = useStyles();
    return (
        <Box className={classes.box}>
            {inputLabel && (
                <Box className={classes.textBox}>
                    <Typography color='primary.subtitle' variant='inputField'>
                        {inputLabel}
                    </Typography>
                </Box>
            )}
            {children}
        </Box>
    );
};
export default InputWrapper;
