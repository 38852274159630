import React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from "@mui/styles";
import { Container, Typography, IconButton, Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
    },
    imagesize: {
        height: 250, width: '100%', display: 'flex', objectFit: 'cover'
    },
    card: {
        height: 250,
        display: 'flex',
    },
    containerTop: {
        marginTop: '6%'
    },
    iconplacing: {
        marginTop: 10
    },
}))

const CardContents = () => {
    const classes = useStyles()
    const navigate = useNavigate();
    const handleClickGet = () => {
        navigate("/get");
    }

    return (
        <div className={classes.mainbg}>
            <Container maxWidth="lg" className={classes.containerTop}>
                <Typography variant='h5' fontFamily='BentonSansPro_Regular' color='primary.dark' gutterBottom>
                    WHAT PAYCARD CAN DO FOR YOU
                </Typography>
                <Box sx={{ backgroundColor: '#ffffff', width: '100%', height: 'auto' }} >
                    <Grid container>
                        <Grid item xs={12} sm={3} md={8}>
                            <img src={require('../../assets/images/img_content_getPayCard.png')} alt="" title='Get a PayCard' className={classes.imagesize} />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Box sx={{ margin: 2 }}>
                                <Typography variant='h5' fontFamily='BentonSansPro_Regular' color='primary.light' gutterBottom>
                                    Get a PayCard
                                </Typography>
                                <Typography variant="subtitle2" paragraph fontFamily='BentonSansPro_Regular' color='primary.dark'>
                                    PIN protected <br />
                                    No cash to carry <br />
                                    Accepted everywhere <br />
                                    Lost card replacement<br />
                                </Typography>
                                <Typography variant='subtitle' color="secondary" fontFamily='BentonSansPro_Regular' fontWeight={700} fontSize={15} onClick={(handleClickGet)}>
                                    Read More  <IconButton ><ArrowForwardIosIcon fontSize="small" color="secondary" /></IconButton>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    );
}

export default CardContents;
