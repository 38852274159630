import React, { useState, useEffect } from 'react';
import { Grid, Box, RadioGroup, FormControlLabel, Typography, TextField, MenuItem, Alert } from '@mui/material/';
import SearchBar from '../../../../common/components/base/search/Search';
import Text from '../../../../common/components/base/text/Text';
import CheckRadio from '../../../../common/components/base/checkradio/CheckRadio';
import { useDispatch } from 'react-redux';
import DatePicker from './../../../../common/components/base/pickers/DatePicker';
import Button from '../../../../common/components/base/button/Button';
import { searchCards } from '../../redux/thunk';
import { DateTime } from 'luxon';
import { saveFilter } from '../../redux/slice';

const startDate = new Date();
const endDate = new Date();
const OFFSET = 0;
const LIMIT = 10;

const filterTopics = [
    {
        name: 'Card Number',
        id: 'cardNumber',
    },
    {
        name: 'Card Holder',
        id: 'cardHolder',
    },
    {
        name: 'Reference Number',
        id: 'cardReference',
    },
];

const SearchLoad = () => {
    const dispatch = useDispatch();
    const [stateValues, setStateValues] = useState({
        searchField: 'cardNumber',
        searchString: '',
        startDate: new Date(startDate.setDate(startDate.getDate() - 1)),
        endDate: endDate,
        selectedYear: endDate.getFullYear(),
        errorMsg: '',
    });

    useEffect(() => {
        setStateValues({ ...stateValues, yearList: createYearList(endDate.getFullYear()) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUserInput = (e) => {
        const { name, value } = e.target;
        setStateValues((prevStateValues) => ({ ...prevStateValues, [name]: value }));
    };

    const handleTopicChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setStateValues((prevStateValues) => ({ ...prevStateValues, searchField: value }));
        }
    };

    const createYearList = (currentYear) => {
        let years = [];
        for (let index = 0; index < 21; index++) {
            years.push(currentYear - index);
        }
        return years;
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const queryString = {
            searchField: stateValues.searchField,
            searchString: stateValues.searchString,
            startDate: `${stateValues.selectedYear}-01-01`,
            endDate: `${stateValues.selectedYear}-12-31`,
            OFFSET,
            LIMIT,
        };
        dispatch(saveFilter(queryString));
        dispatch(searchCards(queryString));
    };

    const validateDate = (startDate, endDate) => {
        if (startDate - endDate > 0) {
            return false;
        } else {
            return true;
        }
    };

    const handleSearchWithDate = () => {
        if (validateDate(stateValues.startDate, stateValues.endDate)) {
            setStateValues({ ...stateValues, errorMsg: '' });
            const queryString = {
                searchField: stateValues.searchField,
                searchString: stateValues.searchString,
                startDate: DateTime.fromJSDate(stateValues.startDate).toISODate(),
                endDate: DateTime.fromJSDate(stateValues.endDate).toISODate(),
                OFFSET,
                LIMIT,
            };
            dispatch(saveFilter(queryString));
            dispatch(searchCards(queryString));
        } else {
            setStateValues({ ...stateValues, errorMsg: 'The start date cannot be greater than the end date.' });
        }
    };

    return (
        <Grid container>
            <Grid item xs={9}>
                <Text light sx={{ mt: 3 }}>
                    FIND
                </Text>
                <Box sx={{ mt: 1 }}>
                    <SearchBar
                        onSubmit={(e) => handleSearch(e)}
                        onChange={handleUserInput}
                        name='searchString'
                        value={stateValues.searchString}
                        placeholder='Enter your search here'
                    />
                </Box>
            </Grid>
            <Grid item xs={3} sx={{ pl: 2 }}>
                <Text light sx={{ mt: 3 }}>
                    FILTER BY YEAR
                </Text>
                <Box sx={{ mt: 1 }}>
                    <TextField
                        name='selectedYear'
                        variant='outlined'
                        size='small'
                        color='secondary'
                        fullWidth
                        value={stateValues.selectedYear}
                        onChange={handleUserInput}
                        InputProps={{
                            className: {
                                [`& fieldset`]: {
                                    borderRadius: 2,
                                },
                            },
                        }}
                        sx={{ backgroundColor: 'white' }}
                        select
                    >
                        {stateValues.yearList &&
                            stateValues.yearList.map((year, i) => (
                                <MenuItem key={i} value={year}>
                                    {year}
                                </MenuItem>
                            ))}
                    </TextField>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ pt: 2 }}>
                    <Text light>(LEAVE BLANK TO SHOW ALL)</Text>
                </Box>
                <hr />
                <Box sx={{ display: 'flex', mt: 3 }}>
                    <Box>
                        <Text light>SEARCH BY</Text>
                        <RadioGroup row onChange={handleTopicChange} name='searchField'>
                            {filterTopics.map((topic) => (
                                <FormControlLabel
                                    key={topic.id + '-filter-topic'}
                                    value={topic.id}
                                    control={
                                        <CheckRadio color='secondary' checked={stateValues?.searchField === topic.id} />
                                    }
                                    label={topic.name}
                                />
                            ))}
                        </RadioGroup>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ mt: 6 }}>
                    <Typography variant='h6' color='primary.light'>
                        FILTER ALLOCATED CARD DATES
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{ pr: 1, mr: 1, mt: 2 }}>
                    <Text light sx={{ mb: -2 }}>
                        START DATE
                    </Text>
                    <DatePicker
                        fullWidth
                        value={stateValues.startDate}
                        name='startDate'
                        onValueChange={(date) => setStateValues({ ...stateValues, startDate: date })}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box sx={{ mt: 2 }}>
                    <Text light sx={{ mb: -2 }}>
                        END DATE
                    </Text>
                    <DatePicker
                        fullWidth
                        value={stateValues.endDate}
                        name='endDate'
                        onValueChange={(date) => setStateValues({ ...stateValues, endDate: date })}
                    />
                </Box>
            </Grid>
            {stateValues.errorMsg && (
                <Grid item xs={12}>
                    <Box sx={{ mt: 1 }}>
                        <Alert severity='error' sx={{ mt: 3 }}>
                            {stateValues.errorMsg}
                        </Alert>
                    </Box>
                </Grid>
            )}
            <Grid item xs={12}>
                <Button
                    onClick={handleSearchWithDate}
                    size='medium'
                    variant='contained'
                    styles={{ margin: '40px 20px 0px 0px' }}
                >
                    Search
                </Button>
            </Grid>
        </Grid>
    );
};

export default SearchLoad;
