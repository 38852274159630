import { createSlice } from '@reduxjs/toolkit';
import { loadingExcelSheetData } from './thunk';
import ReduxStatus from '../../common/constants/ReduxStatus';

const initialState = {
    excel: {
        status: ReduxStatus.empty,
        errorMessages: []
    }
}

export const currentBalanceOfCardsSlice = createSlice({
    name: 'currentBalance',
    initialState,
    extraReducers: {
        [loadingExcelSheetData.pending]: (state, action) => {
            state.excel.status = ReduxStatus.loading;
        },
        [loadingExcelSheetData.fulfilled]: (state, action) => {
            state.excel.status = ReduxStatus.loaded;
        },
        [loadingExcelSheetData.rejected]: (state, action) => {
            state.excel.status = ReduxStatus.loadFailed;
        }
    }
});

export default currentBalanceOfCardsSlice.reducer;
