import Http from "../../../common/utils/Http";

const GET_ACCOUNT_DETAILS_ENDPOINT = "pr/settings/accountDetails";
const SAVE_ACCOUNT_DETAILS_ENDPOINT = "pr/settings/updateAccountDetails";

const getAccountDetails = async () => {
    try {
        let response = await Http.get(GET_ACCOUNT_DETAILS_ENDPOINT);
        return response;
    } catch (error) {
        throw error;
    }
}

const saveAccountDetails = async (payload) => {
    try {
        let response = await Http.post(SAVE_ACCOUNT_DETAILS_ENDPOINT, payload);
       
        return response;
    } catch (error) {
        throw error;
    }
};


const api = {
    getAccountDetails,
    saveAccountDetails
}

export default api;
