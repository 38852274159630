import React from 'react';
import Title from '../../../common/components/base/title/Title';

export default function LoadFundsSummary({ title, subtitle, subtitleArray }) {
    return (
        <Title
            title={title}
            subtitle={subtitle}
            subtitleArray={subtitleArray}
        ></Title>
    );
};
