import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../common/components/base/button/Button';
import Dialog from '../../../common/components/base/dialog/Dialog';
import { formatCurrency } from '../../../common/utils/helper';
import { postQueueApproval } from '../thunk';

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            'width': 'auto !important',
        },
        floatRight: {
            'float': 'right',
        }
    }),
);

const NewBatchLoadStep4 = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        CLIENTBULKUPLOADUUID,
        TOTALCOST,
        LOADDETAIL,
        TOTALFEE,
        TOTALLOAD,
        DOUBLEVOUCHERS,
        FILEERRORS
    } = useSelector((state) => {
        return state.batchLoad.data;
    });

    const [stateConfirmDialogOpen, setStateConfirmDialogOpen] = useState({ open: false });

    const handleOnClickPrev = () => {
        let pathname = '../step3';

        if (!FILEERRORS.length && !DOUBLEVOUCHERS.length) {
            pathname = '../step2';
        }

        navigate({
            pathname,
            search: '?uuid=' + CLIENTBULKUPLOADUUID,
        });
    };

    const handleOnQueueForApproval = (e) => {
        setStateConfirmDialogOpen({ open: true });
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
    };

    const handleOnSaveForLater = () => {
        navigate('../list');
    };

    const handleCloseConfirmDialog = (e) => {
        setStateConfirmDialogOpen({ open: false });
    };

    const handleOkConfirmDialog = (e) => {
        setStateConfirmDialogOpen({ open: false });

        dispatch(postQueueApproval(CLIENTBULKUPLOADUUID)).then(() => {
            navigate('../list');
        });
    };

    return (
        <>
            <form noValidate autoComplete='off' onSubmit={handleOnSubmit}>
                <Typography
                    variant="h6"
                    sx={{ color: "#3c4b6c", fontSize: 20, fontWeight: 400 }}
                    marginBottom={'20px'}
                >
                    Overview of bulk load details
                </Typography>
                <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Card</TableCell>
                                <TableCell>Cardholder Name</TableCell>
                                <TableCell>Load Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {LOADDETAIL.map((detail, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {detail.CARDNUMBER}
                                        </TableCell>
                                        <TableCell>
                                            {detail.CARDHOLDERNAME}
                                        </TableCell>
                                        <TableCell>
                                            {formatCurrency(detail.LOADAMOUNT)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow>
                                <TableCell colSpan={2} align="right" sx={{ border: 0 }}>
                                    Qty
                                </TableCell>
                                <TableCell>
                                    {LOADDETAIL.length}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} align="right" sx={{ border: 0 }}>
                                    Load Amount
                                </TableCell>
                                <TableCell>
                                    {formatCurrency(TOTALLOAD)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} align="right" sx={{ border: 0 }}>
                                    Fee Amount
                                </TableCell>
                                <TableCell>
                                    {formatCurrency(TOTALFEE)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2} align="right" sx={{ border: 0 }}>
                                    Total Cost
                                </TableCell>
                                <TableCell>
                                    {formatCurrency(TOTALCOST)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Button
                    type='button'
                    size='medium'
                    variant='outlined'
                    styles={{ margin: '40px 20px 0px 0px' }}
                    onClick={handleOnClickPrev}
                >
                    Previous
                </Button>
                <Button
                    type='button'
                    size='medium'
                    variant='contained'
                    styles={{ margin: '40px 20px 0px 0px' }}
                    onClick={handleOnQueueForApproval}
                >
                    Queue for approval
                </Button>
                <Button
                    type='submit'
                    size='medium'
                    variant='contained'
                    styles={{ margin: '40px 20px 0px 0px' }}
                    onClick={handleOnSaveForLater}

                >
                    Save for later
                </Button>
                <Dialog
                    open={stateConfirmDialogOpen.open}
                    onClose={handleCloseConfirmDialog}
                    showDialogTitle={true}
                    showDialogActions={false}
                    scroll='paper'
                >
                    <div>
                        <Typography sx={{ mt: 5 }}>
                            Once your bulk load is queued for approval, it can no longer be changed. Do you want to continue?
                        </Typography>
                        <Box textAlign='center'>
                            <Button
                                size='medium'
                                variant='contained'
                                onClick={handleOkConfirmDialog}
                                styles={{ margin: '40px auto 20px auto' }}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </div>
                </Dialog>
            </form>
        </>
    );
};

export default NewBatchLoadStep4;
