import Http from "../../common/utils/Http";

const GET_CARDS_INFO_ENDPOINT = "/pr/cards/allocatedCardDetail?accountCardID=";
const ALLOCATE_CARDS_ENDPOINT = "/pr/cards/allocatedCards";
const POST_BEARER_INFO = "/pr/cards/updateAccountCard";
const POST_CARD_STOP = "/pr/cards/stopCard";
const POST_CARD_ENABLE = "/pr/cards/cancelStoppedCard";
const POST_CARD_RETIRE = "/pr/cards/retireCard";
const GET_CARD_STOP = "/pr/cards/stopReasons";

const fetchCards = async (filters = null) => {
    let url = `${ALLOCATE_CARDS_ENDPOINT}?`;
    if (filters) {
        Object.keys(filters).forEach(key => {
            url += `${key}=${filters[key]}&`;
        })
    }
    try {
        let response = await Http.get(url);
        return response;
    } catch (error) {
        throw error;
    }
};

const getCardInfo = async (id) => {
    try {
        let response = await Http.get(GET_CARDS_INFO_ENDPOINT + id);
        return response;
    } catch (error) {
        throw error;
    }
}

const verifyBearerInfo = async (payload) => {
    try {
        let response = await Http.post(POST_BEARER_INFO, payload);
        return response;
    }
    catch (error) {
        throw error;
    }
};

const verifyCardStop = async (payload) => {
    try {
        let response = await Http.post(POST_CARD_STOP, payload);
        return response;
    }
    catch (error) {
        throw error;
    }
};

const verifyCardEnable = async (payload) => {
    try {
        let response = await Http.post(POST_CARD_ENABLE, payload);
        return response;
    }
    catch (error) {
        throw error;
    }
};

const verifyCardRetire = async (payload) => {
    try {
        let response = await Http.post(POST_CARD_RETIRE, payload);
        return response;
    }
    catch (error) {
        throw error;
    }
};

const getStopReason = async () => {
    try {
        let response = await Http.get(GET_CARD_STOP);
        return response;

    } catch (error) {
        throw error;
    }
}

const api = {
    fetchCards,
    getCardInfo,
    verifyBearerInfo,
    verifyCardStop,
    getStopReason,
    verifyCardEnable,
    verifyCardRetire
};

export default api;
