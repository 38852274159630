import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import Title from "../../../../common/components/base/title";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../common/components/base/button/Button";
import TransactionStatementList from "./TransactionStatementList";
import TransactionStatementDocument from "./TransactionStatementDocument";
import { profileHistoryActions } from "../../slice";
import ReduxStatus from "../../../../common/constants/ReduxStatus";
import Loader from "../../../../common/components/base/loader/Loader";
import { useNavigate } from "react-router-dom";
import { downloadData, PROFILE_HISTORY_HOME_URL } from "../../common/utils";

export default function TransactionStatement() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { statement, docDownload } = useSelector(state => state.profileHistory);
    const getSubtitle = () => `${new Date(statement.data.STARTDATE).toDateString()}  -
                         ${new Date(statement.data.ENDDATE).toDateString()}`;

    const handleDownload = () => {
        if (docDownload.data) {
            downloadData(docDownload.data,
                `${statement.data.STARTDATE} - ${statement.data.ENDDATE}.xls`);
        }
    }

    const handleViewChange = (view) => {
        dispatch(profileHistoryActions.profileStatementViewChanged(view));
    }

    useEffect(() => {
        if (statement.status === ReduxStatus.empty
            || statement.status === ReduxStatus.loadFailed) {
            navigate(PROFILE_HISTORY_HOME_URL);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statement])

    return (
        statement.status === ReduxStatus.loaded && statement.data !== null ?
            <Container>
                <Title
                    title="Transaction statement"
                    subtitle={statement.data ? getSubtitle() : ""}
                    subtitleArray={[]}
                />
                {statement.view === "list" ?
                    <TransactionStatementList />
                    :
                    <TransactionStatementDocument />}
                <Box sx={{ mt: 5, display: "flex" }}>
                    <Box>
                        <Button
                            onClick={handleDownload}
                            color="secondary"
                            variant="contained"
                        >
                            DOWNLOAD
                        </Button>
                    </Box>
                    {statement.view === "list" ?
                        <Box sx={{ ml: 4 }}>
                            <Button
                                onClick={() => handleViewChange("document")}
                                color="secondary"
                                variant="outlined"
                            >
                                PRINTABLE VERSION
                            </Button>
                        </Box>
                        :
                        <Box sx={{ ml: 4 }}>
                            <Button
                                onClick={() => handleViewChange("list")}
                                color="secondary"
                                variant="outlined"
                            >
                                NORMAL VERSION
                            </Button>
                        </Box>}
                </Box>
            </Container>
            :
            <Loader open={true} />
    )
}
