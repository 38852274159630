import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Divider,
    useMediaQuery,
    IconButton,
    Box,
} from '@mui/material';
import Button from '../button/Button';
import Draggable from 'react-draggable';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const MuiDialogStyles = {
    '& .MuiDialog-paper': {
        backgroundColor: 'white',
        borderRadius: '2px',
        padding: '30px 40px 30px 40px',
        width: '570px',
    },
};

function PaperComponent(props) {
    const nodeRef = useRef(null);
    return (
        <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'} nodeRef={nodeRef}>
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}

export default function Dialog(props) {
    const {
        dialogTitle,
        dialogTitleTextAlign,
        children,
        cancelButtonText,
        okButtonText,
        onClose,
        handleOk,
        showDialogTitle,
        showDialogActions,
        ...restProps
    } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div>
            <MuiDialog
                {...restProps}
                fullScreen={fullScreen}
                PaperComponent={PaperComponent}
                sx={MuiDialogStyles}
                aria-labelledby='draggable-dialog-title'
            >
                <Box display='flex' alignItems='center'>
                    <Box flexGrow={1}></Box>
                    <Box>
                        <IconButton onClick={onClose}>
                            <CloseIcon fontSize='large' />
                        </IconButton>
                    </Box>
                </Box>
                {showDialogTitle && (
                    <DialogTitle style={{ cursor: 'move', padding: '0px' }} id='draggable-dialog-title'>
                        <Box display='flex' alignItems='center'>
                            <Box flexGrow={1} textAlign={dialogTitleTextAlign}>
                                {dialogTitle}
                            </Box>
                            <Box></Box>
                        </Box>
                    </DialogTitle>
                )}
                <DialogContent>{children}</DialogContent>
                {showDialogActions && <Divider />}
                {showDialogActions && (
                    <DialogActions>
                        <Button autoFocus onClick={onClose}>
                            {cancelButtonText}
                        </Button>
                        <Button onClick={handleOk}>{okButtonText}</Button>
                    </DialogActions>
                )}
            </MuiDialog>
        </div>
    );
}

Dialog.propTypes = {
    dialogTitle: PropTypes.string,
    dialogTitleTextAlign: PropTypes.string,
    cancelButtonText: PropTypes.string,
    okButtonText: PropTypes.string,
    onClose: PropTypes.func,
    handleOk: PropTypes.func,
    showDialogTitle: PropTypes.bool,
    showDialogActions: PropTypes.bool,
    scroll: PropTypes.string,
};
