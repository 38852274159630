import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import themeConstants from '../common/constants/Theme';
import { formatCurrency } from '../common/utils/helper';
import { loadingExcelSheetData } from '../reports/currentBalanceOfCards/thunk';
import CardOrdersPending from './components/CardOrdersPending';
import DashboardAccordion from './components/DashboardAccordion';
import { dashboardSliceActions } from './slice';
import {
    cancelCardOrder,
    getDashboardCardAllocation,
    getDashboardCardOrders,
    getDashboardLastDeposit,
    getDashboardLastLogin
} from './thunk';
import useCustom from '../common/components/hooks';
import Dialog from '../common/components/base/dialog/Dialog';
import Button from '../common/components/base/button/Button';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

const useStyles = makeStyles((theme) =>
    createStyles({
        mainbg: {
            backgroundColor: theme.palette.background.paper,
            fontSize: '14px'
        },
        icon: {
            objectFit: 'contain',
            color: themeConstants.TITLE_COLOR
        },
        ul: {
            color: themeConstants.ORANGE_LIGHT,
            listStyle: 'none',
            padding: '0',
            '& li': {
                marginBottom: '15px',
            },
            '& button': {
                marginLeft: '20px',
                color: 'black',
                textDecoration: 'none',
                fontSize: '14px',
                '&:hover': {
                    color: "#0089ff"
                },
            },

        },
    }),
);

const Dashboard = () => {
    const classes = useStyles();
    const {
        expandedAccordion,
        data: {
            CARDSTOTAL,
            UNALLOCATEDCARDSTOTAL,
            STATEMENTVALUEDATE,
            LASTLOGINDATE,
            CARDORDERSDASHBOARD
        }
    } = useSelector(state => state.dashboard);

    const userHeader = useSelector(state => state.header.userHeader);

    const [showCancelOrderConfirmDialog, setShowCancelOrderConfirmDialog] = useState(false);
    const [canceledOrderId, setCanceledOrderId] = useState(0);
    const [isCancellingOrder, setIsCancellingOrder] = useState(false);
    const multipleAccounts = JSON.parse(localStorage.getItem('multiple_accounts'));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showAlert } = useCustom();

    useEffect(() => {
        dispatch(getDashboardCardAllocation());
        dispatch(getDashboardLastDeposit());
        dispatch(getDashboardLastLogin());
        dispatch(getDashboardCardOrders());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showCardBalance = (event) => {
        event.preventDefault();
        dispatch(loadingExcelSheetData());
    };

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        dispatch(dashboardSliceActions.setExpandedAccordion(isExpanded ? panel : false));
    };

    const onShowCancelOrderConfirmation = (cardOrderId) => {
        setShowCancelOrderConfirmDialog(true);
        setCanceledOrderId(cardOrderId);
    };

    const onHideCancelOrderConfirmation = () => {
        setShowCancelOrderConfirmDialog(false);
        setCanceledOrderId(0);
        setIsCancellingOrder(false);
    };

    const handleCancelOrder = (cardOrderId) => {
        setIsCancellingOrder(true);
        dispatch(cancelCardOrder(cardOrderId)).then((response) => {
            if (response.type === 'dashboard/cancelCardOrder/fulfilled') {
                dispatch(getDashboardCardOrders()).then(onHideCancelOrderConfirmation);
            } else {
                showAlert('Something went wrong', 'error');
                onHideCancelOrderConfirmation();
            }
        });
    }

    return (
        <>
            <div className={classes.mainbg}>
                <Container maxWidth='lg'>
                    <Container sx={{ padding: '0 !important', marginTop: '64px' }}>
                        <DashboardAccordion
                            data-testid="dashboard-accordion-cards-component"
                            icon={<img src={require(`../assets/images/icon_card.png`)} alt="" className={classes.icon} />}
                            label={'Cards'}
                            expanded={expandedAccordion === 'cards'}
                            onChange={handleChangeAccordion('cards')}
                        >
                            <span>
                                You have {CARDSTOTAL} cards. {UNALLOCATEDCARDSTOTAL} cards are yet not been
                                allocated.
                            </span>

                            <ul className={classes.ul}>
                                <li>
                                    &#9650;
                                    <Link data-testid="order-cards-button"
                                        component="button"
                                        onClick={() => navigate('/dashboard/order-card')}>
                                        Order cards
                                    </Link>
                                </li>
                                <li>
                                    &#9650;
                                    <Link data-testid="allocate-cards-button"
                                        component="button"
                                        onClick={() => navigate('/dashboard/allocate-card')}>
                                        Allocate cards
                                    </Link>
                                </li>
                                <li>
                                    &#9650;
                                    <Link data-testid="manage-cards-button"
                                        component="button"
                                        onClick={() => navigate('/dashboard/manage-card')}>
                                        Manage cards
                                    </Link>
                                </li>
                            </ul>
                        </DashboardAccordion>
                        <DashboardAccordion
                            data-testid="dashboard-accordion-funds-component"
                            icon={<img src={require(`../assets/images/icon_fund.png`)} alt="" className={classes.icon} />}
                            label={'Funds'}
                            expanded={expandedAccordion === 'funds'}
                            onChange={handleChangeAccordion('funds')}
                        >
                            <span>Your balance is {formatCurrency(userHeader?.BALANCEACCOUNT ?? 0)} </span>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <ul className={classes.ul}>
                                        <li>
                                            &#9650;
                                            <Link data-testid="deposit-funds-button"
                                                component="button"
                                                onClick={() => navigate('/dashboard/deposit-funds')}>
                                                Deposit funds into your profile
                                            </Link>
                                        </li>
                                        <li>
                                            &#9650;
                                            <Link data-testid="load-funds-button"
                                                component="button"
                                                onClick={() => navigate('/dashboard/load-funds-from')}>
                                                Loads funds into cards
                                            </Link>
                                        </li>
                                        <li>
                                            &#9650;
                                            <Link data-testid="batch-load-button"
                                                component="button"
                                                onClick={() => navigate('/dashboard/batch-load/step1')}>
                                                Batch load
                                            </Link>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={6}>
                                    <ul className={classes.ul}>
                                        <li>
                                            &#9650;
                                            <Link data-testid="scheduled-loads-button"
                                                component="button"
                                                onClick={() => navigate('/dashboard/scheduled-loads')}>
                                                Scheduled load
                                            </Link>
                                        </li>
                                        <li>
                                            &#9650;
                                            <Link data-testid="cancel-pending-load-button"
                                                component="button"
                                                onClick={() => navigate('/dashboard/cancel-pending-load')}>
                                                Cancel pending load
                                            </Link>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </DashboardAccordion>
                        <DashboardAccordion
                            data-testid="dashboard-accordion-reports-component"
                            icon={<img src={require(`../assets/images/icon_report.png`)} alt="" className={classes.icon} />}
                            label={'Reports'}
                            expanded={expandedAccordion === 'reports'}
                            onChange={handleChangeAccordion('reports')}
                        >
                            <span>
                                {STATEMENTVALUEDATE ? `Funds were last deposited on ${STATEMENTVALUEDATE}.` : `You have not yet deposited funds into your profile.`}
                            </span>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <ul className={classes.ul}>
                                        <li>
                                            &#9650;
                                            <Link data-testid="profile-history-button"
                                                component="button"
                                                onClick={() => navigate('/dashboard/profile-history')}>
                                                View your profile history
                                            </Link>
                                        </li>
                                        <li>
                                            &#9650;
                                            <Link data-testid="card-list-button"
                                                component="button"
                                                onClick={() => navigate('/dashboard/card-list')}>
                                                View a list of cards
                                            </Link>
                                        </li>
                                        <li>
                                            &#9650;
                                            <Link data-testid="card-statement-button"
                                                component="button"
                                                onClick={() => navigate('/dashboard/card-statement')}>
                                                View a card statement
                                            </Link>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={6}>
                                    <ul className={classes.ul}>
                                        <li>
                                            &#9650;
                                            <Link data-testid="download-card-transactions-button"
                                                component="button"
                                                onClick={() => navigate('/dashboard/download-card-transactions')}>
                                                Download all card transactions
                                            </Link>
                                        </li>
                                        <li>
                                            &#9650;
                                            <Link data-testid="download-card-balance-button"
                                                component="button"
                                                onClick={(event) => showCardBalance(event)}>
                                                Download the current balance of cards
                                            </Link>
                                        </li>
                                        <li>
                                            &#9650;
                                            <Link data-testid="download-previous-invoice-button"
                                                component="button"
                                                onClick={() => navigate('/dashboard/download-previous-invoice')}>
                                                Download a previous invoice
                                            </Link>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </DashboardAccordion>
                        <DashboardAccordion
                            data-testid="dashboard-accordion-settings-component"
                            icon={<img src={require(`../assets/images/icon_setting.png`)} alt="" className={classes.icon} />}
                            label={'Settings'}
                            expanded={expandedAccordion === 'settings'}
                            onChange={handleChangeAccordion('settings')}
                        >
                            <span>You last logged in on {LASTLOGINDATE}.</span>
                            <ul className={classes.ul}>
                                <li>
                                    &#9650;
                                    <Link data-testid="registration-details-button"
                                        component="button"
                                        onClick={() => navigate('/dashboard/registration-details')}>
                                        Update your registration details...
                                    </Link>
                                </li>
                                <li>
                                    &#9650;
                                    <Link data-testid="update-your-email-button"
                                        component="button"
                                        onClick={() => navigate('/dashboard/update-your-email')}>
                                        Update your email
                                    </Link>
                                </li>
                                <li>
                                    &#9650;
                                    <Link data-testid="update-your-password-button"
                                        component="button"
                                        onClick={() => navigate('/dashboard/update-your-password')}>
                                        Update your password
                                    </Link>
                                </li>
                            </ul>
                        </DashboardAccordion>
                        {multipleAccounts?
                            <DashboardAccordion
                                data-testid="dashboard-accordion-accounts-component"
                                icon={<ManageAccountsOutlinedIcon className={classes.icon} fontSize='large' color='#858d9d' />}
                                label={'Accounts'}
                                expanded={expandedAccordion === 'accounts'}
                                onChange={handleChangeAccordion('accounts')}
                            >
                                <span>You are currently viewing account {userHeader?.ACCOUNTNUMBER ?? 0}.</span>
                                <ul className={classes.ul}>
                                    <li>
                                        &#9650;
                                        <Link data-testid="select-profile-button"
                                            component="button"
                                            onClick={() => navigate('/selectProfile')}>
                                            Select another account...
                                        </Link>
                                    </li>
                                </ul>
                            </DashboardAccordion>
                            : <></>}
                        <CardOrdersPending cardOrders={CARDORDERSDASHBOARD} onCancelOrder={onShowCancelOrderConfirmation}></CardOrdersPending>
                        <Dialog open={showCancelOrderConfirmDialog}
                            onClose={onHideCancelOrderConfirmation}
                            showDialogTitle={true}
                            showDialogActions={false}>
                            <Box textAlign='center'>
                                <Typography sx={{ mt: 5 }}>Are you sure you want to cancel this card order?</Typography>
                                <Button
                                    size='medium'
                                    variant='contained'
                                    onClick={() => { handleCancelOrder(canceledOrderId) }}
                                    styles={{ margin: '40px auto 20px auto' }}
                                    disabled={isCancellingOrder}
                                >
                                    {isCancellingOrder ? 'Cancelling' : 'Confirm'}
                                </Button>
                            </Box>
                        </Dialog>
                    </Container>
                </Container>
            </div>
        </>
    );
};

export default Dashboard;
