import ReduxStatus from '../../../common/constants/ReduxStatus';

export const fetchingUsers = (state, action) => {
    state.user.status = ReduxStatus.loading;
};

export const fetchedUsers = (state, action) => {
    state.user.data = action.payload;
    state.user.status = ReduxStatus.loaded;
};

export const failedFetchingUsers = (state, action) => {
    state.user.errorMessages = ['Error loading user data'];
    state.user.status = ReduxStatus.loadFailed;
};

export const fetchingCardOrder = (state, action) => {
    state.cardOrder.status = ReduxStatus.loading;
};

export const fetchedCardOrder = (state, action) => {
    state.cardOrder.data = action.payload;
    state.cardOrder.status = ReduxStatus.loaded;
};

export const failedFetchingCardOrder = (state, action) => {
    state.cardOrder.errorMessages = ['Error loading order data'];
    state.cardOrder.status = ReduxStatus.loadFailed;
};

export const sendingCardOrder = (state, action) => {
    state.cardOrder.sendStatus = ReduxStatus.saving;
};

export const sendCardOrder = (state, action) => {
    state.cardOrder.data = {
        ...state.cardOrder.data,
        totalCardCost: action.payload.TOTALCARDCOST,
        clientFaceDeliveryCost: action.payload.CLIENTFACEDELIVERYCOST,
        totalDeliveryCost: action.payload.TOTALDELIVERYCOST,
        totalCost: action.payload.TOTALCOST,
        bankBrancheName: action.payload.BANKBRANCHNAME,
        bankBrancheAddress: action.payload.BANKBRANCHADDRESS,
    };
    state.cardOrder.sendStatus = ReduxStatus.saved;
};

export const failedSendingCardOrder = (state, action) => {
    state.cardOrder.errorMessages = ['Failed sending order to server'];
    state.cardOrder.sendStatus = ReduxStatus.saveFailed;
};

export const sendingConfirmedCardOrder = (state, action) => {
    state.cardOrder.saveStatus = ReduxStatus.saving;
};

export const sendConfirmedCardOrder = (state, action) => {
    state.cardOrder.data = {
        ...state.cardOrder.data,
        accountBalance: action.payload.ACCOUNTBALANCE,
        accountNumber: action.payload.ACCOUNTNUMBER,
        cardOrderId: action.payload.CARDORDERID,
    };
    state.cardOrder.saveStatus = ReduxStatus.saved;
};

export const failedSendingConfirmedCardOrder = (state, action) => {
    state.cardOrder.errorMessages = ['Failed sending order to server'];
    state.cardOrder.saveStatus = ReduxStatus.saveFailed;
};

export const fetchingBankBranches = (state, action) => {
    state.bankBranches.status = ReduxStatus.loading;
};

export const fetchedBankBranches = (state, action) => {
    state.bankBranches.data = action.payload.bankBranches;
    state.bankBranches.status = ReduxStatus.loaded;
};

export const failedFetchingBankBranches = (state, action) => {
    state.bankBranches.errorMessages = ['Error loading bank branche data'];
    state.bankBranches.status = ReduxStatus.loadFailed;
};

export const fetchingInvoice = (state, action) => {
    state.invoice.status = ReduxStatus.loading;
};

export const fetchedInvoice = (state, action) => {
    state.invoice.data = action.payload.pdf;
    state.invoice.status = ReduxStatus.loaded;
};

export const failedFetchingInvoice = (state, action) => {
    state.invoice.errorMessages = ['Error loading invoice data'];
    state.invoice.status = ReduxStatus.loadFailed;
};
