import React from "react";
import ReduxStatus from "../../../../common/constants/ReduxStatus";
import Box from "@mui/material/Box";
import Title from "../../../../common/components/base/title";
import { useSelector } from "react-redux";

export default function CardAllocationSummary({ title, subtitle, subtitleArray }) {
    const { linkedCards, cards } = useSelector(state => state.cardAllocation);

    const getSubtitles = (numCardsLinked, numCardsUnallocated) =>
    ([`You have ${numCardsLinked} cards linked to your profile. ${numCardsUnallocated} 
            cards have not yet been allocated.`,
        "Cards must be allocated before they can be used"]);

    return (
        <>
            {
                (cards.status === ReduxStatus.empty)
                    || (linkedCards.status === ReduxStatus.empty)
                    ?
                    <Box>
                        <Title title={title} />
                    </Box>
                    :
                    <Title title={title}
                        subtitle={subtitle}
                        subtitleArray={subtitleArray ? subtitleArray :
                            getSubtitles(linkedCards.data.length, cards.totalRecords)}
                        sxTitle={{ mb: 4 }} />
            }
        </>
    )
}
