import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyFetchAllocatedCards, verifyGetScheduledClient } from '../thunk';
import { FormControlLabel, Container, RadioGroup, Box, Grid } from '@mui/material';
import DataTable from '../../../common/components/base/datatable';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import Text from '../../../common/components/base/text/Text';
import Button from '../../../common/components/base/button/Button';
import CheckRadio from '../../../common/components/base/checkradio';
import DateTimePicker from '../../../common/components/base/pickers/DatePicker';
import { setLoadedCards } from '../slice';
import { DateTime } from 'luxon';
import { formatCurrency, dateTimeFormating } from '../../../common/utils/helper';
import Title from '../../../common/components/base/title/Title';
import _ from 'lodash';
import { handleAmountAndFee, handleNotifyViaSms } from '../../loadFunds/components/LoadUtils';

const columns = [
    {
        type: "string",
        key: "CARDHOLDER",
        name: "CARD HOLDER",
        width: 200
    },
    {
        type: "string",
        key: "CARDNUMBER",
        name: "CARD NUMBER",
        width: 120
    },
    {
        type: "input",
        key: "AMOUNT",
        name: "AMOUNT",
        width: 100
    },
    {
        type: "string",
        key: "CLIENTMAXIMUMMTD",
        name: "",
        width: 350
    },
    {
        type: "string",
        key: "FEE",
        name: "FEE",
        width: 50
    },
    {
        type: "checkbox",
        key: "TRANSFERSMSNOTIFICATION",
        name: "NOTIFY VIA SMS",
        width: 150
    }
];

const LoadCards = () => {
    const [stateValues, setStateValues] = useState({
        cards: [],
        loadedCards: [],
        loadEffectiveDateOption: "3",
        totalAmount: 0,
        totalFee: 0,
        totalSMSNotificationFee: 0,
        EFFECTIVEDATE: new Date(),
        EFFECTIVESTOPDATE: new Date(),
        isEffectiveDateValidated: true,
        isEffectiveDateEmpty: false,
        isEffectiveStopDateValidated: true,
        isEffectiveStopDateEmpty: false
    });
    
    const userHeader = useSelector(state => state.header.userHeader);
    const allocated_cards = useSelector(state => state.scheduledLoads.allocatedCards.data);
    const metaData = useSelector(state => state.scheduledLoads.allocatedCards.metaData);
    const noDataFoundMessage = useSelector(state => state.scheduledLoads.allocatedCards.errorMessages);
    const loadClientData = useSelector(state => state.scheduledLoads.loadClientData);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        dispatch(setLoadedCards({}));
        let params = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            params[param] = value;
        }
        dispatch(verifyGetScheduledClient(params));
        let filter = { ...params};
        dispatch(verifyFetchAllocatedCards(filter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (allocated_cards.length > 0 && !(_.isEmpty(loadClientData))) {
            let cards = allocated_cards.map(obj => ({
                ...obj,
                CLIENTMAXIMUMMTD: (obj.CARDNUMBER.length <= 10 ? "The maximum load amount is unlimited" : "The load amount must be " + formatCurrency(loadClientData.DETAILS.CLIENTMINIMUMCARDLOAD) + " - " + formatCurrency(loadClientData.DETAILS.CLIENTMAXIMUMBALANCE - obj.BALANCE)),
                AMOUNT: 0,
                FEE: 0,
                TRANSFERSMSNOTIFICATION: 0,
                TRANSFERSMSNOTIFICATIONFEE: 0,
                CLIENTMINIMUMCARDLOAD: loadClientData.DETAILS.CLIENTMINIMUMCARDLOAD,
                CLIENTMAXIMUMBALANCE: loadClientData.DETAILS.CLIENTMAXIMUMBALANCE,
                isInputValid: true
            }))
            setStateValues(prevStateValues => ({ ...prevStateValues, cards }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allocated_cards, loadClientData])
    
    const extraRows = [
        {
            CARDHOLDER: "Total",
            CARDNUMBER: "",
            AMOUNT: formatCurrency(stateValues.totalAmount),
            MAXLOADAMOUNTVALIDATION: "",
            FEE: formatCurrency(stateValues.totalFee)
        }
    ];

    const handleLoadEffectiveDate = (event) => {
        const { name, value } = event.target;
        if(name === 'EFFECTIVEDATE') {
            setStateValues(prevStateValues => ({ ...prevStateValues, isEffectiveDateEmpty: !value}))
        }
        else if(name === 'EFFECTIVESTOPDATE') {
            setStateValues(prevStateValues => ({ ...prevStateValues, isEffectiveStopDateEmpty: !value}))
        }
        setStateValues(prevStateValues => ({ ...prevStateValues, [name]: value }));
    }

    const onChange = (event, element, type) => {
        if (type === "input") {
            handleScheduledLoadsAmountAndFee(event, element);
        }
        if (type === "checkbox") {
            handleScheduledLoadsNotifyViaSms(element);
        }
    }

    const handleScheduledLoadsAmountAndFee = (event, element) => {
        const { cards, loadedCards, totalAmount, totalFee } = handleAmountAndFee(event, element, loadClientData, stateValues);
        setStateValues(prevStateValues => ({ ...prevStateValues, cards, loadedCards, totalAmount, totalFee }));
    }

    const handleScheduledLoadsNotifyViaSms = (element) => {
        const { cards, loadedCards, totalSMSNotificationFee } = handleNotifyViaSms(element, loadClientData, stateValues);
        setStateValues(prevStateValues => ({ ...prevStateValues, cards, loadedCards, totalSMSNotificationFee }));
    }

    const handleContinue = () => {
        let data = {
            loadedCards: stateValues.loadedCards.filter(card => card.AMOUNT >= card.CLIENTMINIMUMCARDLOAD && card.AMOUNT <= (card.CLIENTMAXIMUMBALANCE - card.BALANCE)),
            totalAmount: stateValues.totalAmount,
            totalFee: stateValues.totalFee,
            totalSMSNotificationFee: stateValues.totalSMSNotificationFee,
            loadEffectiveDateOption: stateValues.loadEffectiveDateOption,
            EFFECTIVEDATE: DateTime.fromJSDate(stateValues.EFFECTIVEDATE).toISO(),
            EFFECTIVESTOPDATE: DateTime.fromJSDate(stateValues.EFFECTIVESTOPDATE).toISO(),
        }
        dispatch(setLoadedCards(data));
        let params = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            params[param] = value;
        }
        params["transferUuid"] = loadClientData.TRANSFERUUID;
        params["processDelay"] = 0;
        params["processType"] = stateValues.loadEffectiveDateOption;
        params["startDate"] = dateTimeFormating(stateValues.EFFECTIVEDATE);
        params["endDate"] = dateTimeFormating(stateValues.EFFECTIVESTOPDATE);
        navigate({
            pathname: "/dashboard/scheduled-loads/confirm-loads",
            search: `?${createSearchParams(params)}`
        });
    }

    const handlePagination = (offset, limit) => {
        let filter = { offset, limit };
        dispatch(verifyFetchAllocatedCards(filter));
    }

    const handleDateTimePickerError = (reason) => {
        setStateValues(prevStateValues => ({ ...prevStateValues, isEffectiveDateValidated: !reason}))
    }

    const handleEffectiveStopDateTimePickerError = (reason) => {
        setStateValues(prevStateValues => ({ ...prevStateValues, isEffectiveStopDateValidated: !reason}))
    }

    const getAllocatedCards = () => {
        const { loadedCards } = stateValues;
        const cards = stateValues.cards.map(card => {
            const loadedCardIndex = loadedCards.findIndex(loadedCard => loadedCard.ACCOUNTCARDID === card.ACCOUNTCARDID);
            const loadedCard = loadedCardIndex !== -1 ? { ...loadedCards[loadedCardIndex] } : {};
            return { 
                ...card,
                ...loadedCard,
                ID: card.ACCOUNTCARDID,
                FEE: formatCurrency(card.FEE)
            }
        });
        return cards;
    }

    const rows = getAllocatedCards();

    const enableContinueButton = () => {
        let isAmountInputInAnyRow = rows.some(row => row.AMOUNT > 0);
        let isAmountNotValidated = rows.some(row => !row.isInputValid);
        return !isAmountNotValidated && isAmountInputInAnyRow && !stateValues.isEffectiveDateEmpty && stateValues.isEffectiveDateValidated && !stateValues.isEffectiveStopDateEmpty && stateValues.isEffectiveStopDateValidated;
    }

    return (
        <Container maxWidth="xl" sx={{ width: '1350px !important' }}>
            <Title title={'Scheduled loads'} subtitle={'The balance available on your profile is ' + (userHeader && formatCurrency(+userHeader.BALANCEACCOUNT))}></Title>
            <Box sx={{ mt: 6 }}>
                <DataTable
                    columns={columns}
                    rows={rows}
                    extraRows={extraRows}
                    noDataFoundMessage={noDataFoundMessage}
                    metaData={metaData}
                    onChange={onChange}
                    handlePagination={handlePagination}
                />
            </Box>
            <Box mt={6}>
                <Text color='primary.light'>LOAD EVERY</Text>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="loadEffectiveDateOption"
                    onChange={handleLoadEffectiveDate}
                >
                    <FormControlLabel
                        value="3"
                        control={<CheckRadio color="secondary" checked={stateValues.loadEffectiveDateOption === "3"} />}
                        label="Month"
                    />
                    <FormControlLabel
                        value="2"
                        control={<CheckRadio color="secondary" checked={stateValues.loadEffectiveDateOption === "2"} />}
                        label="Week"
                    />
                    <FormControlLabel
                        value="1"
                        control={<CheckRadio color="secondary" checked={stateValues.loadEffectiveDateOption === "1"} />}
                        label="Day"
                    />
                </RadioGroup>
            </Box>
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Text color='primary.light' size={11} mb={1}>START DATE</Text>
                        <DateTimePicker
                            fullWidth
                            name="EFFECTIVEDATE"
                            value={stateValues.EFFECTIVEDATE}
                            onChange={handleLoadEffectiveDate}
                            onError={handleDateTimePickerError}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Text color='primary.light' size={11} mb={1}>END DATE</Text>
                        <DateTimePicker
                            fullWidth
                            name="EFFECTIVESTOPDATE"
                            value={stateValues.EFFECTIVESTOPDATE}
                            onChange={handleLoadEffectiveDate}
                            onError={handleEffectiveStopDateTimePickerError}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ mt: 3 }}>
                <Button
                    onClick={handleContinue}
                    variant="contained"
                    size="medium"
                    disabled={!enableContinueButton()}
                >
                    CONTINUE
                </Button>
            </Box>
        </Container>
    );
}

export default LoadCards;
