import React from 'react';
import OrderCardForm from '../orderCardForm/components/OrderCardForm'
import Container from '@mui/material/Container';
import Title from '../../../common/components/base/title/index'

const OrderCard = () => {
    return (
        <Container size='sm'>
            <Title title='Card order details' subtitle='Complete required fields to order new cards.' />
            <OrderCardForm />
        </Container>
    );
};

export default OrderCard;
