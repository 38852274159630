import React from 'react';
import { Container } from '@mui/material/';
import Title from '../../../common/components/base/title/Title';
import ReduxStatus from '../../../common/constants/ReduxStatus';
import SearchLoad from './components/SearchLoad';
import CardLoads from './components/CardLoads';
import { useSelector } from 'react-redux';

const FindPendingLoad = () => {
    const pendingLoads = useSelector((state) => state.cancelPendingLoad);
    return (
        <Container size='sm'>
            <Title title='Find a pending load' subtitle='' />
            <SearchLoad />
            {pendingLoads.cards.data && pendingLoads.cards.status === ReduxStatus.loaded && <CardLoads />}
        </Container>
    );
};

export default FindPendingLoad;
