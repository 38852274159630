import React, { useState, useEffect } from "react";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { makeStyles } from '@mui/styles';
import themeConstants from "../../../constants/Theme"
import { routes } from "../../../../app/MyRoutes";

const useStyles = makeStyles({
    root: {
        color: themeConstants.BRAND_COLOR_SECONDARY,
        textDecoration: "none",
        margin: '0 auto !important',
        fontWeight: '600'
    },
    breadcrumbs: {
        margin: '0 auto !important',
        paddingTop: '40px !important'
    }
});

export default function Breadcrumbs({ breadcrumbs, separator, spacing = 2 }) {
    const [newBreadcrumbs, setNewBreadCrumbs] = useState([]) || breadcrumbs;
    const location = useLocation();
    const classes = useStyles();

    useEffect(() => {
        let newBreadcrumbs = [];
        if (location.pathname !== "/dashboard") {
            const paths = location.pathname.split('/');
            paths.shift();
            paths.forEach((item, i) => {
                let lastBreadcrumbPath = newBreadcrumbs.length > 0 ? newBreadcrumbs[newBreadcrumbs.length - 1].url?.replace("/*", "") + "/" + item : "/" + item;
                const isLastItem = paths.length === i + 1;
                let { title, path, children } = routes.find(({ path, children }) => {
                    if (path.includes("*") && !children?.some(child => child.path === item)) {
                        return path === lastBreadcrumbPath + "/*";
                    } else if (path.includes("*") && children?.some(child => child.path === item)) {
                        return children?.some(child => child.path === item);
                    } else {
                        return path === lastBreadcrumbPath;
                    }
                }) || {};
                if (children?.length > 0) {
                    let childrenRoute = children.filter(child => child.path === item)[0];
                    title = childrenRoute?.title ?? title;
                    path = childrenRoute?.path ? path.replace('*', childrenRoute?.path) : path;
                }
                if (isLastItem) {
                    path += location.search;
                }
                newBreadcrumbs.push({ title, url: path?.replace("/*", "") });
            });
        }
        setNewBreadCrumbs(newBreadcrumbs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <div>
            {location.pathname !== "" && location.pathname !== "/" && <Stack spacing={spacing}>
                {newBreadcrumbs && newBreadcrumbs.length > 0 && (
                    <MuiBreadcrumbs className={classes.breadcrumbs}
                        separator={separator ? separator : <NavigateNextIcon fontSize="small" />}>
                        {newBreadcrumbs.map((item, index) => (
                            <Link className={classes.root} to={item.url ? item.url : "#"} key={index + 1}>
                                {item.title}
                            </Link>
                        ))}
                    </MuiBreadcrumbs>
                )}
            </Stack>}
        </div>
    );
}
