import {
    Box,
    Card,
    Container,
    Grid,
    Paper,
    Typography
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Title from '../../common/components/base/title/Title';
import themeConstants from '../../common/constants/Theme';
import { formatCurrency } from '../../common/utils/helper';
import { getDashboardBankAccounts } from './thunk';
import { useNavigate } from 'react-router-dom';
import { PayCardIconContentCopy, PayCardIconContentGoing } from '../../common/components/icons';
import { PayCardIconContentClose } from '../../common/components/icons';

const useStyles = makeStyles((theme) =>
    createStyles({
        mainbg: {
            backgroundColor: theme.palette.background.paper,
            fontSize: '14px',
            paddingBottom: 80,
        },
        mainbg2: {
            backgroundColor: '#FFF',
            fontSize: '14px',
            padding: '60px 0',
        },
        orangeCard: {
            'backgroundImage': `linear-gradient(to right, ${themeConstants.ORANGE_DARK}, ${themeConstants.ORANGE_LIGHT}) !important`,
            'padding': '40px 30px !important',
            'boxShadow': 'none !important',
            '& p': {
                color: '#FFF',
            },
            fontFamily: 'BentonSansPro_Regular',
        },
        squaredPaper: {
            paddingTop: '100%',
            position: 'relative !important',
            boxShadow:
                '0px 8px 9px -5px rgb(0 0 0 / 13%), 0px 15px 22px 2px rgb(0 0 0 / 7%), 0px 6px 28px 5px rgb(0 0 0 / 5%) !important',
        },
        squaredBox: {
            background: '#FFF',
            position: 'absolute',
            top: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            width: '100%',
            height: '100%',
            border: 0,
            cursor: 'pointer',
            fontSize: 14
        },
        squaredLabel: {
            width: '70%',
            margin: 'auto',
            paddingTop: 10,
        },
    }),
);

const SquaredButton = ({ label, icon, link }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleClick = () => {
        window.scrollTo(0, 0);
        navigate(link);
    };

    return (
        <>
            <Paper className={classes.squaredPaper}>
                <Box
                    component='button'
                    onClick={handleClick}
                    className={classes.squaredBox}
                >
                    <div>
                        <div>{icon}</div>
                        <div className={classes.squaredLabel}>{label}</div>
                    </div>
                </Box>
            </Paper>
        </>
    );
};

const DepositFunds = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { ACCOUNTNUMBER, BALANCEACCOUNT, CONTENTDETAIL } = useSelector(
        (state) => state.depositFunds.data,
    );

    useEffect(() => {
        dispatch(getDashboardBankAccounts());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const subTitles = [
        'Funds can be deposited into the pre-approved beneficiary using internet banking only.',
        'In order for your deposit to be correctly allocated, you must use your unique reference number exactly as it is shown, without any spaces, for each deposit.',
        'Deposits take up to 3 days to reflect. Once the deposit has reflected, the funds will be available to load onto cards.',
    ];
    return (
        <>
            <div className={classes.mainbg}>
                <Container maxWidth='lg'>
                    <Title
                        title={'Deposit funds'}
                        subtitleArray={subTitles}
                    />
                    <Card sx={{ mt: 8 }} className={classes.orangeCard}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Typography variant='h4' color='#FFF'>
                                    Instructions
                                </Typography>
                                <p>
                                    Your unique reference number is <b>{ACCOUNTNUMBER}</b>.
                                </p>
                                <p>
                                    You currently have <b>{formatCurrency(BALANCEACCOUNT)}</b> funds available.
                                </p>
                                <br />
                                <p>
                                    Use Internet Banking to deposit funds into
                                    the <b>{CONTENTDETAIL}</b>.
                                </p>
                                <p>
                                    <b>Deposits take up to 3 days to reflect.</b>
                                </p>
                            </Grid>
                            <Grid item xs={4}>
                                <img
                                    src={require('../../assets/images/img_content_depositFunds.png')}
                                    alt=""
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        maxWidth: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
            </div>
            <div className={classes.mainbg2}>
                <Container maxWidth='lg'>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <Typography variant='h6' sx={{ marginBottom: 4 }}>
                                WHAT NOW
                            </Typography>
                            <Typography variant='h6' color='primary.subtitle'>
                                What would you like to do?
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid container spacing={6}>
                                <Grid item xs={4}>
                                    <SquaredButton
                                        label={'I am finished'}
                                        icon={<PayCardIconContentClose />}
                                        link={'/dashboard'}
                                    ></SquaredButton>
                                </Grid>
                                <Grid item xs={4}>
                                    <SquaredButton
                                        label={'Order cards'}
                                        icon={<PayCardIconContentCopy />}
                                        link={'/dashboard/order-card'}
                                    ></SquaredButton>
                                </Grid>
                                <Grid item xs={4}>
                                    <SquaredButton
                                        label={'Allocate cards'}
                                        icon={<PayCardIconContentGoing />}
                                        link={'/dashboard/allocate-card'}
                                    ></SquaredButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};

export default DepositFunds;
