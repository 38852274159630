import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Container, Tab } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Title from '../../common/components/base/title/Title';
import BatchLoadList from './components/BatchLoadList';
import NewBatchLoadStep1 from './components/NewBatchLoadStep1';
import NewBatchLoadStep2 from './components/NewBatchLoadStep2';
import NewBatchLoadStep3 from './components/NewBatchLoadStep3';
import NewBatchLoadStep4 from './components/NewBatchLoadStep4';
import { getGetLoadDetail } from './thunk';

const useStyles = makeStyles((theme) =>
    createStyles({
        mainbg: {
            backgroundColor: theme.palette.background.paper,
            fontSize: '14px'
        },
        mainbgWhite: {
            backgroundColor: '#FFF',
            fontSize: '14px'
        },
        tabPanel: {
            padding: '24px 0 80px 0 !important'
        }
    }),
);

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

const BatchLoad = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search, pathname } = useLocation();
    const uuid = new URLSearchParams(search).get('uuid');
    const fetchUuid = useSelector((state) => {
        return state.batchLoad.data.fetchUuid;
    });

    useEffect(() => {
        if (uuid && fetchUuid) {
            dispatch(getGetLoadDetail(uuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uuid, fetchUuid]);

    useEffect(() => {
        if (['/dashboard/batch-load', '/dashboard/batch-load/'].indexOf(pathname) > -1) {
            navigate("/dashboard/batch-load/step1");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const currentTab = pathname.includes('/batch-load/list') ? 'TABOVERVIEW' : 'TABNEW';

    return (
        <>
            <div className={classes.mainbg}>
                <Container maxWidth='lg'>
                    <Title title="Batch load" subtitle="Load cards in a batch using using file data." />
                </Container>
            </div>
            <div className={classes.mainbgWhite}>
                <Container maxWidth='lg'>
                    <TabContext value={currentTab}>
                        <Box sx={{ mt: 8, borderBottom: 1, borderColor: 'divider' }}>
                            <TabList>
                                <Tab label="START A NEW BATCH LOAD" value={'TABNEW'} component={Link} to="step1" />
                                <Tab label="OVERVIEW PREVIOUS BATCH LOADS" value={'TABOVERVIEW'} component={Link} to="list" />
                            </TabList>
                        </Box>

                        <Box className={classes.tabPanel}>
                            <Routes>
                                <Route path="step1" element={<NewBatchLoadStep1 />} />
                                <Route path="step2" element={<NewBatchLoadStep2 />} />
                                <Route path="step3" element={<NewBatchLoadStep3 />} />
                                <Route path="step4" element={<NewBatchLoadStep4 />} />
                                <Route path="list" element={<BatchLoadList />} />
                            </Routes>
                        </Box>
                    </TabContext>
                </Container>
            </div>
        </>
    );
};

export default BatchLoad;
