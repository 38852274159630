import { createSlice } from '@reduxjs/toolkit';
import ReduxStatus from '../../../common/constants/ReduxStatus';
import { searchCards, fetchPendingLoad, confirmCancelation } from './thunk';

const initialState = {
    cards: {
        data: {},
        filter: {},
        errorMessages: '',
        status: ReduxStatus.empty,
    },
    pendingLoad: {
        data: {},
        errorMessages: '',
        status: ReduxStatus.empty,
    },
};

export const cancelPendingLoadSlice = createSlice({
    name: 'cancelPendingLoads',
    initialState,
    reducers: {
        saveFilter: (state, action) => {
            state.cards.filter = action.payload;
        },
    },
    extraReducers: {
        [searchCards.pending]: (state, action) => {
            state.cards.status = ReduxStatus.loading;
        },
        [searchCards.fulfilled]: (state, action) => {
            state.cards.data = action.payload;
            state.cards.status = ReduxStatus.loaded;
            if (action.payload.PENDINGTRANSFERFUNDS.length === 0) state.cards.errorMessages = 'No results found.';
        },
        [searchCards.rejected]: (state, action) => {
            state.cards.errorMessages = 'No results found...';
            state.cards.status = ReduxStatus.loadFailed;
        },
        [fetchPendingLoad.pending]: (state, action) => {
            state.pendingLoad.status = ReduxStatus.loading;
        },
        [fetchPendingLoad.fulfilled]: (state, action) => {
            state.pendingLoad.data = action.payload;
            state.pendingLoad.status = ReduxStatus.loaded;
        },
        [fetchPendingLoad.rejected]: (state, action) => {
            state.pendingLoad.errorMessages = 'No pending load found...';
            state.pendingLoad.status = ReduxStatus.loadFailed;
        },
        [confirmCancelation.pending]: (state, action) => {
            state.pendingLoad.status = ReduxStatus.saving;
        },
        [confirmCancelation.fulfilled]: (state, action) => {
            state.pendingLoad.status = ReduxStatus.saved;
        },
        [confirmCancelation.rejected]: (state, action) => {
            state.pendingLoad.errorMessages = 'Failed to cancel pending load...';
            state.pendingLoad.status = ReduxStatus.saveFailed;
        },
    },
});

export const { saveFilter } = cancelPendingLoadSlice.actions;

export default cancelPendingLoadSlice.reducer;
