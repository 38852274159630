

const MAX_NAME_LENGTH = 50;
const MAX_COMPANY_NUMBER = 10000;
const MIN_COMPANY_NUMBER = 1;
const MIN_ID_LENGTH = 9;
const MAX_TELEPHONE_NUMBER = 20;
const MAX_CODE_LENGTH = 4;

export const isNameError = (companyname) => {
    if (!companyname || companyname.length > MAX_NAME_LENGTH) {
        return true;
    }
    return false;
};

export const iscnumError = (companyNumber) => {
    if (!companyNumber || companyNumber.length > MAX_COMPANY_NUMBER || companyNumber.length < MIN_COMPANY_NUMBER) {
        return true;
    }
    return false;
};

export const isfirstNameError = (firstName) => {
    if (!firstName || firstName.length > MAX_NAME_LENGTH) {
        return true;
    }
    return false;
};

export const islastNameError = (lastName) => {
    if (!lastName || lastName.length > MAX_NAME_LENGTH) {
        return true;
    }
    return false;
};

export const isIdError = (idNumber) => {
    if (!idNumber || idNumber.length < MIN_ID_LENGTH || idNumber.length > MAX_NAME_LENGTH) {
        return true;
    }
    return false;
};

export const iscontactNumberError = (contactNumber) => {
    if (!contactNumber || contactNumber.length > MAX_TELEPHONE_NUMBER) {
        return true;
    }
    return false;
};

export const iscellphoneNumberError = (cellphoneNumber) => {
    if (!cellphoneNumber || cellphoneNumber.length >MAX_TELEPHONE_NUMBER) {
        return true;
    }
    return false;
};

export const isaddressError = (address) => {
    if (!address || address.length  > MAX_NAME_LENGTH) {
        return true;
    }
    return false;
};

export const iscityError = (city) => {
    if (!city || city.length  > MAX_NAME_LENGTH) {
        return true;
    }
    return false;
};


export const iscodeError = (code) => {
    if (!code || code.length  > MAX_CODE_LENGTH) {
        return true;
    }
    return false;
};

export const isvatError = (vat) => {
    if (!vat || vat.length  > MAX_CODE_LENGTH) {
        return true;
    }
    return false;
};





