import { createSlice } from '@reduxjs/toolkit';
import { verifyFetchNotice, verifyHideNotice } from './thunk';
import ReduxStatus from '../common/constants/ReduxStatus';

const initialState = {
    noticeList: {
        status: ReduxStatus.empty,
        activePage: 1,
        totalPage: 1,
        totalRecord: 0,
        data: [],
        errorMessages: [],
        filter: {},
    }
};

export const noticeSlice = createSlice({
    name: 'notices',
    initialState,
    reducers: {},
    extraReducers: {
        [verifyFetchNotice.pending]: (state, action) => {
            state.noticeList.status = ReduxStatus.loading;
        },
        [verifyFetchNotice.fulfilled]: (state, action) => {
            state.noticeList.data = action.payload.NOTICES;
            state.noticeList.status = ReduxStatus.loaded;
        },
        [verifyFetchNotice.rejected]: (state, action) => {
            state.noticeList.errorMessages = action.payload;
            state.noticeList.status = ReduxStatus.loadFailed;
        },
        [verifyHideNotice.pending]: (state, action) => {
            state.noticeList.status = ReduxStatus.loading;
        },
        [verifyHideNotice.fulfilled]: (state, action) => {
            state.noticeList.data = action.payload.NOTICES;
            state.noticeList.status = ReduxStatus.loaded;
        },
        [verifyHideNotice.rejected]: (state, action) => {
            state.noticeList.errorMessages = action.payload;
            state.noticeList.status = ReduxStatus.loadFailed;
        }
    }
});

export default noticeSlice.reducer;
