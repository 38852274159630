import { createAsyncThunk } from '@reduxjs/toolkit';
import { updatePassword, getAccountEmail } from './service';

export const verifyUpdatePassword = createAsyncThunk(
    'verify/update_password',
    async (params, { rejectWithValue }) => {
        try {
            const response = await updatePassword(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const verifyGetAccountEmail = createAsyncThunk(
    'verify/get_account_details',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getAccountEmail(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
