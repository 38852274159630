const ReduxStatus = {
    empty: '',
    loading: 'loading',
    loaded: 'loaded',
    loadFailed: 'loadFailed',
    saving: 'saving',
    saved: 'saved',
    saveFailed: 'saveFailed',
    editing: 'editing',
    edited: 'edited',
    editFailed: 'editFailed',
    deleting: 'deleting',
    deleted: 'deleted',
    deleteFailed: 'deleteFailed',
}

export const LOGIN_PAGE_STATE = Object.freeze({
    login: 'login',
    passwordReminder: 'passwordReminder', // NOSONAR
    mfaSetupRequired: 'mfaSetupRequired',
    mfaChallengeRequired: 'mfaChallengeRequired',
  });
  

export default ReduxStatus;
