import themeConstants from "../../../common/constants/Theme";

export function getItemFromList(items, key, value){
    return items.find((entry) => entry[key] === value );
}

export function allItemsHaveField(items, field){
    let allHaveField = true;
    items.forEach(item => {
        if(item[field] === undefined){
            allHaveField = false;
        }
    })
    return allHaveField
}

export const defaultCardHolderDetails = {
    firstName: "",
    surname: "",
    cellPhoneNumber: "",
    idNumber: "",
    reference: ""
}

export const cardHolderFieldNames = {
    firstName: "FIRST NAME",
    surname: "SURNAME",
    idNumber: "ID/PASSPORT NUMBER",
    cellPhoneNumber: "CELLPHONE NUMBER",
    reference: "REFERENCE"
}

export const cardHolderRequiredFields = {
    firstName: true,
    surname: true,
    idNumber: true,
    cellPhoneNumber:true
}

export const maxFieldSizes = {
    firstName: 50,
    surname: 50,
    idNumber: 20,
    cellPhoneNumber: 11
}

export const minFieldSizes = {
    idNumber: 7
}

export const prevDisabledStyles = {
    border: `1px solid ${themeConstants.BRAND_COLOR_SECONDARY}`,
    color: themeConstants.BRAND_COLOR_SECONDARY,
    opacity: 0.5
}

export const  defaultRegistrationDetail= {
    firstName: "",
    surname: "",
    cellPhoneNumber: "",
    idNumber: "",
    reference: ""
}

export const ALLOCATE_CARD_HOLDER_DETAILS_URL = "/dashboard/allocate-card/card-holder-details";
export const ALLOCATE_CARD_SELECT_CARDS_URL = "/dashboard/allocate-card";
export const ALLOCATE_CARD_CONFIRM_URL = "/dashboard/allocate-card/confirm";
export const DASHBOARD_URL = "/dashboard";
export const DEPOSIT_FUNDS_URL = "/dashboard/deposit-funds";
export const LOAD_FUNDS_URL = "/dashboard/load-funds-from";
