import Http from "../../common/utils/Http";

export const fetchAllocatedCards = async (params) => {
    try {
        let response = await Http.post('pr/funds/search-scheduled-allocated', params);
        return response;
    } catch ({ errorMessages }) {
        throw errorMessages;
    }
}

export const getScheduledClient = async (params) => {
    try {
        let response = await Http.post('pr/funds/get-scheduled-client', params);
        return response;
    } catch ({ errorMessages }) {
        throw errorMessages;
    }

}

export const saveTransfer = async (params) => {
    return Http.post('pr/funds/save-transfer', params);
}

export const viewInvoice = async (transferID) => {
    try {
        let response = await Http.get('pr/funds/view-invoice?invoiceID=' + transferID, {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
            },
        });
        return response;
    } catch (errorMessages) {
        throw errorMessages;
    }
}
