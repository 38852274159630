import React from 'react';
import { Box, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatCurrency } from './../../../../common/utils/helper';

const useStyles = makeStyles({
    table: {
        fontSize: '30px !important',
    },
    Header: {
        fontWeight: '400 !important',
        color: '#848D9E !important',
        textTransform: 'uppercase',
        fontSize: '14px !important',
    },
    tBody: {
        fontWeight: '400 !important',
        fontSize: '16px !important',
    },
    rowLine: {
        borderBottom: '2px solid rgba(224, 224, 224, 1) !important',
    },
});

export default function PendingLoadTable({ loadId, pendingLoad }) {
    const classes = useStyles();
    return (
        <>
            <Grid item xs={12}>
                <Box sx={{ mt: 2 }}>
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.Header}>PENDING LOAD NUMBER</TableCell>
                                    <TableCell className={classes.Header}>REQUESTED ON</TableCell>
                                    <TableCell className={classes.Header}>NEXT LOAD</TableCell>
                                    <TableCell className={classes.Header}>TRANSFER TYPE</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className={classes.rowLine}>
                                    <TableCell className={classes.tBody}>{loadId}</TableCell>
                                    <TableCell className={classes.tBody}>{pendingLoad.TRANSFERREQUESTDATE}</TableCell>
                                    <TableCell className={classes.tBody}>{pendingLoad.TRANSFEREFFECTIVEDATE}</TableCell>
                                    <TableCell className={classes.tBody}>{pendingLoad.TRANSFERTYPE}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ mt: 6, ml: 1 }}>
                    <Typography variant='h6' color='primary.light'>
                        CARDS
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ mt: 1 }}>
                    <TableContainer>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.Header}>CARD NUMBER</TableCell>
                                    <TableCell className={classes.Header}>CARD AMOUNT</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className={classes.rowLine}>
                                    <TableCell className={classes.tBody}>{pendingLoad.TRANSFERCARDTOACCOUNT}</TableCell>
                                    <TableCell className={classes.tBody}>
                                        {formatCurrency(+pendingLoad.TRANSFERAMOUNT)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Grid>
        </>
    );
}
