import { createSlice } from "@reduxjs/toolkit";
import ReduxStatus from "../../common/constants/ReduxStatus";
import reducers, { extraReducers } from "./reducers";
import { DateTime } from "luxon";

export const initialState = {
    statement: {
        data: null,
        status: ReduxStatus.empty,
        filter: {
            startDate: DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd'),
            endDate: DateTime.now().toFormat('yyyy-MM-dd'),
        },
        view: "list",
        errorMessages: [],
        errorMessageShown: false
    },
    pdf: {
        data: null,
        status: ReduxStatus.empty,
        errorMessage: [],
    },
    docDownload: {
        data: null,
        status: ReduxStatus.empty,
        shouldDownload: false,
        downloaded: true,
        errorMessages: [],
        errorMessageShown: false
    }
}

export const profileHistorySlice = createSlice({
    name: "profileHistory",
    initialState,
    reducers,
    extraReducers,
});

export const profileHistoryActions = profileHistorySlice.actions;

export default profileHistorySlice.reducer;
