import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCards, getLoad, postCancelation } from './service';

export const searchCards = createAsyncThunk(
    'cancelPendingLoad/search_cards',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getCards(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const fetchPendingLoad = createAsyncThunk(
    'cancelPendingLoad/fetch_pending_load',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getLoad(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const confirmCancelation = createAsyncThunk(
    'cancelPendingLoad/confirm_cancelation',
    async (params, { rejectWithValue }) => {
        try {
            const response = await postCancelation(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
