import Http from "../../common/utils/Http";

export const requestEmailChange = async (params) => {
    try {
        let response = await Http.post('pr/settings/requestEmailChange', params);
        return response;
    } catch ({ errorMessages }) {
        throw errorMessages;
    }
}

export const changeEmail = async (params) => {
    try {
        let response = await Http.post('pr/settings/changeEmail', params);
        return response;
    } catch ({ errorMessages }) {
        throw errorMessages;
    }
}
