import React, { useState } from "react";
import Title from '../../../common/components/base/title/Title';
import { useSelector, useDispatch } from 'react-redux';
import { 
    Container, 
    Box,
    Alert,
    Collapse,
    IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DataTable from '../../../common/components/base/datatable';
import Button from '../../../common/components/base/button/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifySaveTransfer } from "../thunk";
import { DateTime } from "luxon";
import { formatCurrency } from '../../../common/utils/helper';
import ReduxStatus from "../../../common/constants/ReduxStatus";
import Loader from "../../../common/components/base/loader/Loader";
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
        fontSize: '14px',
    }
}));

const columns = [
    {
        type: "string",
        key: "CARDHOLDER",
        name: "CARD HOLDER",
        width: 200
    },
    {
        type: "number",
        key: "CARDNUMBER",
        name: "CARD NUMBER",
        width: 120
    },
    {
        type: "number",
        key: "AMOUNT",
        name: "AMOUNT",
        width: 100
    },
    {
        type: "number",
        key: "FEE",
        name: "FEE",
        width: 50
    },
    {
        type: "number",
        key: "TRANSFERSMSNOTIFICATIONFEE",
        name: "SMS NOTIFICATION FEE",
        width: 150
    },
];

const ConfirmLoads = () => {
    const [confirmCardLoadStatus, setConfirmCardLoadStatus] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    const classes = useStyles();
    const userHeader = useSelector(state => state.header.userHeader);
    const loadedDataToConfirm = useSelector(state => state.scheduledLoads.loadedDataToConfirm);
    const loadedCards = loadedDataToConfirm?.loadedCards ?? [];
    const loadEffectiveStartDate = loadedDataToConfirm.EFFECTIVEDATE;
    const loadEffectiveEndDate = loadedDataToConfirm.EFFECTIVESTOPDATE;
    const totalAmount = loadedDataToConfirm.totalAmount;
    const totalFee = loadedDataToConfirm.totalFee;
    const loadEffectiveDateOption = loadedDataToConfirm.loadEffectiveDateOption;
    const totalSMSNotificationFee = loadedDataToConfirm.totalSMSNotificationFee;
    const status = useSelector(state => state.scheduledLoads.saveTransfer.status);
    const confirmCardLoadErrorMessage = useSelector(state => state.scheduledLoads.confirmCardLoadErrorMessage);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleConfirm = () => {
        let cards = [];
        loadedCards.forEach(card => {
            cards.push({
                accountCardId: card.ACCOUNTCARDID,
                transferAmount: parseFloat(card.AMOUNT),
                transferFee: card.FEE,
                transferSMSNotificationFee: card.TRANSFERSMSNOTIFICATIONFEE,
                transferSMSNotification: card.TRANSFERSMSNOTIFICATION
            })
        })
        let params = {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            if (param === "processDelay") {
                params[param] = parseInt(value);
            } else if (param === "processType") {
                params[param] = parseInt(value);
            } else {
                params[param] = value;
            }
        }
        params["accountFrom"] = false;
        params["transferFromAccountId"] = 0;
        params["cards"] = cards;
        dispatch(verifySaveTransfer(params))
            .then(response => {
                if (response.type === "verify/save_transfer/fulfilled" && response.payload.TRANSFERID) {
                    setConfirmCardLoadStatus(true);
                }
                else if (response.type === "verify/save_transfer/rejected") {
                    setOpenAlert(true);
                }
            })
    }

    const checkEffectiveDateOption = (value) => {
        if (value === "1") {
            return "daily";
        }
        if (value === "2") {
            return "weekly";
        }
        if (value === "3") {
            return "monthly";
        }
    };

    const extraRows = [
        {
            CARDHOLDER: "LOAD EFFECTIVE DATE",
            CARDNUMBER: "",
            AMOUNT: loadEffectiveDateOption !== undefined ? ("Will take place " + (checkEffectiveDateOption(loadEffectiveDateOption)) + " from " + (DateTime.fromISO(loadEffectiveStartDate).toISODate()) + " till " + (DateTime.fromISO(loadEffectiveEndDate).toISODate()) + " at 12:00 AM") : "",
            FEE: "",
            TRANSFERSMSNOTIFICATIONFEE: ""
        },
        {
            CARDHOLDER: "FINAL TOTAL",
            CARDNUMBER: "",
            AMOUNT: formatCurrency(totalAmount ?? 0),
            FEE: formatCurrency(totalFee ?? 0),
            TRANSFERSMSNOTIFICATIONFEE: formatCurrency(totalSMSNotificationFee ?? 0)
        }
    ];

    const noDataFoundMessage = loadedCards.length === 0 ? "Balance is not loaded to any card." : "";

    const rows = loadedCards.map(card => {
        const item = Object.assign({}, card);
        item.AMOUNT = formatCurrency(card.AMOUNT);
        item.FEE = formatCurrency(card.FEE);
        item.error = card.FICAVALIDATION;
        item.TRANSFERSMSNOTIFICATIONFEE = formatCurrency(card.TRANSFERSMSNOTIFICATIONFEE);
        return item;
    });

    let isFicaInvalid = rows.some(row => row.error);

    const handleFinished = () => {
        navigate("/dashboard");
    }

    const handleDepositFunds = () => {
        navigate("/dashboard/deposit-funds");
    }

    const handleViewStatement = () => {
        navigate("/dashboard/card-statement");
    }

    return (
        <div className={classes.mainbg}>
            <Container maxWidth="xl">
                {!confirmCardLoadStatus &&
                    <>
                        <Title title={'Confirm Card loads'} subtitle={'The balance available on your profile is ' + (userHeader && formatCurrency(+userHeader.BALANCEACCOUNT))}></Title>
                    </>
                }
                {confirmCardLoadStatus &&
                    <Title title={'Invoice'} subtitle={(userHeader?.BALANCEACCOUNT >= 0) 
                        ? "Your card load request has been submitted and will be processed immediately."
                        : "Your card load request will not be processed until you have deposited funds into your profile"}>
                    </Title>
                }
                <DataTable
                    columns={columns}
                    rows={rows}
                    extraRows={extraRows}
                    noDataFoundMessage={noDataFoundMessage}
                />
                {!confirmCardLoadStatus && <>
                    <Box sx={{ mt: 3 }}>
                        <Collapse in={openAlert}>
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpenAlert(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                {confirmCardLoadErrorMessage}
                            </Alert>
                        </Collapse>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Button
                            onClick={handleConfirm}
                            variant="contained"
                            size="medium"
                            disabled={!_.isEmpty(confirmCardLoadErrorMessage) || rows.length === 0 || isFicaInvalid}
                        >
                            CONFIRM
                        </Button>
                    </Box>
                </>}
                {confirmCardLoadStatus && <>
                    <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                        <Button
                            onClick={handleFinished}
                            variant="contained"
                            size="medium">
                            I'M FINISHED
                        </Button>
                        <Button
                            onClick={handleDepositFunds}
                            variant="outlined"
                            size="medium"
                            styles={{ ml: 4 }}>
                            DEPOSIT FUNDS
                        </Button>
                        <Button
                            onClick={handleViewStatement}
                            variant="outlined"
                            size="medium"
                            styles={{ ml: 4 }}>
                            VIEW STATEMENT
                        </Button>

                    </Box>
                </>}
                {status === ReduxStatus.loading && <Loader open={true} />}
            </Container>
        </div >
    );
}

export default ConfirmLoads;
