import React from 'react';
import { makeStyles } from '@mui/styles';
import LoadCards from './components/LoadCards';
import { Container } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
        fontSize: '14px',
    }
}));

const ScheduledLoads = () => {
    const classes = useStyles();

    return (
        <div className={classes.mainbg}>
            <Container maxWidth="xl">
                <LoadCards />
            </Container>
        </div>
    );
}

export default ScheduledLoads;
