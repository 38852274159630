import Http from "../common/utils/Http";

export const fetchNews = async () => {
    try {
        let response = await Http.get('pr/news/history ');
        return response;
    } catch ({ errorMessages }) {
        throw errorMessages;
    }
}
