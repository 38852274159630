import { createSlice } from '@reduxjs/toolkit';
import ReduxStatus from '../../common/constants/ReduxStatus';
import { verifyRequestEmailChange } from './thunk';


const initialState = {
    status: ReduxStatus.empty,
};

export const updateEmailRequestSlice = createSlice({
    name: 'updateEmailRequest',
    initialState: initialState,
    extraReducers: {
        [verifyRequestEmailChange.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [verifyRequestEmailChange.fulfilled]: (state, action) => {
            state.status = ReduxStatus.loaded;
        },
        [verifyRequestEmailChange.rejected]: (state, action) => {
            state.status = ReduxStatus.loadFailed;
        }
    }
});

export default updateEmailRequestSlice.reducer;
