import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import SelectedCardItem from "./SelectedCardItem";
import Text from "../../../../../common/components/base/text";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

const headCells = [
    {
        id: "accountCardNumber",
        label: "CARD NUMBER",
    },
    {
        id: "accountCardTrackingNumber",
        label: "TRACKING NUMBER",
    },
    {
        id: "accountCardSequenceNumber",
        label: "SEQUENCE NUMBER",
    },
    {
        id: "accountCardExpiryDate",
        label: "EXPIRY DATE",
    },
    {
        id: "removeAction",
        label: "REMOVE"
    }
];

function SelectedCardsHead(props) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>
                        <Text light>
                            {headCell.label}
                        </Text>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function SelectedCardsList() {
    const selectedCards = useSelector((state) => state.cardAllocation.selectedCards);

    return (
        <Box sx={{ width: "100%", mt: 3 }}>
            <Typography variant="h6" sx={{ color: "#3c4b6c", fontSize: 20, fontWeight: 400 }}>
                CARD SELECTED
            </Typography>
            <div sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table aria-labelledby="AvailableCards">
                        <SelectedCardsHead />
                        <TableBody>
                            {selectedCards.map((card, index) => {
                                return (
                                    <SelectedCardItem
                                        card={card}
                                        key={index + "-selected-card"}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Box>
    );
}
