import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchNews } from './service';

export const verifyFetchNews = createAsyncThunk(
    'verify/news_fetch',
    async (params, { rejectWithValue }) => {
        try {
            const response = await fetchNews();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
