import { createSlice } from '@reduxjs/toolkit';
import ReduxStatus from '../../../common/constants/ReduxStatus';
import * as reducers from './reducers';
import {
    fetchUser,
    fetchCardOrder,
    sendCardOrder,
    saveConfirmedCardOrder,
    fetchBankBranches,
    fetchInvoice,
} from './thunk';

const initialState = {
    cardOrder: {
        sendStatus: ReduxStatus.empty,
        saveStatus: ReduxStatus.empty,
        data: {},
        errorMessages: [],
    },
    invoice: {
        status: ReduxStatus.empty,
        data: {},
        errorMessages: [],
    },
    user: {
        status: ReduxStatus.empty,
        data: {},
        errorMessages: [],
    },
    bankBranches: {
        status: ReduxStatus.empty,
        data: [],
        errorMessages: [],
    },
};

export const orderCardSlice = createSlice({
    name: 'orderCard',
    initialState,
    reducers: {
        reset: () => initialState,
        saveCardOrder: (state, action) => {
            state.cardOrder.data = action.payload;
        },
    },
    extraReducers: {
        [fetchUser.pending]: reducers.fetchingUsers,
        [fetchUser.fulfilled]: reducers.fetchedUsers,
        [fetchUser.rejected]: reducers.failedFetchingUsers,

        [fetchCardOrder.pending]: reducers.fetchingCardOrder,
        [fetchCardOrder.fulfilled]: reducers.fetchedCardOrder,
        [fetchCardOrder.rejected]: reducers.failedFetchingCardOrder,

        [sendCardOrder.pending]: reducers.sendingCardOrder,
        [sendCardOrder.fulfilled]: reducers.sendCardOrder,
        [sendCardOrder.rejected]: reducers.failedSendingCardOrder,

        [saveConfirmedCardOrder.pending]: reducers.sendingConfirmedCardOrder,
        [saveConfirmedCardOrder.fulfilled]: reducers.sendConfirmedCardOrder,
        [saveConfirmedCardOrder.rejected]: reducers.failedSendingConfirmedCardOrder,

        [fetchBankBranches.pending]: reducers.fetchingBankBranches,
        [fetchBankBranches.fulfilled]: reducers.fetchedBankBranches,
        [fetchBankBranches.rejected]: reducers.failedFetchingBankBranches,

        [fetchInvoice.pending]: reducers.fetchingInvoice,
        [fetchInvoice.fulfilled]: reducers.fetchedInvoice,
        [fetchInvoice.rejected]: reducers.failedFetchingInvoice,
    },
});

export const { saveCardOrder, reset } = orderCardSlice.actions;
export default orderCardSlice.reducer;
