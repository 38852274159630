import { createAsyncThunk } from '@reduxjs/toolkit';
import { faqList } from './service';

export const getFaqs = createAsyncThunk(
    'faqs',
    async (params, { rejectWithValue }) => {
        try {
            const response = await faqList();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
