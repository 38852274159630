import React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    maindiv: {
        position: 'relative',
        display: 'inline-block',
        width: '100%'
    },
}))

const Banner = () => {
    const classes = useStyles();

    return (
        <div className={classes.maindiv}>
            <Grid container >
                <Grid item xs={12}>
                    <img src={require('../../assets/images/img_content_banner.png')} alt="banner" style={{
                        width: '100%', height: '100%', maxWidth: '100%', objectFit: "cover",
                    }} />
                </Grid>
            </Grid>
        </div>
    )

}

export default Banner;
