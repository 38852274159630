import React, { useEffect, useState } from 'react';

import { Container, Alert } from '@mui/material';
import Title from '../../../common/components/base/title/index';
import Invoice from '../orderCardInvoice/components/Invoice';
import { useSelector, useDispatch } from 'react-redux';
import { formatCurrency } from '../../../common/utils/helper';
import Loader from '../../../common/components/base/loader/Loader';
import ReduxStatus from '../../../common/constants/ReduxStatus';
import { useLocation } from 'react-router-dom';
import { fetchInvoice } from '../redux/thunk';
import { updateBalanceAfterCardOrder } from '../../../common/components/Header/slice';

const OrderCardInvoice = () => {
    const location = useLocation();
    const [order, setOrder] = useState({});
    const status = useSelector((state) => state.orderCard.invoice.status);
    const pdf = useSelector((state) => state.orderCard.invoice.data);
    const dispatch = useDispatch();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const cardOrderId = params.get('cardOrderId');
        setOrder({
            accountBalance: params.get('accountBalance'),
            accountNumber: params.get('accountNumber'),
        });
        dispatch(fetchInvoice(cardOrderId));
        dispatch(updateBalanceAfterCardOrder(+params.get('accountBalance')));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createSubtitleArray = () => {
        if (+order.accountBalance > 0) {
            return [
                'Your card order has been placed.',
                'Once you have received your cards, you can allocate cards and load funds.',
            ];
        } else {
            return [
                `Your unique reference number is ${order.accountNumber}.`,
                `You currently have ${formatCurrency(+order.accountBalance)} funds available.`,
                'Your card order will not be processed until you have deposited funds into',
                'the "Credits Outstanding: PayCard" beneficiary using internet banking.',
                'Your cards will then be delivered, and you can allocate cards and load funds.',
            ];
        }
    };

    return (
        <Container maxWidth="xl" sx={{ width: '1350px !important' }}>
            {order && <Title title='Invoice' subtitleArray={createSubtitleArray()} />}
            {status === ReduxStatus.loadFailed && (
                <Alert severity='error' sx={{ mt: 5 }}>
                    Could not load the invoice. Please try again another time...
                </Alert>
            )}
            {status === ReduxStatus.loading && <Loader open={true} />}
            {status === ReduxStatus.loaded && pdf && <Invoice pdf={pdf} />}
        </Container>
    );
};

export default OrderCardInvoice;
