export const handleAmountAndFee = (event, element, loadClientData, stateValues, inputValidField = 'isInputValid') => {
    let value = event.target.value ? parseFloat(event.target.value) : 0;
    let { ...obj } = element;
    obj.AMOUNT = value;
    let isValidAmount = (value >= obj.CLIENTMINIMUMCARDLOAD && value <= obj.CLIENTMAXIMUMBALANCE - obj.BALANCE) || (value >= 0 && obj.CARDNUMBER.length <= 10);
    obj[inputValidField] = value !== 0 ? isValidAmount : true;
    obj.FEE = obj.AMOUNT && isValidAmount ? calculateFee(obj, loadClientData) : 0;
    let [...cards] = stateValues.cards;
    let index = cards.findIndex(item => item.ACCOUNTCARDID === element.ACCOUNTCARDID);
    cards[index] = obj;
    let [...loadedCards] = stateValues.loadedCards;
    cards.forEach(card => {
        let isAlreadyExist = loadedCards.some(item => item.ACCOUNTCARDID === card.ACCOUNTCARDID);
        if (!isAlreadyExist) {
            loadedCards.push(card);
        }
    })
    let loadedCardIndex = loadedCards.findIndex(item => item.ACCOUNTCARDID === element.ACCOUNTCARDID);
    loadedCards[loadedCardIndex] = obj;
    let totalAmount = loadedCards.reduce((sum, current) => sum + parseFloat(current.AMOUNT), 0);
    let totalFee = loadedCards.reduce((sum, current) => sum + parseFloat(current.FEE), 0);
    return {
        cards,
        loadedCards,
        totalAmount,
        totalFee
    }
}

const calculateFee = (card, loadClientData) => {
    let rebates = loadClientData.REBATE;
    let details = loadClientData.DETAILS;
    let validTransfers = 0;
    let currentNumberOfTransfers = details?.PREVIOUSTRANSFERS ?? 0;

    let fee = 0;
    if (card.ACCOUNTCARDID > 0) {
        if (rebates.length > 0) {
            rebates.forEach(rebate => {
                if (currentNumberOfTransfers + validTransfers > rebate.CLIENTREBATEAMOUNTREQUIRED) {
                    fee = rebate.CLIENTREBATEVALUE;
                }
                else {
                    fee = details.CLIENTTRANSFERFEE;
                }
                validTransfers += 1;
            })
        }
        else {
          fee = details.CLIENTTRANSFERFEE;
        }
    }
    return fee;
}

export const handleNotifyViaSms = (element, loadClientData, stateValues) => {
    let [...cards] = stateValues.cards;
    let [...loadedCards] = stateValues.loadedCards;
    let [...checkedElement] = element;
    cards.forEach(card => {
        let { ...obj } = card;
        obj.TRANSFERSMSNOTIFICATION = 0;
        obj.TRANSFERSMSNOTIFICATIONFEE = 0;
        let checkedElementIndex = checkedElement.findIndex(item => item.ACCOUNTCARDID === card.ACCOUNTCARDID);
        if (checkedElementIndex !== -1) {
            obj.TRANSFERSMSNOTIFICATION = 1;
            obj.TRANSFERSMSNOTIFICATIONFEE = loadClientData.DETAILS.CLIENTSMSCOST;
        }

        let cardIndex = cards.findIndex(item => item.ACCOUNTCARDID === card.ACCOUNTCARDID);
        cards[cardIndex] = obj;

        let isAlreadyExist = loadedCards.some(item => item.ACCOUNTCARDID === card.ACCOUNTCARDID);
        if (!isAlreadyExist) {
            loadedCards.push(card);
        }
        let loadedCardIndex = loadedCards.findIndex(item => item.ACCOUNTCARDID === card.ACCOUNTCARDID);
        loadedCards[loadedCardIndex] = obj;
    })
    let totalSMSNotificationFee = loadedCards.reduce((sum, current) => sum + parseFloat(current.TRANSFERSMSNOTIFICATIONFEE), 0);
    return {
        cards,
        loadedCards,
        totalSMSNotificationFee
    }
}
