import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyFetchNotice, verifyHideNotice } from './thunk';
import { IconButton, Popover, List, Divider, ListItemAvatar, ListItemText, ListItemButton, Typography } from '@mui/material';
import { theme } from '../common/components/base/theme/ThemeProvider';
import { Notifications } from '@mui/icons-material';
import ReactHtmlParser from 'html-react-parser';
import Badge from '@mui/material/Badge';
import { makeStyles } from '@mui/styles';
import NewsIcon from '../news/components/NewsIcon';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    cursor: {
        cursor: 'pointer'
    },
    paper: {
        width: 400
    },
    text: {
        padding: 10
    }
});

const Notices = () => {
    const notices = useSelector(state => state.notices.noticeList.data);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(verifyFetchNotice());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const noticeHide = (messageID) => {
        dispatch(verifyHideNotice(messageID))
            .then(response => {
                if (response.type === "verify/notice_hide/fulfilled") {
                    handleClose();
                    dispatch(verifyFetchNotice());
                }
            })
            .catch(err => {
                throw err;
            });
        navigate('/news', { state: { message_id: messageID } });
    };

    const classes = useStyles();

    return (
        <div>
            <IconButton color="inherit" fontFamily='BentonSansPro_Regular' aria-describedby={id} variant="contained" onClick={handleClick}>
                <Badge badgeContent={notices && notices.length} color="primary">
                    <Notifications />
                </Badge>
            </IconButton>
            < Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={{ top: 25, left: 25 }}
                classes={{ paper: classes.paper }}
            >
                {notices && notices.length > 0 ?
                    <List sx={{ width: '100%', maxWidth: 400, bgcolor: theme.palette.secondary.light, padding: 0 }}>
                        {notices && notices.length > 0 && notices.map(x => {
                            return (
                                <div key={x.NEWSMESSAGEID}>
                                    <ListItemButton className={classes.cursor} onClick={() => noticeHide(x.NEWSMESSAGEID)}>
                                        <ListItemAvatar>
                                            <NewsIcon iconType={x.NEWSMESSAGETYPE} />
                                        </ListItemAvatar>
                                        <ListItemText primary={x.TITLE} secondary={ReactHtmlParser(x.BODY)} />
                                    </ListItemButton>
                                    <Divider />
                                </div>
                            );
                        })}

                    </List>
                    :
                    <Typography className={classes.text} fontFamily='BentonSansPro_Regular'>No record found.</Typography>
                }
            </Popover>
        </div >
    );
};

export default Notices;
