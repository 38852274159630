import React from 'react';
import Dialog from '../../common/components/base/dialog/Dialog';
import { closeDialog } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from './LoginForm';
import PasswordReminderForm from './PasswordReminderForm';
import MfaForm from "./MfaForm";
import SendEmailMfaForm from "./SendEmailMfaForm";

export default function Login() {
    const dispatch = useDispatch();
    const open = useSelector(state => state.login.open);
    const loginPageState = useSelector(state => state.login.loginPageState);

    const pageStateTitles = {
        login: "PAYCARD LOGIN",
        passwordReminder: "FORGOT PASSWORD", // NOSONAR
        mfaSetupRequired: "SETUP MFA",
        mfaChallengeRequired: "ENTER MFA CODE"
    };

    const title = pageStateTitles[loginPageState] || "DEFAULT TITLE";

    const handleClose = () => {
        dispatch(closeDialog());
    }

    const handleOk = () => {
        dispatch(closeDialog());
    }

    return (
        <Dialog
            data-testid="login-component"
            open={open}
            dialogTitle={title}
            dialogTitleTextAlign="center"
            cancelButtonText="Cancel"
            okButtonText="Ok"
            onClose={handleClose}
            handleOk={handleOk}
            showDialogTitle={true}
            showDialogActions={false}
            scroll="paper"
        >
            {loginPageState === "login" && <LoginForm />}
            {loginPageState === "passwordReminder" && <PasswordReminderForm />}
            {loginPageState === "mfaSetupRequired" && <SendEmailMfaForm />}
            {loginPageState === "mfaChallengeRequired" && <MfaForm />}
        </Dialog>
    );
}
