import { createAsyncThunk } from '@reduxjs/toolkit';
import { statementList } from './service';

export const getPrivacyStatement = createAsyncThunk(
    'privacyStatement',
    async (params, { rejectWithValue }) => {
        try {
            const response = await statementList();
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
)
