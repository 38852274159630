import React, { useEffect } from 'react';
import store from './store';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import rg4js from 'raygun4js';
import globalVariables from '../common/constants/globalVariables';

import MyRoutes from './MyRoutes';
import Footer from '../common/components/Footer';
import Header from '../common/components/Header';
import Breadcrumbs from '../common/components/base/breadcrumbs';
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

const cache = createCache({
    key: 'css',
    nonce: globalVariables.RANDOM_NONCE,
    prepend: true,
});

function App() {
    const beforeSend = function (payload) {
        let errorMessage = payload.Details.Error.Message;
        let stackTrace = payload.Details.Error.StackTrace || [];
        // Reference:
        // https://raygun.com/documentation/language-guides/javascript/crash-reporting/troubleshooting/#limitations-of-stack-trace-data
        if (stackTrace.length <= 1) {
            // For generic errors, it may have 1 stacktrace that is raygun's wrapper
            // Therefore, use the keyword 'Script error' to detect generic errors 
            if (errorMessage.startsWith('Script error')) {
                return false;
            }

            // For other errors (Timeouts, rejections, etc...) use the trace data to check if the error is from 3rd party
            let lastTrace = stackTrace[0];
            if (!lastTrace || !lastTrace.ColumnNumber || !lastTrace.LineNumber) {
                return false;
            }
        }
        return payload;
    }

    useEffect(() => {
        rg4js('apiKey', globalVariables.RAYGUN_API_KEY);
        rg4js('enableCrashReporting', true);
        rg4js('onBeforeSend', beforeSend);
        rg4js('options', {
            ignore3rdPartyErrors: true
        });
    }, []);

    return (
        <Provider store={store}>
            <CacheProvider value={cache}>
                <SnackbarProvider maxSnack={3}>
                    <Router>
                        <Header />
                        <Breadcrumbs />
                        <MyRoutes />
                        <Footer />
                    </Router>
                </SnackbarProvider>
            </CacheProvider>
        </Provider>
    );
}

export default App;
