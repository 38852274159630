import React from 'react';
import {
    RadioGroup,
    FormControlLabel,
    Box
} from "@mui/material";
import Text from '../../../common/components/base/text/Text';
import CheckRadio from '../../../common/components/base/checkradio/CheckRadio';
import SearchBar from '../../../common/components/base/search/Search';
import { useSelector, useDispatch } from 'react-redux';
import { changeCardLoadToFilter } from '../slice';
import { searchCardLoadTo } from '../thunk';

const filterTopics = [
    {
        name: "Card Number",
        id: "cardNumber"
    },
    {
        name: "Card Holder",
        id: "cardHolder"
    },
    {
        name: "ID/Passport Number",
        id: "idNumber"
    },
    {
        name: "Card Expiry Date",
        id: "accountCardExpiryDate"
    },
    {
        name: "Reference Number",
        id: "accountCardReference"
    }
]

export default function LoadFundsToSearchFilter() {
    const filter = useSelector(state => state.loadFunds.cardLoadsTo.filter);

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "searchField")
            dispatch(changeCardLoadToFilter({ searchField: value, orderByField: value }));
        if (name === "searchString")
            dispatch(changeCardLoadToFilter({ searchString: value }));
    }

    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(searchCardLoadTo({ ...filter }));
    }

    return (
        <Box sx={{ mt: 6 }}>
            <Text light>
                FIND
            </Text>
            <Box sx={{ mt: 1 }}>
                <SearchBar
                    onSubmit={onSubmit}
                    onChange={handleChange}
                    name="searchString"
                    value={filter.query}
                    placeholder="Enter your search here" />
                <Box sx={{ pt: 2 }}>
                    <Text light>(LEAVE BLANK TO SHOW ALL)</Text>
                </Box>
                <hr />
                <Box sx={{ display: "flex", mt: 3 }}>
                    <Box>
                        <Text light>SEARCH BY</Text>
                        <RadioGroup
                            row
                            onChange={handleChange}
                            name="searchField"
                        >
                            {
                                filterTopics.map((topic) => (
                                    <FormControlLabel
                                        key={topic.id + '-filter-topic'}
                                        value={topic.id}
                                        control={<CheckRadio color="secondary"
                                            checked={filter?.searchField === topic.id} />}
                                        label={topic.name}
                                    />
                                ))
                            }
                        </RadioGroup>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
