import React from 'react';
import {
    Box
} from "@mui/material";
import SearchLoadFrom from './SearchLoadFrom';
import LoadFundsFromSearchFilter from './LoadFundsFromSearchFilter';
import Button from '../../../common/components/base/button/Button';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export default function LoadFundsAnotherCard() {
    const selectedLoadFrom = useSelector(state => state.loadFunds.selectedLoadFrom);

    const navigate = useNavigate();

    const handleContinue = () => {
        let params = { "accountFrom": true, transferFromAccountId: selectedLoadFrom.ACCOUNTCARDID };
        navigate({
            pathname: "/dashboard/load-funds-from/to",
            search: `?${createSearchParams(params)}`
        });
    }

    return (
        <Box sx={{ mt: 2 }}>
            <LoadFundsFromSearchFilter />
            <SearchLoadFrom />
            <Box sx={{ mt: 3 }}>
                <Button
                    onClick={handleContinue}
                    size="medium"
                    variant="contained"
                    disabled={_.isEmpty(selectedLoadFrom)}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
};
