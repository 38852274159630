import { CancelOutlined } from '@mui/icons-material';
import { Card, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import Title from '../../common/components/base/title/Title';
import themeConstants from '../../common/constants/Theme';
import { formatCurrency } from '../../common/utils/helper';

const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            backgroundImage: `linear-gradient(to right, ${themeConstants.ORANGE_DARK}, ${themeConstants.ORANGE_LIGHT}) !important`,
            textAlign: 'center',
        },
        table: {
            padding: '20px',
            width: 'auto !important',
            '& td': {
                color: '#FFF'
            },
            '& th': {
                color: '#FFF',
                borderWidth: '2px'
            }
        }
    }),
);

const CardOrdersPending = ({ cardOrders = [], onCancelOrder = () => {} }) => {
    const classes = useStyles();
    const subTitle = !cardOrders.length ? 'You currently have no awaiting card orders.' : '';
    return (
        <Card className={classes.card}>
            <Title
                title={'Card orders waiting to be processed'}
                subtitle={subTitle}
                sxTitle={{ color: '#FFF', fontSize: '35px' }}
                sxSubtitle={{ color: '#FFF' }}
            ></Title>
            {cardOrders.length ? (
                <TableContainer className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    USER
                                </TableCell>
                                <TableCell>
                                    DATE
                                </TableCell>
                                <TableCell>
                                    QUANTITY
                                </TableCell>
                                <TableCell>
                                    COST
                                </TableCell>
                                <TableCell>
                                    STATUS
                                </TableCell>
                                <TableCell>
                                    FIRST ORDER?
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cardOrders.map((cardOrder, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {cardOrder.ACCOUNTFIRSTNAME} {cardOrder.ACCOUNTLASTNAME}
                                        </TableCell>
                                        <TableCell>
                                            {cardOrder.CARDORDERDATE}
                                        </TableCell>
                                        <TableCell>
                                            {cardOrder.CARDORDERQUANTITY}
                                        </TableCell>
                                        <TableCell>
                                            {formatCurrency(cardOrder.CARDORDERCOST)}
                                        </TableCell>
                                        <TableCell>
                                            {cardOrder.CARDORDERSTATUS}
                                        </TableCell>
                                        <TableCell>
                                            {cardOrder.FIRSTORDER ? 'Yes' : 'No'}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Cancel Order" arrow>
                                                <IconButton variant="contained"
                                                    size="small" sx={{ fontSize: 10, height: 30, width: 30, color: red[500] }}
                                                    onClick={() => onCancelOrder(cardOrder.CARDORDERID)}>
                                                    <CancelOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (<></>)}
        </Card>
    );
};

export default CardOrdersPending;
