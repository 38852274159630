import { createAsyncThunk } from "@reduxjs/toolkit";
import { Buffer } from 'buffer';
import profileHistoryService from "./service";

global.Buffer = Buffer;

export const fetchStatements = createAsyncThunk(
    "profileHistory/fetchStatements",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await profileHistoryService.fetchStatements(filters);
            return response.data;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchStatementsDocumentXLS = createAsyncThunk(
    "profileHistory/fetchStatementsDocumentXLS",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await profileHistoryService.fetchStatementDocumentXLS(filters);
            const docInBase64 = new Buffer.from(response.data).toString('base64');
            return 'data:application/vnd.ms-excel;base64,' + docInBase64;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchStatementsDocumentPDF = createAsyncThunk(
    "profileHistory/fetchStatementsDocumentPDF",
    async (filters, { rejectWithValue }) => {
        try {
            let response = await profileHistoryService.fetchStatementDocumentPDF(filters);
            const pdfInBase64 = new Buffer.from(response.data).toString('base64');
            return 'data:application/pdf;base64,' + pdfInBase64;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);
