import * as regex from '../../../common/utils/regex';
export const MIN_ID_LENGTH = 6;
export const MAX_ID_LENGTH = 20;

export const isFirstNameError = (name) => {
    if (!name || !regex.REGEX_LETTER.test(name)) {
        return true;
    }
    return false;
};

export const isSurNameError = (name) => {
    if (!name || !regex.REGEX_LETTER.test(name)) {
        return true;
    }
    return false;
};

export const isIdError = (idNumber) => {
    if (!idNumber || idNumber.length < MIN_ID_LENGTH || idNumber.length > MAX_ID_LENGTH) {
        return true;
    }
    return false;
};


export const isStopReasonError = (stopReason) => {
    if (!stopReason) {
        return true;
    }
    return false;
};

const phoneTipItems = [
    "Only digits allowed",
    "Must be 10 or 11 characters long",
    "10 character phone must start with 0",
    "11 character phone must start with 27",
    "First 3 digits must not be all zeros"
]

export const phoneNumberTip = (
    <ul style={{paddingLeft: "5px"}}>
        {
            phoneTipItems.map((item, i) => (
                <li style={{margin: "5px"}} key={`${i}-phone-tip`}>{item}</li>
            ))
        }
    </ul>
)
