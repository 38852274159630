import { fetchStatements, fetchStatementsDocumentPDF, fetchStatementsDocumentXLS } from "./thunk";
import ReduxStatus from "../../common/constants/ReduxStatus";

const profileHistoryActions = {
    statementFilterUpdated: (state, action) => {
        state.statement.filter = action.payload;
    },
    profileStatementViewChanged: (state, action) => {
        state.statement.view = action.payload;
    },
    loadingStatementsErrorMessageShown: (state, action) => {
        state.statement.errorMessageShown = action.payload;
    },
    statementDownloadStatusUpdated: (state, action) => {
        state.docDownload.downloaded = action.payload;
    },
    statementDownloadRequested: (state, action) => {
        state.docDownload.shouldDownload = action.payload;
    },
    statementLoadErrorMessageShown: (state, action) => {
        state.statement.errorMessageShown = action.payload;
    },
    docDownloadErrorMessageShown: (state, action) => {
        state.docDownload.errorMessageShown = action.payload;
    },
    pdfLoadErrorMessageShown: (state, action) => {
        state.pdf.errorMessageShown = action.payload;
    }
}

export default profileHistoryActions;

export const extraReducers = {
    [fetchStatements.pending]: (state, action) => {
        state.statement.status = ReduxStatus.loading;
        state.statement.errorMessageShown = false;
    },
    [fetchStatements.fulfilled]: (state, action) => {
        state.statement.data = action.payload;
        state.statement.status = ReduxStatus.loaded;
    },
    [fetchStatements.rejected]: (state, action) => {
        state.statement.status = ReduxStatus.loadFailed;
        state.statement.errorMessages = ["Error loading statements"];
    },
    [fetchStatementsDocumentPDF.pending]: (state, action) => {
        state.pdf.status = ReduxStatus.loading;
    },
    [fetchStatementsDocumentPDF.fulfilled]: (state, action) => {
        state.pdf.data = action.payload;
        state.pdf.status = ReduxStatus.loaded;
    },
    [fetchStatementsDocumentPDF.rejected]: (state, action) => {
        state.pdf.status = ReduxStatus.loadFailed;
        state.pdf.errorMessages = ["Error loading PDF statement"]
    },
    [fetchStatementsDocumentXLS.pending]: (state, action) => {
        state.docDownload.status = ReduxStatus.loading;
        state.docDownload.errorMessageShown = false;
        state.docDownload.downloaded = false;
    },
    [fetchStatementsDocumentXLS.fulfilled]: (state, action) => {
        state.docDownload.data = action.payload;
        state.docDownload.status = ReduxStatus.loaded;
    },
    [fetchStatementsDocumentXLS.rejected]: (state, action) => {
        state.docDownload.status = ReduxStatus.loadFailed;
        state.docDownload.errorMessages = ["Error Downloading Statement report"]
    }
}
