import Http from "../../common/utils/Http";

export const getCurrentBalanceOfCards = async () => {
    try {
        let response = await Http.get('pr/reports/downloadCardBalance', {
            responseType: 'arraybuffer',
            responseEncoding: 'binary',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/excel',
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
}
