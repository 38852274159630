import React from 'react';
import ManageSearch from './searchManageCard/ManageSearch';
import Title from "../../common/components/base/title/Title";
import { Container } from '@mui/material';

const ManageCard = () => {
    const subtitles = [` Search for a card number, card holder name or ID/passport number and`,
        "click on the link to update card details or stop/enable the card"]

    return (
        <Container maxWidth="lg">
            <Title title="Manage cards" subtitleArray={subtitles} sxTitle={{ mb: 4 }} />
            <ManageSearch />
        </Container>
    )
}
export default ManageCard;
