import { createSlice } from '@reduxjs/toolkit';
import { getFaqs } from './thunk';
import ReduxStatus from '../common/constants/ReduxStatus';

const initialState = {
    status: ReduxStatus.empty,
    data: [],
    errorMessages: [],
};

export const faqSlice = createSlice({
    name: 'faqs',
    initialState,
    reducers: {},
    extraReducers: {
        [getFaqs.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [getFaqs.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [getFaqs.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        },
    }
});

export default faqSlice.reducer;
