import React from 'react';
import {
    RadioGroup,
    FormControlLabel,
    Box
} from "@mui/material";
import CheckRadio from '../../../common/components/base/checkradio/CheckRadio';
import Text from '../../../common/components/base/text/Text';
import { useSelector, useDispatch } from 'react-redux';
import { changeLoadFundsFromOption } from '../slice';

export default function LoadFundsFromOption() {
    const loadFundsFromOption = useSelector(state => state.loadFunds.loadFundsFromOption);

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            dispatch(changeLoadFundsFromOption(value));
        }
    }

    return (
        <Box sx={{ mt: 8 }}>
            <Text color='primary.light'>LOAD FUNDS FROM</Text>
            <RadioGroup
                row
                onChange={handleChange}
                name="loadFundsFromOption">
                <FormControlLabel
                    value="profile"
                    control={<CheckRadio color="secondary" checked={loadFundsFromOption === "profile"} />}
                    label="Your Profile"
                />
                <FormControlLabel
                    value="anotherCard"
                    control={<CheckRadio color="secondary" checked={loadFundsFromOption === "anotherCard"} />}
                    label="Another Card"
                />
            </RadioGroup>
        </Box>
    );
};
