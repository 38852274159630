import React from "react";
import Text from "../../../../common/components/base/text";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControlLabel, Typography } from "@mui/material";
import CheckRadio from "../../../../common/components/base/checkradio";
import Box from "@mui/material/Box";
import { cardStatementActions } from "../../slice";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchCardStatement,
	fetchCardStatementDocumentPDF,
	fetchCardStatementDocumentXLS,
} from "../../thunk";
import { CARD_ID_FIELD } from "../../common/utils";

const periods = [
	{
		id: 30,
		name: "30 Days",
	},
	{
		id: 60,
		name: "60 Days",
	},
	{
		id: 90,
		name: "90 Days",
	},
];

export default function CardStatementHistoryPeriod() {
	const dispatch = useDispatch();

	const { period, data } = useSelector(
		(state) => state.cardStatement.statement
	);

	const handlePeriodChange = (event) => {
		const { value, checked } = event.target;
		let filters = { [CARD_ID_FIELD]: data?.ACCOUNTCARDID, days: value };
		if (checked) {
			if (data?.ACCOUNTCARDID) {
				dispatch(fetchCardStatement(filters));
				dispatch(fetchCardStatementDocumentPDF(filters));
				dispatch(fetchCardStatementDocumentXLS(filters));
			}
			dispatch(cardStatementActions.cardStatementPeriodChanged(Number(value)));
		}
	};

	return (
		<>
			<Box sx={{ ml: 2, mb: -2 }}>
				<Typography
					variant="h6"
					sx={{ color: "#3c4b6c", fontSize: 18, fontWeight: 400, mb: 2 }}
				>
					STATEMENT HISTORY PERIOD
				</Typography>
				<RadioGroup row onChange={handlePeriodChange} name="period">
					{periods.map((item) => (
						<FormControlLabel
							key={item.id + "-history-period"}
							value={item.id}
							control={
								<CheckRadio color="secondary" checked={period === item.id} />
							}
							label={<Text>{item.name}</Text>}
						/>
					))}
				</RadioGroup>
			</Box>
		</>
	);
}
