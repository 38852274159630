import { createSlice } from '@reduxjs/toolkit';
import { fetchAllocatedCards, fetchUnAllocatedCards, fetchCardsDocumentXLS, fetchCardsDocumentPDF } from './thunk';
import ReduxStatus from '../../common/constants/ReduxStatus';
import { DateTime } from "luxon";

const initialState = {
    allocatedCards: {
        data: [],
        totalRecords: 0,
        metadata: {
            OFFSET: 0,
            LIMIT: 10,
            TOTAL: 0,
        },
        currentPage: 1,
        status: ReduxStatus.empty,
        errorMessages: [],
        errorMessageShown: false,
        filter: {
            startDate: DateTime.local().minus({ months: 3 }).toFormat('yyyy-MM-dd'),
            endDate: DateTime.now().toFormat('yyyy-MM-dd'),
            offset: 0
        }
    },
    unAllocatedCards: {
        data: [],
        totalRecords: 0,
        metadata: {
            OFFSET: 0,
            LIMIT: 10,
            TOTAL: 0,
        },
        currentPage: 1,
        status: ReduxStatus.empty,
        errorMessages: [],
        errorMessageShown: false,
        filter: {
            offset: 0
        }
    },
    wholeView: {
        view: "list",
    },
    docDownload: {
        data: null,
        status: ReduxStatus.empty,
        shouldDownload: false,
        downloaded: true,
        errorMessages: [],
        errorMessageShown: false
    },
    pdf: {
        data: null,
        status: ReduxStatus.empty,
        errorMessage: [],
    },
};

export const reportCardListSlice = createSlice({
    name: 'reportCardList',
    initialState,
    reducers: {
        reset: () => initialState,
        cardFilterChanged: (state, action) => {
            state.allocatedCards.filter = { ...state.allocatedCards.filter, ...action.payload }
        },
        cardsDownloadStatusUpdated: (state, action) => {
            state.docDownload.downloaded = action.payload;
        },
        cardsDownloadRequested: (state, action) => {
            state.docDownload.shouldDownload = action.payload;
        },
        cardsPDFViewChanged: (state, action) => {
            state.wholeView.view = action.payload;
        },

    },
    extraReducers: {
        [fetchAllocatedCards.pending]: (state, action) => {
            if (action.meta.arg && !action.meta.arg.initialLoad) {
                state.allocatedCards.status = ReduxStatus.loading;
            }

        },
        [fetchAllocatedCards.fulfilled]: (state, action) => {
            state.allocatedCards.data = action.payload["ALLOCATEDCARDS"];
            state.allocatedCards.metadata = action.payload["METADATA"];

            if (action.payload["METADATA"]) {
                state.allocatedCards.totalRecords =
                    action.meta.arg && action.meta.arg.initialLoad
                        ? action.payload["METADATA"]["TOTAL"]
                        : state.allocatedCards.totalRecords
            }

            state.allocatedCards.status = ReduxStatus.loaded;
        },
        [fetchAllocatedCards.rejected]: (state, action) => {
            state.allocatedCards.errorMessages = action.payload;
            state.allocatedCards.status = ReduxStatus.loadFailed;
        },
        //
        [fetchUnAllocatedCards.pending]: (state, action) => {
            if (action.meta.arg && !action.meta.arg.initialLoad) {
                state.unAllocatedCards.status = ReduxStatus.loading;
            }

        },
        [fetchUnAllocatedCards.fulfilled]: (state, action) => {
            state.unAllocatedCards.data = action.payload["UNALLOCATEDCARDS"];
            state.unAllocatedCards.metadata = action.payload["METADATA"];

            if (action.payload["METADATA"]) {
                state.unAllocatedCards.totalRecords =
                    action.meta.arg && action.meta.arg.initialLoad
                        ? action.payload["METADATA"]["TOTAL"]
                        : state.unAllocatedCards.totalRecords
            }

            state.unAllocatedCards.status = ReduxStatus.loaded;
        },
        [fetchUnAllocatedCards.rejected]: (state, action) => {
            state.unAllocatedCards.errorMessages = action.payload;
            state.unAllocatedCards.status = ReduxStatus.loadFailed;
        },
        //
        [fetchCardsDocumentXLS.pending]: (state, action) => {
            state.docDownload.status = ReduxStatus.loading;
            state.docDownload.downloaded = false;
        },
        [fetchCardsDocumentXLS.fulfilled]: (state, action) => {
            state.docDownload.data = action.payload;
            state.docDownload.status = ReduxStatus.loaded;
        },
        [fetchCardsDocumentXLS.rejected]: (state, action) => {
            state.docDownload.status = ReduxStatus.loadFailed;
            state.docDownload.errorMessages = ["Error Downloading Statement report"]
        },
        //
        [fetchCardsDocumentPDF.pending]: (state, action) => {
            state.pdf.status = ReduxStatus.loading;
        },
        [fetchCardsDocumentPDF.fulfilled]: (state, action) => {
            state.pdf.data = action.payload;
            state.pdf.status = ReduxStatus.loaded;
        },
        [fetchCardsDocumentPDF.rejected]: (state, action) => {
            state.pdf.status = ReduxStatus.loadFailed;
            state.pdf.errorMessages = ["Error loading PDF statement"]
        },


    }
});

export const reportCardListActions = reportCardListSlice.actions
export default reportCardListSlice.reducer;
