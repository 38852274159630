import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllocatedCards, getScheduledClient, saveTransfer, viewInvoice } from './service';
import { Buffer } from 'buffer';

export const verifyFetchAllocatedCards = createAsyncThunk(
    'verify/allocatedCard_fetch',
    async (params, { rejectWithValue }) => {
        try {
            const response = await fetchAllocatedCards(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const verifyGetScheduledClient = createAsyncThunk(
    'verify/get_scheduled_client',
    async (params, { rejectWithValue }) => {
        try {
            const response = await getScheduledClient(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const verifySaveTransfer = createAsyncThunk(
    'verify/save_transfer',
    async (params, { rejectWithValue }) => {
        try {
            const response = await saveTransfer(params);
            return response.data;
        } catch (error) {
            let errorMessage = error?.response?.headers?.explanation ?? error?.response?.data;
            return rejectWithValue(errorMessage);
        }
    }
)

export const verifyTransferInvoice = createAsyncThunk(
    'verify/view_transfer_invoice',
    async (params, { rejectWithValue }) => {
        try {
            const pdfResponse = await viewInvoice(params);
            global.Buffer = Buffer;
            const pdfInBase64 = new Buffer.from(pdfResponse.data).toString('base64');
            return { pdf: 'data:application/pdf;base64,' + pdfInBase64 };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
