import {createAsyncThunk} from "@reduxjs/toolkit";
import cardAllocationService from "./service";

export const fetchCards = createAsyncThunk(
    "cardAllocation/fetchCards",
    async (filters, {rejectWithValue}) => {
        try {
            let response = await cardAllocationService.fetchCards(filters)
            return response.data;
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchLinkedCards = createAsyncThunk(
    "cardAllocation/fetchLinkedCards",
    async (param, {rejectWithValue}) => {
        try {
            let response = await cardAllocationService.fetchLinkedCards()
            return response.data['LINKEDCARDS'];
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const allocateCards = createAsyncThunk(
    "cardAllocation/allocateCards",
    async (data, {rejectWithValue}) => {
        try {
            let response = await cardAllocationService.allocateCards(data);
            return response.data;
        } catch (error) {
            if(error.response){
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    }
);

export const bearerValidationCards = createAsyncThunk(
    "cardAllocation/bearerValidationCards",
    async (data, {rejectWithValue}) => {
        try {
            let response = await cardAllocationService.bearerValidationCards(data);
            return response.data;
        } catch (error) {
            if(error.response){
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue(error.message);
        }
    }
);
