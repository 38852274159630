import React from 'react';
import Login from './components/Login';
import { useSelector } from 'react-redux';
import Loader from '../common/components/base/loader/Loader';
import ReduxStatus from '../common/constants/ReduxStatus';

const LoginPage = (props) => {
    const status = useSelector(state => state.login.status);
    return (
        <div>
            <Login {...props} />
            {status === ReduxStatus.loading && <Loader open={true} />}
        </div>
    );
};

export default LoginPage;
