import { ExpandMore as MuiExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import themeConstants from '../../common/constants/Theme';

const useStyles = makeStyles((theme) =>
    createStyles({
        accordion: {
            backgroundColor: '#FFFFFF !important',
            marginBottom: '20px',
            padding: '0 15px',
            boxShadow: "none !important",
            '&:before': {
                height: '0 !important'
            },
        },
        accordionSummaryTypography: {
            paddingLeft: '15px',
            color: themeConstants.GREY,
            fontSize: '22px'
        },
    }),
);

const DashboardAccordion = (props) => {
    const classes = useStyles();
    return (
        <Accordion className={classes.accordion} {...props}>
            <AccordionSummary expandIcon={<MuiExpandMore sx={{ color: '#0E70FF' }} />}>
                {props.icon}
                <Typography
                    variant='h5'
                    className={classes.accordionSummaryTypography}
                >
                    {props.label}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </Accordion>
    );
};

export default DashboardAccordion;
